/** @format */
import "./firmlist.css";
import { React, useEffect, useState, useRef } from "react";
import { FaRegFilePdf } from "react-icons/fa";
import { FaFileCsv } from "react-icons/fa";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import axios from "axios";
import Layout from "../../../Layout/Layout";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { CSVLink } from "react-csv";
import { useGlobalContext } from "components/Context";
import ListComponent from "./ListComponent";

//============== Headers for csv file=======================//

const headers = [
  { label: "Firm Name", key: "firmName" },
  { label: "Contact Name", key: "personName" },
  { label: "Mobile no.", key: "mobileNo" },
  { label: "Email", key: "email" },
  { label: "State", key: "state" },
  { label: "Address", key: "fullAdd" },
  { label: "D.L. No.(1)", key: "dl1" },
  { label: "D.L. No.(2)", key: "dl2" },
  { label: "GSTIN", key: "gst" },
  { label: "FSSAI No.", key: "fssai" },
];

export default function FirmList() {
  const pageSize = 10; // Set the number of items per page;

  let { PaginationControlled, page, setTotalPages } = useGlobalContext();

  const [Firm, setFirm] = useState([]);

  useEffect(() => {
    getFirms();
  }, [page]);

  // const getFirms = async () => {
  //   try {
  //     // Setting token for headers
  //     let token = localStorage.getItem("x-api-key");

  //     const response = await axios.get("api/user/firm/getAllFirmDetails", {
  //       headers: {
  //         "Content-Type": "application/json",
  //         "x-api-key": token,  "x-api-plan": "SaaS"
  //       },
  //       // params: { page: page, pageSize: pageSize },
  //     });

  //     // Handle the response data

  //     const result = response.data;

  //     console.log(result);

  //     if ( result && result.data) {
  //       setFirm(result.data);
  //       // setCount(result.totalItems)
  //     } else {
  //       setFirm([]);
  //     }
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }
  // };

  // code for filtering firm according to search bar;

  const getFirms = async () => {
    try {
      // Setting token for headers
      let token = localStorage.getItem("x-api-key");

      const response = await axios.get("api/user/firm/getAllFirmDetails", {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": token,
          "x-api-plan": "SaaS",
        },
      });

      // Handle the response data
      const result = response.data;

      if (result && result.status === 200 && result.data) {
        setFirm(result.data);
        // setCount(result.totalItems);
      } else {
        // Check for specific error messages or status codes
        if (result && result.message) {
          console.error("Error:", result.message);
          // Handle specific error messages
        } else {
          console.error("Unexpected response structure:", result);
          // Handle unexpected response structure
        }

        // Reset firm data or take other appropriate actions
        setFirm([]);
      }
    } catch (error) {
      // Handle network errors or other exceptions
      console.error("Error:", error.message);

      // Reset firm data or take other appropriate actions
      setFirm([]);
    }
  };

  const [filteredList, setFilteredList] = new useState(Firm);
  const [filter, setFilter] = useState(false);
  const filterFirmReportSearch = (event) => {
    setFilter(true);
    let updatedList = [...Firm];
    updatedList = updatedList.filter((item) => {
      return (
        item.firmName
          .toLowerCase()
          .indexOf(event.target.value.toLowerCase()) !== -1 ||
        item.personName
          .toLowerCase()
          .indexOf(event.target.value.toLowerCase()) !== -1
      );
    });
    setFilteredList(updatedList);
  };

  //==========  converting data to pdf===========//

  const exportPDF = () => {
    const unit = "pt";
    const size = "A3"; // Use A1, A2, A3 or A4

    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 350;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Firm List";
    const headers = [
      [
        "S.No",
        "Firm Name",
        "Contact Name",
        "Mobile no.",
        "Email",
        "State",
        "Address",
        "D.L. No.(1)",
        "D.L. No.(2)",
        "GSTIN",
        "FSSAI No.",
      ],
    ];
    // Add an empty row with spacer cells to create a gap
    // const spacerRow = Array(headers[0].length).fill(""); // Create an empty row with the same number of cells as headers
    // headers.push(spacerRow);
    // console.log(filteredFirmList);
    let data = displayedData.map((elt, index) => [
      index + 1,
      elt.firmName,
      elt.personName,
      elt.mobileNo,
      elt.email,
      elt.state,
      elt.fullAdd,
      elt.dl1,
      elt.dl2,
      elt.gst,
      elt.fssai,
    ]);
    // Adjust the cell padding to create a gap on the X-axis;
    let content = {
      startY: 50,
      head: headers,
      body: data,
      // theme: "grid", // You can choose a theme for styling
      styles: {
        cellPadding: 10, // Adjust this value to set the gap on the X-axis
      },
    };

    doc.text(title, marginLeft, 40);
    autoTable(doc, content);
    doc.save("Firm List.pdf");
  };

  const csvDownloadRef = useRef(null);

  const convertToCsv = () => {
    setTimeout(() => {
      csvDownloadRef.current.link.click();
    }, 500);
  };

  // for delete Firm we are making this function

  function Promptdelete(id) {
    var id = id;
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: false,
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    })
      .then((result) => {
        if (result.isConfirmed) {
          const deleteep = async (id) => {
            //getting token and setting in headers
            let token = localStorage.getItem("x-api-key");
            await axios
              .delete(`api/user/firm/deleteFirmDetails/${id}`, {
                headers: {
                  "Content-Type": "application/json",
                  "x-api-key": token,
                  "x-api-plan": "SaaS",
                },
              })
              .then((response) => {
                console.log(220, response.data);
                Swal.fire("Deleted!", "Your file has been deleted.", "success");
                window.location.reload();
              })
              .catch((error) => {
                console.log(222, error.message);
              });
          };
          deleteep(id);
        }
      })
      .catch((error) => {
        console.error(error.result.data.message);
      });
  }

  // start

  // Your data (Firm) and other variables

  // Calculate the start and end index for the current page

  const startIndex = (page - 1) * pageSize;

  const endIndex = startIndex + pageSize;

  // Filter the data based on the current page;

  const displayedData = (
    filter
      ? filteredList.slice(startIndex, endIndex)
      : Firm.slice(startIndex, endIndex)
  ).map((item, index) => ({
    ...item,
    displayIndex: (page - 1) * pageSize + index + 1,
  }));

  // Calculate the total number of pages;

  const totalPages = Math.ceil(
    (filter ? filteredList.length : Firm.length) / pageSize
  );

  setTotalPages(totalPages);
  // Handle page change;

  // const handleChange = (event, value) => {
  //   setPage(value);
  // };

  return (
    <Layout heading_1="Firm Name" heading_2="Firm List">
      <div className="main-Firmlist-file-heading">
        <div className="Firmlist-main-subpart">
          <div className="Firmlist-start">
            <div
              className="table-top-Firm-list"
              style={{
                alignItems: "center",
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <div className="show-name-Productlist">
  <label htmlFor="show" style={{ color: "black", fontWeight: "bold" }}>
    Show :{" "}
  </label>
  <select id="show">
    <option>All</option>
  </select>
  <FaRegFilePdf
    style={{ cursor: "pointer", color: "grey", height: "30px", width: "50px" }}
    className="pdf-Firmlist"
    onClick={(event) => {
      exportPDF();
    }}
    id="pdf"
  />
  <CSVLink
    data={filter ? filteredList : Firm}
    headers={headers}
    filename="Firm List.csv"
    className="hidden"
    ref={csvDownloadRef}
    target="_blank"
  />
  <FaFileCsv
    style={{
      cursor: "pointer",
      color: "grey",
      position: "relative",
      height: "30px",
      width: "50px",
    }}
    className="csv-Firmlist"
    onClick={(event) => {
      convertToCsv(event);
    }}
    id="csv"
  />
</div>

              <div className="search-name-Firm-list">
                <input
                  style={{ height: 30 }}
                  onChange={filterFirmReportSearch}
                  placeholder="Search by Contact,Firm Name"
                />
              </div>
            </div>
             <ListComponent displayedData={displayedData} ></ListComponent>
          </div>
        </div>
        <PaginationControlled />
        {/* <div className="pagination">
         <Stack spacing={2}>
           <Typography>Page: {page}</Typography>
           <Pagination count={totalPages} page={page} onChange={handleChange} />
         </Stack>
       </div> */}
      </div>
    </Layout>
  );
}

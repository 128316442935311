import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useParams, useNavigate } from "react-router-dom";
import "./Marketplace.css";

import Layout from "../Layout/Layout";

const Marketplace = () => {

    const navigate = useNavigate();
    function importProducts() {
        navigate("/user/productsImport")
    };

    return (
        <div style={{ width: "68rem" }}>
            <Layout heading_1="Marketplace" heading_2="Export To Marketplace">
                <div className="upgrade_plan_marketplace" style={{ overflow: "hidden" }}>
                    <div className="text-upgrade_marketplace">
                        <h4>Hegan Marketplace - Your B2B Pharma Platform</h4>
                    </div>
                    <div className="paragraph-1_marketplace">
                        <ul>
                        <h5>Why Should You Move Your Inventory To Our Marketplace?</h5>
                        <li>
                            To Expand & Grow Nation Wide.
                        </li>
                        <li>
                            To Compete With Price & Volume.
                        </li>
                        </ul>
                    </div>
                    <div className="text-upgrade_marketplace">
                        <h4>Feature</h4>
                    </div>
                    <div className="list-rate_marketplace">
                        <ul>
                            <li>
                                <h6>Single Click Transfer :</h6>
                                <p>With Single Click Button You Can Transfer All Available Stock To Marketplace.</p>
                            </li>
                            <li>
                                <h6>Easy Update: </h6>
                                <p>With Single Click You Can Update Your Daily Available Stock.</p>
                            </li>
                            <li>
                                <h6>Identity Hide: </h6>
                                <p>
                                    If You Want To Hide Your Identity As Seller. A Unique Code Will Be Shown On Marketplace.
                                </p>
                           </li>
                            <li>
                                <h6>Settlement:</h6>
                                <p>All Payment Settlement Is Done Within 2 days Once Order Is Delivered.</p>
                            </li>
                            <li>
                                <h6>Seamless Process: </h6>
                                <p>We Take Care Of Order Processing To Order Delivery. Settlement: All Payment Settlement Is Done Within 2 days Once Order Is Delivered.</p>
                            </li>
                        </ul>
                    </div>
                    <div class="proceed-button_marketplace">
                        <button onClick={importProducts}>IMPORT</button>
                    </div>
                </div>
            </Layout>
        </div>
    );
};

export default Marketplace;

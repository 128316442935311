import { React, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "../ProductUpdate/productupdate.css";
import Swal from "sweetalert2";
import axios from 'axios';
import Layout from "../../Layout/Layout";


const ProductUpdate = () => {

  const [formData, setFormData] = useState({});

  useEffect(() => {
    getProductDetails();
  }, []);


  // on change function;

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(98, name, value)
    setFormData({ ...formData, [name]: value });
  };

  const params = useParams();

  const navigate = useNavigate();

  const getProductDetails = async () => {
    try {
      // let token = JSON.parse(localStorage.getItem("user"));
      //token
      let token = localStorage.getItem('x-api-key');
      let response = await axios.get(`api/user/addProductVertTwo/getProduct/${params.id}`, {
        // method: "get",
        headers: {
          "Content-Type": "application/json",
          'x-api-key': token
        },
      }
      );
      let result = response.data;
      let res = result.data;
      console.log(res);
      setFormData({ formulation: res.formulation, hsn_sac_code: res.hsn_sac_code, brand: res.brand, unitPacking: res.unitPacking, productName: res.productName, category: res.category, description: res.description })

    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  const handleSubmit = async () => {
    try {

      //token

      let token = localStorage.getItem('x-api-key');

      let response = await axios.put(`api/user/addProductVertTwo/updateProduct/${params.id}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            'x-api-key': token
          },
        }
      );

      let result = response.data;
      if (result.message) {
        Swal.fire(
          'Updated!',
          'Your Product has been Updated.',
          'success'
        )
        navigate("/user/productlist");
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: result.message,
        });
        getProductDetails();
        navigate("/user/productlist")
      };
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  return (
    <Layout heading_1="Product" heading_2="Product Update">
      <div className="container-product-generate">
        <div className="form-box-product-generate">
          <div className="c-form-product-generate">
            <div class="two-columns-product-generate">
              <fieldset>
                <label className="c-form-label"> Product Name</label>
                <input
                  type="text"
                  name="productName"
                  value={formData.productName}
                  onChange={handleChange}
                />
              </fieldset>
              <fieldset>
                <label className="c-form-label">Category</label>
                <input
                  type="text"
                  name="category"
                  value={formData.category}
                  onChange={handleChange}
                />
              </fieldset>
            </div>
            <div className="two-columns-product-generate">
              <fieldset>
                <label className="c-form-label">Product Description</label>
                <textarea
                  type="text"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                />
              </fieldset>
              <fieldset>
                <label className="c-form-label"> Unit Pack</label>
                <input
                  type="text"
                  name="unitPacking"
                  value={formData.unitPacking}
                  onChange={handleChange}
                />
              </fieldset>
            </div>
            <div className="two-columns-product-generate">
              <fieldset>
                <label className="c-form-label">Brand</label>

                <input
                  type="text"
                  name="brand"
                  value={formData.brand}
                  onChange={handleChange}
                />
              </fieldset>
              <fieldset>
                <label className="c-form-label">HSN/SAC Code</label>
                <input
                  type="text"
                  name="hsn_sac_code"
                  value={formData.hsn_sac_code}
                  onChange={handleChange}
                />
              </fieldset>
            </div>
            <div className="two-columns-product-generate">
              <fieldset>
                <label className="c-form-label">Formulation</label>
                <textarea
                  type="text"
                  name="formulation"
                  value={formData.formulation}
                  onChange={handleChange}
                />
              </fieldset>
            </div>
            <button className="button-sumbit-purchase-gen" onClick={handleSubmit}>
              Update
            </button>
          </div>
        </div>
      </div>
    </Layout >
  );
};

export default ProductUpdate;

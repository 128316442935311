import { React, useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import { useParams, useNavigate } from "react-router-dom";
import './productview.css'
import Layout from "../../Layout/Layout";
import axios from 'axios';

const ProductView = () => {

  const [formData, setFormData] = useState({});

  useEffect(() => {
    collectData();
  }, []);

  const params = useParams();

  const collectData = async () => {
    try {

      //token
      let token = localStorage.getItem('x-api-key');
      let response = await axios.get(`api/user/addProductVertTwo/getProduct/${params.id}`, {
        // method: "get",
        headers: {
          "Content-Type": "application/json",
          'x-api-key': token
        },
      }
      );
      let result = response.data
      console.log(result, "data getting from server side")
      let res = result.data;
      setFormData(res);
    } catch (error) {
      console.log(error.response.data.message)
    }
  };

  return (
    <Layout heading_1="Product" heading_2="Product View">
      <div className="container-product-generate">
        <div className="form-box-product-generate">
          <div className="c-form-product-generate">
            <div class="two-columns-product-generate">
              <fieldset>
                <label className="c-form-label"> Product Name</label>
                <input
                  disabled
                  placeholder="Enter Product Name"
                  type="text"
                  value={formData.productName} // Replace field1 with the actual field name
                />
              </fieldset>
              <fieldset>
                <label className="c-form-label">Category</label>
                <input
                  disabled
                  placeholder="Enter Category"
                  type="text"
                  value={formData.category} // Replace field1 with the actual field name
                />
              </fieldset>
            </div>
            <div className="two-columns-product-generate">
              <fieldset>
                <label className="c-form-label">Product Description</label>
                <textarea
                  disabled
                  placeholder="Product Description"
                  type="text"
                  value={formData.description} // Replace field1 with the actual field name
                />
              </fieldset>
              <fieldset>
                <label className="c-form-label"> Unit Pack</label>
                <input
                  disabled
                  placeholder="Enter Unit Packing "
                  type="text"
                  value={formData.unitPacking} // Replace field1 with the actual field name
                />
              </fieldset>
            </div>
            <div className="two-columns-product-generate">
              <fieldset>
                <label className="c-form-label">Brand</label>
                <input
                  disabled
                  placeholder="Enter Brand"
                  type="text"
                  value={formData.brand} // Replace field1 with the actual field name
                />
              </fieldset>
              <fieldset>
                <label className="c-form-label">HSN/SAC Code</label>
                <input
                  disabled
                  placeholder="Enter HSN/SAC Code"
                  type="text"
                  value={formData.hsn_sac_code} // Replace field1 with the actual field name
                />
              </fieldset>
            </div>
            <div className="two-columns-product-generate">
              <fieldset>
                <label className="c-form-label">Formulation</label>
                <textarea
                  disabled
                  placeholder="Enter Formulation"
                  type="text"
                  value={formData.formulation} // Replace field1 with the actual field name
                />
              </fieldset>
            </div>
          </div>
        </div>
      </div>
    </Layout >
  )
};

export default ProductView;

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
// import "../Firmview/firmview.css";
import "../firmGenerate/firmgenrator.css"
import Nav2 from "../../navbar/Nav2";
import Layout from "../../Layout/Layout";
import axios from "axios";

const FirmView = () => {
  const [firmName, setfirmName] = useState("");
  const [personName, setpersonName] = useState("");
  const [fullAdd, setfullAdd] = useState("");
  const [mobileNo, setmobileNo] = useState("");
  const [email, setEmail] = useState("");
  const [pinCode, setpinCode] = useState("");
  const [state, setState] = useState("");
  const [dl1, setdl1] = useState("");
  const [dl2, setdl2] = useState("");
  const [gst, setgst] = useState("");
  const [fssai, setfssai] = useState("");
  const [error, setError] = useState(false);

  // radio button ke liye
  // registerAs
  const [registerAs, setregisterAs] = useState("");

  //  for bank details
  const [accountName, setAccountName] = useState("");
  const [accountNo, setAccountNumber] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [branch, setBranch] = useState("");
  const params = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    getProductDetails();
  }, []);

  const getProductDetails = async () => {
    try {
      let token = localStorage.getItem('x-api-key');
      const response = await axios.get(`api/user/firm/getOne/${params.id}`, {
        headers: {
          "Content-Type": "application/json",
          'x-api-key': token
        },
      });

      const result = response.data;
      console.log(result, "data getting from server side");

      if (result.data) {
        const res = result.data;
        setfirmName(res.firmName);
        setpersonName(res.personName);
        setfullAdd(res.fullAdd);
        setmobileNo(res.mobileNo);
        setEmail(res.email);
        setpinCode(res.pinCode);
        setState(res.state);
        setdl1(res.dl1);
        setdl2(res.dl2);
        setgst(res.gst);
        setfssai(res.fssai);
        setregisterAs(res.registerAs);
        setAccountName(res.accountName);
        setAccountNumber(res.accountNo);
        setIfsc(res.ifsc);
        setBranch(res.branch);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Layout className="container-1" heading_1="Firm Name" heading_2="Firm View">
      <div className="form-box-firmGen" style={{ overflow: "hidden" }}>
        <div className="c-form-firm-gen-1">
          <div class="two-main">
            <fieldset className="top-row-firmname">
              <label
                id="c-form-required-firmname"
                className="c-form-label-top-label"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Firm Name
              </label>
              <input
                disabled
                type="text"
                className="firmnameinut inputFeild"
                placeholder="Enter Name"
                required="true"
                value={firmName}
              />
            </fieldset>
          </div>
          <div class="two-columns-three">
            <fieldset className="firmgenerate-fieldset-name">
              <label className="c-form-label">Contact Person Name</label>
              <input
                disabled
                type="text"
                className="c-form-input-firm inputFeild"
                required
                placeholder="Enter Contact Person Name"
                value={personName}
              // onChange={(e) => setpersonName(e.target.value)}
              />
            </fieldset>

            <fieldset>
              <label className="c-form-label">Address</label>
              <input
                disabled
                type="text"
                className="c-form-input-firm inputFeild"
                required
                placeholder="Enter Address"
                value={fullAdd}
              // onChange={(e) => setfullAdd(e.target.value)}
              />
            </fieldset>
          </div>
          <div className="two-columns-three">
            <fieldset>
              <label className="c-form-label">Mobile No.</label>
              <input
                disabled
                placeholder="Enter Mobile No."
                type="text"
                className="c-form-input-firm inputFeild"
                required
                value={mobileNo}
              // onChange={(e) => setmobileNo(e.target.value)}
              />
            </fieldset>

            <fieldset>
              <label className="c-form-label">Email Address</label>
              <input
                disabled
                placeholder="Enter Email Address"
                type="email"
                className="c-form-input-firm inputFeild"
                required
                value={email}
              // onChange={(e) => setEmail(e.target.value)}
              />
            </fieldset>
          </div>
          <div className="two-columns-three">
            <fieldset>
              <label className="c-form-label">Pin Code</label>
              <input
                disabled
                placeholder="Enter Pincode"
                type="text"
                className="c-form-input-firm  inputFeild"
                required
                value={pinCode}
              // onChange={(e) => setpinCode(e.target.value)}
              />
            </fieldset>

            <fieldset>
              <label className="c-form-label">State</label>
              <input
                disabled
                className="c-form-select-firm-state_view"
                value={state}
                style={{
                  boxShadow: "0px 5px 5px 0px rgba(0, 0, 0, 0.2)",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  width: "25.7rem",
                  height: "32px",
                  color: "#000"
                }}
              />
            </fieldset>
          </div>

          <label
            className="radiolabel-1"
            style={{ fontSize: "20px", fontWeight: "bold" }}
          >
            {" "}
            Register As
          </label>
          <div className="radiobutton-1">
            <div
              onClick={() => {
                setregisterAs("Manufacturer");
              }}
            >
              <input
                disabled
                className="inputradio-button "
                type="radio"
                value={registerAs}
                checked={registerAs === "Manufacturer"}
              />
              <label> Manufacturer</label>
            </div>

            <div
              onClick={() => {
                setregisterAs("Distributor");
              }}
            >
              <input
                disabled
                className="inputradio-button"
                type="radio"
                value={registerAs}
                checked={registerAs === "Distributor"}
              />
              <label> Distributor </label>
              <br />
            </div>

            <div
              onClick={() => {
                setregisterAs("Wholesaler");
              }}
            >
              <input
                disabled
                className="inputradio-button"
                type="radio"
                value={registerAs}
                checked={registerAs === "Wholesaler"}
              />
              <label> Wholesaler</label>
              <br />
            </div>

            <div
              onClick={() => {
                setregisterAs("Retailer");
              }}
            >
              <input
                disabled
                className="inputradio-button"
                type="radio"
                value={registerAs}
                name="Retailer"
                checked={registerAs === "Retailer"}
              />
              <label> Retailer</label>
              <br />
            </div>

            <div
              onClick={() => {
                setregisterAs("Hospital");
              }}
            >
              <input
                disabled
                className="inputradio-button"
                type="radio"
                value={registerAs}
                checked={registerAs === "Hospital"}
              />
              <label> Hospital</label>
            </div>
          </div>

          <div className="two-columns-three">
            <fieldset>
              <label className="c-form-label">DL. No. (1)</label>
              <input
                disabled
                placeholder="Enter DL No.(1)"
                type="text"
                className="c-form-input-firm inputFeild"
                required
                value={dl1}
              // onChange={(e) => setdl1(e.target.value)}
              />
            </fieldset>

            <fieldset>
              <label className="c-form-label">DL. No.(2)</label>
              <input
                disabled
                placeholder="Enter D.L No 2"
                type="text"
                className="c-form-input-firm  inputFeild"
                required
                value={dl2}
              // onChange={(e) => setdl2(e.target.value)}
              />
            </fieldset>
          </div>
          <div className="two-columns-three">
            <fieldset>
              <label className="c-form-label">GSTIN</label>
              <input
                disabled
                placeholder="Enter GSTIN "
                type="text"
                className="c-form-input-firm  inputFeild"
                required
                value={gst}
              />
            </fieldset>

            <fieldset>
              <label className="c-form-label" for="last-name">
                FSSAI No.
              </label>
              <input
                disabled
                placeholder="Enter FASSAI No."
                type="text"
                className="c-form-input-firm  inputFeild"
                required
                value={fssai}
              />
            </fieldset>
          </div>
          <label className="banklabel-01-bank">Bank Details</label>
          <div className="spacing"></div>
          <div className="two-columns-three">
            <fieldset>
              <label className="c-form-label">Account Name</label>
              <input
                disabled
                placeholder="Enter Account Name"
                type="text"
                className="c-form-input-firm inputFeild"
                required
                value={accountName}
              />
            </fieldset>

            <fieldset>
              <label className="c-form-label" for="last-name">
                Account No.
              </label>
              <input
                disabled
                placeholder="Enter Account No."
                type="text"
                className="c-form-input-firm inputFeild"
                required
                value={accountNo}
              />
            </fieldset>
          </div>

          <div className="two-columns-three">
            <fieldset>
              <label className="c-form-label">IFSC Code</label>
              <input
                disabled
                placeholder="Enter IFSC Code"
                type="text"
                className="c-form-input-firm  inputFeild"
                required
                value={ifsc}
              />
            </fieldset>

            <fieldset>
              <label className="c-form-label" for="last-name">
                Branch
              </label>
              <input
                disabled
                placeholder="Enter Branch Name"
                type="text"
                className="c-form-input-firm  inputFeild"
                required
                value={branch}
              />
            </fieldset>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FirmView;

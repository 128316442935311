import { React, useState } from "react";
import "./invoiceAssign.css";
import Swal from "sweetalert2";
import Layout from "../../Layout/Layout.js";
import axios from "axios";

export const InvoiceAssign = () => {

  const [invoiceMain, setMain] = useState("");

  const [invoiceEst, setEstimate] = useState("");

  const SendingValues = async () => {
    try {
      // token 
      let token = localStorage.getItem('x-api-key');

      let response = await axios.post("api/user/setting/create",
        {
          invoiceMain,
          invoiceEst
        }, {
        headers: {
          "Content-Type": "application/json",
          'x-api-key': token
        },
      });

      let result = response.data;

      console.log(result, "result");

      if (result.data === 'Created successfully') {
        Swal.fire("Genereted!", "Your Manual Invoice Created.", "success");
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: result.message,
        });
      };
    } catch (error) {
      console.error(error.response.data.message)
    }
  };


  const [invoiceMainDisplay, setInvoiceMainDisplay] = useState('');
  // const [invoiceMain, setInvoiceMain] = useState('');

  const handleChange = (e) => {
    // Get the user entered value
    const enteredValue = e.target.value;

    // Update the displayed value in the input field with the suffix
    if (!/^-0001$/.test(enteredValue)) {
      
    // Update the state only with the entered value (without the suffix)
    setMain(enteredValue);
    }
  };


  return (
    <div className="invoice-container">
      <Layout heading_1="Setting" heading_2="Invoice Sequence Generate">
        <div className="invoiceContainer">
          <div className="invoiceInnerContainer">
            <div className="topheading">
              <h4>
                User Can Assign Their Invoice Sequence As Per Individual Wish.
              </h4>
            </div>
            <div className="heroInvoice">
              <div>
                <div className="inputLabel">
                  <h4 >Main</h4>
                </div>
                <div className="inputfields">
                  <input
                    // value={invoiceMainDisplay}
                    value={invoiceMain}
                    // onChange={handleChange}
                    // value={invoiceMain}
                    // onChange={((e) => setMain(e.target.value))}
                    onChange={handleChange}
                    type="text"
                    style={{ boxShadow: "0px 5px 5px 0px rgba(0, 0, 0, 0.2)", height: '33px' }}
                  />
                 <label>'-0001'</label>
                </div>
              </div>
              <div>
                <div className="inputLabel">
                  <h4>Estimate</h4>
                </div>
                <div className="inputfields">
                  <input
                    value={invoiceEst}
                    onChange={((e) => setEstimate(e.target.value))}
                    type="text"
                    style={{ boxShadow: "0px 5px 5px 0px rgba(0, 0, 0, 0.2)", height: '33px' }}
                  />
                     <label>'-0001'</label>
                </div>
              </div>
            </div>
            <div className="bottom">
              <div className="buttonDiv">
                <button
                  onClick={SendingValues}
                  className="submitButton">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

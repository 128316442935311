/** @format */
import "./ProductsImport.css";
import Button from "@mui/material/Button";
import Layout from "../Layout/Layout";
import { React, useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";

export default function ProductsImport() {
  const [products, setProducts] = useState([]);

  const [minQty, setMinQty] = useState("");

  const [discPer, setDisc] = useState("");

  const [selectAll, setSelectAll] = useState(false);

  const [selectedItems, setSelectedItems] = useState([]);

  // const [selectedProducts, setSelectedProducts] = useState([]);

  useEffect(() => {
    getProducts();
  }, []);

  // code for filtering firm according to search bar;

  const [filteredList, setFilteredList] = new useState(products);

  const [filter, setFilter] = useState(false);

  const filterProductSearch = (event) => {
    console.log("event.target.value", event.target.value);
    if (!event.target.value || event.target.value.length == 0) {
      setFilter(false);
      console.log("filter val", filter);
    } else {
      console.log("filter", filter);
      setFilter(true);
      let updatedList = [...products];
      console.log("updatedList", updatedList);
      updatedList = updatedList.filter((item) => {
        return (
          item.productName
            .toLowerCase()
            .indexOf(event.target.value.toLowerCase()) !== -1 ||
          item.brand.toLowerCase().indexOf(event.target.value.toLowerCase()) !==
            -1
        );
      });
      setFilteredList(updatedList);
      console.log("filteredList", filteredList);
    }
    return;
  };

  const getProducts = async () => {
    try {
      let token = localStorage.getItem("x-api-key");
      let response = await axios.post(
        "api/user/stocks/getMainStocks",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": token,
            "x-api-plan": "SaaS",
          },
        }
      );
      let result = response.data;
      console.log(56, result);
      if (result.data) {
        setProducts(result.data);
      }
    } catch (error) {
      console.error(error.response.data.message);
    }
  };

  const createData = async () => {
    try {
      const hasEmptyMinQty = selectedItems.some(
        (_product) => _product.minQty === "" || _product.minQty == 0
      );

      // const hasEmptyMinQty = products.some((_product) => _product.minQty === "" || _product.minQty == 0);

      console.log(79, selectedItems);

      if (selectedItems.length === 0) {
        return Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please Select Atleast One Product To Save",
        });
      }
      if (hasEmptyMinQty) {
        // Display an error message or handle the error as needed
        return Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Minimum Qty must be filled",
        });
      }

      // return alert('API Hit');

      let token = localStorage.getItem("x-api-key");

      let response = await axios.post(
        "api/user/product/uploadProductsSaaS",
        {
          selectedItems,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": token,
            "x-api-plan": "SaaS",
          },
        }
      );

      if (response.data.status === 200) {
        return Swal.fire("Genereted!", " Updated successfully.", "success");
      }
    } catch (error) {
      console.error(error.response.data.message);
    }
  };

  // start

  // To apply all products;

  const applyMinQtyToAll = () => {
    if (!selectedItems.length) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please Select Products To Apply Min Quantity",
      });
    }

    const updatedProducts = products.map((_product) => {
      const updatedProduct = selectedItems.find(
        (updated) => updated.id === _product.id
      );
      if (updatedProduct) {
        return { ..._product, minQty: minQty };
      }
      return _product;
    });

    const updatedSelectedItems = selectedItems.map((_product) => {
      return { ..._product, minQty: minQty };
    });

    setSelectedItems(updatedSelectedItems);

    // setSelectedProducts(selectedProduct);
    setProducts(updatedProducts);
    Swal.fire("Generated!", "Updated successfully.", "success");
  };

  const applyDiscPerToAll = () => {
    if (!selectedItems.length) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please Select Products To Apply Discount Percentage",
      });
    }

    console.log("applyDiscPerToAll");

    const updatedProducts = products.map((_product) => {
      const updatedProduct = selectedItems.find(
        (updated) => updated.id === _product.id
      );
      if (updatedProduct) {
        return { ..._product, discPercent: discPer };
      }
      return _product;
    });

    const updatedSelectedItems = selectedItems.map((_product) => {
      return { ..._product, discPercent: discPer };
    });

    setSelectedItems(updatedSelectedItems);

    setProducts(updatedProducts);
    Swal.fire("Generated!", "Updated successfully.", "success");
    console.log("applyDiscPerToAll", updatedProducts);
  };

  // const applyMinQtyToAll = () => {

  //   if (!selectedItems.length) {
  //     return Swal.fire({
  //       icon: "error",
  //       title: "Oops...",
  //       text: "PLease Select Products To Apply Min Quantity",
  //     });
  //   };

  //   console.log("applyMinQtyToAll");

  //   // if (filter) {
  //   //   const _filteredList = filteredList.map((_product) => ({
  //   //     ..._product,
  //   //     minQty: minQty,
  //   //   }));
  //   //   setFilteredList(_filteredList);
  //   // } else {

  //   // const updatedProducts = products.map((_product) => ({
  //   //   ..._product,
  //   //   minQty: minQty,
  //   // }));
  //   // const updatedProducts = selectedItems.map((_product) => ({
  //   //   ..._product,
  //   //   minQty: minQty,
  //   // }));
  //   // setProducts([...products, updatedProducts]);
  //   // setProducts(selectedItems);
  //   // }

  //   const updatedProducts = selectedItems.map((_product) => ({
  //     ..._product,
  //     minQty: minQty,
  //   }));

  //   // Update the existing products array with the changes in selectedItems
  //   const updatedProductsArray = products.map((product) => {
  //     const updatedProduct = updatedProducts.find((updated) => updated.id === product.id);
  //     return updatedProduct || product;
  //     // return updatedProduct;
  //   });

  //   Swal.fire("Genereted!", " Updated successfully.", "success");
  //   setProducts(updatedProductsArray);
  //   console.log("applyMinQtyToAll", products)
  // };

  // const applyDiscPerToAll = () => {

  //   if (!selectedItems.length) {
  //     return Swal.fire({
  //       icon: "error",
  //       title: "Oops...",
  //       text: "PLease Select Products To Apply Discount Percentage",
  //     });
  //   };

  //   console.log("applyDiscPerToAll");

  //   const updatedProducts = selectedItems.map((_product) => ({
  //     ..._product,
  //     discPercent: discPer,
  //   }));

  //   // Update the existing products array with the changes in selectedItems
  //   const updatedProductsArray = products.map((product) => {
  //     const updatedProduct = updatedProducts.find((updated) => updated.id === product.id);
  //     return updatedProduct || product;
  //     // return updatedProduct;
  //   });

  //   Swal.fire("Genereted!", " Updated successfully.", "success");
  //   console.log("updatedProductsArray", updatedProductsArray)
  //   setProducts(updatedProductsArray);
  //   console.log("applyDiscPerToAll", products)

  //   // const _filteredList = filteredList.map((_product) => ({
  //   //   ..._product,
  //   //   discPercent: discPer,
  //   // }));

  //   // setProducts(updatedProducts);
  //   // setFilteredList(updatedProducts);
  // };

  const toggleSelectAll = () => {
    if (selectAll) {
      setSelectedItems([]);
    } else {
      if (filter) {
        setSelectedItems([...filteredList]);
      } else {
        // setSelectedItems([...selectedItems]);
        setSelectedItems([...products]);
      }
    }
    setSelectAll(!selectAll);
  };

  const toggleItem = (item) => {
    const updatedItems = [...selectedItems];

    // Check if an item with a specific id exists in updatedItems
    const index = updatedItems.findIndex((obj) => obj.id === item.id);

    if (index !== -1) {
      // If an item with the same id exists, remove it
      updatedItems.splice(index, 1);
    } else {
      // If not, add the item to the array
      updatedItems.push(item);
    }

    // const updatedItems = [...selectedItems];

    // if (updatedItems.some(obj => obj.id === item.id)) {
    //   updatedItems.splice(updatedItems.indexOf(item), 1);
    // } else {
    //   updatedItems.push(item);
    //   // updatedItems.push(JSON.parse(JSON.stringify(item)));
    // };

    // if (updatedItems.includes(item.id)) {
    //   updatedItems.splice(updatedItems.indexOf(item), 1);
    // } else {
    //   updatedItems.push(item);
    //   // updatedItems.push(JSON.parse(JSON.stringify(item)));
    // }

    setSelectedItems(updatedItems);
  };

  // handleFieldChange;

  const handleFieldChange = (productId, field, value) => {
    // Update the React state with the new field value
    const updatedProducts = products.map((_product) =>
      _product.id === productId ? { ..._product, [field]: value } : _product
    );

    const updatedSelectedProducts = selectedItems.map((_product) =>
      _product.id === productId ? { ..._product, [field]: value } : _product
    );

    setProducts(updatedProducts);

    setSelectedItems(updatedSelectedProducts);

    console.log("handleFieldChange", selectedItems);

    console.log(102, products);
  };

  return (
    <Layout heading_1="Export" heading_2="Export To Marketplace">
      <div className="main-Productlist-file">
        <div className="Productlist-main_marketplace">
          <div className="Productlist">
            <div className="table-top-Productlist_marketplace">
              <div className="show-name-Productlist_marketplace">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "2rem",
                  }}
                >
                  <div style={{ marginLeft: "2rem" }}>
                    <label>
                      <input
                        type="checkbox"
                        checked={selectAll}
                        onChange={toggleSelectAll}
                      />
                      <b
                        style={{
                          position: "relative",
                          bottom: "8px",
                          right: "20px",
                        }}
                      >
                        Select All
                      </b>
                    </label>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "2rem",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <label>
                        <b>Min. Qty.</b>
                      </label>
                      <input
                        style={{ marginTop: "0", width: "56px" }}
                        type="text"
                        value={minQty}
                        placeholder="Min.Qty."
                        onChange={(e) => setMinQty(e.target.value)}
                      />
                      <Button
                        variant="contained"
                        size="small"
                        color="success"
                        style={{
                          height: "24px",
                          fontSize: "12px",
                        }}
                        onClick={applyMinQtyToAll}
                      >
                        Apply To All
                      </Button>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <label>
                        <b>Disc. %Age</b>
                      </label>
                      <input
                        style={{ marginTop: "0", width: "56px" }}
                        type="text"
                        value={discPer}
                        placeholder="Disc. %"
                        onChange={(e) => setDisc(e.target.value)}
                      />
                      <Button
                        variant="contained"
                        size="small"
                        color="success"
                        style={{
                          height: "24px",
                          fontSize: "12px",
                        }}
                        onClick={applyDiscPerToAll}
                      >
                        Apply To All
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="search-name-Productlist_marketplace">
                <input
                  style={{ margin: "10px" }}
                  placeholder="Search by Brand, Product Name"
                  onChange={filterProductSearch}
                />
              </div>
            </div>
            <div className="table--customer--Productlist_marketplace">
              <table id="customers-Productlist_marketplace">
                <tr className="table-customer-heading_marketplace">
                  <th>S.No</th>
                  <th>Select</th>
                  <th>Product Name</th>
                  <th>Category</th>
                  <th>Formulation</th>
                  <th>Brand</th>
                  <th>Description</th>
                  <th>Unit Packing</th>
                  <th>Expiry Date</th>
                  <th>Available Qty</th>
                  <th>HSN/SAC Code</th>
                  <th>Batch No.</th>
                  <th>MRP</th>
                  <th>Trade Rate</th>
                  <th>Tax</th>
                  <th>Min. Qty.</th>
                  <th>Disc. %</th>
                  <th>Bonus Deal</th>
                </tr>
                {filter
                  ? filteredList.map((_product, index) => (
                      <tr
                        key={_product.id}
                        className="outputlist1-products-list"
                      >
                        <td
                          className={(index + 1) % 2 === 1 ? "white" : "grey"}
                        >
                          <span className="span-width_product_marketplace">
                            {index + 1}
                          </span>
                        </td>
                        <td key={_product.id + "check"}>
                          <label>
                            <input
                              type="checkbox"
                              // checked={selectedItems.includes(_product)}
                              checked={selectedItems.some(
                                (obj) => obj.id === _product.id
                              )}
                              onChange={() => toggleItem(_product)}
                            />
                            {/* {_product} */}
                          </label>
                        </td>
                        <td>
                          <input
                            type="text"
                            disabled={
                              !selectedItems.some(
                                (obj) => obj.id === _product.id
                              )
                            }
                            value={_product.productName}
                            onChange={(e) =>
                              handleFieldChange(
                                _product.id,
                                "productName",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            disabled={
                              !selectedItems.some(
                                (obj) => obj.id === _product.id
                              )
                            }
                            value={_product.category}
                            onChange={(e) =>
                              handleFieldChange(
                                _product.id,
                                "category",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            disabled={
                              !selectedItems.some(
                                (obj) => obj.id === _product.id
                              )
                            }
                            value={_product.formulation}
                            onChange={(e) =>
                              handleFieldChange(
                                _product.id,
                                "formulation",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            disabled={
                              !selectedItems.some(
                                (obj) => obj.id === _product.id
                              )
                            }
                            value={_product.brand}
                            onChange={(e) =>
                              handleFieldChange(
                                _product.id,
                                "brand",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            disabled={
                              !selectedItems.some(
                                (obj) => obj.id === _product.id
                              )
                            }
                            value={_product.description}
                            onChange={(e) =>
                              handleFieldChange(
                                _product.id,
                                "description",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            disabled={
                              !selectedItems.some(
                                (obj) => obj.id === _product.id
                              )
                            }
                            value={_product.unitPacking}
                            onChange={(e) =>
                              handleFieldChange(
                                _product.id,
                                "unitPacking",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            disabled={
                              !selectedItems.some(
                                (obj) => obj.id === _product.id
                              )
                            }
                            value={_product.expiry}
                            onChange={(e) =>
                              handleFieldChange(
                                _product.id,
                                "expiry",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            disabled={
                              !selectedItems.some(
                                (obj) => obj.id === _product.id
                              )
                            }
                            value={_product.availableStocks}
                            onChange={(e) =>
                              handleFieldChange(
                                _product.id,
                                "availableStocks",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            disabled={
                              !selectedItems.some(
                                (obj) => obj.id === _product.id
                              )
                            }
                            value={_product.hsn_sac_code}
                            onChange={(e) =>
                              handleFieldChange(
                                _product.id,
                                "hsn_sac_code",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            disabled={
                              !selectedItems.some(
                                (obj) => obj.id === _product.id
                              )
                            }
                            value={_product.batchNo}
                            onChange={(e) =>
                              handleFieldChange(
                                _product.id,
                                "batchNo",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            disabled={
                              !selectedItems.some(
                                (obj) => obj.id === _product.id
                              )
                            }
                            value={_product.mrp}
                            onChange={(e) =>
                              handleFieldChange(
                                _product.id,
                                "mrp",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            disabled={
                              !selectedItems.some(
                                (obj) => obj.id === _product.id
                              )
                            }
                            value={_product.tradeRate}
                            onChange={(e) =>
                              handleFieldChange(
                                _product.id,
                                "tradeRate",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            disabled={
                              !selectedItems.some(
                                (obj) => obj.id === _product.id
                              )
                            }
                            value={
                              parseFloat(_product.igst)
                                ? parseFloat(_product.igst)
                                : parseFloat(_product.cgst) +
                                  parseFloat(_product.sgst)
                            }
                            onChange={(e) =>
                              handleFieldChange(
                                _product.id,
                                "productTax",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            disabled={
                              !selectedItems.some(
                                (obj) => obj.id === _product.id
                              )
                            }
                            value={_product.minQty}
                            onChange={(e) =>
                              handleFieldChange(
                                _product.id,
                                "minQty",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            disabled={
                              !selectedItems.some(
                                (obj) => obj.id === _product.id
                              )
                            }
                            value={_product.discPercent}
                            onChange={(e) =>
                              handleFieldChange(
                                _product.id,
                                "discPercent",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            disabled={
                              !selectedItems.some(
                                (obj) => obj.id === _product.id
                              )
                            }
                            value={_product.free}
                            onChange={(e) =>
                              handleFieldChange(
                                _product.id,
                                "free",
                                e.target.value
                              )
                            }
                          />
                        </td>
                      </tr>
                    ))
                  : products.map((_product, index) => (
                      <tr
                        key={_product.id}
                        className="outputlist1-products-list"
                      >
                        <td
                          className={(index + 1) % 2 === 1 ? "white" : "grey"}
                        >
                          <span className="span-width_product_marketplace">
                            {index + 1}
                          </span>
                        </td>
                        <td key={_product.id + "check"}>
                          <label>
                            <input
                              type="checkbox"
                              // checked={selectedItems.includes(_product)}
                              checked={selectedItems.some(
                                (obj) => obj.id === _product.id
                              )}
                              onChange={() => toggleItem(_product)}
                            />
                          </label>
                        </td>
                        <td>
                          <input
                            type="text"
                            disabled={
                              !selectedItems.some(
                                (obj) => obj.id === _product.id
                              )
                            }
                            value={_product.productName}
                            onChange={(e) =>
                              handleFieldChange(
                                _product.id,
                                "productName",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            disabled={
                              !selectedItems.some(
                                (obj) => obj.id === _product.id
                              )
                            }
                            value={_product.category}
                            onChange={(e) =>
                              handleFieldChange(
                                _product.id,
                                "category",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            disabled={
                              !selectedItems.some(
                                (obj) => obj.id === _product.id
                              )
                            }
                            value={_product.formulation}
                            onChange={(e) =>
                              handleFieldChange(
                                _product.id,
                                "formulation",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            disabled={
                              !selectedItems.some(
                                (obj) => obj.id === _product.id
                              )
                            }
                            value={_product.brand}
                            onChange={(e) =>
                              handleFieldChange(
                                _product.id,
                                "brand",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            disabled={
                              !selectedItems.some(
                                (obj) => obj.id === _product.id
                              )
                            }
                            value={_product.description}
                            onChange={(e) =>
                              handleFieldChange(
                                _product.id,
                                "description",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            disabled={
                              !selectedItems.some(
                                (obj) => obj.id === _product.id
                              )
                            }
                            value={_product.unitPacking}
                            onChange={(e) =>
                              handleFieldChange(
                                _product.id,
                                "unitPacking",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            disabled={
                              !selectedItems.some(
                                (obj) => obj.id === _product.id
                              )
                            }
                            value={_product.expiry}
                            onChange={(e) =>
                              handleFieldChange(
                                _product.id,
                                "expiry",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            disabled={
                              !selectedItems.some(
                                (obj) => obj.id === _product.id
                              )
                            }
                            value={_product.availableStocks}
                            onChange={(e) =>
                              handleFieldChange(
                                _product.id,
                                "availableStocks",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            disabled={
                              !selectedItems.some(
                                (obj) => obj.id === _product.id
                              )
                            }
                            value={_product.hsn_sac_code}
                            onChange={(e) =>
                              handleFieldChange(
                                _product.id,
                                "hsn_sac_code",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            disabled={
                              !selectedItems.some(
                                (obj) => obj.id === _product.id
                              )
                            }
                            value={_product.batchNo}
                            onChange={(e) =>
                              handleFieldChange(
                                _product.id,
                                "batchNo",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            disabled={
                              !selectedItems.some(
                                (obj) => obj.id === _product.id
                              )
                            }
                            value={_product.mrp}
                            onChange={(e) =>
                              handleFieldChange(
                                _product.id,
                                "mrp",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            disabled={
                              !selectedItems.some(
                                (obj) => obj.id === _product.id
                              )
                            }
                            value={_product.tradeRate}
                            onChange={(e) =>
                              handleFieldChange(
                                _product.id,
                                "tradeRate",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            disabled={
                              !selectedItems.some(
                                (obj) => obj.id === _product.id
                              )
                            }
                            value={
                              parseFloat(_product.igst)
                                ? parseFloat(_product.igst)
                                : parseFloat(_product.cgst) +
                                  parseFloat(_product.sgst)
                            }
                            onChange={(e) =>
                              handleFieldChange(
                                _product.id,
                                "productTax",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            disabled={
                              !selectedItems.some(
                                (obj) => obj.id === _product.id
                              )
                            }
                            value={_product.minQty}
                            onChange={(e) =>
                              handleFieldChange(
                                _product.id,
                                "minQty",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            disabled={
                              !selectedItems.some(
                                (obj) => obj.id === _product.id
                              )
                            }
                            value={_product.discPercent}
                            onChange={(e) =>
                              handleFieldChange(
                                _product.id,
                                "discPercent",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            disabled={
                              !selectedItems.some(
                                (obj) => obj.id === _product.id
                              )
                            }
                            value={_product.free}
                            onChange={(e) =>
                              handleFieldChange(
                                _product.id,
                                "free",
                                e.target.value
                              )
                            }
                          />
                        </td>
                      </tr>
                    ))}
              </table>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "15px",
              }}
            >
              <Button
                onClick={createData}
                variant="contained"
                size="small"
                color="success"
              >
                Export/Update
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

import React from "react";
import './Coupon.css';
import Layout from "../Layout/Layout";
const coupons = [
    {
        id: 1,
        code: "SAVE10",
        description: "Save 10% on your purchase",
    },
    {
        id: 2,
        code: "FREESHIP",
        description: "Free shipping on orders over $50",
    },
    {
        id: 3,
        code: "FLASHSALE",
        description: "Flash sale: 20% off today only",
    },
    // Add more coupons as needed
];


function CouponList() {
    return (
        <Layout heading_1="Coupon" heading_2="Coupon List">
            <div className="coupon-container">
                <div className="coupon-div">
                    <ul>
                        {coupons.map((coupon) => (
                            <li key={coupon.id}>
                                <h2>Coupon Code: {coupon.code}</h2>
                                <p>Description: {coupon.description}</p>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </Layout >
    );
};

export default CouponList;


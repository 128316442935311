/** @format */

import "./productlist.css";
// import ProductListHeader from "./ProductListHeader";
import Layout from "../../Layout/Layout";
import { React, useEffect, useState, useRef } from "react";
import { FaRegFilePdf } from "react-icons/fa";
import { FaFileCsv, FaRegCopy } from "react-icons/fa";
import Swal from "sweetalert2";
import { useParams, useNavigate, Link } from "react-router-dom";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { CSVLink } from "react-csv";
import { useGlobalContext } from "components/Context";
import axios from "axios";

//============== Headers for csv file=======================//
const headers = [
  { label: "Product Name", key: "productName" },
  { label: "Brand", key: "brand" },
  { label: "Category", key: "category" },
  { label: "Description", key: "description" },
  { label: "Unit Packing", key: "unitPacking" },
  { label: "HSN/SAC Code", key: "hsn_sac_code" },
  { label: "Formulation", key: "formulation" },
];

export default function ProductList() {
  const pageSize = 10;
  const [product, setProduct] = useState([]);
  // let { PaginationControlled, page, setCount, pageSize } = useGlobalContext();
  let { PaginationControlled, page, setTotalPages } = useGlobalContext();

  console.log(29, page, pageSize);

  useEffect(() => {
    getProducts();
  }, [page]);

  const getProducts = async () => {
    //let token = JSON.parse(localStorage.getItem("user"));
    //console.log(token.auth);
    //token
    try {
      let token = localStorage.getItem("x-api-key");
      let response = await axios.get("api/user/addProductVertTwo/getAll", {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": token,
          "x-api-plan": "SaaS",
        },
        // params: { page: page, pageSize: pageSize },
      });

      // result = await result.json();

      let result = response.data;

      if (result.data) {
        console.log('all result data for genereated products ',result.data);
        setProduct(result.data);
        // setCount(result.totalItems)
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  // code for filtering firm according to search bar;
  const [filteredList, setFilteredList] = new useState(product);
  const [filter, setFilter] = useState(false);
  const filterProductSearch = (event) => {
    setFilter(true);
    let updatedList = [...product];
    updatedList = updatedList.filter((item) => {
      return (
        item.productName
          .toLowerCase()
          .indexOf(event.target.value.toLowerCase()) !== -1 ||
        item.brand.toLowerCase().indexOf(event.target.value.toLowerCase()) !==
          -1
      );
    });
    setFilteredList(updatedList);
  };

  //==========  converting data to pdf===========
  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 350;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Product List";
    const headers = [
      [
        "S.No",
        "Product Name",
        "Brand",
        "Category",
        "Description",
        "Unit Packing",
        "HSN/SAC Code",
        "Formulation",
      ],
    ];
    // console.log(filteredProductList);
    let data;
    if (filter) {
      data = filteredList.map((elt, index) => [
        index + 1,
        elt.productName,
        elt.brand,
        elt.category,
        elt.description,
        elt.unitPacking,
        elt.hsn_sac_code,
        elt.formulation,
      ]);
    } else {
      data = product.map((elt, index) => [
        index + 1,
        elt.productName,
        elt.brand,
        elt.category,
        elt.description,
        elt.unitPacking,
        elt.hsn_sac_code,
        elt.formulation,
      ]);
    }
    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    autoTable(doc, content);
    doc.save("Product List.pdf");
  };

  //========= converting filtered data to csv============//
  const csvDownloadRef = useRef(null);
  const convertToCsv = () => {
    setTimeout(() => {
      csvDownloadRef.current.link.click();
    }, 500);
  };

  // search hande user can search by typing firm name or user name;

  const searchHandle = async (event) => {
    // console.log(event.target.value);
    //let token = JSON.parse(localStorage.getItem("user"));
    let key = event.target.value;
    console.log(key);
    if (key) {
      //token
      let token = localStorage.getItem("x-api-key");
      let response = await axios.get(
        `api/user/addProductVertTwo/getFilter/${key}`,
        {
          // method: "get",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": token,
            "x-api-plan": "SaaS",
          },
        }
      );
      let result = response.data;
      if (result) {
        setProduct(result.data);
      }
    } else {
      getProducts();
    }
  };

  // for delete product we are making this function
  function Promptdelete(id) {
    var id = id;
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: false,
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteProduct = async (id) => {
          //token
          let token = localStorage.getItem("x-api-key");
          let response = await axios.delete(
            `api/user/addProductVertTwo/deleteProduct/${id}`,
            {
              // method: "Delete",
              headers: {
                "Content-Type": "application/json",
                "x-api-key": token,
                "x-api-plan": "SaaS",
              },
            }
          );
          let result = response.data;
          if (result) {
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
            // alert(result.message);
            getProducts();
          }
        };
        deleteProduct(id);
      }
    });
  }

  const navigate = useNavigate();

  function navigateToSaleListView() {
    navigate("/Product-list-view");
  }

  function navigateToSaleListEdit() {
    navigate("/Product-list-edit");
  }

  // start

  // Your data (Firm) and other variables

  // Calculate the start and end index for the current page

  const startIndex = (page - 1) * pageSize;

  const endIndex = startIndex + pageSize;

  // Filter the data based on the current page;

  const displayedData = (
    filter
      ? filteredList.slice(startIndex, endIndex)
      : product.slice(startIndex, endIndex)
  ).map((item, index) => ({
    ...item,
    displayIndex: (page - 1) * pageSize + index + 1,
  }));

  // Calculate the total number of pages;

  const totalPages = Math.ceil(
    (filter ? filteredList.length : product.length) / pageSize
  );

  setTotalPages(totalPages);
  // Handle page change;

  // const handleChange = (event, value) => {
  //   setPage(value);
  // };

  return (
    <Layout heading_1="Product Name" heading_2="Product List">
      <div className="main-Productlist-file">
        <div className="Productlist-main">
          <div className="Productlist">
            <div className="table-top-Productlist">
              <div className="show-name-Productlist">
                <label
                  htmlFor="show"
                  style={{ color: "black", fontWeight: "bold" }}
                >
                  Show :{" "}
                </label>
                <select id="show">
                  <option>All</option>
                </select>
                <FaRegFilePdf
                  className="pdf-Productlist"
                  style={{ cursor: "pointer" }}
                  onClick={(event) => {
                    exportPDF();
                  }}
                  id="pdf"
                />
                <CSVLink
                  data={filter ? filteredList : product}
                  headers={headers}
                  filename="Firm List.csv"
                  className="hidden"
                  ref={csvDownloadRef}
                  target="_blank"
                />
                <FaFileCsv
                  className="csv-Productlist"
                  style={{ cursor: "pointer" }}
                  onClick={(event) => {
                    convertToCsv(event);
                  }}
                />
              </div>
              <div className="search-name-Productlist">
                <input
                  placeholder="Search by Brand, Product Name"
                  onChange={filterProductSearch}
                />
              </div>
            </div>
            <div className="table--customer--Productlist">
              <table id="customers-Productlist">
                <tr className="table-customer-heading-Productlist">
                  <th>S.No</th>
                  <th>Product Name</th>
                  <th>Brand</th>
                  <th>Category</th>
                  <th>Description</th>
                  <th>Unit Packing</th>
                  <th>HSN/SAC Code</th>
                  <th>Formulation</th>
                  <th>Action</th>
                </tr>
                {filter
                  ? displayedData.map((item, index) => (
                      <tr key={item.id} className="outputlist1-product-list">
                        <td
                          className={(index + 1) % 2 === 1 ? "white" : "grey"}
                        >
                          <span className="span-width_product">
                            {item.displayIndex}
                          </span>
                        </td>
                        <td
                          className={(index + 1) % 2 === 1 ? "white" : "grey"}
                        >
                          <span className="span-width_product">
                            {" "}
                            {item.productName}
                          </span>
                        </td>
                        <td
                          className={(index + 1) % 2 === 1 ? "white" : "grey"}
                        >
                          <span className="span-width_product">
                            {" "}
                            {item.brand}
                          </span>
                        </td>
                        <td
                          className={(index + 1) % 2 === 1 ? "white" : "grey"}
                        >
                          <span className="span-width_product">
                            {" "}
                            {item.category}
                          </span>
                        </td>
                        <td
                          className={(index + 1) % 2 === 1 ? "white" : "grey"}
                        >
                          <span className="span-width_product">
                            {" "}
                            {item.description}
                          </span>
                        </td>
                        <td
                          className={(index + 1) % 2 === 1 ? "white" : "grey"}
                        >
                          <span className="span-width_product">
                            {" "}
                            {item.unitPacking}
                          </span>
                        </td>
                        <td
                          className={(index + 1) % 2 === 1 ? "white" : "grey"}
                        >
                          <span className="span-width_product">
                            {" "}
                            {item.hsn_sac_code}
                          </span>
                        </td>
                        <td
                          className={(index + 1) % 2 === 1 ? "white" : "grey"}
                        >
                          <span className="span-width_product">
                            {" "}
                            {item.formulation}
                          </span>
                        </td>

                        <div className="action-button-Product-table">
                          <button className="view-sale-list">
                            <Link to={"/user/viewproduct/" + item.id}>
                              View
                            </Link>
                          </button>

                          <button className="edit-sale-list">
                            <Link to={"/user/updateproduct/" + item.id}>
                              {" "}
                              Edit
                            </Link>
                          </button>

                          <button
                            className="del-sale-list"
                            onClick={() => Promptdelete(item.id)}
                          >
                            Delete
                          </button>
                        </div>
                      </tr>
                    ))
                  : displayedData.map((item, index) => (
                      <tr key={item.id} className="outputlist1-product-list">
                        <td
                          className={(index + 1) % 2 === 1 ? "white" : "grey"}
                        >
                          <span className="span-width_product">
                            {item.displayIndex}
                          </span>
                        </td>
                        <td
                          className={(index + 1) % 2 === 1 ? "white" : "grey"}
                        >
                          <span className="span-width_product">
                            {" "}
                            {item.productName}
                          </span>
                        </td>
                        <td
                          className={(index + 1) % 2 === 1 ? "white" : "grey"}
                        >
                          <span className="span-width_product">
                            {" "}
                            {item.brand}
                          </span>
                        </td>
                        <td
                          className={(index + 1) % 2 === 1 ? "white" : "grey"}
                        >
                          <span className="span-width_product">
                            {" "}
                            {item.category}
                          </span>
                        </td>
                        <td
                          className={(index + 1) % 2 === 1 ? "white" : "grey"}
                        >
                          <span className="span-width_product">
                            {" "}
                            {item.description}
                          </span>
                        </td>
                        <td
                          className={(index + 1) % 2 === 1 ? "white" : "grey"}
                        >
                          <span className="span-width_product">
                            {" "}
                            {item.unitPacking}
                          </span>
                        </td>
                        <td
                          className={(index + 1) % 2 === 1 ? "white" : "grey"}
                        >
                          <span className="span-width_product">
                            {" "}
                            {item.hsn_sac_code}
                          </span>
                        </td>
                        <td
                          className={(index + 1) % 2 === 1 ? "white" : "grey"}
                        >
                          <span className="span-width_product">
                            {" "}
                            {item.formulation}
                          </span>
                        </td>
                        <div className="action-button-Product-table">
                          <button className="view-sale-list">
                            <Link to={"/user/viewproduct/" + item.id}>
                              View
                            </Link>
                          </button>

                          <button className="edit-sale-list">
                            <Link to={"/user/updateproduct/" + item.id}>
                              {" "}
                              Edit
                            </Link>
                          </button>

                          <button
                            className="del-sale-list"
                            onClick={() => Promptdelete(item.id)}
                          >
                            Delete
                          </button>
                        </div>
                      </tr>
                    ))}
              </table>
            </div>
            <PaginationControlled />
          </div>
        </div>
      </div>
    </Layout>
  );
}

// import { React, useEffect, useState, useRef } from "react";
import './LedgerUpdate.css';
// import { LedgerUpdateHeader } from "./LedgerUpdateHeader";
import Layout from "../../Layout/Layout";
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import axios from 'axios';

const LedgerUpdate = () => {

  let [finalGoodReturn, setfinalGoodReturn] = useState(0);
  let [TotalSumPay, setTotalSumPay] = useState("");
  let [GoodsReturnSum, setGoodsReturnPay] = useState("");
  // for showing model and hiding model
  const [showModal2, setShowModal2] = React.useState(false);

  function openModal2() {
    setShowModal2(function (item) {
      return !item;
    });
  };





  let [ProductEntries, setProductEntries] = useState([]);
  // formula one of the calculation's

  const [customOrder,setCustomOrder] =useState(0)


  const [remarks,setRemarks] = useState();
    //for pop up show and hide
 const [showPopup, setShowPopup] = useState(false);

 const [trackIndex,setTrackIndex] = useState('')

 const handleOpenPopup = (index) => {
   setShowPopup(!showPopup);
 };

 const handleClosePopupForUpdate = () => {
   setShowPopup(!showPopup);
  //  alert('coming in update')
    UpdateArrayofObject(trackIndex);
    return
 };

 const handleClosePopupForSave = () => {
   setShowPopup(!showPopup);
  //  alert('comin in save button closed')
    FormulaOne()
   return
 };


  const [showUpdate,setShowUpdate] = useState('');
  const [firmName, setFirmName] = useState("");
  const [dateOfTrans, setdateOfTrans] = useState("");
  const [mode, setMode] = useState("");
  const [checqueNo, setchecqueNo] = useState(0);
  const [paid, setpaid] = useState(0);
  const [recieve, setrecieve] = useState(0);
  //total
  const [totalrecieve, setTotalrecieve] = useState("");
  const [totalpaid, settotalpaid] = useState("");
  //higher
  const [bankName, setBankName] = useState("");
  const [region, setRegion] = useState("");
  const [region1, setRegion1] = useState(["Select Region", "Local", "Center"]);

  //setting current date for the sstem 
  let tempDate = new Date();
  let ddate = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();
  const [currdateOfTrans, setcurrDate] = useState(ddate);
  const [openingBalance, setOpeningBalance] = useState("");
  const [currentBalance, setcurrentBalance] = useState(0);

  let modeData = [
    {
      mode: 'Cash'
    },
    {
      mode: 'Online'
    }
  ]

  function FormulaOne() {
    arrayOfObject();
  }

  useEffect(() => {
    getFirmData();
    getledgerData();
  }, []);


  function arrayOfObject() {
    // arry of object for products
   
    let ProductObj = {
      dateOfTrans: dateOfTrans,
      paid: +paid,
      recieve: +recieve ? recieve : 0,
      firmName: firmName,
      mode: mode,
      checqueNo: checqueNo,
      remarks: remarks,
      customOrder: customOrder
    };
    ProductEntries.push(ProductObj);
    console.log(customOrder)
    console.log(ProductEntries)
    //calling this function for
    setCustomOrder(prevOrder => prevOrder + 1);

    // let totalAmt;
    // ProductEntries.forEach((item) => {
    //   totalAmt = 0;
    //   totalAmt += parseFloat(item.recieve);
    //   totalAmt -= parseFloat(item.paid);
    // });

    // let finalAmt = parseFloat(totalAmt) + parseFloat(currentBalance);
    // console.log('final amt: ' + currentBalance);
    // // let totalAmount = parseFloat(finalAmt) + parseFloat(openingBalance)
    // // setCurrAmt(finalAmt);
    // setcurrentBalance(parseFloat(finalAmt))
    

    //calling this function for further calculation of product array of object
    AfterAddingAllValuesCalculationFunction();
    console.log("calling function");
  }


  function AfterAddingAllValuesCalculationFunction() {

    const recalculatedTotals = calculateTotals(ProductEntries, openingBalance);
    console.log('Recalculated Totals:', recalculatedTotals);

    setcurrentBalance(parseFloat(recalculatedTotals.finalAmt))
    settotalpaid(recalculatedTotals.totalPaid);
    setTotalrecieve(recalculatedTotals.totalReceive);
  };

  const calculateTotals = (entries, currentBalance) => {
    let totalReceive = 0;
    let totalPaid = 0;
  
    for (let i = 0; i < entries.length; i++) {
      if (entries[i].paid) {
        let v = parseFloat(entries[i].paid);
        totalPaid += isNaN(v) ? 0 : v;
      }
      if (entries[i].recieve) {
        let v = parseFloat(entries[i].recieve);
        totalReceive += isNaN(v) ? 0 : v;
      }
    }
  
    let totalAmt = (totalReceive - totalPaid).toFixed(2);
    let finalAmt = parseFloat(totalAmt) + parseFloat(currentBalance);
  
    return {
      totalReceive: totalReceive.toFixed(2),
      totalPaid: totalPaid.toFixed(2),
      totalAmt: totalAmt,
      finalAmt: finalAmt.toFixed(2),
    };
  };



  //function is closed;
  //global params id we are using this id in two function one for getting data other for sending;
  const params = useParams();

  const getledgerData = async () => {
    //token;

    let token = localStorage.getItem('x-api-key');

    let response = await axios.post(`api/user/ledger/view/${params.id}`, {}, {
      // method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": token
      },
    });

    let data = response.data;
    setBankName(data.data.bankName);
    setRegion(data.data.region);
    setOpeningBalance(data.data.openingBalance);
    setcurrentBalance(data.data.currentBalance);
    settotalpaid(data.data.totalAmtPaid);
    setTotalrecieve(data.data.totalAmtReceive);
    setProductEntries(data.data.ledgerPayments);
    setCustomOrder(data.data.ledgerPayments[data.data.ledgerPayments.length -1].customOrder+1);
  };

  // getting all firm details from data base
  const [firmData, setFirmData] = useState("");

  const getFirmData = async () => {
    let token = localStorage.getItem('x-api-key');
    let response = await axios.get("api/user/firm/getAllFirmDetails", {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": token
      },
    }
    );
    let result = response.data;
    setFirmData(result.data);
  };

  //Delete Array of Object
  // function DeleteArrayOfObject(index) {
  //   ProductEntries.splice(index, 1)
  //   openModal2();
  //   AfterAddingAllValuesCalculationFunction();
  // }
  function DeleteArrayOfObject(index) {
    let deleteItem = ProductEntries[index];
    ProductEntries.splice(index, 1);
    // console.log(deleteItem);
    // let rec = deleteItem.recieve;
    // let pay = deleteItem.paid;
    // let totalAmt = 0;
    // totalAmt -= parseFloat(rec);
    // totalAmt += parseFloat(pay);
    // let finalAmt = totalAmt + parseFloat(currentBalance);
    // // let totalAmount = parseFloat(finalAmt) - parseFloat(openingBalance)
    // setcurrentBalance(parseFloat(finalAmt));
    openModal2();
    AfterAddingAllValuesCalculationFunction();
  }

  // updating vlues which are changed by the user 
  const [UpdateIndex, setIndexForUpdate] = useState("") //for index
  const [updatePaid, setUpdatePaid] = useState("") 
  const [updateDate, setUpdateDate] = useState("") 
  const [updateRecieve, setUpdateRecieve] = useState("") 
  const [updateFirmName, setUpdateFirmName] = useState("") 
  const [updateMode, setUpdateMode] = useState("") 
  const [updateChequeNo, setUpdateChequeNo] = useState("") 


  function UpdateArrayofObject(index) {

    console.log(bankName, region, currdateOfTrans, totalrecieve, totalpaid, openingBalance,
      currentBalance, ProductEntries);

    if (dateOfTrans) { ProductEntries[index].dateOfTrans = dateOfTrans }
    if (paid) { ProductEntries[index].paid = paid }
    if (recieve) { ProductEntries[index].recieve = recieve }
    if (firmName) { ProductEntries[index].firmName = firmName }
    if (mode) { ProductEntries[index].mode = mode }
    if (checqueNo) { ProductEntries[index].checqueNo = checqueNo }
    if (remarks) { ProductEntries[index].remarks = remarks}

    // let updatedItem = ProductEntries[index];
    // // ProductEntries.splice(index, 1);
    // console.log(updatedItem);
    // let rec = updatedItem.recieve;
    // let pay = updatedItem.paid;
    // let totalAmt = 0;
    // totalAmt += parseFloat(rec);
    // totalAmt -= parseFloat(pay);
    // let finalAmt = totalAmt + parseFloat(openingBalance);
    // // let totalAmount = parseFloat(finalAmt) - parseFloat(openingBalance)
    // setcurrentBalance(parseFloat(finalAmt));

    openModal2();

    // calling function after adding all the values 
    // to recalculate the total sum 
    AfterAddingAllValuesCalculationFunction();

  }

  //sending updated values to the user
  const sendLedgerEntryData = async () => {
    //token
    try {
    console.log('console all data before send',{
      bankName: bankName,
      region: region,
      currdateOfTrans: currdateOfTrans,
      totalAmtReceive: totalrecieve,
      totalAmtPaid: totalpaid ? totalpaid : 0,
      openingBalance: openingBalance,
      currentBalance: currentBalance,
      paymentEntries: ProductEntries
    })
    // return
    let token = localStorage.getItem('x-api-key');
    let response = await axios.put(`api/user/ledger/update/${params.id}`,
      {
        bankName: bankName,
        region: region,
        currdateOfTrans: currdateOfTrans,
        totalAmtReceive: totalrecieve,
        totalAmtPaid:  totalpaid ? totalpaid : 0,
        openingBalance: openingBalance,
        currentBalance: currentBalance,
        paymentEntries: ProductEntries
      },
      {
        // method: "put",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": token
        },
        // body: JSON.stringify(),
      });

    if (response.status === 200) {
      let result = response.data;

      if (result.message === "success") {
        Swal.fire("Generated!", "Your ledger entry has been updated.", "success");
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: result.data.message,
        });
      }
    } else {
      // Handle unexpected status codes or errors
      // Display an appropriate error message to the user
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to update the ledger entry. Please try again later.",
      });
    }
  } catch (error) {
    // Handle any unexpected errors that might occur during the request
    // Display an appropriate error message to the user
    console.error("Error:", error);
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "An unexpected error occurred. Please try again later.",
    });
  }
  
  };

 

  // --------------------------------------------------- showing data in comments ---------------------
  // setting for output of product list

  return (
    <Layout heading_1="Ledger" heading_2="Ledger Update">
      <>
      <div className="main-div-ledger-entry">
        {/* new added part 1 */}
        <div className="center-values-ledger-entry">
         {/* accound name and region section */}
          <div className="AC-RegionDiv-ledger-entry">
            
          <div className="dateBalanceInfoDivdateDiv">
              <label className="dateLabeldocLabel">Date</label>
              <input
                className="dateLabeldocLabelinput"
                value={currdateOfTrans}
              ></input>
            </div>

            <div className="acntDiv feildsDiv">
              <label className="acname">A/C Name</label>
              <select
                value={bankName}
                onChange={(e) => setBankName(e.target.value)}
                className="Bal-Amt"
                style={{
                  marginRight: "94px",
                  borderRadius: "4px",
                  fontSize: "16px",
                  marginLeft: 10,
                  width: "200px",
                  height: "30px",
                  border: "1px solid #ccc",
                }}
              >
                Firm Name
                <br />
                <option value="">Choose A/C Name</option>
                {firmData
                  ? firmData.map((item, index) => (
                      <option
                        placeholder="A/C Name"
                        onChange={(e) => setBankName(item.accountName)}
                      >
                        {item.accountName}
                      </option>
                    ))
                  : "no data"}
              </select>
            </div>

            <div className="regionDiv feildsDiv">
              <label>Region</label>
              {/* type */}
              <select
                className="inputDiv regionInput"
                onChange={(e) => {
                  console.log("272", e.target.value);
                  setRegion(e.target.value);
                }}
                value={region}
                style={{ fontSize: "16px", borderRadius: "5px" }}
              >
                {region1 && region1.map((item, index) => <option>{item}</option>)}
              </select>
            </div>
          </div>
          {/* date and balance  */}
          <div className="dateAndBalanceDiv">
           
            <div className="dateBalanceInfoDiv openingBalanceDiv">
              <label className="openingBalanceLabel docLabel">
                Opening Balance
              </label>
              <input
                className="dateLabeldocLabelinput"
                value={openingBalance}
                onChange={(e) => setOpeningBalance(e.target.value)}
              />
            </div>
            <div className="dateBalanceInfoDiv currBalanceDiv">
              <label className="currentBalanceLabel docLabel">
                Current Balance
              </label>
              <input className="dateLabeldocLabelinput"
              //  value={ parseFloat(currAmt) + parseFloat(openingBalance) }
               value={currentBalance} 
              //  onChange={((e) => setcurrentBalance(e.target.value))}
                />
            </div>
          </div>
        </div>
        {/* new added part 1 closed */}


          {/* new added part 2 open  */}
          <div 
        className="quantity-box-top-top-ledger-create"
        >
          <table
           id="customers-ledger-entry"
           >
          
          <thead className="ledger-entry-headings">
           
            <tr>
              <th>S.No.</th>
              <th>Firm Name</th>
              <th>Date</th>
              <th>Mode</th>
              <th>Cheque No</th>
              <th>Credit</th>
              <th>Debit</th>
              <th>Action</th>
            </tr>

            
            <tr className="ledger-entry-value-heading-input"
            >
              <td>
                <span className="span-width">0</span>
              </td>
              <td>
                <select
                  onChange={(e) => setFirmName(e.target.value)}
                  className="ProductName-entry"
                  style={{ width: "10rem" }}
                >
                  Firm Name
                  <br />
                  <option value="">Choose Firm Name</option>
                  {firmData
                    ? firmData.map((item, index) => (
                        <option
                          placeholder="First Name"
                          onChange={(e) => setFirmName(e.target.value)}
                        >
                          {item.firmName}
                        </option>
                      ))
                    : "no data"}
                </select>
              </td>
              <td>
                <input
                  type="Date"
                  value={dateOfTrans}
                  onChange={(e) => setdateOfTrans(e.target.value)}
                />
              </td>
              <td>
                <select onChange={(e) => setMode(e.target.value)}>
                  Firm Name
                  <br />
                  {modeData
                    ? modeData.map((item, index) => (
                        <option
                          placeholder="First Name"
                          onChange={(e) => setFirmName(e.target.value)}
                        >
                          {item.mode}
                        </option>
                      ))
                    : "no data"}
                </select>
              </td>
              <td>
                <input
                  type="text"
                  value={checqueNo}
                  onChange={(e) => {
                    setchecqueNo(e.target.value);
                  }}
                />
              </td>
              <td>
                <input
                  type="Number"
                  value={recieve}
                  onChange={(e) => {
                    setrecieve(e.target.value);
                  }}
                />
              </td>
              <td>
                <input
                  type="Number"
                  value={paid}
                  onChange={(e) => {
                    setpaid(e.target.value);
                  }}
                />
              </td>
              <td>
                <button
                  onClick={() => {
                    handleOpenPopup();
                    setShowUpdate('')
                    // FormulaOne();
                  }}
                  // onClick={FormulaOne}
                  style={{
                    backgroundColor: "rgb(1, 89, 152)",
                    color: "white",
                    borderRadius: "7px",
                    fontSize: "16px",
                    padding: "2px 12px",
                  }}
                >
                  Save
                </button>
              </td>
            </tr>
            </thead>
            
              
            <tbody className="payment-entries-scroll">
            {ProductEntries ? (
              ProductEntries.map(function (item, index) {
                return (
                
                  <tr>
                  <td className={(index + 1) % 2 === 1 ? "white" : "grey"} ><span className='span-width'>{index + 1}</span></td>

                  <td>
                    {/* sdfgh dvfghjkl;lkjhgfghjkl;kjhgfdsfghjkl;lkujyhtgrfertyuioilukyjthrgefwrtyuiouytrewertyuiopiuytrertyuioiuytrertyuioiuytrertyuiouytr*/}
                    <input
                      value={updateFirmName && UpdateIndex === index ? firmName : item.firmName}
                      onChange={((e) => setFirmName(e.target.value))}
                      onClick={(() => { setUpdateFirmName(true); setIndexForUpdate(index) })}
                    />
                  </td>
                  <td>
                    <input
                      value={updateDate && UpdateIndex === index ? dateOfTrans : item.dateOfTrans}
                      onChange={((e) => setdateOfTrans(e.target.value))}
                      onClick={(() => { setUpdateDate(true); setIndexForUpdate(index) })}
                    />
                  </td>
                  <td>
                    <input
                      value={updateMode && UpdateIndex === index ? mode : item.mode}
                      onChange={((e) => setMode(e.target.value))}
                      onClick={(() => { setUpdateMode(true); setIndexForUpdate(index) })}
                    />
                  </td>
                  <td>
                    <input
                      value={updateChequeNo && UpdateIndex === index ? checqueNo : item.checqueNo}
                      onChange={((e) => setchecqueNo(e.target.value))}
                      onClick={(() => { setUpdateChequeNo(true); setIndexForUpdate(index) })}
                    />
                  </td>
                  <td>
                    <input
                      value={updateRecieve && UpdateIndex === index ? recieve : item.recieve}
                      onChange={((e) => setrecieve(e.target.value))}
                      onClick={(() => { setUpdateRecieve(true); setIndexForUpdate(index) })}

                    ></input>
                  </td>
                  <td>
                    <input
                      value={updatePaid && UpdateIndex === index ? paid : item.paid}
                      onChange={((e) => setpaid(e.target.value))}
                      onClick={(() => { setUpdatePaid(true); setIndexForUpdate(index) })}
                    />
                  </td>
                  <button
                   id='lastbutton'
                   className='deleteButton'
                   onClick={(() => { 
                    DeleteArrayOfObject(index) 
                    })} 
                    >  Delete </button>
                  <button
                  className='updateButton'
                    id='lastbutton'
                   onClick={(() => { 
                    // UpdateArrayofObject(index); 
                    handleOpenPopup(index);
                    setRemarks(item.remarks);
                    setTrackIndex(index);
                    setShowUpdate(true)
                    })} 
                    >Update</button>
                </tr>
                 
                );
              })
            ) : (
              <h3>no Product selected</h3>
            )}
            </tbody>
          </table>
        </div>
          {/* new added part 2 closed */}
     
        {/* newa added part 3 open */}
        <div id="amount-receive">
          <div>
            <label id="amount-receive-total">Total</label>
          </div>
          <div>
            <label style={{ marginRight: 20 }}>Credit</label>
            <input id="inputid" value={totalrecieve} />
          </div>

          <div>
            <label style={{ marginRight: 20 }}>Debit</label>
            <input id="inputid" value={totalpaid} />
          </div>

          <div style={{ width: 100 }}>
            <button onClick={sendLedgerEntryData}>Save</button>
          </div>
        </div>
        {/* new added part 3 closed */}

      </div>

      { showPopup &&  <div>
      <div className="input-remarks-modal">
        <div className="input-remarks-modal-main">
          <textarea 
          value={remarks}
          onChange={(event) => setRemarks(event.target.value)}
          placeholder="Enter remarks here..." />
          { showUpdate ? <button  onClick={ () =>{ handleClosePopupForUpdate();  } }>OK</button> :
           <button  onClick={ () =>{   handleClosePopupForSave(); } }>OK</button> }
        </div>
      </div>
    </div>}
    </>
    </Layout >
  );
};
export default LedgerUpdate;
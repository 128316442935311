import React, { useState, useEffect, useRef } from 'react';


import "./SalesEntry.css";
import warningIcon from './warning-icon.png';

const WarningPopup = ({ showWarning, handleCloseWarning, warningMessage }) => {
  const okButtonRef = useRef(null);

  useEffect(() => {
    if (showWarning && okButtonRef.current) {
      okButtonRef.current.focus();
    }
    if(showWarning && okButtonRef.current && okButtonRef.current.focus()){
        alert('wow');
    }
  }, [showWarning]);

  return (
    <>
       {showWarning && (
        <div class="overlay">
          <div className="parentWarningPopup">
           {/*  first half */}
           <div class="top">
           <span class="close-icon" 
           onClick={ () =>{ 
            handleCloseWarning();
          }}
            >&times;</span>
           </div>

            {/* second half */}
            <img src={warningIcon} alt="Warning Icon" class="warning-icon" />
            <div class="bottom">
            
            <div class="warning-title">Warning!</div>
            <div class="warning-message">{warningMessage}</div>
           <div
           style={{
            alignItems:"center",
            justifyContent:"center",
            display:"flex",
            alignSelf:"center",
           }}
           >
           <button
            style={{
              backgroundColor:'#cc2328',
              height:30,
              width:70,
              alignItems:"center",
              justifyContent:"center",
              display:"flex",
              alignSelf:"center",
            }}
            ref={okButtonRef}
            // tabIndex={0} 
            autoFocus={true}
            // autoFocus // Focuses on the button when it appears
            onKeyDown={(event) =>{
                 if(event.key == 'Enter' || event.key == 'Tab'){
                  handleCloseWarning();
                 }
            }}
            // onClick={ () =>{
            //     handleCloseWarning();
            //     }}
            > 
            Ok
            </button>
             </div>
          </div>   
          
        </div>
        
    </div>
      )}
    </>
  );
};

export default WarningPopup;

import './LedgerView.css';
import axios from "axios";

// import Header from '../../navbar/Nav2';
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import Layout from "../../Layout/Layout";

const LedgerView = () => {

  //setting current date
  let tempDate = new Date();
  let ddate = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate() + ' ' + tempDate.getHours() + ':' + tempDate.getMinutes() + ':' + tempDate.getSeconds();

  const [currDate, setcurrDate] = useState(ddate);
  const [openingBalance, setOpeningBalance] = useState("");
  const [currentBalance, setcurrentBaklance] = useState("");
  const [totalCredit, setTotalcredit] = useState("");
  const [totalDebit, settotalDebit] = useState("");

  const [bankName, setBankName] = useState("");
  const [region, setRegion] = useState("");


  useEffect(() => {
    getledgerData();
  }, []);


  // getting all the product details from the data base
  const [ledgerList, setLedgerlist] = useState([]);
  const params = useParams();
  console.log(31, params.id);

  const getledgerData = async () => {
    //token
    console.log(params.id);
    let token = localStorage.getItem('x-api-key');
    console.log(token);
    // let result = await axios("https://hegan-backend.onrender.com/api/user/getAll", {
    let response = await axios.post(`api/user/ledger/view/${params.id}`, {}, {
      // method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": token
      },
    });
    let data =  response.data;
    console.log('getting view data in this ')
    setBankName(data.data.bankName);
    setRegion(data.data.region);
    setOpeningBalance(data.data.openingBalance);
    setcurrentBaklance(data.data.currentBalance);
    settotalDebit(data.data.totalAmtPaid);
    setTotalcredit(data.data.totalAmtReceive);

    setLedgerlist(data.data.ledgerPayments);
    console.log(ledgerList);
    console.log(data.data.ledgerPayments)
  };







  // --------------------------------------------------- showing data in comments ---------------------
  // setting for output of product list










  // const getledgerData = async () => {
  //   //token;
  //   console.log(params.id)

  //   let token = localStorage.getItem('x-api-key');

  //   let response = await axios.post(`api/user/ledger/view/${params.id}`, {}, {
  //     // method: "post",
  //     headers: {
  //       "Content-Type": "application/json",
  //       "x-api-key": token
  //     },
  //   });

  //   let data = response.data;
  //   console.log(response.data);

  //     setBankName(data.bankName);
  //     setRegion(data.region);
  //     setOpeningBalance(data.openingBalance);
  //     setcurrentBaklance(data.currentBalance);
  //     settotalDebit(data.totalAmtPaid);
  //     setTotalcredit(data.totalAmtReceive);
  
  //   //   setLedgerlist(result.data.ledgerPayments);
  //   // setBankName(data.bankName);
  //   // setRegion(data.region);
  //   // setOpeningBalance(data.openingBalance);
  //   // setcurrentBaklance(data.currentBalance);
  //   // settotalpaid(data.totalAmtPaid);
  //   // setTotalrecieve(data.totalAmtReceive);
  //   // setProductEntries(data.data.ledgerPayments);
  // };



  return (
    <Layout heading_1="Ledger" heading_2="Ledger View">
      <div className="main-div-purchase-entry">
      
        
        {/* new added first one half start*/}
        <div className="center-values-ledger-entry">
         {/* accound name and region section */}
          <div className="AC-RegionDiv-ledger-entry">
            
          <div className="dateBalanceInfoDivdateDiv">
              <label className="dateLabeldocLabel">Date</label>
              <input
                className="dateLabeldocLabelinput"
                value={currDate}
              ></input>
            </div>

            <div className="acntDiv feildsDiv">
              <label className="acname">A/C Name</label>
              <input
                value={bankName}
                // onChange={(e) => setBankName(e.target.value)}
                className="Bal-Amt"
                style={{
                  marginRight: "94px",
                  borderRadius: "4px",
                  fontSize: "16px",
                  marginLeft: 10,
                  width: "200px",
                  height: "30px",
                  border: "1px solid #ccc",
                }}
              />
              
            </div>
            <div className="regionDiv feildsDiv">
              <label>Region</label>
              {/* type */}
              <input
                className="inputDiv regionInput"
                value={region}
                style={{ fontSize: "16px", borderRadius: "5px",width: 100 }}
              />              
            </div>
          </div>
          {/* date and balance  */}
          <div className="dateAndBalanceDiv">
           
            <div className="dateBalanceInfoDiv openingBalanceDiv">
              <label className="openingBalanceLabel docLabel">
                Opening Balance
              </label>
              <input
                className="dateLabeldocLabelinput"
                value={openingBalance}
                // onChange={(e) => setOpeningBalance(e.target.value)}
              />
            </div>
            <div className="dateBalanceInfoDiv currBalanceDiv">
              <label className="currentBalanceLabel docLabel">
                Current Balance
              </label>
              <input className="dateLabeldocLabelinput" 
              value={ currentBalance }
               />
            </div>
          </div>
        </div>
        {/* new added first one half end */}


      {/* second half start   */}
     
      <div className="quantity-box-top-top">
          <table id="customers-purchase-entry-ledger">
            <tr>
              <th>S.No.</th>
              <th>Firm Name</th>
              <th>Date</th>
              <th>Mode</th>
              <th>Cheque No </th>
              <th>Credit</th>
              <th>Debit</th>
            </tr>
            {ledgerList ? ledgerList.length>0 &&  ledgerList.map(function (item, index) {
              return (
                <tr>
                  {/* <td className={ (index+1)% 2===1 ? "white" : "grey" } ><span className='span-width'>{index + 1}</span></td> */}
                  <td>{index + 1}</td>
                  <td>
                    {item.firmName}
                  </td>
                  <td>
                    {item.dateOfTrans}
                  </td>
                  <td>
                    {item.mode}
                  </td>
                  <td>
                    {item.checqueNo}
                  </td>
                  <td>
                    {item.recieve}
                  </td>
                  <td>
                    {item.paid}
                  </td>
                </tr>
              );
            }) : <h3>no Product selected</h3>}
          </table>
        </div>
      {/* second half end */}
      

     

        {/* last third half start  */}
        <div id="amount-receive">
          <div>
            <label id="amount-receive-total">Total</label>
          </div>
          <div>
            <label style={{ marginRight: 20 }}>Credit</label>
            <input id="inputid" value={totalCredit} />
          </div>

          <div>
            <label style={{ marginRight: 20 }}>Debit</label>
            <input id="inputid" value={totalDebit} />
          </div>
        </div>
          {/* last third half end */}

      </div>
    </Layout >
  );
};
export default LedgerView;
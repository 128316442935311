import "./BankList.css";
// import BankListHeader from "./BankListHeader";
import Layout from "../../Layout/Layout.js";
// import back from "./back";
import { React, useEffect, useState, useRef } from "react";
import { FaRegFilePdf } from "react-icons/fa";
import { FaFileCsv, FaRegCopy } from "react-icons/fa";
import Swal from "sweetalert2";
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from 'axios';
import autoTable from "jspdf-autotable";
import { CSVLink } from "react-csv";
import { jsPDF } from "jspdf";



//============== Headers for csv file=======================
const headers = [
  { label: "Firm Name", key: "firmName" },
  { label: "Address", key: "address" },
  { label: "Account Name", key: "accountName" },
  { label: "Account Number", key: "accountNo" },
  { label: "IFSC Code", key: "ifsc" },
  { label: "Branch Name", key: "branch" },
];

export default function BankList() {
  const [Bank, setBank] = useState([]);
  const getBanks = () => {
    let token = localStorage.getItem("x-api-key");
    axios
      .get("api/user/firm/getAllFirmDetails", {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": token,  "x-api-plan": "SaaS"
          
        },
      })
      .then(response => {
        // Update the bank state with the response data
        setBank(response.data.data);
        console.log(Bank);
      })
      .catch(error => {
        console.log(error.response.data.message);
      });
  };

  useEffect(() => {
    getBanks();
  }, []);


  // code for filtering firm according to search bar;
  const [filteredList, setFilteredList] = new useState(Bank);
  const [filter, setFilter] = useState(false);
  const filterBankSearch = (event) => {
    console.log(event.target.value)
    setFilter(true);
    let updatedList = [...Bank];
    console.log(updatedList);
    updatedList = updatedList.filter((item) => {
      return (
        item.firmName
          .toLowerCase()
          .indexOf(event.target.value.toLowerCase()) !== -1 ||
        item.accountNo.toLowerCase().indexOf(event.target.value.toLowerCase()) !==
        -1
      );
    });
    setFilteredList(updatedList);
  };


  //==========  converting data to pdf===========
  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 350;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Product List";
    const headers = [
      [
        "S.No",
        "Firm Name",
        "Address",
        "Account Name",
        "Account Number",
        "IFSC Code",
        "Branch Name",
      ],
    ];
    // console.log(filteredProductList);
    let data;
    if (filter) {
      data = filteredList.map((elt, index) => [
        index + 1,
        elt.firmName,
        elt.fullAdd,
        elt.accountNo,
        elt.ifsc,
        elt.branch,
      ]);
    } else {
      data = Bank.map((elt, index) => [
        index + 1,
        elt.firmName,
        elt.fullAdd,
        elt.accountNo,
        elt.ifsc,
        elt.branch,
      ]);
    }
    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    autoTable(doc, content);
    doc.save("Bank List.pdf");
  };

  //========= converting filtered data to csv============//
  const csvDownloadRef = useRef(null);
  const convertToCsv = () => {
    setTimeout(() => {
      csvDownloadRef.current.link.click();
    }, 500);
  };

  return (
    <Layout heading_1="Bank" heading_2="Bank Details">
      <div className="main-Banklist-file">
        <div className="Banklist-main">
          {/* <BankListHeader /> */}
          <div className="Banklist">
            <div className="table-top-Banklist">
              <div className="show-name-Banklist">
                <label htmlFor="show">Show : </label>
                <input 
                id="show"
                style={{
                  width: "100px",
                  height: "30px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                }}
                />
                <FaRegFilePdf
                  className="pdf-Banklist"
                  style={{ cursor: "pointer" }}
                  onClick={(event) => {
                    exportPDF();
                  }}
                  id="pdf"
                />
                <FaFileCsv
                  className="csv-Banklist"
                  style={{ cursor: "pointer" }}
                  onClick={(event) => {
                    convertToCsv(event);
                  }}
                />
              </div>

              <div className="search-name-Banklist">
                <input placeholder="Search by Account No, Firm Name"
                  onChange={filterBankSearch}
                />
              </div>
            </div>
            <div className="table-customer-Banklist">
              <table id="customers-Banklist">
                <tr className="table-customer-heading-Banklist">
                  <th>S.No</th>
                  <th>Firm Name</th>
                  <th>Address</th>
                  <th>Account Name</th>
                  <th>Account No.</th>
                  <th>IFSC Code</th>
                  <th>Branch Name</th>
                  <th>Action</th>
                </tr>
                {filter ? (
                  filteredList.map((item, index) => (
                    <tr key={item.id} className="outputlist1-Bank-list">
                      <td className={(index + 1) % 2 === 1 ? "white" : "grey"}>
                        <span className="span-Bwidth">{index + 1}</span>
                      </td>
                      <td className={(index + 1) % 2 === 1 ? "white" : "grey"}>
                        <span className="span-Bwidth"> {item.firmName}</span>
                      </td>
                      <td className={(index + 1) % 2 === 1 ? "white" : "grey"}>
                        <span className="span-Bwidth"> {item.fullAdd}</span>
                      </td>
                      <td className={(index + 1) % 2 === 1 ? "white" : "grey"}>
                        <span className="span-Bwidth"> {item.accountName}</span>
                      </td>
                      <td className={(index + 1) % 2 === 1 ? "white" : "grey"}>
                        <span className="span-Bwidth"> {item.accountNo}</span>
                      </td>
                      <td className={(index + 1) % 2 === 1 ? "white" : "grey"}>
                        <span className="span-Bwidth"> {item.ifsc}</span>
                      </td>
                      <td className={(index + 1) % 2 === 1 ? "white" : "grey"}>
                        <span className="span-Bwidth"> {item.branch}</span>
                      </td>

                      <div className="action-button-Bank-table">

                        <button className="edit-sale-list">
                          <Link to={"/user/updateBank/" + item.id}> Edit</Link>
                        </button>
                      </div>
                    </tr>
                  ))
                ) : Bank.map((item, index) => (
                  <tr key={item.id} className="outputlist1-Bank-list">
                    <td className={(index + 1) % 2 === 1 ? "white" : "grey"}>
                      <span className="span-Bwidth">{index + 1}</span>
                    </td>
                    <td className={(index + 1) % 2 === 1 ? "white" : "grey"}>
                      <span className="span-Bwidth"> {item.firmName}</span>
                    </td>
                    <td className={(index + 1) % 2 === 1 ? "white" : "grey"}>
                      <span className="span-Bwidth"> {item.fullAdd}</span>
                    </td>
                    <td className={(index + 1) % 2 === 1 ? "white" : "grey"}>
                      <span className="span-Bwidth"> {item.accountName}</span>
                    </td>
                    <td className={(index + 1) % 2 === 1 ? "white" : "grey"}>
                      <span className="span-Bwidth"> {item.accountNo}</span>
                    </td>
                    <td className={(index + 1) % 2 === 1 ? "white" : "grey"}>
                      <span className="span-Bwidth"> {item.ifsc}</span>
                    </td>
                    <td className={(index + 1) % 2 === 1 ? "white" : "grey"}>
                      <span className="span-Bwidth"> {item.branch}</span>
                    </td>

                    <div className="action-button-Bank-table">

                      <button className="edit-sale-list">
                        <Link to={"/user/updateBank/" + item.id}> Edit</Link>
                      </button>
                    </div>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

import React from 'react'
// import { BsFillBellFill, BsSearch } from "react-icons/bs";
import './nav2.css'


const Nav2 = () => {
      return (

            <div className='navbar-right-side'>
                  {/* <div className='navbar-right-side-search'>
                <BsSearch className='navbar-logo-size'/>
                <input className='BsSearch' placeholder='type here...' />

          </div>
                
          <div className='navbar-right-side-logo'>                
                  <BsFillBellFill className='navbar-logo-size' />
                  <p>Name</p>
              </div> */}


            </div>
      )
}

export default Nav2
import "./layout.css";
import { MdNotificationsActive } from "react-icons/md";
import { useNavigate, Link } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import { AiOutlineDownload } from "react-icons/ai";
import { CgLogOut } from "react-icons/cg";
import { RxAvatar } from "react-icons/rx";
import React, { useState, useEffect } from "react";
import { useGlobalContext } from "components/Context";
import notification_icon from "../../images/notification_icon.png";
import axios from "axios";
import Swal from "sweetalert2";

const Layout = ({ heading_1, children, heading_2 }) => {
  let user = localStorage.getItem("userName");

  user = user.slice(0, 27) + "";

  let {
    readNotification,
    unreadNotification,
    checkIsReadNotification,
    handleLogout,
  } = useGlobalContext();

  // uncommented this one 
  function handleLogoutButton() {
      handleLogout();
      setTimeout(() => {
          navigate("/")
      }, 1000);
  };

  function handleLogoutButton() {
    // Display the confirmation dialog
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, logout",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        // User clicked the "Yes, logout" button, call the API
        handleLogout();
        setTimeout(() => {
          navigate("/");
        }, 1000);
      }
    });
  }

  useEffect(() => {
    getExpiryUser();
  }, []);

  const [expiryDays, setExpiryDays] = useState("");
  const [isPaymentDone,setIsPaymentDone] = useState("");

  // search hande user can search by typing firm name or user name
  // const getExpiryUser = async (event) => {
  //   let token = localStorage.getItem("x-api-key");
  //   let result = await axios.post(
  //     `api/user/expiryUser`,
  //     {},
  //     {
  //       // method: "post",
  //       headers: {
  //         "Content-Type": "application/json",
  //         "x-api-key": token,
  //         "x-api-plan": "SaaS",
  //       },
  //     }
  //   );
  //   // result = await result.json();
  //   console.log("user expiray date days count data");
  //   console.log(result.data.data);
  //   setExpiryDays(result.data.data);
  // };


  const getExpiryUser = async (event) => {
    try {
      console.log('get expir is working')
      let token = localStorage.getItem("x-api-key");
      if (!token) {
        console.log("Invalid or missing token.");
        return;
      }
     console.log('user token',token);

      let result = await axios.post(
        `api/user/expiryUser`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": token,
            "x-api-plan": "SaaS",
          },
        }
      );

   console.log('expiry user',result.data)
      if(result.data.status == 404){
        // alert('not found')
        // uncommented this one from 2
        console.log('user expiry not found')
        localStorage.setItem("x-api-key", "");
        localStorage.setItem("isLoggedIn", false);
        setTimeout(() => {
          navigate("/");
        }, 1000);
      }
  
      if (result.status !== 200 || !result.data || !result.data.data) {
        localStorage.setItem("x-api-key", "");

        localStorage.setItem("isLoggedIn", false);
        console.error("Invalid response from the server.");
        return;
      }
     
      console.log("User expiry date days count data");
      console.log(result.data.data);
      setExpiryDays(result.data.data);
      setIsPaymentDone(result.data.isPaymentDone)

    } catch (error) {
      localStorage.setItem("x-api-key", "");
      localStorage.setItem("isLoggedIn", false);
    
      setTimeout(() => {
        navigate("/");
      }, 1000);
    
      console.log('getting error in reposne')
      console.log(error.message);
      console.error("Error fetching expiry user data:", error.message);
      // Handle any other errors that may occur during the API call
    }
  };


  const navigate = useNavigate();
  const handleUserInfo = () => {
    // Navigate to the /dashboard page
    navigate("/user/Profile");
  };
  const handleDesktop = () => {
    // Navigate to the /desktop page
    navigate("/user/Desktop");
  };
  const [showProfileBox, setShowProfileBox] = useState(false);
  const [showUserProfile, setShowUserProfile] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);

  // notification functiion;

  const handleNotificationClick = async () => {
    // Simulate a new notification and emit it to the server
    //update_users_notifications
    navigate("/user/notifications");
    let token = localStorage.getItem("x-api-key");
    // Call the logout API when the "Log Out" button is clicked
    await checkIsReadNotification();
    axios
      .post(
        "api/user/update_users_notifications",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": token,
            "x-api-plan": "SaaS",
          },
          // You may include authentication tokens if required
        }
      )
      .then((result) => {
        // Handle the response if needed (e.g., show a message to the user)
        console.log(result.data);
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle error scenarios
      });
    // Get_notifications();
  };

  const handleProfileBox = (event) => {
    setShowProfileBox(!showProfileBox);
  };

  const closeUserInfo = (event) => {
    setShowUserProfile(false);
  };

  const closeProfileBoxWithEscape = (event) => {
    setShowProfileBox(false);
  };

  const closeUserInfoWithEscape = (event) => {
    setShowUserProfile(false);
  };

  useEffect(() => {
    function handleEscapeKey(event) {
      if (event.keyCode === 27) {
        if (showProfileBox) {
          closeProfileBoxWithEscape();
        }
        if (showUserProfile) {
          closeUserInfoWithEscape();
        }
      }
    }

    window.addEventListener("keydown", handleEscapeKey);

    return () => {
      window.removeEventListener("keydown", handleEscapeKey);
    };
  }, [showProfileBox, showUserProfile]);

  // Assuming you have variables like heading_1, expiryDays, etc. defined elsewhere

  const handleUpgradeClick = (e) => {
    e.preventDefault();
    navigate("/user/upgrade");
  };

  return (
    <div
    // style={{width:'75%'}}
    >
      <h4 className="heading_1">
        <span className="head_span_1">{heading_1}</span>
        { isPaymentDone ?  ( <span className="head_span_2">
          Your Plan Validity Ends In: {expiryDays} days
        </span> ) : (
        <span className="head_span_2">
          Your Trial Period end in : {expiryDays} days
        </span>)}

        <button
          className="upgrade-button"
          onClick={handleUpgradeClick} // Call the handleUpgradeClick function
        >
          Upgrade Your Plan
        </button>

        {/* <div className="ellipsis-container">
                    <span>{user}</span>
                </div>
                <div style={{
                    display: 'flex',
                    width: '200px',
                    justifyContent: 'end',
                }}>
                    <div style={{ display: "flex", marginRight: "1rem" }}>
                        <div className="icon-container">
                            <img
                                src={notification_icon} // Replace with the actual path to your bell icon image
                                alt="Bell Icon"
                                onClick={handleNotificationClick}
                                style={{
                                    cursor: "pointer",
                                    width: "25px", // Adjust the width as needed
                                    height: "25px", // Adjust the height as needed
                                }}
                            />
                            <span style={{
                                fontSize: '11px',
                                position: 'relative',
                                right: '9px',
                                bottom: '10px',
                                color: 'white',
                            }}>{unreadNotification}
                            </span>
                        </div>
                        <RxAvatar
                            onClick={handleProfileBox}
                            style={{ cursor: "pointer", alignSelf: "center" }}
                            size={25}
                        />
                    </div>
                </div> */}

        <div
          style={{
            display: "flex",
            width: "40%", // Adjust the width as needed
            justifyContent: "space-between", // This will position the divs at both ends
          }}
        >
          <div className="ellipsis-container">
            <span style={{ color: "black" }}>{user}</span>
          </div>
          <div>
            <div style={{ display: "flex" }}>
              <div className="icon-container">
                <img
                  src={notification_icon} // Replace with the actual path to your bell icon image
                  alt="Bell Icon"
                  onClick={handleNotificationClick}
                  style={{
                    cursor: "pointer",
                    width: "25px", // Adjust the width as needed
                    height: "25px", // Adjust the height as needed
                  }}
                />
                <span
                  style={{
                    fontSize: "11px",
                    position: "relative",
                    right: "9px",
                    bottom: "10px",
                    color: "white",
                  }}
                >
                  {unreadNotification}
                </span>
              </div>
              <RxAvatar
                onClick={handleProfileBox}
                style={{ cursor: "pointer", alignSelf: "center" }}
                size={25}
              />
            </div>
          </div>
        </div>
      </h4>
      <h4 className="heading_2">
        <span className="head_span_3">{heading_2}</span>
      </h4>
      {children}
      {/* </div> */}
      {showProfileBox && (
        <div className="profileBoxContainer_1">
          <div className="profileDiv innerBoxDiv_1" onClick={handleUserInfo}>
            <div
              className="profileIcon iconDiv_1"
              style={{ display: "flex", marginLeft: "8%" }}
            >
              <CgProfile size={20} style={{ marginTop: "8px" }} />
              <h4 style={{ marginLeft: "18px", fontSize: "17px" }}>Profile</h4>
            </div>
            {/* <div className="headingProfile heading-Div_1" >
                            <h4>Profile</h4>
                        </div> */}
          </div>
          <div
            className="downloadSdkDiv innerBoxDiv_1"
            onClick={handleDesktop}
            style={{ height: "53px" }}
          >
            <div
              className="downloadIcon iconDiv_1"
              style={{ display: "flex", marginLeft: "8%" }}
            >
              <AiOutlineDownload size={20} style={{ marginTop: "16px" }} />
              <h4
                style={{
                  marginLeft: "18px",
                  marginTop: "8px",
                  fontSize: "17px",
                }}
              >
                Desktop Version
              </h4>
            </div>
          </div>
          <div className="logoutDiv innerBoxDiv_1" style={{ height: "53px" }}>
            <div
              className="logoutIcon iconDiv_1"
              style={{ display: "flex", marginLeft: "8%" }}
            >
              <CgLogOut size={20} style={{ marginTop: "8px" }} />
              <h4
                onClick={handleLogoutButton}
                style={{ marginLeft: "18px", fontSize: "17px" }}
              >
                Logout
              </h4>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Layout;

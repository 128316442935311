/** @format */

import React, { useState, useEffect, useRef } from "react";
import { useParams} from "react-router-dom";
// import Header from "./PurchaseEntryHeader";
import Layout from "../../Layout/Layout";
import "../PurchaseEntry/Entry.css";
import "../PurchaseEntry/firmModal.css";
// import { useNavigate } from "react-router";
import { Switch, ConfigProvider } from "antd";
import hegan from "../PurchaseEntry/heganB-Wsymbol.png";
import axios from "axios";

const PurchaseViewEstimate = () => {
  const [state1, setState1] = React.useState("");

  // ============== seller details api for print out ==================== //

  let [userDetails, setUserDetails] = useState({});
  const BuyerDetails = async () => {
    try {
      //token
      let token = localStorage.getItem("x-api-key");

      let response = await axios.post(
        "api/user/getOneUser",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": token,
            "x-api-plan": "SaaS",
          },
          // method: "post",
        }
      );

      let result = response.data;

      console.log(580, result);

      setUserDetails(result.data);
    } catch (error) {
      console.log(error.response.data.message);
    }
  };


  // for value update while changing on existing data in purchase entry array of object;


  // --------------------firm modal code---------------------------- //
  const [selectedFirm, setSelectedFirm] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");

  const handleSelectedFirm = (val) => {
    setSelectedFirm(val);
  };

  const handleSelectedProduct = (val) => {
    setSelectedProduct(val);
  };

  // --------------------firm modal code-------------------------- //
  //getting all firm details from the data base
  const [firmData, setFirmData] = useState("");
 
  // for showing model and hiding model
  const [showModal, setShowModal] = React.useState(false);
  const [indexView, setIndexView] = useState("");

  const [showModal2, setShowModal2] = React.useState(false);

  function openModal(index) {
    setIndexView(index)
      setShowModal(function (item) {
        return !item;
      });
  }

  function openModal2() {
    setShowModal2(function (item) {
      return !item;
    });
  }

  //firm name and firm id
  const [firm, setFirm] = React.useState("");
  const [firmId, setFirmId] = useState("");

  //product id check line no 564;

  const [ProductId, setProductId] = useState("");

  const [region, setRegion] = React.useState("");
  const [type, setType] = React.useState("");
  const [invoiceSelect, setInvoiceSelect] = useState(true);
  const [cnRefSelect, setCnRefSelect] = useState(false);

  // print options ref
  const mainSalesInvoiceToggleRef = useRef(null);

  const estimateSalesInvoiceToggleRef = useRef(null);

  const confirmChoicesRef = useRef(null);


  useEffect(() => {}, [setInvoiceSelect, setCnRefSelect]);
  // getting data from backend to show prefilled data in input box 
   //getting data from backend to set in field to update
   const params = useParams();
  //  alert(params.id)
   const updateProductDetails = async () => {
     //token
     try{
     let token = localStorage.getItem("x-api-key");
    //  alert(token)
     let response = await axios.post(`api/user/purchase/view/${params.id}`, {}, {
       // method: "POST",
       headers: {
         "Content-Type": "application/json",
         "x-api-key": token,
         "x-api-plan": "SaaS",
       },
     });
 
     let result = response.data;
     console.log("getting data", result);
     let res = result.data;
     console.log(555, "firm name colecton data ",res.firmNameCollection);
     setFirmId(res.firmNameCollection.id);
     setFirm(res.firmName);
    //  calling funciton for firm details 
    handleSelectedFirm(res.firmName);
    getInvoiceHistory(res.firmNameCollection.id);

     setRegion(res.region);
     setType(res.type);
     setDate(res.date);
     setInvoice(res.invoiceNo);
     setAddress(res.firmNameCollection.fullAdd);
     setEmail(res.firmNameCollection.email);
     setMobile(res.firmNameCollection.mobileNo);
     setGst(res.firmNameCollection.gst);
     setDL1(res.firmNameCollection.dl1);
     setDL2(res.firmNameCollection.dl2);
     setFssai(res.firmNameCollection.fssai);
     setState1(res.firmNameCollection.state)
     setSubtotal(res.subTotal);
     setDisAmt(res.discAmt);
     setGoodReturn(res.goodReturnAmt);
     setTotalTax(res.totalTax);
     setCnVoucher(res.cnVoucher);
     setGrandTotal(res.grandTotal);
     setProductEntries(res.purchaseProdEntries);
     console.log('product entries product',res.purchaseProdEntries)
    }catch(error){
       console.log('error in getting data in purchase update',error.message);
    }
   };

   useEffect( () => {
    updateProductDetails();
   },[])
 
 
 

  // ======================================Navigate Modal Code======================================//

  const [date, setDate] = React.useState("");
  const [invoice, setInvoice] = React.useState("");
  const [cnReference, setCnReference] = React.useState("NA");

  const [address, setAddress] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [gst, setGst] = React.useState("");
  const [DL1, setDL1] = React.useState("NA");
  const [DL2, setDL2] = React.useState("NA");
  const [fssai, setFssai] = React.useState("NA");

  //all the final details of purchase entry
  const [subtotal, setSubtotal] = useState(0);
  const [disAmt, setDisAmt] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [goodReturn, setGoodReturn] = useState(0);
  const [cnVoucher, setCnVoucher] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [grandTotal2, setGrandTotal2] = useState(0);

  // select tag value check line 717

  const [selects, setSelects] = React.useState(false);

  //purchase product entry
  const [unitPack, setUnitPack] = useState("");
  let [igst, setigst] = useState(0);
  let [cgst, setcgst] = useState(0);
  let [sgst, setsgst] = useState(0);
  let [cess, setcess] = useState(0);

  //alll product data for displaying product in the input field
  const [productData, setProductData] = useState([]);
  // storing product name in generatedData variable
  const [generatedData, setGeneratedData] = React.useState("");

  const [product, setProductName] = useState("");
  const [category, setCategory] = useState("");
  const [brand, setBrand] = useState("");
  const [description, setDescription] = useState("");
  const [unitpacking, setUnitPacking] = useState("");
  const [hsn_sac_code, setHsn_sac_Code] = useState("");

  let [ProductEntries, setProductEntries] = useState([]);


  useEffect(() => {
    getFirmData();
    getProductData();
    BuyerDetails();
  }, []);


  // Creating CN voucher function for cn data to minus from grand total
  function cnVoucherFunction(props) {
    var minus = grandTotal - props[0];

    setGrandTotal2(minus);
    totalGrand(minus);
  }
  // setting actual data for user
  function totalGrand(minus) {
    setGrandTotal2(minus);
  }

  // getting all firm details from data base
  const getFirmData = async () => {
    try {
      //token
      let token = localStorage.getItem("x-api-key");
      let response = await axios.get("api/user/firm/getAllFirmDetails", {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": token,
          "x-api-plan": "SaaS",
        },
      });
      let result = response.data;
      console.log(1243, result);
      if (result) {
        setFirmData(result.data);
        getInvoiceHistory(result.data.id);
        handleSelectedFirm(result.data.firmName);
      } else {
        setFirmData([]);
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  // invoice history of a firm with the particular seller after selecting a firm on prseeing button select a firm.

  const [selectedFirmInvoiceHistory, setSelectedFirmInvoiceHistory] = useState(
    []
  );
  const getInvoiceHistory = async (selectedFirmId) => {
    //token
    // alert(selectedFirmId);
    try {
      let token = localStorage.getItem("x-api-key");

      let response = await axios.get(
        `api/user/purchase/invoicePList/${selectedFirmId}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": token,
            "x-api-plan": "SaaS",
          },
          // method: "get",
        }
      );

      let result = response.data;
      console.log("result", result);
      // setInvoiceHistory(result.data);
      if (result) {
        setSelectedFirmInvoiceHistory(result.data);
      } else {
        setSelectedFirmInvoiceHistory([]);
      }
      flipIFirmInvoiceModal();
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  // getting all the product details from the data base;
  const getProductData = async () => {
    try {
      //token
      let token = localStorage.getItem("x-api-key");
      let response = await axios.get(
        "api/user/addProductVertTwo/getAllProducts",
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": token,
            "x-api-plan": "SaaS",
          },
        }
      );
      let result = response.data;
      console.log('product data in purchse entry',result);
      if (result) {
        setProductData(result.data);
      } else {
        setProductData([]);
      }
    } catch (error) {
      console.log(error.response.data.message);
    }

    // console.log(result.data);
    // console.log("product name", productData, result);
  };

  const [showRecordsButton, setShowRecordsButton] = React.useState(false);

  const handleHideRecords = () => {
    setShowRecordsButton(false);
  };

  // -------------------------- showing data in comments --------------------- //
  // setting for output of product list
  //setting product id here
  const [SelectProductResultData, setSelectProductResultData] = useState("");

  const generateData = async (event, nameOfProduct) => {
    try {
      const newData = nameOfProduct;
      setGeneratedData(newData);
      for (let i = 0; i < productData.length; i++) {
        if (productData[i].productName === nameOfProduct) {
          //product id setting
          setProductId(productData[i].id);
          setProductName(productData[i].productName);
          setCategory(productData[i].category);
          setBrand(productData[i].brand);
          setDescription(productData[i].description);
          setUnitPacking(productData[i].unitPacking);
          setHsn_sac_Code(productData[i].hsn_sac_code);
        }
      }
      flipModal();

      // getting data when user select a particular product
      //if that product is already purchased then we are getting the previous details
      //token;

      let token = localStorage.getItem("x-api-key");
      let response = await axios.post(
        `api/user/purchase/firmListByProduct/${ProductId}`,
        {},
        {
          // method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": token,
            "x-api-plan": "SaaS",
          },
        }
      );
      let resultTable = response.data;
      if (resultTable) {
        setSelectProductResultData(resultTable.data);
      } else {
        setSelectProductResultData([]);
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };
  // On selecting product from product list;
  const popup = SelectProductResultData ? (
    SelectProductResultData.map(function (item) {
      return (
        <tr>
          <td>{item.date}</td>
          <td>{item.firmName}</td>
          <td>{item.invoiceNo}</td>
          <td>{item.qty}</td>
          <td>{item.free}</td>
          <td>{item.tradeRate}</td>
          <td>{item.discPercent}</td>
          <td>{item.batchNo}</td>
          <td>{item.expiry}</td>
          <td>{item.mrp}</td>
          <td>{item.igst}</td>
        </tr>
      );
    })
  ) : (
    <h6>No Data Found.</h6>
  );

  // On selecting firm from firm list we are showing previous invoice limit 10;

  // for firm invoice selection
  const [firmInvoiceSelect, setFirmInvoiceSelect] = useState(false);
  function flipIFirmInvoiceModal() {
    setFirmInvoiceSelect(true);
    setSelects(false);
  }

  const popupInvoice = selectedFirmInvoiceHistory ? (
    selectedFirmInvoiceHistory.map(function (item) {
      return (
        <tr>
          <td>{item.date}</td>
          <td>{item.firmName}</td>
          <td>{item.invoiceNo}</td>
          <td>{item.grandTotal}</td>
        </tr>
      );
    })
  ) : (
    <h2>No Data Found </h2>
  );

  function flipModal() {
    setSelects(true);
    setFirmInvoiceSelect(false);
  }

  // COMMENTS TABLE - Purchase Details;
  function CommentsTable(props) {
    return (
      <div className="total-empty-purchase">
        <div
          className="save-print-view-button-purchase"
          style={{ width: "auto", gap: "10px" }}
        >
          <button
            className="printbutton_purchase"
            onKeyDown={(event) => {
              handlePShowPrintOptions(event);
            }}
          
            style={{ fontWeight: "bold" }}
            onClick={(event) => {
              handleViewInvoice(true);
            }}
          >
            Print
            <span> (P)</span>
          </button>
          <button
            className="viewbutton_purchase"
            onKeyDown={(event) => {
         
              handleOpenInvoice(event);
              handleVOpenSalesInvoice(event);
            }}
       
            onClick={(event) => {
              // handleOpenInvoice(event);
              // handleOpenSalesInvoice(event);
              // handleShowPrintOptions(event);
              handleViewInvoice(false);
            }}
            style={{ fontWeight: "bold" }}
          >
            View
            <span> (V)</span>
          </button>
        </div>
      </div>
    );
  }

  // for adding unit pack of previous product automatically.
  function unitpackfunction(unitpack) {
    console.log('getting unit pack',unitpack)
    // for (let i = 0; i < productData.length; i++) {
      // if (productData[i].productName == v.target.value) {
        setUnitPack(unitpack);
      // }
    // }
  }

  const [showInvoice, setShowInvoice] = useState(false);

  const handleOpenInvoice = (event) => {
    setShowInvoice(true);
  };


  //========================= Sale Invoice Logic==========================
  const [showSalesInvoice, setShowSalesInvoice] = useState(false);


  const handleVOpenSalesInvoice = (event) => {
    if (event.key == "V" || event.key == "v") {
      setShowSalesInvoice(true);
    }
  };

  // =========================Print Modal Code ===============================//
  const [showPrintOptions, setShowPrintOptions] = useState(false);
  const [showMainSalesInvoice, setshowMainSalesInvoice] = useState(false);
  const [showMainInvoiceReceipt, setshowMainInvoiceReceipt] = useState(false);
  const [showEstimateSalesInvoice, setshowEstimateSalesInvoice] =
    useState(false);
  const [showEstimateInvoiceReceipt, setshowEstimateInvoiceReceipt] =
    useState(false);

  // open functions
  const handleShowPrintOptions = () => {
    setShowPrintOptions(!showPrintOptions);
  };

  const handlePShowPrintOptions = (event) => {
    if (event.key == "p" || event.key == "P") {
      setShowPrintOptions(true);
    }
  };

  const handleMainSalesInvoice = () => {
    setshowMainSalesInvoice(!showMainSalesInvoice);
  };


  // y and n button states and functions

  const [checkedMainSalesValue, setcheckedMainSalesValue] = useState(false);
  const [checkedMainInvoiceValue, setcheckedMainInvoiceValue] = useState(false);
  const [checkedEstimateSalesValue, setcheckedEstimateSalesValue] =
    useState(false);
  const [checkedEstimateInvoiceValue, setcheckedEstimateInvoiceValue] =
    useState(false);

  const handleYNMainSales = (event) => {
    if (event.key == "y" || event.key == "Y") {
      setcheckedMainSalesValue(true);
    } else if (event.key == "n" || event.key == "N") {
      setcheckedMainSalesValue(false);
    }
  };


  const handleYNEstimateSales = (event) => {
    if (event.key == "y" || event.key == "Y") {
      setcheckedEstimateSalesValue(true);
    } else if (event.key == "n" || event.key == "N") {
      setcheckedEstimateSalesValue(false);
    }
  };



  const handleMainSales = (event) => {
    setcheckedMainSalesValue(!checkedMainSalesValue);
  };

  const handleMainInvoice = (event) => {
    setcheckedMainInvoiceValue(!checkedMainInvoiceValue);
  };

  const handleEstimateSales = (event) => {
    setcheckedEstimateSalesValue(!checkedEstimateSalesValue);
  };

  const handleEstimateInvoice = (event) => {
    setcheckedEstimateInvoiceValue(!checkedEstimateInvoiceValue);
  }

// adding net rate into this data which we are getting from backend
  function  calculateNetRate() {

  let result =   ProductEntries.map( (item) => {
// Assuming productExist is an object containing properties: 
// item.qty, item.free, item.tradeRate, item.discPercent, item.productTax, item.amount, item.mrp

// Modifying the calculation to use the provided variable names and storing the net rate in item.netRate
item.netRate = item.qty * item.tradeRate; 

if (item.productTax || item.discPercent) {
  let taxDiscountAmount = 0;
  taxDiscountAmount = (item.netRate * item.discPercent) / 100;
  let taxDiscountedRate = item.netRate - taxDiscountAmount;
  let taxAmount = (taxDiscountedRate * parseInt(item.productTax)) / 100;
  item.netRate = taxDiscountedRate + taxAmount;
}

if (
  item.free && 
  item.free.length > 0 && 
  item.free != 0
) {
  let x, y;
  if (item.free.includes("+")) {
    [x, y] = item.free.split("+");
  } else {
    x = item.free;
    y = 0;
  }
  const xInt = parseInt(x);
  const yInt = parseInt(y);
  const freeT = (yInt * 100) / (xInt + yInt);
  const data = item.qty * item.tradeRate; // subTotal
  const data2 = (data * freeT) / 100; // bonusDiscount
  const data3 = data - data2; // after giving free amount of subtotal
  const dis = (item.discPercent / 100) * data3; // discount percentage
  const AMt = data3 - dis; // totalDiscount
  const tax = parseInt(item.productTax); // tax Cal
  const disTax = AMt * (tax / 100); // tax percentage
  item.netRate = (disTax + AMt).toFixed(2); // final Net Rate
}

     })
        
     console.log("added net rate into this ",ProductEntries)
  }

  const handleViewInvoice = async (isPrint) => {
    // Your multiple contents to display in the new tab or window;


    const result =  calculateNetRate();

    const productRowsM = [];

    if (ProductEntries.length > 0) {
      ProductEntries.forEach((item, index) => {
        // If only "main" is true and sellType is "M", add to productRowsM array
        productRowsM.push(`
      <div>
      <tr key="${index}">
        <td className="tdDiv SD" style="border-right: 1px solid #ccc; padding: 3px;">${
          index + 1
        }</td>
        <td className="FD" style="border-right: 1px solid #ccc; padding: 3px;">${
          item.productName
        }</td>
        <td className="FD" style="border-right: 1px solid #ccc; padding: 3px;">${
          item.batchNo
        }</td>
        <td className="FD" style="border-right: 1px solid #ccc; padding: 3px;">${
          item.qty
        }</td>
        <td className="FD" style="border-right: 1px solid #ccc; padding: 3px;">${
          item.free
        }</td>
        <td className="FD" style="border-right: 1px solid #ccc; padding: 3px;">${
          item.tradeRate
        }</td>
        <td className="AD" style="border-right: 1px solid #ccc; padding: 3px;">${
          item.discPercent
        }</td>
        <td className="AD" style="border-right: 1px solid #ccc; padding: 3px;">${
          item.productTax
        }</td>
        <td className="FD" style="border-right: 1px solid #ccc; padding: 3px;">${
          (item.netRate/ item.qty).toFixed(2) 
        }</td>
        <td className="FD" style="border-right: 1px solid #ccc; padding: 3px;">${
          item.amount
        }</td>
        <td className="FD" style="border-right: 1px solid #ccc; padding: 3px;">${
          item.mrp
        }</td>
        <td className="FD" style="border-right: 1px solid #ccc; padding: 3px;">${
          item.expiry
        }</td>
      </tr>
      </div>
    `);
      });
    }

    // Seller and buyer both reciept;

    let content2 = `
        <body>
          <div id="print-section-2" style="max-width: 800px; margin: 0 auto; padding: 20px; box-sizing: border-box; font-family: Arial, sans-serif;">
            <div class="saleInvoice-container" style="margin-bottom: 1rem;">
              <div class="saleInvoice-head" style="margin-bottom: 1rem; font-size : 12px; ">
                <ul class="saleInvoice-headings" style="list-style-type: none; padding: 0; margin: 0; display: flex; justify-content: space-between; align-items: center;">
                  <li>Date: ${date}</li>
                  <li>Invoice No.: ${invoice}</li>
                  <li class="hide-for-E" style="margin-left: 10px; margin-right: 10px;">
                    <img src=${hegan} alt="hegan logo" style="height: 20px;"></img> hegan.in
                  </li>
                  <li class="hide-for-E" style="margin-left: 10px; margin-right: 10px;">Tax Invoice</li>
                  <li class="hide-for-E" style="margin-left: 10px; margin-right: 10px;">Original For Buyer</li>
                </ul>
              </div>
              <div style="background: white; width: 100%; page-break-inside: avoid;">
                <div class="saleInvoice-tables" style="display: flex;">
                  <table class="saleInvoice-details" style="width: 50%; height: 10px; border-collapse: collapse; font-size:12px">
                    <tbody>
                      <tr>
                        <th class="saleInvoice-tableHeading" colspan="2" style="background-color: #f0f0f0; text-align: center; border: 1px solid #ccc; padding: 8px;">Seller Details</th>
                      </tr>
                      <div>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">Firm Name-</td>
                        <td style="background: white; border: 1px solid #ccc;">${firm}</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">D.L(1)-</td>
                        <td style="background: white; border: 1px solid #ccc;">${DL1}</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">D.L(2)-</td>
                        <td style="background: white; border: 1px solid #ccc;">${DL2}</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">Phone No.-</td>
                        <td style="background: white; border: 1px solid #ccc;">${mobile}</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">Address-</td>
                        <td style="background: white; border: 1px solid #ccc;">${address}</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">State-</td>
                        <td style="background: white; border: 1px solid #ccc;">${state1}</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">GST-</td>
                        <td style="background: white; border: 1px solid #ccc;">${gst}</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">FSSAI-</td>
                        <td style="background: white; border: 1px solid #ccc;">${fssai}</td>
                      </tr>
                    </tbody>
                  </table>
                  <table class="saleInvoice-details" style="width: 50%; border-collapse: collapse; font-size:12px">
                    <tbody>
                      <tr>
                        <th class="saleInvoice-tableHeading" colspan="2" style="height: 22px; background-color: #f0f0f0; text-align: center; border: 1px solid #ccc; padding: 8px;">Buyer Details</th>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">Firm Name-</td>
                        <td style="background: white; border: 1px solid #ccc;">${
                          userDetails.firmName
                        }</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">D.L(1)-</td>
                        <td style="background: white; border: 1px solid #ccc;">${
                          userDetails.dl_no_20b
                        }</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">D.L(2)-</td>
                        <td style="background: white; border: 1px solid #ccc;">${
                          userDetails.dl_no_21b
                        }</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">Phone No.-</td>
                        <td style="background: white; border: 1px solid #ccc;">${
                          userDetails.mobile
                        }</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">Address-</td>
                        <td style="background: white; border: 1px solid #ccc;">${
                          userDetails.address
                        }</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">State-</td>
                        <td style="background: white; border: 1px solid #ccc;">${
                          userDetails.state
                        }</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">GST-</td>
                        <td style="background: white; border: 1px solid #ccc;">${
                          userDetails.gst_no
                        }</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">FSSAI-</td>
                        <td style="background: white; border: 1px solid #ccc;">${
                          userDetails.fssai_no
                        }</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">TYPE-</td>
                        <td style="background: white; border: 1px solid #ccc;">${type}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="saleInvoice-productDetails" style="background: white; page-break-inside: avoid; margin-top: 20px; font-size:12px">
                <table style="width: 100%; border-collapse: collapse;">
                <thead style="overflowX: auto; font-size:12px">
                <tr>
                <th style="text-align: center; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 8px;">S.No</th>
                <th style="text-align: center; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 8px;">Product</th>
                <th style="text-align: center; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 8px;">Batch</th>
                <th style="text-align: center; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 8px;">Qty</th>
                <th style="text-align: center; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 8px;">Free</th>
                <th style="text-align: center; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 8px;">T.rate</th>
                <th style="text-align: center; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 8px;">Disc%</th>
                <th style="text-align: center; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 8px;">Tax</th>
                <th style="text-align: center; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 8px;">N.Rate</th>
                <th style="text-align: center; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 8px;">Amt</th>
                <th style="text-align: center; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 8px;">MRP</th>
                <th style="text-align: center; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 8px;">EXP</th>
              </tr>
            </thead>
            <tbody style="font-size: 12px; text-align: center;">
              ${productRowsM.join("")}
            </tbody>
          </table>
        </div>
        <div class="saleInvoice-footer" style="margin-top: auto;  page-break-inside: avoid; border-top: 1px solid #ccc; page-break-inside: avoid;">
        </div>
        <div class="saleInvoice-footer" style="background: white; margin-top: 20px;">
          <div class="saleInvoice--costDetails" style="display: flex;font-size: smaller; justify-content: space-between; margin-top: 20px; padding: 10px; page-break-inside: avoid;">
            <div class="saleInvoice-costDetails">
              <div class="taxes-align" style="display: flex; line-height : 2rem; margin-bottom: 1rem;">
                <div class="saleInvoice-amountList" style="width: 80px;">
                  <label class="saleInvoice-amounts" style="font-weight: bold;">CGST</label>
                 <div>
                  <span style = "margin-left: 0rem; border: 2px solid #ccc;">${cgst}</span>
                 </div>
                </div>

                <div class="saleInvoice-amountList" style="width: 80px;" >
                  <label class="saleInvoice-amounts" style="font-weight: bold;">SGST</label>
                   <div>
                  <span style = "margin-left: 0rem; border: 2px solid #ccc;">${sgst}</span>
                   </div>
                </div>
                <div class="saleInvoice-amountList" style="width: 80px;">
                  <label class="saleInvoice-amounts" style="font-weight: bold;">IGST</label>
                   <div>
                  <span style = "margin-left: 0rem; border: 2px solid #ccc;">${igst}</span>
                   </div>
                </div>
                <div class="saleInvoice-amountList" style="width: 80px;" >
                  <label class="saleInvoice-amounts" style="font-weight: bold;">CESS</label>
                   <div>
                  <span style = "margin-left: 0rem; border: 2px solid #ccc;">${cess}</span>
                   </div>
                </div>
              </div>

            </div>


            <!--- making changes here open -->



            <div class="saleInvoice-outerCostDiv" style="border: 1px solid black; padding: 10px;">
  <table style="width: 100%; border-collapse: collapse;">
    <!-- First Key-Value Pair -->
    <tr>
      <td style="border: 1px solid black; padding: 5px; font-weight: bold;">Sub Total</td>
      <td style="border: 1px solid black; padding: 5px;">${subtotal}</td>
    </tr>

    <!-- Second Key-Value Pair -->
    <tr>
      <td style="border: 1px solid black; padding: 5px; font-weight: bold;">Discount Amount</td>
      <td style="border: 1px solid black; padding: 5px;">${disAmt}</td>
    </tr>

    <!-- Third Key-Value Pair -->
    <tr>
      <td style="border: 1px solid black; padding: 5px; font-weight: bold;">Tax Amount</td>
      <td style="border: 1px solid black; padding: 5px;">${parseFloat(totalTax)}</td>
    </tr>

    <!-- Fourth Key-Value Pair -->
    <tr>
      <td style="border: 1px solid black; padding: 5px; font-weight: bold;">G/R Amount</td>
      <td style="border: 1px solid black; padding: 5px;">${goodReturn}</td>
    </tr>

    <!-- Fifth Key-Value Pair -->
    <tr>
      <td style="border: 1px solid black; padding: 5px; font-weight: bold;">CN Amount</td>
      <td style="border: 1px solid black; padding: 5px;">${cnVoucher ? cnVoucher : 0}</td>
    </tr>

    <!-- Sixth Key-Value Pair -->
    <tr>
      <td style="border: 1px solid black; padding: 5px; font-weight: bold;">Payable Amount</td>
      <td style="border: 1px solid black; padding: 5px;">${grandTotal}</td>
    </tr>
  </table>
</div>





            <!--- making changes closed -->
          
          




          </div>
        </div>
        </div>
      </div>
    </div>
  `;

    // Combine the contents into a single string;
    const combinedContent = `
    <html>
      <head>
        <title>Invoice Receipt</title>
      <style>
      /* Add your CSS styles here */
      body {
        font-family: Arial, sans-serif;
        margin: 20px;
      }
      .saleInvoice-container,
      .invoiceReceipt-container {
        border: 1px solid #ccc;
        padding: 10px;
        margin-bottom: 20px;
        page-break-inside: avoid;
      }
      .saleInvoice-headings {
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0;
      }
      /* Add more styles as needed for other elements */
    </style>
     </head>
      <body>
        ${content2}
      </body>
    </html>`;

    //===========I added this ========== //

    if (isPrint) {
      console.log("isPrint", isPrint);
      // Print functionality
      const printWindow = window.open("", "_blank");
      printWindow.document.open();
      printWindow.document.write(combinedContent);
      printWindow.document.close();
      printWindow.print();
      printWindow.close();
    } else {
      // View functionality
      const viewWindow = window.open("", "_blank");
      viewWindow.document.open();
      viewWindow.document.write(combinedContent);
      viewWindow.document.close();
    }
  };

  return (
    <Layout heading_1="Purchase" heading_2="Purchase View Estimate">
      <div className="main-div-purchase---entry">
        {showPrintOptions && (
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: "#015998",
              },
            }}
          >
            <div className="printInvoiceContainer">
              <div className=" printInnerBoxDiv" style={{ height: "53px" }}>
                <div className="printHeadingDiv">
                  <h6>Main Sales Invoice</h6>
                </div>
                <div className="printIconDiv">
                  <Switch
                    onChange={handleMainSalesInvoice}
                    checkedChildren="Y"
                    unCheckedChildren="N"
                    ref={mainSalesInvoiceToggleRef}
                    autoFocus
                    onKeyDown={(event) => {
                  
                      handleYNMainSales(event);
                    }}
                    onClick={(event) => {
                      handleMainSales(event);
                    }}
                    checked={checkedMainSalesValue}
                  />
                </div>
              </div>

              <div className=" printInnerBoxDiv" style={{ height: "53px" }}>
                <div className=" printHeadingDiv">
                  <h6>Estimate Sales Invoice</h6>
                </div>
                <div className="printIconDiv">
                  <Switch
                    checkedChildren="Y"
                    unCheckedChildren="N"
                    ref={estimateSalesInvoiceToggleRef}
                    onKeyDown={(event) => {
                   
                      handleYNEstimateSales(event);
                    }}
                    onClick={(event) => {
                      handleEstimateSales(event);
                    }}
                    checked={checkedEstimateSalesValue}
                  />
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  style={{
                    background: "#015998",
                    color: "whitesmoke",
                    borderRadius: "5px",
                  }}
                  onClick={(event) => {
                    handleShowPrintOptions(event);
                  }}
                 
                  ref={confirmChoicesRef}
                >
                  Confirm Choices
                </button>
              </div>
            </div>
          </ConfigProvider>
        )}
        {/* end here */}

        <div className="first-half-purchase">
          <div className="top-two-purchase">
            <form className="input-box-purchase">
              <div
                className="input-box-name-purchase firmNameDiv"
                // onClick={openFirmModal}
              >
                <label className="FirmName-purchase"> Firm Name</label>
                <input
                  htmlFor="firmName"
                  className="FirmName-purchase"
                  placeholder="Select Firm Name klkl"
                  style={{
                    width: "25rem",
                    boxShadow: "0px 5px 5px 0px rgba(0, 0, 0, 0.2)",
                  }}
                  value={firm}
                >
                </input>
              </div>

              <div className="input-box-date-purchase">
                <label htmlFor="date" style={{ color: "black" }}>
                  {" "}
                  Date
                </label>

                <input
                  id="date"
                  type="date"
                  value={date}
                  style={{ height: "30px", width: "97%" }}
                />
              </div>

              <div className="input-box-region-purchase regionDiv">
                <label className="FirmName-purchase"> Region</label>
                <select
                  className="FirmName-purchase"
                  style={{ width: "8rem" }}
                >
                    <option placeholder="select something">{region}</option>
                </select>
              </div>

              <div className="input-box-name-purchase regionDiv">
                <label className="FirmName-purchase"> Type</label>
                <select
                  className="FirmName-purchase"
                  style={{ width: "8rem" }}
                >
                <option placeholder="select something">{type}</option>
                </select>
              </div>

              {invoiceSelect && (
                <div className="input-box-invoice-purchase regionDiv">
                  <label htmlFor="invoiceNo" style={{ color: "black" }}>
                    Invoice No.
                  </label>

                  <input
                    id="invoiceNo"
                    type="name"
                    name="invoice"
                    value={invoice}
                    onChange={(e) => setInvoice(e.target.value)}
                
                    // onFocus={handleShowRecords}
                    onBlur={handleHideRecords}
                   
                    style={{ marginTop: "9px", height: "28px" }}
                  />
                </div>
              )}

              {cnRefSelect && (
                <div className="cnRef-box-invoice-purchase ">
                  <label htmlFor="cnRefNo" style={{ color: "black" }}>
                    Credit Note Ref No.{" "}
                  </label>

                  <input
                    id="cnrefNo"
                    type="name"
                    name="cnReference"
                    value={cnReference}
                    onChange={(e) => setCnReference(e.target.value)}
                 
                  
                    style={{ marginTop: "9px", height: "26px" }}
                  />
                </div>
              )}
            </form>

            {/* here product list entries getting product and dialing entry for products */}

            <div className="quantity-box-purchase">
              <div
                className="product"
                style={{ overflow: "auto", height: "100%" }}
              >
                <div className="quantity-box-top">
                  <table id="customers-purchase-entry">
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th 
                      >Product Name</th>
                      <th className="innerMEG">
                        <th>
                          <th className="M">M</th>
                        </th>
                        <th>
                          <th className="E">E</th>
                        </th>
                        <th className="G">G</th>
                      </th>

                      <th>Unit Pack</th>
                      <th>Qty</th>
                      <th>Free</th>
                      <th>Trade Rate</th>
                      <th>Disc%</th>
                      <th>Amt</th>
                      <th>Act</th>
                    </tr>
                    <tr>{selects}</tr>

                    <tr>
                      <td>
                        <input
                          type="text"
                          value={"0"}
                        // style={{width:20}}
                        />
                      </td>
                      <td>
                        <input
                          className="ProductName-entry"
                          style={{ width: "19rem" }}
                        />
                        <br />
                      </td>
                      <td className="checkbox-td">
                        <td>
                          <input
                            className="checkbox-1"
                            type="checkbox"
                          />
                        </td>
                        <td>
                          <input
                            className="checkbox-2"
                            type="checkbox"
                          />
                        </td>
                        <td>
                          <input
                            className="checkbox-3"
                            type="checkbox"
                          />
                        </td>
                      </td>
                      <td>
                        <input
                          type="text"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                        />
                      </td>
                      <td
                       style={{
                        color: "white",
                        width:'4.7rem',
                      }}
                      >
                      <td
                        style={{
                          backgroundColor: "#015998",
                          color: "white",
                          borderRadius: "5px",
                          width:'8rem',
                          height:'1rem'
                        }}
                      >
                        Action
                      </td>
                      </td>
                    </tr>
                    </thead>
                    <tbody 
                    // style={{marginLeft:18}}
                    >
                    {openModal2 && ProductEntries ? (
                      ProductEntries.map(function (item, index) {
                        return (
                          <tr>
                            <td
                              className={
                                (index + 1) % 2 === 1 ? "white" : "grey"
                              }
                            >
                              <input 
                              value={index+1}
                              className="span-width"
                              style={{width:60}}
                              />
                             
                            </td>
                            <td>
                               <input
                                value={item.productName}
                                style={{ width: "303px" }}
                              />
                            </td>
                            <td >
                              <td>
                                <input
                                  value={
                                      item.purchaseType
                                  }
                                  style={{ position: "relative",width:62 }}
                                />
                              </td>
                              <td>
                                <input
                                  className="inputbox-goodsreturn"
                                  value={
                                  item.returnProduct
                                  }
                                
                                  style={{ position: "relative",width:59 }}
                                />
                              </td>
                            </td>
                            <td>
                              <input
                                value={
                                 item.unitPack
                                }
                              />
                            </td>
                            <td>
                              <input
                                value={
                                item.qty
                                }
                               
                              ></input>
                            </td>
                            <td>
                              <input
                                value={
                                 item.free
                                }
                             
                              />
                            </td>
                            <td>
                              <input
                                value={
                                  item.tradeRate
                                }
                              
                              />
                            </td>
                            <td>
                              <input
                                value={
                                 item.discPercent
                                }
                              />
                            </td>
                            <td>
                            <input
                            value ={item.amount}
                            />
                             </td>
                             <td>
                            <div className="">
                              
                              <button
                                onClick={(e) => {
                                  // DeleteArrayOfObject(index);
                                  openModal(index);
                                  generateData(e, item.productName);
                                  handleSelectedProduct(item.productName);
                                  unitpackfunction(item.unitPacking)
                                }}
                                style={{
                                  marginTop: "0px",
                                  borderRadius: "3px",
                                  backgroundColor: "green",
                                  color: "white",
                                  width: "63px",
                                  height: "30px",
                                  display:"flex",
                                  alignItems:"center",
                                  justifyContent:"center"
                                }}
                              >
                                View
                              </button>
                              
                            </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <h3>no Product selected</h3>
                    )}
                     </tbody>
                  </table>
                </div>
              </div>

              {/* batchNo number and all the other information which is used by the user while purchasing the product */}

              <div className="quantity-box-bottom-purchase">
               


              <div className="recent--history--table">
          {showModal && 
             <div className="modal-overlay">
             {/* <div className="modal-content"> */}
          <div className="allViewDetails-purchase-view" >

            { ProductEntries && ProductEntries.length > 0 ? ProductEntries.map(function (item, index) {
              if (index === indexView) {
                return (
                  <tr>
                    <button 
                    className="CloseView-purchase" 
                    onClick={(() => { 
                      openModal(index); 
                      handleSelectedFirm(firm);
                      getInvoiceHistory(firmId);
                  
                    })} 
                    style={{
                      height:'.5rem',
                      width:'.5rem',
                      display:'flex',
                      justifyContent:"center",
                      alignItems:"center",
                      marginLeft:'400px',
                      marginTop:-10,
                    }}
                    >X</button>
                 
                    <div className="viewPratham">
                      <td className="productnameview">
                        <label>Product Name</label>
                        <input value={item.productName} />
                      </td>
                      <td className="productnameview">
                        <label className="labelcolor"> Purchase Type</label>
                        <input
                          value={item.purchaseType}
                        />
                      </td>
                      <td className="productnameview">
                        <label>Goods Return</label>
                        <input
                          value={
                            item.returnProduct
                          }
                        />
                      </td>

                    </div>
                    <div className="viewDivitiye">
                      <td className="productnameview">
                        <label>Unit Pack</label>
                        <input
                          value={item.unitPack}
                        />
                      </td>
                      <td className="productnameview">
                        <label>Quantity</label>
                        <input
                          value={
                            item.qty
                          }
                        ></input>
                      </td>
                      <td className="productnameview">
                        <label>Free</label>
                        <input
                          value={item.free}
                        />
                      </td>
                    </div>
                    <div className="viewtritya">
                      <td className="productnameview">
                        <label>Trade Rate</label>
                        <input
                          value={
                            item.tradeRate
                          }
                        />
                      </td>
                      <td className="productnameview">
                        <label>Discount</label>
                        <input
                          value={
                            item.discPercent
                          }
                        />
                      </td>

                      <td className="productnameview">
                        <label>Amount</label>
                        <input
                          value={item.amount}
                        />
                      </td>
                    </div>

                   
                  
                      <div className="ViewlistFirst">
                          <td className="productnameview">
                            <label>Batch No.</label>
                            <input
                              value={item.batchNo}
                            />
                          </td>
                        
                          <td className="productnameview">
                            <label >Expiry</label>
                            <input
                              value={item.expiry}
                            />
                          </td>
          
                        <td className="productnameview">
                          <label>MRP</label>
                          <input
                            value={item.mrp}
                          />
                        </td>
                      </div>

                      <div className="ViewlistSecond">
                        <td className="productnameview">
                          <label >HSN/SAC</label>
                          <input
                            value={item.hsn_sac}
                          />
                        </td>

                        <td className="productnameview">
                          <label >IGST</label>
                          <input
                            value={item.igst}
                          />
                        </td>

                        <td className="productnameview">
                          <label >CGST</label>
                          <input
                            value={item.cgst}
                          />
                        </td>
                      </div>


                      <div className="ViewlistFourth">
                        <td className="productnameview">
                          <label>SGST</label>
                          <input
                            value={item.sgst}
                          />
                        </td>

                        <td className="productnameview">
                          <label>CESS</label>
                          <input
                            value={item.cess}
                          />
                        </td>
                      </div>
                   

                  </tr>
                );
              } return ""
            }) : <h3>no Product selected</h3>}
          </div>
          </div>
          // </div>
          }
        </div>




                {/* qty details  */}
                <div className="qty-details-purchase">
                  {showRecordsButton && (
                    <table id="customers-purchase">
                      <tr className="qty-detail-header">
                        <th>Date </th>
                        <th>Invoice No.</th>
                        <th>Amount </th>
                      </tr>
                      <tbody>
                        {selectedFirmInvoiceHistory
                          ? selectedFirmInvoiceHistory.map(function (
                              item,
                              index
                            ) {
                              return (
                                <tr key={item.id}>
                                  <td>{item.date} </td>
                                  <td>{item.invoiceNo}</td>
                                  <td>{item.grandTotal}</td>
                                </tr>
                              );
                            })
                          : "No entries"}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* here all firm name genereator detials which we are pre filling from the data which we are getting from the backend firm list     */}

          <div className="contact-box--purchase">
            {/* <div className="contact-box-input"> */}
            <label
              htmlFor="add"
              style={{ fontWeight: "bold" }}
              className="side-bar-details-in-purchase-entry"
            >
              Address
            </label>
            <input
              id="add"
              type="text"
              value={address}
              style={{ height: "2rem", borderColor: "grey" ,width:'9.5rem' }}
            />
            <label
              htmlFor="email"
              style={{ fontWeight: "bold" }}
              className="side-bar-details-in-purchase-entry"
            >
              Email
            </label>
            <input
              id="email"
              value={email}
              style={{ height: "2rem", borderColor: "grey" }}
            />
            <label
              htmlFor="mobile"
              style={{ fontWeight: "bold" }}
              className="side-bar-details-in-purchase-entry"
            >
              Mobile No.
            </label>
            <input
              id="mobile"
              value={mobile}
              style={{ height: "2rem", borderColor: "grey" }}
            />
            <label
              htmlFor="gst"
              style={{ fontWeight: "bold" }}
              className="side-bar-details-in-purchase-entry"
            >
              GSTIN
            </label>
            <input
              id="gst"
              value={gst}
              style={{ height: "2rem", borderColor: "grey" }}
            />

            <label
              style={{ fontWeight: "bold" }}
              className="side-bar-details-in-purchase-entry"
            >
              D.L.1
            </label>
            <input
              id="DL2"
              value={DL1}
              style={{ height: "2rem", borderColor: "grey" }}
            />
            <label
              style={{ fontWeight: "bold" }}
              className="side-bar-details-in-purchase-entry"
            >
              D.L.2
            </label>
            <input
              id="DL2"
              value={DL2}
              style={{ height: "2rem", borderColor: "grey" }}
            />
            <label
              style={{ fontWeight: "bold" }}
              className="side-bar-details-in-purchase-entry"
            >
              FSSAI No.
            </label>
            <input
              id="fssai"
              value={fssai}
              style={{ height: "2rem", borderColor: "grey" }}
            />
          </div>
        </div>
        {/* </div> */}

        <div className="bottom-half-purchase">
          <div className="total-box-purchase">
            <CommentsTable
              generatedData={{
                product: generatedData,
                cat: category,
                brand: brand,
                unit: unitpacking,
                hac: hsn_sac_code,
              }}
            />
            {/* <div className="total-values"> */}
            <div className="total-values-button--purchase">
              <button className="sub-total">Sub. Total</button>
              <button className="disc-amount">Disc. Amount</button>
              <button className="total-tax">Total Tax Amt.</button>
              <button className="goods-return"> G/R Amt.</button>
              <button className="cn-voucher">Credit Note Voucher </button>
              <button className="payable-amount">Payable Amount</button>
            </div>
            {/* </div> */}
          </div>
          <div className="blank-box-purchase">
            <input 
            value={subtotal} />
            <input
              value={disAmt}
             
             
              style={{ position: "relative", top: "8px" }}
            />
            <input
          
             
              value={parseFloat(totalTax).toFixed(2)}
              style={{ position: "relative", top: "14px" }}
            />
            <input
            
            
              value={goodReturn}
              style={{ position: "relative", top: "16px" }}
            />
            <input
              value={cnVoucher}
              onChange={(e) => {
                setCnVoucher(e.target.value);
                cnVoucherFunction([e.target.value]);
              }}
           
            
              style={{ position: "relative", top: "19px" }}
            />
            <input
              value={
                cnVoucher
                  ? parseFloat(grandTotal2).toFixed(2)
                  : parseFloat(grandTotal).toFixed(2)
              }
              style={{ position: "relative", top: "17px" }}
            />
          </div>
        </div>
        <div
          className="recent-history-table"
          style={{
            position: "relative",
            top: "-21rem",
            left: "5rem",
            width: "33rem",
          }}
        >
          {selects && (
            // <div style={{position:'relative',left:'2rem'}}>
            <div>
              <table id="customers--purchase">
                <tr>
                  <th>Date</th>
                  <th>Firm Name</th>
                  <th>Invoice No.</th>
                  <th>Qty</th>
                  <th>Free</th>
                  <th>Trade Rate</th>
                  <th>Disc %</th>
                  <th>Batch No.</th>
                  <th>Expiry</th>
                  <th>M.R.P</th>
                  <th>Tax</th>
                </tr>

                {popup}

                {/* <th></th> */}
              </table>
              {/* <p onClick={flipModal}>x</p> */}
            </div>
          )}
        </div>

        <div
          className="recent-history-table"
          // style={{ top: "-33rem", left: "5rem", width: "37" }}
          style={{
            position: "absolute",
            top: "55.7%",
            left: "25.5%",
            width: "44%",
            height: "auto",
          }}
        >
          {firmInvoiceSelect && (
            <div style={{marginLeft:30,position:"absolute"}}>
              <table id="customers--purchase">
                <tr>
                  <th>Date</th>
                  <th>Firm Name</th>
                  <th>Invoice No.</th>
                  <th>Grand Total</th>
                </tr>
                {popupInvoice}
              </table>
              {/* <p onClick={flipIFirmInvoiceModal}>x</p> */}
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default PurchaseViewEstimate;
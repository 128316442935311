/** @format */
import "./PurchaseList.css";
import Layout from "../../Layout/Layout";
import Swal from "sweetalert2";
import { React, useEffect, useState, useRef } from "react";
import { FaRegFilePdf } from "react-icons/fa";
import { FaFileCsv } from "react-icons/fa";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { CSVLink } from "react-csv";
import { useGlobalContext } from "components/Context";

//============== Headers for csv file======================= //

const headers = [
  { label: "Date", key: "date" },
  { label: "Invoice N0.", key: "invoiceNo" },
  { label: "Firm Name", key: "firmName" },
  { label: "Region", key: "region" },
  { label: "Type", key: "type" },
  { label: "Amount", key: "amount" },
];

export default function PurchaseList() {
  // const [count, setCount] = useState(0);

  const pageSize = 10;

  const navigate = useNavigate();

  // let { PaginationControlled, page, setCount, pageSize } = useGlobalContext();

  let { PaginationControlled, page, setTotalPages } = useGlobalContext();

  console.log(29, page, pageSize);

  function navigateToSaleListEdit() {
    navigate("/purchase-update");
  }

  const [PurchaseListData, setPurchaseListData] = useState([]);

  useEffect(() => {
    getPurchaseListDataFunction();
  }, [page]);

  const getPurchaseListDataFunction = () => {
    // Token
    const token = localStorage.getItem("x-api-key");

    axios
      .post(
        "api/user/purchase/Mlist",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": token,
            "x-api-plan": "SaaS",
          },
          // params: { page: page, pageSize: pageSize },
        }
      )
      .then((response) => {
        console.log("comming", response.data.data);
        setPurchaseListData(response.data.data);
        // setCount(response.data.totalItems);
      })
      .catch((error) => {
        // Handle errors here
        console.error(error);
      });
  };

  // code for filtering firm according to search bar;

  const [filteredList, setFilteredList] = new useState(PurchaseListData);
  const [filter, setFilter] = useState(false);
  const filterListSearch = (event) => {
    setFilter(true);
    let updatedList = [...PurchaseListData];
    updatedList = updatedList.filter((item) => {
      // we can add one more field to search like invoice number;
      return (
        item.firmName
          .toLowerCase()
          .indexOf(event.target.value.toLowerCase()) !== -1 ||
        item.invoiceNo
          .toLowerCase()
          .indexOf(event.target.value.toLowerCase()) !== -1
      );
    });
    console.log("updatedList", updatedList);
    setFilteredList(updatedList);
  };

  //==========  converting data to pdf=========== //
  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 350;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Purchase List-Main";
    const headers = [
      ["S.No", "Date", "Invoice No.", "Firm Name", "Region", "Type", "Amount"],
    ];
    // console.log(filteredPurchaseListData);
    let data;
    if (filter) {
      data = filteredList.map((elt, index) => [
        index + 1,
        elt.date,
        elt.invoiceNo,
        elt.firmName,
        elt.region,
        elt.type,
        elt.grandTotal,
      ]);
    } else {
      data = PurchaseListData.map((elt, index) => [
        index + 1,
        elt.date,
        elt.invoiceNo,
        elt.firmName,
        elt.region,
        elt.type,
        elt.grandTotal,
      ]);
    }

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    autoTable(doc, content);
    doc.save("Purchase List-Main.pdf");
  };

  //========= converting filtered data to csv============ //
  const csvDownloadRef = useRef(null);
  const convertToCsv = () => {
    setTimeout(() => {
      csvDownloadRef.current.link.click();
    }, 500);
  };

  // delete product function;

  function DeletePurchaseListFunction(id) {
    var id = id;
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: false,
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteProduct = async (id) => {
          // let token = JSON.parse(localStorage.getItem("user"));
          //token
          let token = localStorage.getItem("x-api-key");
          let response = await axios.delete(
            `api/user/purchase/deletePurchase/${id}`,
            {
              // method: "Delete",
              headers: {
                "Content-Type": "application/json",
                "x-api-key": token,
                "x-api-plan": "SaaS",
              },
            }
          );
          let result = response.data;
          if (result) {
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
            // calling get all purchase list function for refresh of the list
            getPurchaseListDataFunction();
          }
        };
        deleteProduct(id);
      }
    });
  }

  // start

  // Your data (Firm) and other variables

  // Calculate the start and end index for the current page

  const startIndex = (page - 1) * pageSize;

  const endIndex = startIndex + pageSize;

  // Filter the data based on the current page;

  let displayedData = (
    filter
      ? filteredList.slice(startIndex, endIndex)
      : PurchaseListData.slice(startIndex, endIndex)
  ).map((item, index) => ({
    ...item,
    displayIndex: (page - 1) * pageSize + index + 1,
  }));

  // Calculate the total number of pages;

  const totalPages = Math.ceil(
    (filter ? filteredList.length : PurchaseListData.length) / pageSize
  );

  setTotalPages(totalPages);

  // Handle page change;

  // const handleChange = (event, value) => {
  //   setPage(value);
  // };

  const ele = displayedData.map(function (item, index) {
    return (
      <tr key={item.id}>
        <td>
          <span className="span-width">{item.displayIndex}</span>
        </td>
        <td>
          <span className="span-width">{item.date}</span>
        </td>
        <td>
          <span className="span-width">{item.invoiceNo}</span>
        </td>
        <td>
          <span className="span-width">{item.firmName}</span>
        </td>
        <td>
          <span className="span-width">{item.region}</span>
        </td>
        <td>
          <span className="span-width">{item.type}</span>
        </td>
        <td>
          <span className="span-width">{item.grandTotal}</span>
        </td>
        <td className="action-button-Purchase-table">
          <button className="edit-sale-list">
            <Link to={"/user/purchase-update/" + item.id}>Edit</Link>
          </button>
          <button className="view-sale-list">
            <Link to={"/user/purchase-view-main/" + item.id}>View </Link>
          </button>
          {/* <button onClick={(() => {navigateToSaleListView(item.id);})} className='view-sale-list'>View</button> */}
          <button
            className="del-sale-list"
            onClick={() => {
              DeletePurchaseListFunction(item.id);
            }}
          >
            Delete
          </button>
        </td>
      </tr>
    );
  });

  return (
    <Layout heading_1="Purchase" heading_2="Purchase List Main">
      <div className="main-Purchaselist-file">
        <div className="Purchaselist-main">
          {/* <PurchaseListHeader /> */}
          <div className="Purchaselist">
            {/* <div className="Purchaselist--header">
            <h4>Purchase List-Main</h4>
          </div> */}

            <div className="table-top-Purchaselist">
              <div className="show-name-Purchaselist">
                <label htmlFor="show">Show : </label>
                <select
                  id="show showSelect"
                  style={{
                    height: "36px",
                    width: "69px",
                    marginLeft: "1rem",
                    marginRight: "1rem",
                    borderRadius: "5px",
                  }}
                >
                  <option>All</option>
                </select>
                <FaRegFilePdf
                  className="pdf-Purchaselist"
                  style={{ cursor: "pointer" }}
                  onClick={(event) => {
                    exportPDF();
                  }}
                />
                <CSVLink
                  data={filter ? filteredList : PurchaseListData}
                  headers={headers}
                  filename="Purchase List-Main.csv"
                  className="hidden"
                  ref={csvDownloadRef}
                  target="_blank"
                />
                <FaFileCsv
                  className="csv-Purchaselist"
                  style={{ cursor: "pointer" }}
                  onClick={(event) => {
                    convertToCsv(event);
                  }}
                />
              </div>

              <div className="search-name-Purchaselist">
                <input
                  placeholder="Search by Firm, Invoice No."
                  onChange={filterListSearch}
                />
              </div>
            </div>
            <div
             className="table-customer--Purchaselist"
             >
              <table 
              id="customers-Purchaselist"
              >
                <tr 
                // className="table-customer-heading-Purchaselist"
                >
                  <th>S.No</th>
                  <th style={{minWidth:60}}>Date</th>
                  <th style={{minWidth:30}}>Invoice No.</th>
                  <th>Firm Name </th>
                  <th>Region</th>
                  <th>Type</th>
                  <th>Amount </th>
                  <th>Action</th>
                </tr>
                {ele}
              </table>
            </div>
          </div>
          <PaginationControlled />
        </div>
      </div>
    </Layout>
  );
}

import React, { useState, useEffect } from "react";
import { Line } from 'react-chartjs-2';
import './graph.css'
import axios from 'axios';
import { Chart as ChartJS, Title, Tooltip, LineElement, Legend, CategoryScale, LinearScale, PointElement, Filler } from 'chart.js/auto';
ChartJS.register(
  Title, Tooltip, LineElement, Legend,
  CategoryScale, LinearScale, PointElement, Filler
)

function Graph() {
  let [jan, setJan] = useState(0);
  let [feb, setFeb] = useState(0);
  let [march, setMarch] = useState(0);
  let [april, setApril] = useState(0);
  let [may, setMay] = useState(0);
  let [june, setJune] = useState(0);
  let [july, setJuly] = useState(0);
  let [aug, setAug] = useState(0);
  let [sep, setSep] = useState(0);
  let [oct, setOct] = useState(0);
  let [nov, setNov] = useState(0);
  let [dec, setDec] = useState(0);
  const GettingSalesByMonth = async () => {
    let token = localStorage.getItem('x-api-key');

    const result = await axios.get("api/user/dashboard/saleByMonth", {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": token
      },
    }
    );
    if(result && result.data && result.data.data){
    console.log(39, result.data.data);
    setJan(result.data.data.janToFeb)
    setFeb(result.data.data.febToMarch)
    setMarch(result.data.data.marchToApril)
    setApril(result.data.data.aprilToMay)
    setMay(result.data.data.mayToJune)
    setJune(result.data.data.juneToJuly)
    setJuly(result.data.data.julyToAug)
    setAug(result.data.data.augToSep)
    setSep(result.data.data.sepToOct)
    setOct(result.data.data.octToNov)
    setNov(result.data.data.novToDec)
    setDec(result.data.data.decToJan)
    console.log(52, sep);
    }
    //  alert(may)
  }
  // alert(may);
  const [data, setData] = React.useState({
    labels: ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"],
    datasets: [
      {
        axis: 'y',
        label: 'Sale By Months',
        data: [jan, feb, march, april, may, june, july, aug, sep, oct, nov, dec],
        fill: true,
        backgroundColor: [
          '#0A9AE3',
          '#E30A0A',
          '#E3990A',
          '#0A20E3',
          '#C451ED'
        ],
        borderColor: [
          '#0A9AE3',
          '#E30A0A',
          '#E3990A',
          '#0A20E3',
          '#C451ED'
        ],
        borderWidth: 4
      },
    ]
  });
  useEffect(() => {
    GettingSalesByMonth();
  }, [])

  // Add this useEffect to update the chart data when state variables change
  useEffect(() => {
    setData({
      ...data,
      datasets: [
        {
          ...data.datasets[0],
          data: [jan, feb, march, april, may, june, july, aug, sep, oct, nov, dec],
        },
      ],
    });
  }, [jan, feb, march, april, may, june, july, aug, sep, oct, nov, dec]);
  return (
    <div className='graph1'>
      <p>Sale by Months</p>
      <Line data={data}>Hello</Line>
    </div>
  );
}

export default Graph;
import React, { useEffect, useState } from "react";
import "./ShareContent.css";
import twitter from "../../images/twitter.png";
import whatsapp from "../../images/whatsapp.png";
import facebook from "../../images/facebook.png";
import gmail from "../../images/gmail.png";
import coupon from "../../images/coupon.png";
import refer from "../../images/referearn_new.png";
import sign from "../../images/sign.jpg";
import Layout from "../Layout/Layout";
import axios from "axios";
import Swal from "sweetalert2";

function ShareContent() {
  const [copied, setCopied] = useState(false);
  const copyToClipboard = () => {
    const linkInput = document.getElementById("link-input");
    linkInput.select();
    document.execCommand("copy");
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  // Getting coupen and signup details;

  const [signup, setSignup] = useState(0);

  const [totalCoupon, setTotalCoupon] = useState(0);

  const getCoupon_details = async () => {
    // Simulate sending OTP
    try {
      let token = localStorage.getItem("x-api-key");
      let response = await axios.post(
        "api/admin/coupon/get_V2_refer_details",
        {},
        {
          // method: "post",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": token,
            "x-api-plan": "SaaS",
          },
        }
      );
      // response = await result.json();
      if (response.data.status == 200) {
        setSignup(response.data.data.no_of_registration_by_using_refcode);
        setTotalCoupon(response.data.data.no_of_order_using_refcode);
      } else if (response.data.status != 200) {
        // Swal.fire({
        //     icon: "error",
        //     title: "Oops...",
        //     text: response.data.message,
        // });
        console.error(response.data.message);
      }
    } catch (error) {
      console.error(error.response.data.message);
    }
  };

  // Getting refer code
  const [coupen, setCoupen] = useState("");

  const getRefer_code = async () => {
    // Simulate sending OTP
    try {
      let token = localStorage.getItem("x-api-key");
      let response = await axios.post(
        "api/admin/coupon/getRefCoupon",
        {},
        {
          // method: "post",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": token,
            "x-api-plan": "SaaS",
          },
        }
      );
      // response = await result.json();
      if (response.status === 200) {
        setCoupen(response.data.data.referCodeV2);
        console.log("referCodeV2", coupen);
      }
      console.log("response72", response.data.data.referCodeV2);
    } catch (error) {
      console.error(error.response.data.message);
    }
  };

  useEffect(() => {
    getRefer_code();
    getCoupon_details();
  }, []);

  return (
    <div className="share-container" style={{ width: "79.2vw" }}>
      <Layout heading_1="Coupon" heading_2="Refer Coupon">
        <div className="refferal-page">
          <div
            id="innner-div-refferal"
            style={{
              backgroundColor: "#015998",
              height: "300px",
              borderRadius: "10px",
              padding: "10px",
            }}
          >
            <h3 id="head-text">Refer and Earn</h3>
            <p id="head-text" style={{ fontFamily: "inherit" }}>
              Share your referral with your friends & earn coupons upto 200₹
            </p>
            <div className="pic-div">
              <div className="middle-icon">
                <div className="coupen-text">
                  <img src={coupon} />
                  <h5 id="head-5">Total Coupon</h5>
                  <input
                    type="text"
                    value={totalCoupon}
                    style={{
                      width: "40px",
                      marginLeft: "10px",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                    readOnly
                  />
                </div>
                <div className="coupen-text">
                  <img src={sign} />
                  <h5 id="head-5">Signed Up</h5>
                  <input
                    type="text"
                    value={signup}
                    style={{
                      width: "40px",
                      marginLeft: "40px",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                    readOnly
                  />
                </div>
              </div>
              <div className="icon-coupen">
                <img src={refer} />
              </div>
            </div>
            <div className="code-input">
              <input
                type="text"
                value={coupen}
                id="link-input"
                readOnly
                style={{
                  width: "15rem",
                  height: "44px",
                  border: "2px dashed black",
                }}
              />
              <button
                onClick={copyToClipboard}
                style={{
                  backgroundColor: "green",
                  color: "white",
                  width: "100px",
                  height: "36px",
                  borderRadius: "6px",
                  position: "relative",
                  left: "200px",
                  bottom: "43px",
                }}
              >
                {copied ? "Link Copied!" : "Copy Link"}
              </button>
            </div>
            <div className="footer-picture">
              <h4 style={{ color: "darkgray" }}>Share via Social Media</h4>
              <div
                className="icon-container"
                style={{
                  display: "flex",
                  position: "relative",
                  left: "222px",
                  bottom: "41px",
                }}
              >
                <a
                  href="https://wa.me"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ marginRight: "10px" }}
                >
                  <img src={whatsapp} />
                </a>
                <a
                  href="https://mail.google.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ marginRight: "10px" }}
                >
                  <img src={gmail} />
                </a>
                <a
                  href="https://twitter.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ marginRight: "10px" }}
                >
                  <img src={twitter} alt="Heart Icon" />
                </a>
                <a
                  href="https://www.facebook.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={facebook} alt="Envelope Icon" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}

export default ShareContent;

/** @format */

import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
// import Header from "./PurchaseEntryHeader";
import Layout from "../../Layout/Layout";
import "../PurchaseEntry/Entry.css";
import "../PurchaseEntry/firmModal.css";
import Swal from "sweetalert2";
import { AgGridReact } from "ag-grid-react";
import { AiOutlineClose } from "react-icons/ai";

import WarningPopup from "../../Sales/SalesEntry/WarningPopup";

// import { useNavigate } from "react-router";
import { Switch, ConfigProvider } from "antd";
import { FirmModal } from "../PurchaseEntry/FirmModal";
import hegan from "../PurchaseEntry/heganB-Wsymbol.png";
import axios from "axios";

const Entry = () => {
  const [state1, setState1] = React.useState("");

  // ============== seller details api for print out ==================== //

  let [userDetails, setUserDetails] = useState({});
  const BuyerDetails = async () => {
    try {
      //token
      let token = localStorage.getItem("x-api-key");

      let response = await axios.post(
        "api/user/getOneUser",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": token,
            "x-api-plan": "SaaS",
          },
          // method: "post",
        }
      );

      let result = response.data;

      console.log(580, result);

      setUserDetails(result.data);
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  const navigate = useNavigate();

  // for value update while changing on existing data in purchase entry array of object;

  const [updateIndex, setUpdateIndex] = useState(false);
  const [updateState, setUpdateState] = useState(false);
  const [focus, setFocus] = useState(false);
  // const handleFocus = () => {
  //   setFocus(true);
  // };

  const handleBlur = () => {
    setFocus(false);
  };

  // --------------------firm modal code---------------------------- //
  const [showFirmModal, setshowFirmModal] = useState(false);
  const [selectedFirm, setSelectedFirm] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");

  const handleSelectedFirm = (val) => {
    setSelectedFirm(val);
  };

  const handleSelectedProduct = (val) => {
    setSelectedProduct(val);
  };

  const inputRef = useRef(null);

  const openFirmModal = (event) => {
    setshowFirmModal(true);
    if (event.keyCode === 13) {
      setshowFirmModal(true);
    }
  };

  const closeFirmModal = () => {
    setshowFirmModal(false);
    dateRef.current.focus();
  };

  // ---------------------------------------Product Modal Code-----------------------------------//

  // invoice-close-button-ref
  const closeInvoiceBtnRef = useRef(null);

  const [showProductModal, setshowProductModal] = useState(false);

  // const inputRef = useRef(null);

  const openProductModal = (event) => {
    setshowProductModal(true);
    if (event.keyCode === 13) {
      setshowProductModal(true);
    }
  };

  const closeProductModal = () => {
    setshowProductModal(false);
    mMRef.current.focus();
  };

  // useEffect(() => {
  //   if (showFirmModal) {
  //     inputRef.current.focus();
  //   }
  //   // console.log('myBoolean changed:', showFirmModal);
  //   // const searchElement = inputRef.current;
  //   // searchElement.focus();
  // }, [showFirmModal]);

  // ---------------------------------------Product Modal Code-----------------------------------//

  useEffect(() => {
    if (showFirmModal) {
      inputRef.current.focus();
    }
    function handleEscapeKey(event) {
      if (event.keyCode === 27) {
        if (showFirmModal) {
          closeFirmModal();
        }
        if (showProductModal) {
          closeProductModal();
        }
        // if (event.key === "Enter" && !event.shiftKey) {
        // console.log("target:", event.target);
        // event.preventDefault();
        switch (event.target) {
          case field6Ref.current:
            field17Ref.current.focus();
            break;
        }
        // }
      }
    }
    window.addEventListener("keydown", handleEscapeKey);

    return () => {
      window.removeEventListener("keydown", handleEscapeKey);
    };
  }, [showFirmModal, showProductModal]);

  // --------------------firm modal code-------------------------- //

  const [isMChecked, setIsMChecked] = useState(true);
  const [isEChecked, setIsEChecked] = useState(false);
  const [isGChecked, setIsGChecked] = useState(false);

  const handleMKeyDown = (event) => {
    // console.log(event.target.id);
    if (!isEChecked) {
      if (event.key === "m" || event.key === "M") {
        setIsMChecked(!isMChecked);
      }
    } else {
      if (event.key === "m" || event.key === "M") {
        setIsMChecked(true);
        setIsEChecked(false);
      }
    }
  };

  const handleEKeyDown = (event) => {
    // console.log(event.target.id);
    if (!isMChecked) {
      if (event.key === "e" || event.key === "E") {
        setIsEChecked(!isEChecked);
      }
    } else {
      if (event.key === "e" || event.key === "E") {
        setIsEChecked(true);
        setIsMChecked(false);
      }
    }
  };

  const handleGKeyDown = (event) => {
    // console.log(event.target.id);
    if (event.key === "g" || event.key === "G") {
      if (isGChecked) {
        setIsGChecked(false);
      } else {
        setIsGChecked(true);
      }
      console.log(153, "isGChecked", isGChecked);
    }
  };

  const handleMclick = () => {
    if (!isEChecked) {
      setIsMChecked(!isMChecked);
    } else {
      setIsMChecked(true);
      setIsEChecked(false);
    }
  };

  const handleEclick = () => {
    if (!isMChecked) {
      setIsEChecked(!isEChecked);
    } else {
      setIsEChecked(true);
      setIsMChecked(false);
    }
  };

  const handleGclick = () => {
    if (isGChecked) {
      setIsGChecked(false);
    } else {
      setIsGChecked(true);
    }
  };

  //getting all firm details from the data base
  const [firmData, setFirmData] = useState("");
  // console.log(firmData, "data is here ")
  // if user is updating ME or G in the product list
  const [goodChange, setgoodChange] = useState("");
  const [meregister, setMeregister] = useState("");

  // we are storing something in this varibale using update button to avoid new index creation in object while updating

  // this is for M F G checkbox
  // register as wheter m or e it depends on user
  const [registerAs, setregisterAs] = useState("M");
  const [g, setG] = React.useState("");
  const [actualG, setActualG] = useState("");

  const handleChangeG = (event) => {
    if (event.key) {
      // alert('hi')
      if (event.key == "m" || event.key == "M") {
        if (actualG == "") {
          setActualG("G");
        } else {
          setActualG("");
        }
      }
    } else {
      // alert('he')
      if (actualG == "") {
        setActualG("G");
      } else {
        setActualG("");
      }
    }

    // } else {
    //   setActualG("");
    // }
  };




//for warning pop up to fill mandtory fileds



const [showWarning, setShowWarning] = useState(false);
const [warningMessage, setWarningMessage] = useState('');

const handleShowWarning = (message) => {
  console.log('Opening warning');
  // if(showWarning && okButtonRef.current){
    // okButtonRef.current.focus();
    // alert('working');
  // }
   
  setWarningMessage(message);
  setShowWarning(true);
};

const handleCloseWarning = () => {
  // console.log('Closing warning');
  // if(showWarning && okButtonRef.current){
    // okButtonRef.current.focus();
    // alert('working');
  // }
  setShowWarning(false); 
};

//to check mandatory fields have values 
const validateFields = (event) => {
  // if (event.key === "Enter" && !event.shiftKey ) {
  //   event.preventDefault();


  let isCheckSelected = isMChecked || isEChecked || isGChecked;

  if(!selectedFirm && mTradeRateRef.current){
    handleShowWarning('Please Select Firm Name');
    // firmNameRef.current.focus();
    return false;
  } 
  else if(!date && mTradeRateRef.current){
    handleShowWarning('Please Select A Date');
    dateRef.current.focus();
    return false;
  } 
  else if (!region && mTradeRateRef.current){
    handleShowWarning('Please Select A Region');
    
//  alert('done to doneuhkj');
    // regionRef.current.focus();
    return false;
  }
   else if (!type && mTradeRateRef.current){
    handleShowWarning('Please Select A Type');
    typeRef.current.focus();
    return false;
  } else if (!invoice && mTradeRateRef.current){
    handleShowWarning('Please Enter Invoice Number');
    InvoiceNoRef.current.focus();
    return false;
    //InvoiceNoRef
  }
   else if (!selectedProduct && mTradeRateRef.current) {
    handleShowWarning('Please Select A Product');
    mProductNameRef.current.focus();
    return false;
  } else if(!isCheckSelected && mTradeRateRef.current){
    handleShowWarning('Please Check Atleast One Check Box')
    mMRef.current.focus();
    return false;
  } else if(!qty && mTradeRateRef.current || qty == 0){
    handleShowWarning('Please Enter Quantity')
    mQuantityRef.current.focus();
    return false;
  } else if(!tradeRate && mTradeRateRef.current || tradeRate == 0){
    handleShowWarning('Please Enter Trade Rate')
    mTradeRateRef.current.focus();
    return false;
    // alert('Please Enter Trade Rate');
  }
  else{
    // handleCloseWarning();
    return true;
  }
// } 
};


  // setting for subtotle which we are suign in line no 226;

  let [subCalculate, setSubCalculate] = useState("");
  let [discPercentAmount, setDiscountAmount] = useState("");
  let [FinallTax, setFinallTex] = useState("0");
  let [finalGoodReturn, setfinalGoodReturn] = useState("");
  let [TotalSumPay, setTotalSumPay] = useState("");
  let [GoodsReturnSum, setGoodsReturnPay] = useState("");
  // for showing model and hiding model
  const [showModal, setShowModal] = React.useState(false);
  const [showModal2, setShowModal2] = React.useState(false);

  //for vlaues updat in tax pop up
  const [showModalUpdateValue, setShowModalUpdateValue] = useState(false);

  const UpdateStateForUpdatedValues = () => {
    setShowModalUpdateValue(!showModalUpdateValue);
  };

  function openModalUpdateValue(id) {
    console.log(
      "coming in open model for igst cgst sgst where we are storing id for uniqueness",
      id
    );
    if (id >= 0) {
      setOnClickedDone(id);
      console.log("coming in the ", id);
      if (ProductEntries[id].batchNo) {
        setBatchUpdateValue(ProductEntries[id].batchNo);
      }
      if (ProductEntries[id].expiry) {
        setExpiryUpdateValue(ProductEntries[id].expiry);
      }
      if (ProductEntries[id].hsn_sac) {
        setHsnSacUpdateValue(ProductEntries[id].hsn_sac);
      }
      if (ProductEntries[id].mrp) {
        setMrpUpdateValue(ProductEntries[id].mrp);
      }
      if (ProductEntries[id].igst) {
        setIgstUpdateValue(ProductEntries[id].igst);
      }
      if (ProductEntries[id].cgst) {
        setCgstUpdateValue(ProductEntries[id].cgst);
      }
      if (ProductEntries[id].sgst) {
        setSgstUpdateValue(ProductEntries[id].sgst);
      }
      if (ProductEntries[id].cess) {
        setCessUpdateValue(ProductEntries[id].cess);
      }
      UpdateStateForUpdatedValues();
    } else {
      UpdateStateForUpdatedValues();
    }
  }

  //for open box which open while clicking on percentage
  const [onClickedDone, setOnClickedDone] = useState("khali he ");
  //calling this function from productentries.map
  //for every index trade rate qty disc ke liye
  const [everyIndex, setEveryIndex] = useState("khali he");

  function openModal(id) {
    console.log(
      "coming in open model for igst cgst sgst where we are storing id for uniqueness"
    );

    console.log(id);

    if (id >= 0) {
      setOnClickedDone(id);
      if (ProductEntries[id].batchNo) {
        setBatch(ProductEntries[id].batchNo);
      }
      if (ProductEntries[id].expiry) {
        setexpiry(ProductEntries[id].expiry);
      }
      if (ProductEntries[id].hsn_sac) {
        sethsn_sac(ProductEntries[id].hsn_sac);
      }
      if (ProductEntries[id].mrp) {
        setMrp(ProductEntries[id].mrp);
      }
      if (ProductEntries[id].igst) {
        setigst(ProductEntries[id].igst);
      }
      if (ProductEntries[id].cgst) {
        setcgst(ProductEntries[id].cgst);
      }
      if (ProductEntries[id].sgst) {
        setsgst(ProductEntries[id].sgst);
      }
      if (ProductEntries[id].cess) {
        setcess(ProductEntries[id].cess);
      }
      setShowModal(function (item) {
        return !item;
      });
    } else {
      //  setShowModal(!showModal )
      setShowModal(function (item) {
        return !item;
      });
    }
  }

  function openModalWithEnter(event) {
    if (event.key === "Enter") {
      setShowModal(function (item) {
        return !item;
      });
    }
  }

  function openModal2() {
    setShowModal2(function (item) {
      return !item;
    });
  }

  //firm name and firm id
  const [firm, setFirm] = React.useState("");
  const [firmId, setFirmId] = useState("");

  //product id check line no 564;

  const [ProductId, setProductId] = useState("");

  const [region, setRegion] = React.useState("");
  const [type, setType] = React.useState("");
  const regionSelect = ["Select Region", "Local", "Center"];
  const typeData = [
    "Select Type",
    "Cash",
    "Credit",
    // "Credit Note"
  ];

  const [localSelect, setLocalSelect] = useState(false);
  const [centerSelect, setCenterSelect] = useState(false);

  function chekingdata(value) {
    for (let i = 1; i < regionSelect.length; i++) {
      if (regionSelect[i] === value) {
        if (value === "Local") {
          setLocalSelect(true);
          setCenterSelect(false);
        } else if (value === "Center") {
          setCenterSelect(true);
          setLocalSelect(false);
        }
      }
    }
  }

  const [invoiceSelect, setInvoiceSelect] = useState(true);
  const [cnRefSelect, setCnRefSelect] = useState(false);

  function settingInvoiceAndCN(value) {
    // alert(value)
    // for (let i = 1; i < typeData.length; i++) {
    // if (typeData[i] === value) {
    // alert(value)
    if (value === "Cash") {
      // alert(value)
      setInvoiceSelect(true);
      setCnRefSelect(false);
    } else if (value === "Credit") {
      // alert(value)
      setInvoiceSelect(true);
      setCnRefSelect(false);
    } else if (value === "CN") {
      // alert('hi')
      setInvoiceSelect(false);
      setCnRefSelect(true);
    }
    // }
    // }
  }

  // -----------------------------------enter button Logic--------------------------------------
  // function handleEnter(event) {
  //   if (event.keyCode === 13) {
  //     const form = event.target.form;
  //     const index = Array.prototype.indexOf.call(form, event.target);
  //     // console.log(index, event.target);
  //     if (!(event.target.name == "invoice")) {
  //       form.elements[index + 1].focus();
  //       // console.log(form.elements);
  //     }
  //     event.preventDefault();
  //   }
  // }

  const field1Ref = useRef(null);
  const dateRef = useRef(null);
  const field3Ref = useRef(null);
  const typeRef = useRef(null);
  const field6Ref = useRef(null);
  const mProductNameRef = useRef(null);
  const mMRef = useRef(null);
  const field9Ref = useRef(null);
  const mGRef = useRef(null);
  const field11Ref = useRef(null);
  const mQuantityRef = useRef(null);
  const field13Ref = useRef(null);
  const mTradeRateRef = useRef(null);
  const field15Ref = useRef(null);
  const field16Ref = useRef(null);
  const field17Ref = useRef(null);
  const field18Ref = useRef(null);
  const field19Ref = useRef(null);
  const field20Ref = useRef(null);
  const field21Ref = useRef(null);
  const field22Ref = useRef(null);
  const field23Ref = useRef(null);
  const field24Ref = useRef(null);
  const field25Ref = useRef(null);

  const InvoiceNoRef = useRef(null);
  const field27Ref = useRef(null);

  const batchRef = useRef(null);
  const expiryRef = useRef(null);
  const mrpRef = useRef(null);
  const hsnSacRef = useRef(null);
  const igstRef = useRef(null);
  const cgstRef = useRef(null);
  const sgstRef = useRef(null);
  const cessRef = useRef(null);

  const updatePopUpRef = useRef(null);

  // bottom refs
  const bSubTotalBoxRef = useRef(null);
  const bDiscountAmtBoxRef = useRef(null);
  const bTotalTaxBoxRef = useRef(null);
  const bGoodsReturnBoxRef = useRef(null);
  const bCnVoucherBoxRef = useRef(null);
  const bPayableAmountBoxRef = useRef(null);
  // bottom buttons ref
  const bSaveButtonRef = useRef(null);
  const bPrintButtonRef = useRef(null);
  const bViewButtonRef = useRef(null);
  const mSnRef = useRef(null);

  // print options ref
  const mainSalesInvoiceToggleRef = useRef(null);
  const mainInvoiceToggleRef = useRef(null);
  const estimateSalesInvoiceToggleRef = useRef(null);
  const estimateInvoiceToggleRef = useRef(null);
  const confirmChoicesRef = useRef(null);

  const handleTaxEnter = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      switch (event.target) {
        case batchRef.current:
          expiryRef.current.focus();
          break;
        case expiryRef.current:
          mrpRef.current.focus();
          break;
        case mrpRef.current:
          hsnSacRef.current.focus();
          break;
        case hsnSacRef.current: {
          if (centerSelect) {
            igstRef.current.focus();
            break;
          }
          if (localSelect) {
            cgstRef.current.focus();
            break;
          }
        }
        case igstRef.current:
          cessRef.current.focus();
          break;
        case cgstRef.current:
          {
          }
          sgstRef.current.focus();
          break;
        case sgstRef.current:
          cessRef.current.focus();
          break;

        case !showModalUpdateValue && cessRef.current:
          field15Ref.current.focus();
          openModal();
          // FormulaOne();
          break;
        case showModalUpdateValue && cessRef.current:
          updatePopUpRef.current.focus();
          break;
      }
    } else if (event.key === "ArrowUp") {
      event.preventDefault();
      switch (event.target) {
        case batchRef.current: {
          mTradeRateRef.current.focus();
          openModal();
          break;
        }
        case expiryRef.current:
          batchRef.current.focus();
          break;
        case expiryRef.current:
          batchRef.current.focus();
          break;
        case mrpRef.current:
          expiryRef.current.focus();
          break;
        case hsnSacRef.current:
          mrpRef.current.focus();
          break;
        case mrpRef.current:
          expiryRef.current.focus();
          break;
        case mrpRef.current:
          expiryRef.current.focus();
          break;
        case hsnSacRef.current:
          mrpRef.current.focus();
          break;
        case igstRef.current:
          hsnSacRef.current.focus();
          break;
        case cessRef.current: {
          if (centerSelect) {
            igstRef.current.focus();
            break;
          } else if (localSelect) {
            sgstRef.current.focus();
            break;
          } else {
            hsnSacRef.current.focus();
            break;
          }
        }
        case sgstRef.current:
          cgstRef.current.focus();
          break;
        case cgstRef.current:
          hsnSacRef.current.focus();
          break;
        case igstRef.current:
          hsnSacRef.current.focus();
          break;
      }
    }
  };

  useEffect(() => {}, [setInvoiceSelect, setCnRefSelect]);

  const handleRegionEnter = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      switch (event.target) {
        case field3Ref.current:
          typeRef.current.focus();
          break;
      }
    } else if (event.key === "ArrowUp") {
      event.preventDefault();
      switch (event.target) {
        case field3Ref.current:
          dateRef.current.focus();
          break;
      }
    }
  };

  const handleIvCnEnter = (event) => {
    // console.log(296,invoiceSelect);
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      switch (event.target) {
        case typeRef.current: {
          if (typeRef.current && invoiceSelect) {
            InvoiceNoRef.current.focus();
            break;
          }
          if (typeRef.current && cnRefSelect) {
            field27Ref.current.focus();
            break;
          }
        }
      }
    } else if (event.key === "ArrowUp") {
      event.preventDefault();
      switch (event.target) {
        case typeRef.current:
          field3Ref.current.focus();
          break;
        case field3Ref.current:
          field3Ref.current.focus();
          break;
      }
    }
  };

  const handleEnter = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      // console.log("target:", event.target);
      event.preventDefault();
      switch (event.target) {
        case field1Ref.current:
          dateRef.current.focus();
          break;
        case dateRef.current:
          field3Ref.current.focus();
          break;

        // case typeRef.current: {
        //   if (typeRef.current && setInvoiceSelect) {
        //     InvoiceNoRef.current.focus();
        //     break;
        //   }
        //   if (typeRef.current && setCnRefSelect) {
        //     field27Ref.current.focus();
        //     break;
        //   }
        // }

        case InvoiceNoRef.current:
          field6Ref.current.focus();
          break;
        case field27Ref.current:
          field6Ref.current.focus();
          break;
        case field6Ref.current:
          mProductNameRef.current.focus();
          break;
        case mProductNameRef.current:
          mMRef.current.focus();
          break;
        case mMRef.current:
          field9Ref.current.focus();
          break;
        case field9Ref.current:
          mGRef.current.focus();
          break;
        case mGRef.current:
          field11Ref.current.focus();
          break;
        case field11Ref.current:
          mQuantityRef.current.focus();
          break;
        case mQuantityRef.current:
          field13Ref.current.focus();
          break;
        case field13Ref.current:
          mTradeRateRef.current.focus();
          break;
        case mTradeRateRef.current:
          field15Ref.current.focus();
          break;
        case field15Ref.current: {
          //544
          FormulaOne();
          setIsEChecked(false);
          // setIsMChecked(false);
          setIsGChecked(false);
          field6Ref.current.focus();
          break;
        }

        case field16Ref.current:
          field17Ref.current.focus();
          break;
        case field17Ref.current:
          field18Ref.current.focus();
          break;
        case field18Ref.current:
          field19Ref.current.focus();
          break;
        case field19Ref.current:
          field20Ref.current.focus();
          break;
        case field20Ref.current:
          field21Ref.current.focus();
          break;
        case field21Ref.current:
          field22Ref.current.focus();
          break;
        case field22Ref.current:
          field23Ref.current.focus();
          break;
        case field23Ref.current:
          field24Ref.current.focus();
          break;
        case field24Ref.current:
          field25Ref.current.focus();
          break;
      }
    } else if (event.key === "ArrowUp") {
      event.preventDefault();
      switch (event.target) {
        case dateRef.current:
          field1Ref.current.focus();
          break;
        case field3Ref.current:
          dateRef.current.focus();
          break;
        case typeRef.current:
          field3Ref.current.focus();
          break;
        case InvoiceNoRef.current:
          typeRef.current.focus();
          break;
        case field27Ref.current:
          typeRef.current.focus();
          break;
        case field6Ref.current: {
          if (field6Ref.current && invoiceSelect) {
            InvoiceNoRef.current.focus();
            break;
          }
          if (field6Ref.current && cnRefSelect) {
            field27Ref.current.focus();
            break;
          }
        }

        case mProductNameRef.current:
          field6Ref.current.focus();
          break;
        case mMRef.current:
          mProductNameRef.current.focus();
          break;
        case field9Ref.current:
          mMRef.current.focus();
          break;
        case mGRef.current:
          field9Ref.current.focus();
          break;
        case field11Ref.current:
          mGRef.current.focus();
          break;
        case mQuantityRef.current:
          field11Ref.current.focus();
          break;
        case field13Ref.current:
          mQuantityRef.current.focus();
          break;
        case mTradeRateRef.current:
          field13Ref.current.focus();
          break;
        case field15Ref.current:
          mTradeRateRef.current.focus();
          break;
        case field16Ref.current:
          field15Ref.current.focus();
          break;
        case field17Ref.current:
          field16Ref.current.focus();
          break;
        case field18Ref.current:
          field17Ref.current.focus();
          break;
        case field19Ref.current:
          field18Ref.current.focus();
          break;
        case field20Ref.current:
          field19Ref.current.focus();
          break;
        case field21Ref.current:
          field20Ref.current.focus();
          break;
        case field22Ref.current:
          field21Ref.current.focus();
          break;
        case field23Ref.current:
          field22Ref.current.focus();
          break;
        case field24Ref.current:
          field23Ref.current.focus();
          break;
        case field25Ref.current:
          field24Ref.current.focus();
          break;
      }
    }
  };





  const params = useParams();
  //  alert(params.id)
   const updateProductDetails = async () => {
     //token
     try{
     let token = localStorage.getItem("x-api-key");
    //  alert(token)
     let response = await axios.post(`api/user/purchase/view/${params.id}`, {}, {
       // method: "POST",
       headers: {
         "Content-Type": "application/json",
         "x-api-key": token,
         "x-api-plan": "SaaS",
       },
     });
 
     let result = response.data;
     console.log("getting data", result);
     let res = result.data;
     console.log(555, "res ka data", res.id);
     setFirmId(res.firmNameCollection.id);
     setFirm(res.firmName);
     setRegion(res.region);
     setType(res.type);
     setDate(res.date);
     setInvoice(res.invoiceNo);
     setAddress(res.firmNameCollection.fullAdd);
     setEmail(res.firmNameCollection.email);
     setMobile(res.firmNameCollection.mobileNo);
     setGst(res.firmNameCollection.gst);
     setDL1(res.firmNameCollection.dl1);
     setDL2(res.firmNameCollection.dl2);
     setFssai(res.firmNameCollection.fssai);
     setSubtotal(res.subTotal);
     setDisAmt(res.discAmt);
     setGoodReturn(res.goodReturnAmt);
     setTotalTax(res.totalTax);
     setCnVoucher(res.cnVoucher);
     setGrandTotal(res.grandTotal);
     setProductEntries(res.purchaseProdEntries);
    }catch(error){
       console.log('error in getting data in purchase update',error.message);
    }
   };

   useEffect( () => {
    updateProductDetails();
   },[])
 

  // sending updated data to backend
  const sendPurchaseData = async () => {
    //token
    try{

      console.log('send data',{
        firmName: firm,
        firmId: firmId,
        region: region,
        type: type,
        date: date,
        invoiceNo: invoice,
        subTotal: subtotal,
        discAmt: disAmt,
        totalTax: totalTax,
        grandTotal: grandTotal,
        cnVoucher: cnVoucher,
        productEntries: ProductEntries,
      })

      // return
     console.log("ProductEntries", ProductEntries);
    let token = localStorage.getItem("x-api-key");
    let response = await axios.put(
      `api/user/purchase/edit/${params.id}`,
      {
        firmName: firm,
        firmId: firmId,
        region: region,
        type: type,
        date: date,
        invoiceNo: invoice,
        subTotal: subtotal,
        discAmt: disAmt,
        totalTax: totalTax,
        grandTotal: grandTotal,
        cnVoucher: cnVoucher,
        productEntries: ProductEntries,
      },
      {
        // method: "put",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": token,
          "x-api-plan": "SaaS",
        },
      }
    );

    let result = response.data;
    console.log("ProductEntries", ProductEntries);
    console.log("sending values", result);
    if (result.message === "update details successfully") {
      Swal.fire("Genereted!", " Updated successfully.", "success");
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: result.message,
      });
    }
   }catch(error){
            console.log('error',error.message);
   }
  };

  // ======================================Navigate Modal Code======================================//

  const [date, setDate] = React.useState("");
  const [invoice, setInvoice] = React.useState("");
  const [cnReference, setCnReference] = React.useState("NA");

  const [address, setAddress] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [gst, setGst] = React.useState("");
  const [DL1, setDL1] = React.useState("NA");
  const [DL2, setDL2] = React.useState("NA");
  const [fssai, setFssai] = React.useState("NA");

  //all the final details of purchase entry
  const [subtotal, setSubtotal] = useState(0);
  const [disAmt, setDisAmt] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [goodReturn, setGoodReturn] = useState(0);
  const [cnVoucher, setCnVoucher] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [grandTotal2, setGrandTotal2] = useState(0);

  // select tag value check line 717

  const [selects, setSelects] = React.useState(false);

  //purchase product entry
  const [unitPack, setUnitPack] = useState("");
  const [qty, setqty] = useState(0);
  const [free, setFree] = useState("0");
  const [tradeRate, setTradeRate] = useState(0);
  const [discPercent, setDiscount] = useState(0);
  const [amount, setAmount] = useState(0);

  const [batchNo, setBatch] = React.useState("");
  const [expiry, setexpiry] = React.useState(0);
  const [mrp, setMrp] = React.useState("00.00");
  const [hsn_sac, sethsn_sac] = React.useState("");
  const [netRate, setNetRate] = React.useState("");

  let [igst, setigst] = useState(0);
  let [cgst, setcgst] = useState(0);
  let [sgst, setsgst] = useState(0);
  let [cess, setcess] = useState(0);

  //purchase update entry in the coming entry
  const [unitPackUpdateValue, setUnitPackUpdateValue] = useState("");
  const [qtyUpdateValue, setQtyUpdateValue] = useState("");
  const [freeUpdateValue, setFreeUpdateValue] = useState("");
  const [tradeRateUpdateValue, setTradeRateUpdateValue] = useState("");
  const [discPercentUpdateValue, setDiscountUpdateValue] = useState("");
  const [amountUpdateValue, setAmountUpdateValue] = useState(0);

  const [batchNoUpdateValue, setBatchUpdateValue] = useState("");
  const [expiryUpdateValue, setExpiryUpdateValue] = useState("");
  const [mrpUpdateValue, setMrpUpdateValue] = useState("");
  const [hsnSacUpdateValue, setHsnSacUpdateValue] = useState("");
  const [netRateUpdateValue, setNetRateUpdateValue] = useState("");

  const [igstUpdateValue, setIgstUpdateValue] = useState(0);
  const [cgstUpdateValue, setCgstUpdateValue] = useState(0);
  const [sgstUpdateValue, setSgstUpdateValue] = useState(0);
  const [cessUpdateValue, setCessUpdateValue] = useState(0);

  //for value update in pucrchase entry every array
  //purchase product entry
  const [unitPack2, setUnitPack2] = useState("");
  const [qty2, setqty2] = useState(false);
  const [free2, setFree2] = useState("");
  const [tradeRate2, setTradeRate2] = useState("");
  const [discPercent2, setDiscount2] = useState("");
  const [amount2, setAmount2] = useState("");

  const [batchNo2, setBatch2] = React.useState("");
  const [expiry2, setexpiry2] = React.useState("");
  const [mrp2, setMrp2] = React.useState("");
  const [hsn_sac2, sethsn_sac2] = React.useState("");
  const [netRate2, setNetRate2] = React.useState("");
  let [igst2, setigst2] = useState("");
  let [cgst2, setcgst2] = useState("");
  let [sgst2, setsgst2] = useState("");
  let [cess2, setcess2] = useState("");

  // here we are dividing value's of igst into sgst and cgst
  // function vikram(igst) {
  //   setcgst(igst / 2);
  //   setsgst(igst / 2);
  // }

  //alll product data for displaying product in the input field
  const [productData, setProductData] = useState([]);
  // storing product name in generatedData variable
  const [generatedData, setGeneratedData] = React.useState("");

  const [product, setProductName] = useState("");
  const [category, setCategory] = useState("");
  const [brand, setBrand] = useState("");
  const [description, setDescription] = useState("");
  const [unitpacking, setUnitPacking] = useState("");
  const [hsn_sac_code, setHsn_sac_Code] = useState("");

  let [ProductEntries, setProductEntries] = useState([]);
  let [firmEntries, setfirmEntries] = useState([]);

  // const [inputDate, setInputDate] = useState("");
  // const [inputInvoiceNo, setInputInvoiceNo] = useState("");
  // const [inputAmt, setInputAmt] = useState("");

  // this function for user's data update if user is updating something then this we be called
  function FormulaTwo(p) {
    setActualG('');
    setUpdateIndex('');
    console.log("update phase 2 formula");
    // formula one if we are getting free in 10+1 format
    const regex = /(\d+)\+(\d+)/;
    const freeGet = ProductEntries[p].free;
    const match = freeGet.match(regex);
    if (match) {
      // alert('match')
      console.log("match in 10+!=1");
      let x = parseInt(match[1]); // Extracted x value
      let y = parseInt(match[2]); // Extracted y value
      console.log("x:", x);
      console.log("y:", y);

      x = parseInt(x);
      y = parseInt(y);
      //  if user select 10+1 like then we are making it in percentage
      let freeT = (y * 100) / (x + y);
      freeT = freeT.toFixed(2);
      console.log("free trade Free", freeT);
      ProductEntries[p].freeDiscount = freeT;
      x = 0;
      y = 0;

      // if qty and trader discpernt are empty then use current vlaues from array
      let subTotal = ProductEntries[p].qty * ProductEntries[p].tradeRate;
      // here we are minus the free 10+1 like amount from total amount just like discount
      let FreeDiscountAmount =
        (ProductEntries[p].freeDiscount / 100) * subTotal || 0;
      // here we are subtract the free discount amount from subtotal
      let afterFreeDiscountSubTotal = subTotal - FreeDiscountAmount;
      let DiscountAmount =
        (ProductEntries[p].discPercent / 100) * afterFreeDiscountSubTotal;
      //here we are subtract the discount amount from the sub total
      let finalAmount = afterFreeDiscountSubTotal - DiscountAmount;

      let tax =
        parseFloat(ProductEntries[p].igst) +
        parseFloat(ProductEntries[p].cess) +
        parseFloat(ProductEntries[p].sgst) +
        parseFloat(ProductEntries[p].cgst);
      let totalTax = (finalAmount * tax) / 100;

      //for single product
      let singleProductTax = (finalAmount * tax) / 100;

      setTotalTax(parseFloat(totalTax));

      let final = (totalTax + finalAmount).toFixed(2);
      setAmount(final);
      ProductEntries[p].amount = final;
      ProductEntries[p].productTax = singleProductTax;
      ProductEntries[p].freeDiscount = freeT;
      AfterAddingAllValuesCalculationFunction();
      return;
    } else {
      console.log("coming in else part");
      // if qty and trader discpernt are empty then use current vlaues from array
      let data = ProductEntries[p].qty * ProductEntries[p].tradeRate;
      let dis = (ProductEntries[p].discPercent / 100) * data;
      let AMt = data - dis;

      let tax =
        parseFloat(ProductEntries[p].igst) +
        parseFloat(ProductEntries[p].cess) +
        parseFloat(ProductEntries[p].sgst) +
        parseFloat(ProductEntries[p].cgst);
      let totalTax = (AMt * tax) / 100;

      //for single product
      let singleProductTax = (AMt * tax) / 100;

      setTotalTax(parseFloat(totalTax));
      let final = (totalTax + AMt).toFixed(2);
      setAmount(final);
      ProductEntries[p].amount = final;
      ProductEntries[p].productTax = singleProductTax;
      ProductEntries[p].freeDiscount = 0; //if we are not getting 10+1 like values then making taht index empty
      AfterAddingAllValuesCalculationFunction();
      return;
    }
  }

  // formula one of the calculation's
  function FormulaOne() {
    // formula one if we are getting free in 10+1 format
    if (free) {
      for (let i = 0; i < free.length; i++) {
        if (free[i] === "+") {
          let x = "";
          let y = "";
          for (let i = 0; i < free.length; i++) {
            if (free[i] !== "+") {
              x += free[i];
            } else {
              break;
            }
          }
          for (let i = 0; i < free.length; i++) {
            if (free[i] !== "+") {
              y += free[i];
            } else {
              y = "";
            }
          }
          x = parseInt(x);
          y = parseInt(y);

          let freeT = (y * 100) / (x + y);
          freeT = freeT.toFixed(2);
          let data = qty * tradeRate;
          let data2 = (data * freeT) / 100;
          let data3 = data - data2;
          let dis = (discPercent / 100) * data3;
          let AMt = data3 - dis;
          if (!cess) {
            cess = 0;
          }
          if (!cgst) {
            cgst = 0;
          }
          if (!igst) {
            igst = 0;
          }
          if (!sgst) {
            sgst = 0;
          }
          let tax =
            parseFloat(igst) +
            parseFloat(cess) +
            parseFloat(sgst) +
            parseFloat(cgst);
          let totalTax = (AMt * tax) / 100;

          //for single product
          let singleProductTax = (AMt * tax) / 100;
          setTotalTax(parseFloat(totalTax));

          let final = (totalTax + AMt).toFixed(2);
          setAmount(final);
          arrayOfObject(final, singleProductTax, freeT);
          return;
        }
      }
    }

    // formula two if we are not getting value in free like 10+1
    let data = qty * tradeRate;
    let dis = (discPercent / 100) * data;
    let AMt = data - dis;
    if (!cess) {
      cess = 0;
    }
    if (!cgst) {
      cgst = 0;
    }
    if (!igst) {
      igst = 0;
    }
    if (!sgst) {
      sgst = 0;
    }

    let tax =
      parseFloat(igst) + parseFloat(cess) + parseFloat(sgst) + parseFloat(cgst);
    let totalTax = (AMt * tax) / 100;

    //for single product
    let singleProductTax = (AMt * tax) / 100;

    setTotalTax(parseFloat(totalTax));
    let final = (totalTax + AMt).toFixed(2);
    setAmount(final);
    arrayOfObject(final, singleProductTax);
    return;
  }

  useEffect(() => {
    getFirmData();
    getProductData();
    BuyerDetails();
  }, []);

  function arrayOfObject(props, singleProductTax, freeDiscount) {
    // console.log("singleProductTax", singleProductTax);
    let firmObject = {
      firmName: firmId,
      region: region,
      type: type,
      data: date,
      invoice: invoice,
      subtotal: subtotal,
      disAmt: disAmt,
      totalTax: props,
      grandTotal: grandTotal,
      cnVoucher: cnVoucher,
    };

    // console.log("firmObject", firmObject);
    // setting net rate to variable
    let res = props / qty;
    setNetRate(res);

    // arry of object for products
    let ProductObj = {
      productName: generatedData,
      productId: ProductId,
      purchaseType: registerAs,
      returnProduct: actualG,
      unitPack: unitPack,
      qty: parseInt(qty),
      free: free,
      freeDiscount: freeDiscount ? freeDiscount : 0,
      tradeRate: tradeRate,
      goodReturnAmt: goodReturn,
      // totalTax: tax2,
      productTax: singleProductTax,
      discPercent: discPercent,
      amount: props,
      batchNo: batchNo,
      expiry: expiry,
      mrp: mrp,
      hsn_sac: hsn_sac,
      netRate: netRate,
      igst: parseFloat(igst),
      sgst: parseFloat(sgst),
      cess: parseFloat(cess),
      cgst: parseFloat(cgst),
    };

    ProductEntries.push(ProductObj);
    setActualG("");
    //calling this function for further calculation of product array of object
    AfterAddingAllValuesCalculationFunction();
  }

  function AfterAddingAllValuesCalculationFunction() {
    let tax = 0;
    let subCalculate = 0;
    let discPercentAmount = 0;
    let FreeDiscountPercentage = 0;
    let finalGoodsReturn = 0;

    for (let i = 0; i < ProductEntries.length; i++) {
      const entry = ProductEntries[i];

      if (entry.returnProduct === "G") {
        const amount = parseFloat(entry.amount);

        if (!isNaN(amount)) {
          finalGoodsReturn += amount;
          console.log("Amount:", amount.toFixed(2));
          finalGoodsReturn = parseFloat(finalGoodsReturn.toFixed(2));
          console.log("Final Good Return:", finalGoodsReturn);
          setGoodReturn(finalGoodsReturn);
        } else {
          console.error("Error: Unable to parse amount");
        }
      }

      if (!entry.returnProduct) {
        tax += parseFloat(entry.productTax) || 0;
        subCalculate += entry.tradeRate * entry.qty || 0;
        let data = entry.qty * entry.tradeRate || 0;

        // Calculate FreeDiscountPercentage first
        FreeDiscountPercentage += (entry.freeDiscount / 100) * data || 0;
        console.log(
          "FreeDiscountPercentage",
          FreeDiscountPercentage,
          entry.freeDiscount
        );
        // Calculate discPercentAmount using the updated data (after FreeDiscountPercentage calculation)
        let dataAfterFreeDiscount = data - FreeDiscountPercentage || 0;
        discPercentAmount +=
          (entry.discPercent / 100) * dataAfterFreeDiscount || 0;
        console.log("discPercentAmount", discPercentAmount);
        data = 0;
      }
    }

    setTotalTax(parseFloat(tax.toFixed(2)));
    setSubtotal(parseInt(subCalculate));
    setDisAmt(
      parseFloat(discPercentAmount + FreeDiscountPercentage).toFixed(2)
    );
    setGoodReturn(finalGoodsReturn);
    let TotalSumPay = 0;
    let GoodsReturnSum = 0;

    for (let i = 0; i < ProductEntries.length; i++) {
      const entry = ProductEntries[i];

      if (!entry.returnProduct) {
        let v = parseFloat(entry.amount).toFixed(2);
        TotalSumPay += parseFloat(v);
      }

      if (entry.returnProduct) {
        let v = parseFloat(entry.amount).toFixed(2);
        GoodsReturnSum += parseFloat(v);
      }
    }

    let sum = TotalSumPay - GoodsReturnSum;
    console.log("Sum TotalSumPay - GoodsReturnSum:", sum);
    // setGrandTotal(sum);
    let val =0
    if(cnVoucher){
      val = parseFloat(cnVoucher);
    }
    setGrandTotal(sum+val);
  }

  //function is closed

  // Creating CN voucher function for cn data to minus from grand total
  function cnVoucherFunction(props) {
    var minus = grandTotal - props[0];

    // setGrandTotal2(minus);
    // totalGrand(minus);
  }
  // setting actual data for user
  function totalGrand(minus) {
    // setGrandTotal2(minus);
  }

  // getting all firm details from data base
  const getFirmData = async () => {
    try {
      //token
      let token = localStorage.getItem("x-api-key");
      let response = await axios.get("api/user/firm/getAllFirmDetails", {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": token,
          "x-api-plan": "SaaS",
        },
      });
      let result = response.data;
      console.log(1243, result);
      if (result) {
        setFirmData(result.data);
      } else {
        setFirmData([]);
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  // invoice history of a firm with the particular seller after selecting a firm on prseeing button select a firm.

  const [selectedFirmInvoiceHistory, setSelectedFirmInvoiceHistory] = useState(
    []
  );
  const getInvoiceHistory = async (selectedFirmId) => {
    //token
    try {
      let token = localStorage.getItem("x-api-key");

      let response = await axios.get(
        `api/user/purchase/invoicePList/${selectedFirmId}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": token,
            "x-api-plan": "SaaS",
          },
          // method: "get",
        }
      );

      let result = response.data;
      console.log("result", result);
      // setInvoiceHistory(result.data);
      if (result) {
        setSelectedFirmInvoiceHistory(result.data);
      } else {
        setSelectedFirmInvoiceHistory([]);
      }
      flipIFirmInvoiceModal();
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  // getting all the product details from the data base;
  const getProductData = async () => {
    try {
      //token
      let token = localStorage.getItem("x-api-key");
      let response = await axios.get("api/user/addProductVertTwo/getAll", {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": token,
          "x-api-plan": "SaaS",
        },
      });
      let result = response.data;
      console.log("product data in purchse entry", result);
      if (result) {
        console.log("getting product name ", result.data);
        setProductData(result.data);
        setViewedProducts(result.data);
      } else {
        setProductData([]);
      }
    } catch (error) {
      console.log(error.response.data.message);
    }

    // console.log(result.data);
    // console.log("product name", productData, result);
  };

  //setting selected firmname in the select box
  //setting firmid to the variable to send the selected firm id in the backend
  function handleChange(e) {
    // console.log(firmData);
    const enteredName = e.target.value;
    setFirm(enteredName);

    for (let i = 0; i < firmData.length; i++) {
      if (firmData[i].firmName === e.target.value) {
        setFirmId(firmData[i].id);
        setAddress(firmData[i].fullAdd);
        setEmail(firmData[i].email);
        setMobile(firmData[i].mobileNo);
        setGst(firmData[i].email);
        setDL1(firmData[i].dl1);
        setDL2(firmData[i].dl2);
        setFssai(firmData[i].fssai);
      }
    }
  }

  function handleOnClickModalFirmChange(event, e) {
    // console.log(firmData);
    // console.log(580, event, e);

    const enteredName = e;

    console.log(1331, e);
    setFirm(enteredName);

    for (let i = 0; i < firmData.length; i++) {
      if (firmData[i].firmName === e) {
        setFirmId(firmData[i].id);
        setAddress(firmData[i].fullAdd);
        setEmail(firmData[i].email);
        setMobile(firmData[i].mobileNo);
        setGst(firmData[i].email);
        setDL1(firmData[i].dl1);
        setDL2(firmData[i].dl2);
        setFssai(firmData[i].fssai);
      }
    }

    closeFirmModal();
    emptyFirmSearch();
  }

  function handleModalFirmChange(event, e) {
    // console.log(firmData);
    // console.log(580, event, e);
    if (event.keyCode === 13) {
      const enteredName = e;

      // console.log(e);
      setFirm(enteredName);

      for (let i = 0; i < firmData.length; i++) {
        if (firmData[i].firmName === e) {
          setFirmId(firmData[i].id);
          setAddress(firmData[i].fullAdd);
          setEmail(firmData[i].email);
          setMobile(firmData[i].mobileNo);
          setGst(firmData[i].email);
          setDL1(firmData[i].dl1);
          setDL2(firmData[i].dl2);
          setFssai(firmData[i].fssai);
        }
      }

      closeFirmModal();
      emptyFirmSearch();
    }
  }
  // =====================Search firm modal logic============================//
  // filteredList, firmData
  const [idArray, setIdArray] = useState([]);
  const [filter, setFilter] = useState(false);

  // code for filtering firm according to search bar;
  const [filteredList, setFilteredList] = new useState(firmData);
  const handleFirmChange = (event) => {
    setFilter(true);
    let updatedList = [...firmData];
    updatedList = updatedList.filter((item) => {
      // we can add one more field to search like invoice number;
      return (
        item.firmName
          .toLowerCase()
          .indexOf(event.target.value.toLowerCase()) !== -1
      );
    });
    console.log("updatedList", updatedList);
    setFilteredList(updatedList);
  };

  const emptyFirmSearch = () => {
    setIdArray([]);
    // setFirm_Data([]);
    // setSelectedFirm('')
  };
  // =======================Search Product Modal Logic====================================//

  // code for filtering firm according to search bar;
  // const [filteredProdList, setFilteredProdList] = new useState(productData);
  // const [filterProd, setFilterProd] = useState(false);
  // const filterProdListSearch = (event) => {
  //   setFilterProd(true);
  //   let updatedList = [...productData];
  //   updatedList = updatedList.filter((item) => {
  //     // we can add one more field to search like invoice number;
  //     return (
  //       item.productName
  //         .toLowerCase()
  //         .indexOf(event.target.value.toLowerCase()) !== -1 ||
  //       item.brand.toLowerCase().indexOf(event.target.value.toLowerCase()) !==
  //         -1
  //     );
  //   });
  //   setFilteredProdList(updatedList);
  // };

  // const emptyProductSearch = () => {
  //   // setProdIdArray([]);
  //   // setProduct_Data([]);
  // };

  const [viewedProducts, setViewedProducts] = useState(productData); // Initialize with original data
  const [prodIdArray, setProdIdArray] = useState([]);

  const filterProdListSearch = (event) => {
    const userInput = event.target.value.toLowerCase().trim();

    if (userInput === "") {
      setViewedProducts(productData); // Show original data when the search query is empty
    } else {
      const updatedList = productData.filter(
        (item) =>
          item.productName.toLowerCase().includes(userInput) ||
          item.brand.toLowerCase().includes(userInput)
      );
      setViewedProducts(updatedList); // Show filtered data
    }
  };

  // Function to reset filter and clear search query
  const resetFilter = () => {
    setViewedProducts(productData); // Reset to show original data
  };

  const emptyProductSearch = () => {
    setProdIdArray([]);
    // setProduct_Data([]);
  };

  // =======================Search Product Modal Logic=====================================//

  //Delete Array of Object
  function DeleteArrayOfObject(index) {
    ProductEntries.splice(index, 1);
    openModal2();
    AfterAddingAllValuesCalculationFunction();
  }

  function updateArrayofObject(p) {
    // console.log("aa gaya me to andar");
    // console.log(1393, p, onClickedDone, everyIndex);
    // if(onClickedDone != p && everyIndex != p) return
    if (everyIndex == p) {
      console.log("every index", everyIndex);
      console.log(p);
      if (qtyUpdateValue && qty2 == p) {
        ProductEntries[p].qty = qtyUpdateValue;
        console.log(qty);
      }
      if (unitPackUpdateValue && unitPack2 == p) {
        ProductEntries[p].unitPack = unitPackUpdateValue;
      }
      if (freeUpdateValue && freeUpdateValue.length > 0 && free2 == p) {
        ProductEntries[p].free = freeUpdateValue;
      }
      if (tradeRateUpdateValue && tradeRate2 == p) {
        console.log("tradeRate2", tradeRateUpdateValue, p);
        ProductEntries[p].tradeRate = tradeRateUpdateValue;
      }
      if (discPercentUpdateValue && discPercent2 == p) {
        ProductEntries[p].discPercent = discPercentUpdateValue;
      }

      if (goodChange) {
        ProductEntries[p].returnProduct = actualG;
      }
      if (meregister) {
        ProductEntries[p].purchaseType = registerAs;
      }
      if (generatedData) {
        ProductEntries[p].ProductName = generatedData;
      }
      FormulaTwo(p);
      // return
    }
    //
    console.log("update array of object ka index plus index");
    console.log(onClickedDone, p);
    if (onClickedDone == p) {
      console.log("onclick done", p);

      if (igstUpdateValue) {
        ProductEntries[p].igst = igstUpdateValue;
      }
      if (cgstUpdateValue) {
        ProductEntries[p].cgst = cgstUpdateValue;
      }
      if (sgstUpdateValue) {
        ProductEntries[p].sgst = sgstUpdateValue;
      }
      if (cessUpdateValue) {
        ProductEntries[p].cess = cessUpdateValue;
      }
      //
      if (batchNoUpdateValue) {
        ProductEntries[p].batchNo = batchNoUpdateValue;
      }
      if (expiryUpdateValue) {
        ProductEntries[p].expiry = expiryUpdateValue;
      }
      if (hsnSacUpdateValue) {
        ProductEntries[p].hsn_sac = hsnSacUpdateValue;
      }
      if (mrpUpdateValue) {
        ProductEntries[p].mrp = mrpUpdateValue;
      }
      FormulaTwo(p);
      return;
    } else {
      // alert('else return')
      return;
    }
  }

  // sending purchasing data to backend;
  // const sendPurchaseData = async () => {
  //   //token
  //   try {
  //     let token = localStorage.getItem("x-api-key");
  //     let response = await axios.post(
  //       "api/user/purchase/Purchase",
  //       {
  //         firmName: firm,
  //         firmId: firmId,
  //         region: region,
  //         type: type,
  //         date: date,
  //         invoiceNo: invoice,
  //         subTotal: subtotal,
  //         discAmt: disAmt,
  //         goodReturnAmt: goodReturn,
  //         totalTax: totalTax,
  //         cnRefNo: cnReference,
  //         igst: region == "Center" ? totalTax : null,
  //         sgst: region == "Local" ? totalTax / 2 : null,
  //         cgst: region == "Local" ? totalTax / 2 : null,
  //         grandTotal: cnVoucher ? grandTotal2 : grandTotal,
  //         cnVoucher: cnVoucher,
  //         productEntries: ProductEntries,
  //       },
  //       {
  //         // method: "post",
  //         headers: {
  //           "Content-Type": "application/json",
  //           "x-api-key": token,
  //           "x-api-plan": "SaaS",
  //         },
  //         // body: JSON.stringify({
  //         // }),
  //       }
  //     );

  //     let result = response.data;

  //     if (result.message === "Purchase Entry created successfully") {
  //       Swal.fire(
  //         "Genereted!",
  //         "Your Purchase Entry has been Created.",
  //         "success"
  //       );
  //       // navigate("/productlist");
  //     } else if (result.message === "Stocks updated successfully") {
  //       Swal.fire(
  //         "Genereted!",
  //         "Your Purchase Entry has been Created.",
  //         "success"
  //       );
  //       // navigate("/productlist");
  //     } else if (result.message === "Credit Note created successfully") {
  //       Swal.fire(
  //         "Genereted!",
  //         "Your Purchase Entry has been Created.",
  //         "success"
  //       );
  //       // navigate("/")
  //     } else {
  //       Swal.fire({
  //         icon: "error",
  //         title: "Oops...",
  //         text: result.message,
  //       });
  //     }
  //   } catch (error) {
  //     console.log(error.response.data.message);
  //   }
  // };

  const [showRecordsButton, setShowRecordsButton] = React.useState(false);

  const handleShowRecords = () => {
    setShowRecordsButton(true);
  };

  const handleHideRecords = () => {
    setShowRecordsButton(false);
  };
  // for updating producct list in purchase entry
  const [updateProductListName, setUpdateProductListName] = useState(false);

  // -------------------------- showing data in comments --------------------- //

  // const [generatedData, setGeneratedData] = React.useState("");

  // setting for output of product list
  //setting product id here
  const [SelectProductResultData, setSelectProductResultData] = useState("");

  const generateData = async (event, nameOfProduct) => {
    try {
      const newData = nameOfProduct;
      setGeneratedData(newData);
      for (let i = 0; i < productData.length; i++) {
        if (productData[i].productName === nameOfProduct) {
          //product id setting
          setProductId(productData[i].id);
          setProductName(productData[i].productName);
          setCategory(productData[i].category);
          setBrand(productData[i].brand);
          setDescription(productData[i].description);
          setUnitPacking(productData[i].unitPacking);
          setHsn_sac_Code(productData[i].hsn_sac_code);
        }
      }
      flipModal();

      // getting data when user select a particular product
      //if that product is already purchased then we are getting the previous details
      //token;

      let token = localStorage.getItem("x-api-key");
      let response = await axios.post(
        `api/user/purchase/firmListByProduct/${ProductId}`,
        {},
        {
          // method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": token,
            "x-api-plan": "SaaS",
          },
        }
      );
      let resultTable = response.data;
      if (resultTable) {
        setSelectProductResultData(resultTable.data);
      } else {
        setSelectProductResultData([]);
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };
  // On selecting product from product list;
  const popup = SelectProductResultData ? (
    SelectProductResultData.map(function (item) {
      return (
        <tr>
          <td>{item.date}</td>
          <td>{item.firmName}</td>
          <td>{item.invoiceNo}</td>
          <td>{item.qty}</td>
          <td>{item.free}</td>
          <td>{item.tradeRate}</td>
          <td>{item.discPercent}</td>
          <td>{item.batchNo}</td>
          <td>{item.expiry}</td>
          <td>{item.mrp}</td>
          <td>{item.igst}</td>
        </tr>
      );
    })
  ) : (
    <h6>No Data Found.</h6>
  );

  // On selecting firm from firm list we are showing previous invoice limit 10;

  // for firm invoice selection
  const [firmInvoiceSelect, setFirmInvoiceSelect] = useState(false);
  function flipIFirmInvoiceModal() {
    setFirmInvoiceSelect(true);
    setSelects(false);
  }

  const popupInvoice = selectedFirmInvoiceHistory ? (
    selectedFirmInvoiceHistory.map(function (item) {
      return (
        <tr>
          <td style={{minWidth:'8rem'}}>{item.date}</td>
          <td style={{minWidth:'8rem'}}>{item.firmName}</td>
          <td style={{minWidth:'8rem'}}>{item.invoiceNo}</td>
          <td style={{minWidth:'8rem'}}>{item.grandTotal}</td>
        </tr>
      );
    })
  ) : (
    <h2>No Data Found </h2>
  );

  function flipModal() {
    setSelects(true);
    setFirmInvoiceSelect(false);
  }

  // COMMENTS TABLE - Purchase Details;
  function CommentsTable(props) {
    return (
      <div className="total-empty-purchase">
        <div
          className="save-print-view-button-purchase"
          style={{ width: "auto", gap: "10px" }}
        >
          <button
            className="savebutton_purchase"
            onClick={sendPurchaseData}
            onKeyDown={handleEnter}
            ref={field23Ref}
            style={{ fontWeight: "bold" }}
          >
            Save
            <span> (S)</span>
          </button>
          <button
            className="printbutton_purchase"
            onKeyDown={(event) => {
              handleEnter(event);
              handlePShowPrintOptions(event);
            }}
            ref={field24Ref}
            style={{ fontWeight: "bold" }}
            onClick={(event) => {
              handleViewInvoice(true);
            }}
          >
            Print
            <span> (P)</span>
          </button>
          <button
            className="viewbutton_purchase"
            onKeyDown={(event) => {
              handleEnter(event);
              handleOpenInvoice(event);
              handleVOpenSalesInvoice(event);
            }}
            ref={field25Ref}
            onClick={(event) => {
              // handleOpenInvoice(event);
              // handleOpenSalesInvoice(event);
              // handleShowPrintOptions(event);
              handleViewInvoice(false);
            }}
            style={{ fontWeight: "bold" }}
          >
            View
            <span> (V)</span>
          </button>
        </div>
      </div>
    );
  }

  // COMMENTS TABLE - Sales
  // function CommentsTable(props) {
  //   return (
  //     <div className="total--empty">

  //       {/* <label className="product-result-label"></label>
  //       <p className="product-result"></p>
  //       <label className="product-result-label"></label>
  //       <p className="product-result"></p>
  //       <label className="product-result-label"></label>
  //       <p className="product-result"></p>
  //       <label className="product-result-label"></label>
  //       <p className="product-result"></p>
  //       <label className="product-result-label"></label>
  //       <p className="product-result"></p> */}

  //       <div className="save-print-view--button">
  //         <button
  //           className="save-button"
  //           onClick={sendSalesData}
  //           onKeyDown={handleEnter}
  //           ref={bSaveButtonRef}
  //         >
  //           Save (S)
  //         </button>
  //         <button
  //           className="print-button"
  //           onKeyDown={(event) => {
  //             handleEnter(event);
  //             handlePShowPrintOptions(event);
  //           }}
  //           ref={bPrintButtonRef}
  //           onClick={(event) => {
  //             handleShowPrintOptions(event);
  //           }}
  //         >
  //           Print (P)
  //         </button>
  //         <button
  //           className="view-button"
  //           onKeyDown={(event) => {
  //             handleEnter(event);
  //             handleOpenInvoice(event);
  //             handleVOpenSalesInvoice(event);
  //           }}
  //           ref={bViewButtonRef}
  //           onClick={(event) => {
  //             handleOpenInvoice(event);
  //             handleOpenSalesInvoice(event);
  //           }}
  //         >
  //           View (V)
  //         </button>
  //       </div>
  //     </div>
  //   );
  // }

  // useEffect(() => {
  //   const element = document.getElementById("input-box")
  //   const inputs = element.querySelectorAll("select");

  //   const handleKeyDown = (e) => {
  //     if (e.key === "Enter") {s
  //       e.preventDefault();
  //       const nextInput = e.target.nextElementSibling;
  //       if (nextInput) {
  //         nextInput.focus();
  //       }
  //     }
  //   };

  //   inputs.forEach((input) => {
  //     input.addEventListener("keydown", handleKeyDown);
  //   });

  //   return () => {
  //     inputs.forEach((input) => {
  //       input.removeEventListener("keydown", handleKeyDown);
  //     });
  //   };
  // }, []);

  // const ele = firm_Data ? (
  //   firm_Data.map(function (item, index) {
  //     return (
  //       <tr key={index}>
  //         <td  className="tdDiv SD">{index + 1}</td>
  //         <td className="tdDiv FD" >{item.firmName}</td>
  //         <td className="tdDiv AD">{item.state}</td>
  //         <td className="tdDiv AD">{item.state}</td>
  //       </tr>
  //     );
  //   })
  // ) : (
  //   <h1> "No Data Found "</h1>
  // );

  // for adding unit pack of previous product automatically.
  function unitpackfunction(unitpack) {
    console.log("getting unit pack", unitpack);
    // for (let i = 0; i < productData.length; i++) {
    // if (productData[i].productName == v.target.value) {
    setUnitPack(unitpack);
    // }
    // }
  }

  const [showInvoice, setShowInvoice] = useState(false);

  const handleOpenInvoice = (event) => {
    setShowInvoice(true);
  };

  const handleVOpenInvoice = (event) => {
    if (event.key == "V" || event.key == "v") {
      setShowInvoice(true);
    }
  };

  const handleCloseInvoice = (event) => {
    setShowInvoice(false);
  };

  //========================= Sale Invoice Logic==========================
  const [showSalesInvoice, setShowSalesInvoice] = useState(false);

  const handleOpenSalesInvoice = (event) => {
    setShowSalesInvoice(true);
  };

  const handleVOpenSalesInvoice = (event) => {
    if (event.key == "V" || event.key == "v") {
      setShowSalesInvoice(true);
    }
  };

  const handleCloseSalesInvoice = (event) => {
    setShowSalesInvoice(false);
  };
  // =========================Print Modal Code ===============================//
  const [showPrintOptions, setShowPrintOptions] = useState(false);
  const [showMainSalesInvoice, setshowMainSalesInvoice] = useState(false);
  const [showMainInvoiceReceipt, setshowMainInvoiceReceipt] = useState(false);
  const [showEstimateSalesInvoice, setshowEstimateSalesInvoice] =
    useState(false);
  const [showEstimateInvoiceReceipt, setshowEstimateInvoiceReceipt] =
    useState(false);

  // open functions
  const handleShowPrintOptions = () => {
    setShowPrintOptions(!showPrintOptions);
  };

  const handlePShowPrintOptions = (event) => {
    if (event.key == "p" || event.key == "P") {
      setShowPrintOptions(true);
    }
  };

  const handleMainSalesInvoice = () => {
    setshowMainSalesInvoice(!showMainSalesInvoice);
  };

  const handleMainInvoiceReceipt = () => {
    setshowMainSalesInvoice(!showMainInvoiceReceipt);
  };

  const handleEstimateSalesInvoice = () => {
    setshowMainSalesInvoice(!showEstimateSalesInvoice);
  };

  const handleEstimateInvoiceReceipt = () => {
    setshowMainSalesInvoice(!showEstimateInvoiceReceipt);
  };

  // y and n button states and functions

  const [checkedMainSalesValue, setcheckedMainSalesValue] = useState(false);
  const [checkedMainInvoiceValue, setcheckedMainInvoiceValue] = useState(false);
  const [checkedEstimateSalesValue, setcheckedEstimateSalesValue] =
    useState(false);
  const [checkedEstimateInvoiceValue, setcheckedEstimateInvoiceValue] =
    useState(false);

  const handleYNMainSales = (event) => {
    if (event.key == "y" || event.key == "Y") {
      setcheckedMainSalesValue(true);
    } else if (event.key == "n" || event.key == "N") {
      setcheckedMainSalesValue(false);
    }
  };

  const handleYNMainInvoice = (event) => {
    if (event.key == "y" || event.key == "Y") {
      setcheckedMainInvoiceValue(true);
    } else if (event.key == "n" || event.key == "N") {
      setcheckedMainInvoiceValue(false);
    }
  };

  const handleYNEstimateSales = (event) => {
    if (event.key == "y" || event.key == "Y") {
      setcheckedEstimateSalesValue(true);
    } else if (event.key == "n" || event.key == "N") {
      setcheckedEstimateSalesValue(false);
    }
  };

  const handleYNEstimateInvoice = (event) => {
    if (event.key == "y" || event.key == "Y") {
      setcheckedEstimateInvoiceValue(true);
    } else if (event.key == "n" || event.key == "N") {
      setcheckedEstimateInvoiceValue(false);
    }
  };

  const handleMainSales = (event) => {
    setcheckedMainSalesValue(!checkedMainSalesValue);
  };

  const handleMainInvoice = (event) => {
    setcheckedMainInvoiceValue(!checkedMainInvoiceValue);
  };

  const handleEstimateSales = (event) => {
    setcheckedEstimateSalesValue(!checkedEstimateSalesValue);
  };

  const handleEstimateInvoice = (event) => {
    setcheckedEstimateInvoiceValue(!checkedEstimateInvoiceValue);
  };

  // ======================== Escape Key Logic =====================//

  useEffect(() => {
    if (showFirmModal) {
      inputRef.current.focus();
    }
    function handleEscapeKey(event) {
      if (event.keyCode === 27) {
        if (showFirmModal) {
          closeFirmModal();
        }
        if (showProductModal) {
          closeProductModal();
        }

        switch (event.target) {
          case mSnRef.current:
            bSubTotalBoxRef.current.focus();
            break;
        }
        if (showInvoice) {
          handleCloseInvoice();
        }
        if (showSalesInvoice) {
          handleCloseSalesInvoice();
        }
        if (showPrintOptions) {
          handleShowPrintOptions();
        }
      }
    }

    window.addEventListener("keydown", handleEscapeKey);

    return () => {
      window.removeEventListener("keydown", handleEscapeKey);
    };
  }, [
    showFirmModal,
    showProductModal,
    showInvoice,
    showSalesInvoice,
    showPrintOptions,
  ]);

  // Print Logic;

  // I added this code;

  const handleViewInvoice = async (isPrint) => {
    // Your multiple contents to display in the new tab or window;

    const productRowsM = [];

    if (ProductEntries.length > 0) {
      ProductEntries.forEach((item, index) => {
        // If only "main" is true and sellType is "M", add to productRowsM array
        productRowsM.push(`
      <div>
      <tr key="${index}">
        <td className="tdDiv SD" style="border-right: 1px solid #ccc; padding: 3px;">${
          index + 1
        }</td>
        <td className="FD" style="border-right: 1px solid #ccc; padding: 3px;">${
          item.productName
        }</td>
        <td className="FD" style="border-right: 1px solid #ccc; padding: 3px;">${
          item.batchNo
        }</td>
        <td className="FD" style="border-right: 1px solid #ccc; padding: 3px;">${
          item.qty
        }</td>
        <td className="FD" style="border-right: 1px solid #ccc; padding: 3px;">${
          item.free
        }</td>
        <td className="FD" style="border-right: 1px solid #ccc; padding: 3px;">${
          item.tradeRate
        }</td>
        <td className="AD" style="border-right: 1px solid #ccc; padding: 3px;">${
          item.discPercent
        }</td>
        <td className="AD" style="border-right: 1px solid #ccc; padding: 3px;">${
          item.productTax
        }</td>
        <td className="FD" style="border-right: 1px solid #ccc; padding: 3px;">${
          item.netRate
        }</td>
        <td className="FD" style="border-right: 1px solid #ccc; padding: 3px;">${
          item.amount
        }</td>
        <td className="FD" style="border-right: 1px solid #ccc; padding: 3px;">${
          item.mrp
        }</td>
        <td className="FD" style="border-right: 1px solid #ccc; padding: 3px;">${
          item.expiry
        }</td>
      </tr>
      </div>
    `);
      });
    }

    // Seller and buyer both reciept;

    let content2 = `
        <body>
          <div id="print-section-2" style="max-width: 800px; margin: 0 auto; padding: 20px; box-sizing: border-box; font-family: Arial, sans-serif;">
            <div class="saleInvoice-container" style="margin-bottom: 1rem;">
              <div class="saleInvoice-head" style="margin-bottom: 1rem; font-size : 12px; ">
                <ul class="saleInvoice-headings" style="list-style-type: none; padding: 0; margin: 0; display: flex; justify-content: space-between; align-items: center;">
                  <li>Date: ${date}</li>
                  <li>Invoice No.: ${invoice}</li>
                  <li class="hide-for-E" style="margin-left: 10px; margin-right: 10px;">
                    <img src=${hegan} alt="hegan logo" style="height: 20px;"></img> hegan.in
                  </li>
                  <li class="hide-for-E" style="margin-left: 10px; margin-right: 10px;">Tax Invoice</li>
                  <li class="hide-for-E" style="margin-left: 10px; margin-right: 10px;">Original For Buyer</li>
                </ul>
              </div>
              <div style="background: white; width: 100%; page-break-inside: avoid;">
                <div class="saleInvoice-tables" style="display: flex;">
                  <table class="saleInvoice-details" style="width: 50%; height: 10px; border-collapse: collapse; font-size:12px">
                    <tbody>
                      <tr>
                        <th class="saleInvoice-tableHeading" colspan="2" style="background-color: #f0f0f0; text-align: center; border: 1px solid #ccc; padding: 8px;">Seller Details</th>
                      </tr>
                      <div>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">Firm Name-</td>
                        <td style="background: white; border: 1px solid #ccc;">${firm}</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">D.L(1)-</td>
                        <td style="background: white; border: 1px solid #ccc;">${DL1}</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">D.L(2)-</td>
                        <td style="background: white; border: 1px solid #ccc;">${DL2}</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">Phone No.-</td>
                        <td style="background: white; border: 1px solid #ccc;">${mobile}</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">Address-</td>
                        <td style="background: white; border: 1px solid #ccc;">${address}</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">State-</td>
                        <td style="background: white; border: 1px solid #ccc;">${state1}</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">GST-</td>
                        <td style="background: white; border: 1px solid #ccc;">${gst}</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">FSSAI-</td>
                        <td style="background: white; border: 1px solid #ccc;">${fssai}</td>
                      </tr>
                    </tbody>
                  </table>
                  <table class="saleInvoice-details" style="width: 50%; border-collapse: collapse; font-size:12px">
                    <tbody>
                      <tr>
                        <th class="saleInvoice-tableHeading" colspan="2" style="height: 22px; background-color: #f0f0f0; text-align: center; border: 1px solid #ccc; padding: 8px;">Buyer Details</th>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">Firm Name-</td>
                        <td style="background: white; border: 1px solid #ccc;">${
                          userDetails.firmName
                        }</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">D.L(1)-</td>
                        <td style="background: white; border: 1px solid #ccc;">${
                          userDetails.dl_no_20b
                        }</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">D.L(2)-</td>
                        <td style="background: white; border: 1px solid #ccc;">${
                          userDetails.dl_no_21b
                        }</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">Phone No.-</td>
                        <td style="background: white; border: 1px solid #ccc;">${
                          userDetails.mobile
                        }</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">Address-</td>
                        <td style="background: white; border: 1px solid #ccc;">${
                          userDetails.address
                        }</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">State-</td>
                        <td style="background: white; border: 1px solid #ccc;">${
                          userDetails.state
                        }</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">GST-</td>
                        <td style="background: white; border: 1px solid #ccc;">${
                          userDetails.gst_no
                        }</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">FSSAI-</td>
                        <td style="background: white; border: 1px solid #ccc;">${
                          userDetails.fssai_no
                        }</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">TYPE-</td>
                        <td style="background: white; border: 1px solid #ccc;">${type}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="saleInvoice-productDetails" style="background: white; page-break-inside: avoid; margin-top: 20px; font-size:12px">
                <table style="width: 100%; border-collapse: collapse;">
                <thead style="overflowX: auto; font-size:12px">
                <tr>
                <th style="text-align: center; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 8px;">S.No</th>
                <th style="text-align: center; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 8px;">Product</th>
                <th style="text-align: center; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 8px;">Batch</th>
                <th style="text-align: center; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 8px;">Qty</th>
                <th style="text-align: center; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 8px;">Free</th>
                <th style="text-align: center; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 8px;">T.rate</th>
                <th style="text-align: center; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 8px;">Disc%</th>
                <th style="text-align: center; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 8px;">Tax</th>
                <th style="text-align: center; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 8px;">N.Rate</th>
                <th style="text-align: center; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 8px;">Amt</th>
                <th style="text-align: center; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 8px;">MRP</th>
                <th style="text-align: center; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 8px;">EXP</th>
              </tr>
            </thead>
            <tbody style="font-size: 12px; text-align: center;">
              ${productRowsM.join("")}
            </tbody>
          </table>
        </div>
        <div class="saleInvoice-footer" style="margin-top: auto;  page-break-inside: avoid; border-top: 1px solid #ccc; page-break-inside: avoid;">
        </div>
        <div class="saleInvoice-footer" style="background: white; margin-top: 20px;">
          <div class="saleInvoice--costDetails" style="display: flex;font-size: smaller; justify-content: space-between; margin-top: 20px; padding: 10px; page-break-inside: avoid;">
            <div class="saleInvoice-costDetails">
              <div class="taxes-align" style="display: flex; line-height : 2rem; margin-bottom: 1rem;">
                <div class="saleInvoice-amountList" style="width: 80px;">
                  <label class="saleInvoice-amounts" style="font-weight: bold;">CGST</label>
                 <div>
                  <span style = "margin-left: 0rem; border: 2px solid #ccc;">${cgst}</span>
                 </div>
                </div>

                <div class="saleInvoice-amountList" style="width: 80px;" >
                  <label class="saleInvoice-amounts" style="font-weight: bold;">SGST</label>
                   <div>
                  <span style = "margin-left: 0rem; border: 2px solid #ccc;">${sgst}</span>
                   </div>
                </div>
                <div class="saleInvoice-amountList" style="width: 80px;">
                  <label class="saleInvoice-amounts" style="font-weight: bold;">IGST</label>
                   <div>
                  <span style = "margin-left: 0rem; border: 2px solid #ccc;">${igst}</span>
                   </div>
                </div>
                <div class="saleInvoice-amountList" style="width: 80px;" >
                  <label class="saleInvoice-amounts" style="font-weight: bold;">CESS</label>
                   <div>
                  <span style = "margin-left: 0rem; border: 2px solid #ccc;">${cess}</span>
                   </div>
                </div>
              </div>

            </div>

           <div class="saleInvoice-totalCost" style="line-height:2rem; page-break-inside: avoid;">
              <div class="saleInvoice-innerCostDiv">
                <label style = "font-weight:bold; margin-right: 10rem">Sub Total</label>
                <span style = "border: 2px solid #ccc;">${subtotal}</span>
              </div>
              <div class="saleInvoice-innerCostDiv">
                <label style = "font-weight:bold; margin-right: 10rem">Disc Amt.</label>
                <span style = "border: 2px solid #ccc;">${disAmt}</span>
              </div>
              <div class="saleInvoice-innerCostDiv">
                <label style = "font-weight:bold; margin-right: 10rem">Tax Amt.</label>
                <span style = "border: 2px solid #ccc; margin-left: 6px">${parseFloat(
                  totalTax
                )}</span>
              </div>
              <div class="saleInvoice-innerCostDiv">
                <label style = "font-weight:bold; margin-right: 10rem">G.R.</label>
                <span style = "border: 2px solid #ccc;margin-left: 33px">${goodReturn}</span>
              </div>
              <div class="saleInvoice-innerCostDiv">
                <label style = "font-weight:bold; margin-right: 10rem">CN Amt.</label>
                <span style = "border: 2px solid #ccc; margin-left: 9px;">${cnVoucher}</span>
              </div>
              <div class="saleInvoice-innerCostDiv">
                <label style = "font-weight:bold; margin-right: 10rem">Payable Amount</label>
                <span style = "border: 2px solid #ccc; position: relative;right: 44px">${grandTotal}</span>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  `;

    // Combine the contents into a single string;
    const combinedContent = `
    <html>
      <head>
        <title>Invoice Receipt</title>
      <style>
      /* Add your CSS styles here */
      body {
        font-family: Arial, sans-serif;
        margin: 20px;
      }
      .saleInvoice-container,
      .invoiceReceipt-container {
        border: 1px solid #ccc;
        padding: 10px;
        margin-bottom: 20px;
        page-break-inside: avoid;
      }
      .saleInvoice-headings {
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0;
      }
      /* Add more styles as needed for other elements */
    </style>
     </head>
      <body>
        ${content2}
      </body>
    </html>`;

    //===========I added this ========== //

    if (isPrint) {
      console.log("isPrint", isPrint);
      // Print functionality
      const printWindow = window.open("", "_blank");
      printWindow.document.open();
      printWindow.document.write(combinedContent);
      printWindow.document.close();
      printWindow.print();
      printWindow.close();
    } else {
      // View functionality
      const viewWindow = window.open("", "_blank");
      viewWindow.document.open();
      viewWindow.document.write(combinedContent);
      viewWindow.document.close();
    }
  };

  //for static input box border color change

  const [focusedInput, setFocusedInput] = useState(null);

  const handleFocus = (inputName) => {
    setFocusedInputUpdates("");
    setFocusedInput(inputName);
  };

  //to set focus on input box and make their border color change
  //for array of object in array
  const [focusedInputUpdates, setFocusedInputUpdates] = useState(null);

  const handleFocusForUpdate = (inputName) => {
    console.log(inputName);
    setFocusedInputUpdates(inputName);
    setFocusedInput("");
  };

  //new this one for update array of object front values
  //to add enter to focus on next index in array of object functionlities
  const inputRefs = useRef([]);

  const handleKeyDown = (event, currentIndex, refNumber) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();

      const currentRowRefs = inputRefs.current[currentIndex];
      console.log("currentRowRefs", currentRowRefs[refNumber].current);
      if (
        currentRowRefs &&
        currentRowRefs[refNumber] &&
        currentRowRefs[refNumber].current
      ) {
        const nextRef = currentRowRefs[refNumber + 1];
        if (nextRef) {
          nextRef.current.focus();
        }
      }
    }
  };

  const generateTableRowsData = () => {
    return openModal2 && ProductEntries ? (
      ProductEntries.map(function (item, index) {
        inputRefs.current[index] = Array.from({ length: 8 }, () =>
          React.createRef()
        );
        console.log(2418, index);
        return (
          <tr>
            <td className={(index + 1) % 2 === 1 ? "white" : "grey"}>
              <input
                value={index + 1}
                className="span-width"
                style={{ width: 60 }}
              />
              {/* <span className="span-width">{index + 1}</span> */}
            </td>
            <td>
              {/* sdfgh dvfghjkl;lkjhgfghjkl;kjhgfdsfghjkl;lkujyhtgrfertyuioilukyjthrgefwrtyuiouytrewertyuiopiuytrertyuioiuytrertyuioiuytrertyuiouytr*/}
              <input
                value={item.productName}
                onClick={(event) => {
                  setUpdateProductListName(true);
                  setUpdateIndex(index);
                  openProductModal(event)
                  
                }}
                ref={inputRefs.current[index][0]}
                onKeyDown={(e) => handleKeyDown(e, index, 0)}
                // style={{ width: "303px" }}

                onFocus={() => handleFocusForUpdate(`productName${index}`)}
                style={{
                  width: "303px",
                  border:
                    focusedInputUpdates === `productName${index}`
                      ? "2px solid #015998"
                      : "1px solid #ccc",
                }}
              />
            </td>

            {/* <select
            onChange={generateData}
            className={
              updateProductListName && updateIndex === index
                ? "ProductName"
                : "none"
            }
          >
            Product Name
            <br />
            {productData
              ? productData.map((item, index) => (
                  <option
                    placeholder="First Name"
                    onChange={(e) =>
                      setGeneratedData(e.target.value)
                    }
                  >
                    {item.productName}
                  </option>
                ))
              : "no data"}
          </select> */}

            <td>
              <td>
                <input
                  value={
                    // meregister && updateIndex === index
                    //   ? registerAs
                    //   :
                    item.purchaseType
                  }
                  // onChange={(e) => {
                  //   setMeregister("good");
                  //   setregisterAs("M");
                  //   setUpdateIndex(index);
                  // }}
                  ref={inputRefs.current[index][1]}
                  onKeyDown={(e) => handleKeyDown(e, index, 1)}
                  // style={{ position: "relative",width:36,marginRight:1}}

                  onFocus={() => handleFocusForUpdate(`registerAs${index}`)}
                  style={{
                    position: "relative",
                    width: 36,
                    marginRight: 1,
                    border:
                      focusedInputUpdates === `registerAs${index}`
                        ? "2px solid #015998"
                        : "1px solid #ccc",
                  }}
                />
              </td>
              <td>
                <input
                  className="inputbox-goodsreturn"
                  value={
                    goodChange && updateIndex === index
                      ? actualG
                      : item.returnProduct
                  }
                  onChange={(e) => {
                    setgoodChange("good");
                    setActualG(e.target.value);
                    setUpdateIndex(index);
                    setEveryIndex(index);
                  }}
                  ref={inputRefs.current[index][2]}
                  onKeyDown={(e) => handleKeyDown(e, index, 2)}
                  // style={{ position: "relative",width:36 }}

                  onFocus={() =>
                    handleFocusForUpdate(`actualGUpdateValue${index}`)
                  }
                  style={{
                    position: "relative",
                    width: 36,
                    border:
                      focusedInputUpdates === `actualGUpdateValue${index}`
                        ? "2px solid #015998"
                        : "1px solid #ccc",
                  }}
                />
              </td>
            </td>
            <td>
              <input
                value={
                  unitPackUpdateValue &&
                  unitPack2 == index &&
                  updateState == false
                    ? unitPackUpdateValue
                    : item.unitPack
                }
                onChange={(e) => {
                  setUnitPackUpdateValue(e.target.value);
                  setUpdateIndex(index);
                  setUnitPack2(index);
                  setEveryIndex(index);
                  setUpdateState(false);
                }}
                ref={inputRefs.current[index][3]}
                onKeyDown={(e) => handleKeyDown(e, index, 3)}
                onFocus={() =>
                  handleFocusForUpdate(`unitPackUpdateValue${index}`)
                }
                style={{
                  border:
                    focusedInputUpdates === `unitPackUpdateValue${index}`
                      ? "2px solid #015998"
                      : "1px solid #ccc",
                }}

                //  onClick={unitPack2.push(index)}
              />
            </td>
            <td>
              <input
                //this is line 816
                value={
                  qtyUpdateValue && qty2 === index && updateState == false
                    ? qtyUpdateValue
                    : item.qty
                }
                onChange={(e) => {
                  setQtyUpdateValue(e.target.value);
                  setqty2(index);
                  setUpdateIndex(index);
                  setEveryIndex(index);
                  setUpdateState(false);
                }}
                ref={inputRefs.current[index][4]}
                onKeyDown={(e) => handleKeyDown(e, index, 4)}
                onFocus={() => handleFocusForUpdate(`qtyUpdateValue${index}`)}
                style={{
                  border:
                    focusedInputUpdates === `qtyUpdateValue${index}`
                      ? "2px solid #015998"
                      : "1px solid #ccc",
                }}
              ></input>
            </td>
            <td>
              <input
                value={
                  freeUpdateValue && free2 === index && updateState == false
                    ? freeUpdateValue
                    : item.free
                }
                onChange={(e) => {
                  setFreeUpdateValue(e.target.value);
                  setFree2(index);
                  setUpdateIndex(index);
                  setEveryIndex(index);
                  setUpdateState(false);
                }}
                ref={inputRefs.current[index][5]}
                onKeyDown={(e) => handleKeyDown(e, index, 5)}
                onFocus={() => handleFocusForUpdate(`freeUpdateValue${index}`)}
                style={{
                  border:
                    focusedInputUpdates === `freeUpdateValue${index}`
                      ? "2px solid #015998"
                      : "1px solid #ccc",
                }}
              />
            </td>
            <td>
              <input
                value={
                  tradeRateUpdateValue &&
                  tradeRate2 === index &&
                  updateState == false
                    ? tradeRateUpdateValue
                    : item.tradeRate
                }
                onChange={(e) => {
                  setTradeRateUpdateValue(e.target.value);
                  setTradeRate2(index);
                  setUpdateIndex(index);
                  setEveryIndex(index);
                  setUpdateState(false);
                }}
                ref={inputRefs.current[index][6]}
                // onKeyDown={(e) => }

                onKeyDown={(event) => {
                  if (event.key === "Enter" || event.key === "Tab") {
                    openModalUpdateValue(index);
                    handleKeyDown(event, index, 6);
                  }
                }}
                onFocus={() =>
                  handleFocusForUpdate(`tradeRateUpdateValue${index}`)
                }
                style={{
                  border:
                    focusedInputUpdates === `tradeRateUpdateValue${index}`
                      ? "2px solid #015998"
                      : "1px solid #ccc",
                }}
              />
            </td>
            <td>
              <input
                value={
                  discPercentUpdateValue &&
                  discPercent2 === index &&
                  updateState == false
                    ? discPercentUpdateValue
                    : item.discPercent
                }
                onChange={(e) => {
                  setDiscountUpdateValue(e.target.value);
                  setDiscount2(index);
                  setUpdateIndex(index);
                  setEveryIndex(index);
                  setUpdateState(false);
                }}
                onClick={() => {
                  console.log(2586, index, discPercent2);
                  openModalUpdateValue(index);
                }}
                ref={inputRefs.current[index][7]}
                onKeyDown={(e) => handleKeyDown(e, index, 7)}
                onFocus={() =>
                  handleFocusForUpdate(`discPercentUpdateValue${index}`)
                }
                style={{
                  border:
                    focusedInputUpdates === `discPercentUpdateValue${index}`
                      ? "2px solid #015998"
                      : "1px solid #ccc",
                }}
              />
            </td>
            <td>
              <input value={item.amount} />
            </td>
            <td>
              <div className="">
                <button
                  // id="arrayofobject-update"
                  onClick={(event) => {
                    // alert(index);
                    updateArrayofObject(index);
                  }}
                  style={{
                    marginTop: "2px",
                    marginLeft: "2.5px",
                    borderRadius: "3px",
                    backgroundColor: "darkgreen",
                    color: "white",
                    width: "63px",
                    height: "30px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Update
                </button>
                <button
                  onClick={() => {
                    DeleteArrayOfObject(index);
                  }}
                  style={{
                    marginTop: "0px",
                    borderRadius: "3px",
                    backgroundColor: "red",
                    color: "white",
                    width: "63px",
                    height: "30px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Delete
                </button>
              </div>
            </td>
          </tr>
        );
      })
    ) : (
      <h3>no Product selected</h3>
    );
  };

  return (
    <Layout heading_1="Purchase Update" heading_2="Purchase Entry Update">
      <div className="main-div-purchase---entry">
        {/* <Header /> firm list pop up select code */}
        {showFirmModal && (
          <>
            <div class="overlay">
              <div id="firmContainer-sales">
                <div className="topSection">
                  <h3 className="productText">Firm List</h3>

                  <div>
                    <input
                      className="searchBar2Sales"
                      type="text"
                      placeholder="Search by Firm Name"
                      onChange={(event) => {
                        handleFirmChange(event);
                      }}
                      defaultValue=""
                      ref={inputRef}
                      autoFocus={true}
                    />
                  </div>

                  <div className="search-closeDivSales">
                    <div>
                      <AiOutlineClose
                        className="iconsDivSales"
                        type="button"
                        color="whiteSmoke"
                        size={23}
                        onClick={(event) => {
                          closeFirmModal();
                          emptyFirmSearch();
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className="table"
                  // style={{backgroundColor:'#015998'}}
                >
                  <div className="table-row header">
                    <div className="table-cell" style={{ maxWidth: "50px" }}>
                      S.No
                    </div>
                    <div className="table-cell">Firm Name</div>
                    <div className="table-cell">Address</div>
                    <div className="table-cell">Action</div>
                  </div>
                  <div className="table-body">
                    {/* Table Body */}
                    {filter
                      ? filteredList.map((item, index) => (
                          <div className="table-row" key={index}>
                            <div
                              className="table-cell"
                              style={{ maxWidth: "50px" }}
                            >
                              {index + 1}
                            </div>
                            <div className="table-cell">{item.firmName}</div>
                            <div className="table-cell">{item.fullAdd}</div>

                            <div className="table-cell">
                              <button
                                onClick={(event) => {
                                  handleOnClickModalFirmChange(
                                    event,
                                    item.firmName
                                  );
                                  closeFirmModal();
                                  emptyFirmSearch();
                                  handleSelectedFirm(item.firmName);
                                  getInvoiceHistory(item.id);
                                }}
                                onKeyDown={(event) => {
                                  handleModalFirmChange(event, item.firmName);
                                  handleSelectedFirm(item.firmName);
                                }}
                                style={{
                                  background: "#015998",
                                  color: "whitesmoke",
                                  borderRadius: "3px",
                                }}
                              >
                                Select Firm
                              </button>
                            </div>
                          </div>
                        ))
                      : firmData &&
                        firmData.map((item, index) => (
                          // <div className="tableRow" key={index}>
                          <div className="table-row" key={index}>
                            <div
                              className="table-cell"
                              style={{ maxWidth: "50px" }}
                            >
                              {index + 1}
                            </div>
                            <div className="table-cell">{item.firmName}</div>
                            <div className="table-cell">{item.fullAdd}</div>
                            <div className="table-cell">
                              <button
                                onClick={(event) => {
                                  handleOnClickModalFirmChange(
                                    event,
                                    item.firmName
                                  );
                                  closeFirmModal();
                                  emptyFirmSearch();
                                  handleSelectedFirm(item.firmName);
                                  getInvoiceHistory(item.id);
                                }}
                                onKeyDown={(event) => {
                                  handleModalFirmChange(event, item.firmName);
                                  handleSelectedFirm(item.firmName);
                                }}
                                style={{
                                  background: "#015998",
                                  color: "whitesmoke",
                                  borderRadius: "3px",
                                }}
                              >
                                Select Firm
                              </button>
                            </div>
                          </div>
                        ))}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {showProductModal && (
          <div class="overlay">
            <div id="firmContainer-sales">
              <div className="belowNavbar">
                <div className="firmListHeadingDiv">
                  <h3 className="productText">Product List</h3>
                </div>

                <div className="search-closeDiv">
                  <div>
                    <input
                      className="searchBar2"
                      type="text"
                      placeholder="Search by Product Name"
                      onChange={(event) => {
                        filterProdListSearch(event);
                      }}
                      defaultValue=""
                      ref={inputRef}
                      autoFocus={true}
                    />
                  </div>
                  <div>
                    <AiOutlineClose
                      className="iconsDiv"
                      type="button"
                      color="whiteSmoke"
                      size={23}
                      onClick={(event) => {
                        closeProductModal();
                        resetFilter();
                        emptyProductSearch();
                      }}
                    />
                  </div>
                </div>
              </div>

              {/* <div className="productTable"> */}

              <div
                className="table-Third-pop-up-meg-table"
                style={{
                  // width:'32rem',
                  overflow: "auto",
                }}
              >
                {/* head  */}
                <div className="Table-header-content">
                  <div
                    //  className="table-cell-for-third-table"
                    id="third-table-header-sno"
                  >
                    S.No
                  </div>
                  <div
                    className="table-cell-for-third-table"
                    id="third-table-header"
                  >
                    Product Name
                  </div>
                  <div
                    className="table-cell-for-third-table"
                    id="third-table-header"
                  >
                    Brand
                  </div>
                  <div
                    className="table-cell-for-third-table"
                    id="third-table-header"
                  >
                    Category
                  </div>
                  <div
                    className="table-cell-for-third-table"
                    id="third-table-header"
                  >
                    HSN/SAC Code
                  </div>
                  <div
                    className="table-cell-for-third-table"
                    id="third-table-header"
                  >
                    Action
                  </div>
                </div>

                <div
                //  className="productsellTableBody"
                >
                  {viewedProducts &&
                    viewedProducts.length > 0 &&
                    viewedProducts.map(function (item, index) {
                      return (
                        // <div className="productsellTableRow" key={index}>
                        <div className="table-row" key={index}>
                          <div
                            id="third-table-header-sno-1"
                            style={{ maxWidth: "5rem" }}
                          >
                            {index + 1}
                          </div>
                          <div className="table-cell-for-third-table">
                            {item.productName}
                          </div>
                          <div className="table-cell-for-third-table">
                            {item.brand}
                          </div>
                          <div className="table-cell-for-third-table">
                            {item.category}
                          </div>
                          <div className="table-cell-for-third-table">
                            {item.hsn_sac_code}
                          </div>
                          {/* <div className="table-cell-for-third-table"> */}
                          <button
                            onClick={(e) => {
                              // handleModalProductChange(item.productName);
                              closeProductModal();
                              emptyProductSearch();
                              generateData(e, item.productName);
                              handleSelectedProduct(item.productName);
                              unitpackfunction(item.unitPacking);

                              if(updateIndex || updateIndex === 0){
                                ProductEntries[updateIndex].productName = item.productName;
                                ProductEntries[updateIndex].productVerTwoId = item.id;
                                }else{
                                  handleSelectedProduct(item.productName);
                                }

                            }}
                            onKeyDown={(e) => {
                              // handleModalProductChange(event, item.productName);
                              generateData(e, item.productName);
                              handleSelectedProduct(item.productName);
                            }}
                            style={{
                              background: "#015998",
                              color: "whitesmoke",
                              borderRadius: "3px",
                            }}
                          >
                            Select Product
                          </button>
                          {/* </div> */}
                        </div>
                      );
                    })}
                </div>
              </div>

              {/* </div> */}
            </div>
          </div>
        )}
        {/* <div className="div-header-purchase" style={{ height: "35px" }}>
        <h4>Purchase Entry</h4>
      </div> */}

        {showPrintOptions && (
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: "#015998",
              },
            }}
          >
            <div className="printInvoiceContainer">
              <div className=" printInnerBoxDiv" style={{ height: "53px" }}>
                <div className="printHeadingDiv">
                  <h6>Main Sales Invoice</h6>
                </div>
                <div className="printIconDiv">
                  <Switch
                    onChange={handleMainSalesInvoice}
                    checkedChildren="Y"
                    unCheckedChildren="N"
                    ref={mainSalesInvoiceToggleRef}
                    autoFocus
                    onKeyDown={(event) => {
                      handleEnter(event);
                      handleYNMainSales(event);
                    }}
                    onClick={(event) => {
                      handleMainSales(event);
                    }}
                    checked={checkedMainSalesValue}
                  />
                </div>
              </div>

              <div className=" printInnerBoxDiv" style={{ height: "53px" }}>
                <div className=" printHeadingDiv">
                  <h6>Estimate Sales Invoice</h6>
                </div>
                <div className="printIconDiv">
                  <Switch
                    checkedChildren="Y"
                    unCheckedChildren="N"
                    ref={estimateSalesInvoiceToggleRef}
                    onKeyDown={(event) => {
                      handleEnter(event);
                      handleYNEstimateSales(event);
                    }}
                    onClick={(event) => {
                      handleEstimateSales(event);
                    }}
                    checked={checkedEstimateSalesValue}
                  />
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  style={{
                    background: "#015998",
                    color: "whitesmoke",
                    borderRadius: "5px",
                  }}
                  onClick={(event) => {
                    handleShowPrintOptions(event);
                  }}
                  onKeyDown={(event) => {
                    handleEnter(event);
                  }}
                  ref={confirmChoicesRef}
                >
                  Confirm Choices
                </button>
              </div>
            </div>
          </ConfigProvider>
        )}
        {/* end here */}

        <div className="first-half-purchase">
          <div className="top-two-purchase">
            <form className="input-box-purchase">
              <div
                className="input-box-name-purchase firmNameDiv"
                onClick={openFirmModal}
              >
                <labeL className="FirmName-purchase"> Firm Name</labeL>
                <input
                  htmlFor="firmName"
                  onChange={handleChange}
                  className="FirmName-purchase"
                  placeholder="Select Firm Name klkl"
                  // style={{
                  //   width: "25rem",
                  //   boxShadow: "0px 5px 5px 0px rgba(0, 0, 0, 0.2)",
                  // }}

                  onKeyDown={(event) => {
                    handleEnter(event);
                    closeFirmModal();
                  }}
                  // onFocus={openFirmModal}
                  ref={field1Ref}
                  value={selectedFirm}
                  onFocus={(event) => {
                    handleFocus("firmName");
                    openFirmModal(event);
                  }}
                  style={{
                    width: "25rem",
                    boxShadow: "0px 5px 5px 0px rgba(0, 0, 0, 0.2)",
                    border:
                      focusedInput === "firmName"
                        ? "2px solid #015998"
                        : "1px solid #ccc",
                  }}

                  // onBlur={closeFirmModal}
                >
                  {/* Firm Name */}
                  {/* {firmData
                  ? firmData.map((item, index) => (
                      <option type="button" placeholder="First Name">
                        {item.firmName}
                      </option>
                    ))
                  : "no entries"} */}
                </input>
              </div>

              <div className="input-box-date-purchase">
                <label htmlFor="date" style={{ color: "black" }}>
                  {" "}
                  Date
                </label>

                <input
                  id="date"
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  onKeyDown={handleEnter}
                  ref={dateRef}
                  // style={{ height: "30px", width: "97%" }}
                  onFocus={(event) => {
                    handleFocus("Date");
                  }}
                  style={{
                    height: "30px",
                    width: "97%",
                    border:
                      focusedInput === "Date"
                        ? "2px solid #015998"
                        : "1px solid #ccc",
                  }}
                />
              </div>

              <div className="input-box-region-purchase regionDiv">
                <labeL className="FirmName-purchase"> Region</labeL>
                <select
                  onChange={(e) => {
                    setRegion(e.target.value);
                    chekingdata(e.target.value);
                  }}
                  className="FirmName-purchase"
                  // placeholder="select something"
                  // style={{ width: "8rem" }}
                  onKeyDown={handleRegionEnter}
                  ref={field3Ref}
                  onFocus={(event) => {
                    handleFocus("Region");
                  }}
                  style={{
                    width: "8rem",
                    border:
                      focusedInput === "Region"
                        ? "2px solid #015998"
                        : "1px solid #ccc",
                  }}
                >
                  {/* type */}
                  {regionSelect
                    ? regionSelect.map((item, index) => (
                        <option placeholder="select something">{item}</option>
                      ))
                    : "no entries"}
                </select>
              </div>

              <div className="input-box-name-purchase regionDiv">
                <labeL className="FirmName-purchase"> Type</labeL>
                <select
                  onChange={(e) => {
                    if (e.target.value == "Credit Note") {
                      setType("CN");
                      settingInvoiceAndCN("CN");
                    } else {
                      setType(e.target.value);
                      settingInvoiceAndCN(e.target.value);
                    }
                  }}
                  className="FirmName-purchase"
                  // placeholder="select something"
                  // style={{ width: "8rem" }}
                  onKeyDown={handleIvCnEnter}
                  ref={typeRef}
                  onFocus={(event) => {
                    handleFocus("Type");
                  }}
                  style={{
                    width: "8rem",
                    border:
                      focusedInput === "Type"
                        ? "2px solid #015998"
                        : "1px solid #ccc",
                  }}
                >
                  {/* type */}
                  {typeData
                    ? typeData.map((item, index) => (
                        <option placeholder="select something">{item}</option>
                      ))
                    : "no data"}
                </select>
              </div>

              {invoiceSelect && (
                <div className="input-box-invoice-purchase regionDiv">
                  <label htmlFor="invoiceNo" style={{ color: "black" }}>
                    Invoice No.
                  </label>

                  <input
                    id="invoiceNo"
                    type="name"
                    name="invoice"
                    value={invoice}
                    onChange={(e) => setInvoice(e.target.value)}
                    onKeyDown={handleEnter}
                    // onFocus={handleShowRecords}
                    onBlur={handleHideRecords}
                    ref={InvoiceNoRef}
                    // style={{ marginTop: "9px", height: "28px" }}

                    onFocus={(event) => {
                      handleFocus("Invoice No.");
                    }}
                    style={{
                      marginTop: "9px",
                      height: "28px",
                      border:
                        focusedInput === "Invoice No."
                          ? "2px solid #015998"
                          : "1px solid #ccc",
                    }}
                  />
                </div>
              )}

              {cnRefSelect && (
                <div className="cnRef-box-invoice-purchase ">
                  <label htmlFor="cnRefNo" style={{ color: "black" }}>
                    Credit Note Ref No.{" "}
                  </label>

                  <input
                    id="cnrefNo"
                    type="name"
                    name="cnReference"
                    value={cnReference}
                    onChange={(e) => setCnReference(e.target.value)}
                    onKeyDown={handleEnter}
                    ref={field27Ref}
                    style={{ marginTop: "9px", height: "26px" }}
                  />
                </div>
              )}
            </form>

            {/* here product list entries getting product and dialing entry for products */}

            <div className="quantity-box-purchase">
              <div
                className="product"
                style={{ overflow: "auto", height: "100%" }}
              >
                <div
                // className="quantity-box-top"
                >
                  <table id="customers-purchase-entry">
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th onClick={openProductModal}>Product Name</th>
                        <th className="innerMEG">
                          <th>
                            <th className="M">M</th>
                          </th>
                          {/* <th>
                          <th className="E">E</th>
                        </th> */}
                          <th className="G">G</th>
                        </th>

                        <th>Unit Pack</th>
                        <th>Qty</th>
                        <th>Free</th>
                        <th>Trade Rate</th>
                        <th>Disc%</th>
                        <th>Amt</th>
                        <th>Act</th>
                      </tr>
                      <tr>{selects}</tr>

                      <tr>
                        <td>
                          <input
                            type="text"
                            value={"0"}
                            ref={field6Ref}
                            onKeyDown={handleEnter}
                            // style={{width:20}}
                          />
                        </td>
                        <td>
                          <input
                            onChange={(e) => {
                              generateData(e);
                              // unitpackfunction(e);
                            }}
                            className="ProductName-entry"
                            onClick={openProductModal}
                            // onFocus={openProductModal}
                            onKeyDown={handleEnter}
                            value={selectedProduct}
                            // style={{ width: "19rem" }}
                            ref={mProductNameRef}
                            onFocus={(event) => {
                              handleFocus("productName");
                              openProductModal(event);
                            }}
                            style={{
                              width: "19rem",
                              border:
                                focusedInput === "productName"
                                  ? "2px solid #015998"
                                  : "1px solid #ccc",
                            }}
                          />
                          {/* Product Name */}
                          <br />
                          {/* {productData
                          ? productData.map((item, index) => (
                              <option
                                placeholder="First Name"
                                onChange={(e) =>
                                  setGeneratedData(e.target.value)
                                }
                              >
                                {item.productName}
                              </option>
                            ))
                          : "no data"} */}
                          {/* </input> */}
                        </td>
                        <td className="checkbox-td">
                          <td
                            onClick={() => {
                              setregisterAs("M");
                            }}
                          >
                            <input
                              className="checkbox-1"
                              type="checkbox"
                              value={registerAs}
                              checked={isMChecked}
                              // onClick={(event) => {
                              //   handleMclick(event);
                              //   setregisterAs("M");
                              // }}
                              // onKeyDown={(event) => {
                              //   handleMKeyDown(event);
                              //   handleEnter(event);
                              //   if (event.key == "m" || event.key == "M") {
                              //     setregisterAs("M");
                              //   }
                              // }}
                              ref={mMRef}
                              onFocus={() => handleFocus("mchecked")}
                              style={{
                                border:
                                  focusedInput === "mchecked"
                                    ? "2px solid #015998"
                                    : "1px solid #ccc",
                              }}

                              // style={{ outline: "none" }}
                              // checked={registerAs === "M"}
                            />
                          </td>

                          {/* <td
                          onClick={() => {
                            setregisterAs("E");
                          }}
                        >
                          <input
                            className="checkbox-2"
                            type="checkbox"
                            value={registerAs}
                            checked={isEChecked}
                            onClick={(event) => {
                              handleEclick(event);
                              setregisterAs("E");
                              setEveryIndex('khali he')
                            }}
                            onKeyDown={(event) => {
                              handleEKeyDown(event);
                              handleEnter(event);
                              if (event.key == "e" || event.key == "E") {
                                setregisterAs("E");
                                setEveryIndex('khali he')
                              }
                            }}
                            ref={field9Ref}
                            // style={{ outline: "none" }}

                            // value={registerAs}
                            // checked={registerAs === "E"}
                          />
                        </td> */}
                          <td>
                            <input
                              className="checkbox-3"
                              type="checkbox"
                              disabled={cnRefSelect ? true : false}
                              value={registerAs}
                              checked={isGChecked}
                              onClick={(event) => {
                                handleGclick(event);
                                handleChangeG(event);
                                setEveryIndex("khali he");
                              }}
                              onKeyDown={(event) => {
                                handleGKeyDown(event);
                                handleEnter(event);
                                handleChangeG(event);
                                setEveryIndex("khali he");
                              }}
                              ref={mGRef}
                              // style={{ outline: "none" }}
                              onFocus={() => handleFocus("gregister")}
                              style={{
                                border:
                                  focusedInput === "gregister"
                                    ? "2px solid #015998"
                                    : "1px solid #ccc",
                              }}
                            />
                          </td>
                        </td>
                        <td>
                          <input
                            type="text"
                            value={
                              // unitPack && updateState == false ? "0" : unitPack
                              unitPack
                            }
                            onChange={(e) => {
                              setUnitPack(e.target.value);
                              // setUpdateState(true);
                              // setEveryIndex('khali he')
                            }}
                            onKeyDown={handleEnter}
                            ref={field11Ref}
                            onFocus={() => handleFocus("unitpack")}
                            style={{
                              border:
                                focusedInput === "unitpack"
                                  ? "2px solid #015998"
                                  : "1px solid #ccc",
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={qty}
                            onChange={(e) => {
                              setqty(e.target.value);
                              // setUpdateState(true);
                              // setEveryIndex('khali he')
                            }}
                            onKeyDown={handleEnter}
                            ref={mQuantityRef}
                            onFocus={() => handleFocus("quantity")}
                            style={{
                              border:
                                focusedInput === "quantity"
                                  ? "2px solid #015998"
                                  : "1px solid #ccc",
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={
                              free
                              //  && updateState == false ? "0" : free
                            }
                            onChange={(e) => {
                              setFree(e.target.value);
                              // setUpdateState(true);
                              // setEveryIndex('khali he')
                            }}
                            onKeyDown={handleEnter}
                            ref={field13Ref}
                            onFocus={() => handleFocus("free")}
                            style={{
                              border:
                                focusedInput === "free"
                                  ? "2px solid #015998"
                                  : "1px solid #ccc",
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={
                              tradeRate
                              // && updateState == false ? "0" : tradeRate
                            }
                            onChange={(e) => {
                              setTradeRate(e.target.value);
                              // setUpdateState(true);
                              // setEveryIndex('khali he')
                            }}
                            onKeyDown={(event) => {
                              if(event.key === 'Enter' && mTradeRateRef.current === document.activeElement || event.key === 'Tab' && mTradeRateRef.current === document.activeElement)
                              if (validateFields(event)) {
                              handleEnter(event);
                              openModalWithEnter(event);
                              }else{
                                alert('fill mandatory fields');
                              }
                            }}
                            ref={mTradeRateRef}
                            onFocus={() => handleFocus("tradeRate")}
                            style={{
                              border:
                                focusedInput === "tradeRate"
                                  ? "2px solid #015998"
                                  : "1px solid #ccc",
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={
                              discPercent
                              // && updateState == false
                              //   ? "0"
                              //   : discPercent
                            }
                            onChange={(e) => {
                              setDiscount(e.target.value);
                              // setUpdateState(true);
                              // setEveryIndex('khali he')
                              // setDiscount2('khali he ')
                            }}
                            onClick={() => openModal()}
                            onKeyDown={(event) => {
                              handleEnter(event);
                            }}
                            ref={field15Ref}
                            onFocus={() => handleFocus("discount")}
                            style={{
                              border:
                                focusedInput === "discount"
                                  ? "2px solid #015998"
                                  : "1px solid #ccc",
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={amount}
                            onKeyDown={handleEnter}
                            ref={field16Ref}
                          />
                        </td>
                        <td
                          style={{
                            color: "white",
                            width: "4.7rem",
                          }}
                        >
                          <td
                            style={{
                              backgroundColor: "#015998",
                              color: "white",
                              borderRadius: "5px",
                              width: "8rem",
                              height: "1rem",
                            }}
                          >
                            Action
                          </td>
                        </td>
                      </tr>
                    </thead>

                    <tbody
                    // style={{marginLeft:18}}
                    >
                      {ProductEntries.length
                        ? generateTableRowsData()
                        : "no data found"}
                    </tbody>
                  </table>
                </div>
              </div>

              {/* batchNo number and all the other information which is used by the user while purchasing the product */}

              <div className="quantity-box-bottom-purchase">
                {showModal && (
                  // <>
                  <div className="modal-background">
                    <div className="qty-comments-purchase-entry">
                      <button
                        id="close-button"
                        onClick={() => setShowModal(false)}
                        style={{
                          height: ".5rem",
                          width: ".5rem",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "400px",
                          marginTop: -10,
                        }}
                      >
                        X
                      </button>
                      <div className="box">
                        <div className="label-for-purchase-second">
                          <div
                            className="BatchNo"
                            style={{ marginLeft: "0.8rem" }}
                          >
                            <label
                              htmlFor="batchNo"
                              style={{ fontWeight: "bold" }}
                            >
                              Batch No.
                            </label>
                            <input
                              id="BatchNo"
                              value={batchNo}
                              type="text"
                              onChange={(e) => {
                                setBatch(e.target.value);
                                // setBatch2(index)
                              }}
                              autoFocus
                              ref={batchRef}
                              onKeyDown={handleTaxEnter}
                              // style={{ height: "2rem", borderColor: "grey" }}

                              onFocus={() => handleFocus("batchNumber")}
                              style={{
                                height: "2rem",
                                borderColor: "grey",
                                border:
                                  focusedInput === "batchNumber"
                                    ? "2px solid #015998"
                                    : "1px solid #ccc",
                              }}
                            />
                          </div>

                          <div
                            className="expiry"
                            style={{ marginLeft: "0.8rem" }}
                          >
                            <label
                              htmlFor="expiry"
                              style={{ fontWeight: "bold" }}
                            >
                              Expiry
                            </label>
                            <input
                              id="expiry"
                              value={expiry}
                              type="date"
                              onChange={(e) => setexpiry(e.target.value)}
                              ref={expiryRef}
                              onKeyDown={handleTaxEnter}
                              // style={{
                              //   height: "2rem",
                              //   borderColor: "grey",
                              //   width: "6.5rem",
                              // }}

                              onFocus={() => handleFocus("Expiry")}
                              style={{
                                height: "2rem",
                                borderColor: "grey",
                                width: "6.5rem",
                                border:
                                  focusedInput === "Expiry"
                                    ? "2px solid #015998"
                                    : "1px solid #ccc",
                              }}
                            />
                          </div>

                          <div className="mrp" style={{ marginLeft: "0.8rem" }}>
                            <label htmlFor="mrp" style={{ fontWeight: "bold" }}>
                              MRP
                            </label>
                            <input
                              id="mrp"
                              value={mrp}
                              type="text"
                              onChange={(e) => setMrp(e.target.value)}
                              ref={mrpRef}
                              onKeyDown={handleTaxEnter}
                              // style={{ height: "2rem", borderColor: "grey" }}

                              onFocus={() => handleFocus("MRP")}
                              style={{
                                height: "2rem",
                                borderColor: "grey",
                                border:
                                  focusedInput === "MRP"
                                    ? "2px solid #015998"
                                    : "1px solid #ccc",
                              }}
                            />
                          </div>
                          <div
                            className="hsn_sac"
                            style={{ marginLeft: "0.8rem" }}
                          >
                            <label htmlFor="hsn" style={{ fontWeight: "bold" }}>
                              HSN/SAC
                            </label>
                            <input
                              id="hsn_sac"
                              value={hsn_sac}
                              type="text"
                              onChange={(e) => sethsn_sac(e.target.value)}
                              ref={hsnSacRef}
                              onKeyDown={handleTaxEnter}
                              // style={{ height: "2rem", borderColor: "grey" }}

                              onFocus={() => handleFocus("HSN/SAC")}
                              style={{
                                height: "2rem",
                                borderColor: "grey",
                                border:
                                  focusedInput === "HSN/SAC"
                                    ? "2px solid #015998"
                                    : "1px solid #ccc",
                              }}
                            />
                          </div>
                        </div>

                        <div className="label-for-four-purchase">
                          <div className="NetRate"></div>

                          {/* <label className="TAX" style={{ fontWeight: "bold" }}>
                        TAX
                      </label> */}
                          {centerSelect && (
                            <div className="igst">
                              <label
                                htmlFor="Net Rate"
                                style={{
                                  fontWeight: "bold",
                                  marginLeft: "2rem",
                                }}
                              >
                                IGST
                              </label>
                              <input
                                value={igst}
                                type="text"
                                onChange={(e) => {
                                  setigst(e.target.value);
                                  // FormulaOne();
                                }}
                                ref={igstRef}
                                onKeyDown={handleTaxEnter}
                                // style={{ height: "2rem", borderColor: "grey" }}

                                onFocus={() => handleFocus("IGST")}
                                style={{
                                  height: "2rem",
                                  borderColor: "grey",
                                  border:
                                    focusedInput === "IGST"
                                      ? "2px solid #015998"
                                      : "1px solid #ccc",
                                }}
                              />
                            </div>
                          )}

                          {localSelect && (
                            <div className="cgst">
                              <label
                                htmlFor="cgst"
                                style={{
                                  fontWeight: "bold",
                                  marginLeft: "2rem",
                                }}
                              >
                                CGST
                              </label>

                              <input
                                id="cgst"
                                value={cgst}
                                type="text"
                                onChange={(e) => setcgst(e.target.value)}
                                ref={cgstRef}
                                onKeyDown={handleTaxEnter}
                                // style={{ height: "2rem", borderColor: "grey" }}

                                onFocus={() => handleFocus("CGST")}
                                style={{
                                  height: "2rem",
                                  borderColor: "grey",
                                  border:
                                    focusedInput === "CGST"
                                      ? "2px solid #015998"
                                      : "1px solid #ccc",
                                }}
                              />
                            </div>
                          )}

                          {localSelect && (
                            <div className="sgst">
                              <label
                                htmlFor="Net Rate"
                                style={{
                                  fontWeight: "bold",
                                  marginLeft: "2rem",
                                }}
                              >
                                SGST
                              </label>
                              <input
                                id="Net Rate"
                                className="NetRate"
                                value={sgst}
                                type="text"
                                onChange={(e) => setsgst(e.target.value)}
                                ref={sgstRef}
                                onKeyDown={handleTaxEnter}
                                // style={{ height: "2rem", borderColor: "grey" }}

                                onFocus={() => handleFocus("SGST")}
                                style={{
                                  height: "2rem",
                                  borderColor: "grey",
                                  border:
                                    focusedInput === "SGST"
                                      ? "2px solid #015998"
                                      : "1px solid #ccc",
                                }}
                              />
                            </div>
                          )}
                          <div className="cess">
                            <label
                              htmlFor="Net Rate"
                              style={{ fontWeight: "bold", marginLeft: "2rem" }}
                            >
                              CESS
                            </label>
                            <input
                              id="Net Rate"
                              value={cess}
                              type="text"
                              onChange={(e) => {
                                setcess(e.target.value);
                              }}
                              onKeyDown={(event) => {
                                // arrayOfObject();
                                // FormulaOne();
                                // openModal();
                                handleTaxEnter(event);
                              }}
                              ref={cessRef}
                              // style={{ height: "2rem", borderColor: "grey" }}
                              onFocus={() => handleFocus("CESS")}
                              style={{
                                height: "2rem",
                                borderColor: "grey",
                                border:
                                  focusedInput === "CESS"
                                    ? "2px solid #015998"
                                    : "1px solid #ccc",
                              }}
                            />
                          </div>
                        </div>
                        {/* <button
                      className="submit-firm"
                      onClick={() => {
                        // arrayOfObject();
                        FormulaOne();
                        openModal();
                      }}
                    >
                      Add Product
                    </button> */}
                      </div>
                    </div>
                  </div>
                  // </>
                )}

                {showModalUpdateValue && (
                  // <>
                  <div className="modal-background">
                    <div className="qty-comments-purchase-entry">
                      <button
                        id="close-button"
                        onClick={() => {
                          UpdateStateForUpdatedValues();
                        }}
                        style={{
                          height: ".5rem",
                          width: ".5rem",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "400px",
                          marginTop: -10,
                        }}
                      >
                        X
                      </button>
                      <div className="box">
                        <div className="label-for-purchase-second">
                          <div
                            className="BatchNo"
                            style={{ marginLeft: "0.8rem" }}
                          >
                            <label
                              htmlFor="batchNo"
                              style={{ fontWeight: "bold" }}
                            >
                              Batch No.
                            </label>
                            <input
                              id="BatchNo"
                              value={batchNoUpdateValue}
                              type="text"
                              onChange={(e) => {
                                setBatchUpdateValue(e.target.value);
                                // setBatch2(index)
                              }}
                              autoFocus
                              ref={batchRef}
                              onKeyDown={handleTaxEnter}
                              // style={{ height: "2rem", borderColor: "grey" }}

                              onFocus={() =>
                                handleFocusForUpdate(`batchNoindex`)
                              }
                              style={{
                                height: "2rem",
                                borderColor: "grey",
                                border:
                                  focusedInputUpdates === `batchNoindex`
                                    ? "2px solid #015998"
                                    : "1px solid #ccc",
                              }}
                            />
                          </div>

                          <div
                            className="expiry"
                            style={{ marginLeft: "0.8rem" }}
                          >
                            <label
                              htmlFor="expiry"
                              style={{ fontWeight: "bold" }}
                            >
                              Expiry
                            </label>
                            <input
                              id="expiry"
                              value={expiryUpdateValue}
                              type="date"
                              onChange={(e) =>
                                setExpiryUpdateValue(e.target.value)
                              }
                              ref={expiryRef}
                              onKeyDown={handleTaxEnter}
                              // style={{
                              //   height: "2rem",
                              //   borderColor: "grey",
                              //   width: "6.5rem",
                              // }}

                              onFocus={() =>
                                handleFocusForUpdate(`Expiryindex`)
                              }
                              style={{
                                height: "2rem",
                                borderColor: "grey",
                                width: "6.5rem",
                                border:
                                  focusedInputUpdates === `Expiryindex`
                                    ? "2px solid #015998"
                                    : "1px solid #ccc",
                              }}
                            />
                          </div>

                          <div className="mrp" style={{ marginLeft: "0.8rem" }}>
                            <label htmlFor="mrp" style={{ fontWeight: "bold" }}>
                              MRP
                            </label>
                            <input
                              id="mrp"
                              value={mrpUpdateValue}
                              type="text"
                              onChange={(e) =>
                                setMrpUpdateValue(e.target.value)
                              }
                              ref={mrpRef}
                              onKeyDown={handleTaxEnter}
                              // style={{ height: "2rem", borderColor: "grey" }}
                              onFocus={() => handleFocusForUpdate(`MRPindex`)}
                              style={{
                                height: "2rem",
                                borderColor: "grey",
                                border:
                                  focusedInputUpdates === `MRPindex`
                                    ? "2px solid #015998"
                                    : "1px solid #ccc",
                              }}
                            />
                          </div>
                          <div
                            className="hsn_sac"
                            style={{ marginLeft: "0.8rem" }}
                          >
                            <label htmlFor="hsn" style={{ fontWeight: "bold" }}>
                              HSN/SAC
                            </label>
                            <input
                              id="hsn_sac"
                              value={hsnSacUpdateValue}
                              type="text"
                              onChange={(e) =>
                                setHsnSacUpdateValue(e.target.value)
                              }
                              ref={hsnSacRef}
                              onKeyDown={handleTaxEnter}
                              // style={{ height: "2rem", borderColor: "grey" }}
                              onFocus={() =>
                                handleFocusForUpdate(`HSN/SACindex`)
                              }
                              style={{
                                height: "2rem",
                                borderColor: "grey",
                                border:
                                  focusedInputUpdates === `HSN/SACindex`
                                    ? "2px solid #015998"
                                    : "1px solid #ccc",
                              }}
                            />
                          </div>
                        </div>

                        <div className="label-for-four-purchase">
                          <div className="NetRate"></div>

                          {/* <label className="TAX" style={{ fontWeight: "bold" }}>
                        TAX
                      </label> */}
                          {region == "Center" && (
                            <div className="igst">
                              <label
                                htmlFor="Net Rate"
                                style={{
                                  fontWeight: "bold",
                                  marginLeft: "2rem",
                                }}
                              >
                                IGST
                              </label>
                              <input
                                value={igstUpdateValue}
                                type="text"
                                onChange={(e) => {
                                  setIgstUpdateValue(e.target.value);
                                  // FormulaOne();
                                }}
                                ref={igstRef}
                                onKeyDown={handleTaxEnter}
                                // style={{ height: "2rem", borderColor: "grey" }}
                                onFocus={() =>
                                  handleFocusForUpdate(`IGSTindex`)
                                }
                                style={{
                                  height: "2rem",
                                  borderColor: "grey",
                                  border:
                                    focusedInputUpdates === `IGSTindex`
                                      ? "2px solid #015998"
                                      : "1px solid #ccc",
                                }}
                              />
                            </div>
                          )}

                          {region == "Local" && (
                            <div className="cgst">
                              <label
                                htmlFor="cgst"
                                style={{
                                  fontWeight: "bold",
                                  marginLeft: "2rem",
                                }}
                              >
                                CGST
                              </label>

                              <input
                                id="cgst"
                                value={cgstUpdateValue}
                                type="text"
                                onChange={(e) =>
                                  setCgstUpdateValue(e.target.value)
                                }
                                ref={cgstRef}
                                onKeyDown={handleTaxEnter}
                                // style={{ height: "2rem", borderColor: "grey" }}
                                onFocus={() =>
                                  handleFocusForUpdate(`CGSTindex`)
                                }
                                style={{
                                  height: "2rem",
                                  borderColor: "grey",
                                  border:
                                    focusedInputUpdates === `CGSTindex`
                                      ? "2px solid #015998"
                                      : "1px solid #ccc",
                                }}
                              />
                            </div>
                          )}

                          {region == "Local" && (
                            <div className="sgst">
                              <label
                                htmlFor="Net Rate"
                                style={{
                                  fontWeight: "bold",
                                  marginLeft: "2rem",
                                }}
                              >
                                SGST
                              </label>
                              <input
                                id="Net Rate"
                                className="NetRate"
                                value={sgstUpdateValue}
                                type="text"
                                onChange={(e) =>
                                  setSgstUpdateValue(e.target.value)
                                }
                                ref={sgstRef}
                                onKeyDown={handleTaxEnter}
                                // style={{ height: "2rem", borderColor: "grey" }}
                                onFocus={() =>
                                  handleFocusForUpdate(`SGSTindex`)
                                }
                                style={{
                                  height: "2rem",
                                  borderColor: "grey",
                                  border:
                                    focusedInputUpdates === `SGSTindex`
                                      ? "2px solid #015998"
                                      : "1px solid #ccc",
                                }}
                              />
                            </div>
                          )}
                          <div className="cess">
                            <label
                              htmlFor="Net Rate"
                              style={{ fontWeight: "bold", marginLeft: "2rem" }}
                            >
                              CESS
                            </label>
                            <input
                              id="Net Rate"
                              value={cessUpdateValue}
                              type="text"
                              onChange={(e) => {
                                setCessUpdateValue(e.target.value);
                              }}
                              onKeyDown={(event) => {
                                // arrayOfObject();
                                // FormulaOne();
                                // openModal();
                                handleTaxEnter(event);
                              }}
                              ref={cessRef}
                              // style={{ height: "2rem", borderColor: "grey" }}
                              onFocus={() => handleFocusForUpdate(`CESSindex`)}
                              style={{
                                height: "2rem",
                                borderColor: "grey",
                                border:
                                  focusedInputUpdates === `CESSindex`
                                    ? "2px solid #015998"
                                    : "1px solid #ccc",
                              }}
                            />
                          </div>
                          <button
                            ref={updatePopUpRef}
                            onClick={() => {
                              updateArrayofObject(onClickedDone);
                              UpdateStateForUpdatedValues();
                            }}
                            onFocus={() => handleFocusForUpdate(`Updateindex`)}
                            style={{
                              height: "2rem",
                              borderColor: "grey",
                              border:
                                focusedInputUpdates === `Updateindex`
                                  ? "2px solid #015998"
                                  : "1px solid #ccc",

                              display:"flex",
                              justifyContent:"center",
                              alignItems:'center',
                              marginTop:20
                            }}
                          >
                            Update
                          </button>
                        </div>
                        {/* <button
                      className="submit-firm"
                      onClick={() => {
                        // arrayOfObject();
                        FormulaOne();
                        openModal();
                      }}
                    >
                      Add Product
                    </button> */}
                      </div>
                    </div>
                  </div>
                  // </>
                )}


                {/* for warning pop up message  */}

                <WarningPopup
                  showWarning={showWarning}
                  handleCloseWarning={handleCloseWarning}
                  warningMessage={warningMessage}
                />

                {/* qty details  */}
                <div className="qty-details-purchase">
                  {showRecordsButton && (
                    <table id="customers-purchase">
                      <tr className="qty-detail-header">
                        <th>Date </th>
                        <th>Invoice No.</th>
                        <th>Amount </th>
                      </tr>
                      <tbody>
                        {selectedFirmInvoiceHistory
                          ? selectedFirmInvoiceHistory.map(function (
                              item,
                              index
                            ) {
                              return (
                                <tr key={item.id}>
                                  <td>{item.date} </td>
                                  <td>{item.invoiceNo}</td>
                                  <td>{item.grandTotal}</td>
                                </tr>
                              );
                            })
                          : "No entries"}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* here all firm name genereator detials which we are pre filling from the data which we are getting from the backend firm list     */}

          <div className="contact-box--purchase">
            {/* <div className="contact-box-input"> */}
            <label
              htmlFor="add"
              style={{ fontWeight: "bold" }}
              className="side-bar-details-in-purchase-entry"
            >
              Address
            </label>
            <input
              id="add"
              value={address}
              // style={{ height: "2rem", borderColor: "grey", width: "9.5rem" }}
            />
            <label
              htmlFor="email"
              style={{ fontWeight: "bold" }}
              className="side-bar-details-in-purchase-entry"
            >
              Email
            </label>
            <input
              id="email"
              value={email}
              // style={{ height: "2rem", borderColor: "grey" }}
            />
            <label
              htmlFor="mobile"
              style={{ fontWeight: "bold" }}
              className="side-bar-details-in-purchase-entry"
            >
              Mobile No.
            </label>
            <input
              id="mobile"
              value={mobile}
              // style={{ height: "2rem", borderColor: "grey" }}
            />
            <label
              htmlFor="gst"
              style={{ fontWeight: "bold" }}
              className="side-bar-details-in-purchase-entry"
            >
              GSTIN
            </label>
            <input
              id="gst"
              value={gst}
              // style={{ height: "2rem", borderColor: "grey" }}
            />

            <label
              style={{ fontWeight: "bold" }}
              className="side-bar-details-in-purchase-entry"
            >
              D.L.1
            </label>
            <input
              id="DL2"
              value={DL1}
              // style={{ height: "2rem", borderColor: "grey" }}
            />
            <label
              style={{ fontWeight: "bold" }}
              className="side-bar-details-in-purchase-entry"
            >
              D.L.2
            </label>
            <input
              id="DL2"
              value={DL2}
              // style={{ height: "2rem", borderColor: "grey" }}
            />
            <label
              style={{ fontWeight: "bold" }}
              className="side-bar-details-in-purchase-entry"
            >
              FSSAI No.
            </label>
            <input
              id="fssai"
              value={fssai}
              // style={{ height: "2rem", borderColor: "grey" }}
            />
          </div>
        </div>
        {/* </div> */}

        <div className="bottom-half-purchase">
          <div className="total-box-purchase">
            <CommentsTable
              generatedData={{
                product: generatedData,
                cat: category,
                brand: brand,
                unit: unitpacking,
                hac: hsn_sac_code,
              }}
            />
            {/* <div className="total-values"> */}
            <div className="total-values-button--purchase">
              <button>Sub. Total</button>
              <button>Disc. Amount</button>
              <button>Tax Amount</button>
              <button> G/R Amount</button>
              <button>Others </button>
              <button>Payable Amount</button>
            </div>
            {/* </div> */}
          </div>
          <div className="blank-box-purchase">
            <input onKeyDown={handleEnter} ref={field17Ref} value={subtotal} />
            <input
              value={disAmt}
              onKeyDown={handleEnter}
              ref={field18Ref}
              // style={{ position: "relative", top: "8px" }}
            />
            <input
              onKeyDown={handleEnter}
              ref={field19Ref}
              value={parseFloat(totalTax).toFixed(2)}
              // style={{ position: "relative", top: "14px" }}
            />
            <input
              onKeyDown={handleEnter}
              ref={field20Ref}
              value={goodReturn}
              // style={{ position: "relative", top: "16px" }}
            />
            <input
              value={cnVoucher}
              onChange={(e) => {
                setCnVoucher(e.target.value);
                // cnVoucherFunction([e.target.value]);

                const newCnVoucher = e.target.value;
                const previousCnVoucher = cnVoucher || 0; // Use 0 if cnVoucher is falsy

                // Update cnVoucher state
                setCnVoucher(newCnVoucher);

                // Convert to integers for more accurate calculations (assuming currency)
                const parsedNewCnVoucher = parseFloat(newCnVoucher, 10) || 0;
                const parsedPreviousCnVoucher = parseFloat(previousCnVoucher, 10) || 0;

                // Calculate the difference to update the grandTotal
                const diff = parsedNewCnVoucher - parsedPreviousCnVoucher;

                // Update grandTotal by adding the difference
                const updatedGrandTotal = parseFloat(grandTotal) + diff;
                setGrandTotal(updatedGrandTotal.toFixed(2));
                // alert(grandTotal)
              }}
              onKeyDown={handleEnter}
              ref={field21Ref}
              // style={{ position: "relative", top: "19px" }}
            />
            <input
              onKeyDown={handleEnter}
              ref={field22Ref}
              value={
                // cnVoucher
                //   ? parseFloat(grandTotal2).toFixed(2)
                //   :
                //  parseFloat(grandTotal).toFixed(2)
                grandTotal
              }
              // style={{ position: "relative", top: "17px" }}
            />
          </div>
        </div>
        <div
          className="recent-history-table"
          style={{
            position: "relative",
            top: "-21rem",
            left: "5rem",
            width: "33rem",
          }}
        >
          {selects && (
            // <div style={{position:'relative',left:'2rem'}}>

            <div
              className="table"
              style={{
                height: "13rem",
                marginright: 10,
                width: "32rem",
                overflow: "auto",
              }}
            >
              {/* head */}
              <div
                className="table-row header"
                id="product-select-table-header"
              >
                <div className="table-cell-second-pop-up">Date</div>
                <div className="table-cell-second-pop-up">Firm Name</div>
                <div className="table-cell-second-pop-up">Invoice No.</div>
                <div className="table-cell-second-pop-up">Quantity</div>
                <div className="table-cell-second-pop-up">Free</div>
                <div className="table-cell-second-pop-up">Trade Rate</div>
                <div className="table-cell-second-pop-up">Disc %</div>
                <div className="table-cell-second-pop-up">Batch No.</div>
                <div className="table-cell-second-pop-up">Expiry</div>
                <div className="table-cell-second-pop-up">M.R.P.</div>
                <div className="table-cell-second-pop-up">Tax</div>
              </div>

              <div className="table-body">
                {/* Product History */}
                {SelectProductResultData ? (
                  SelectProductResultData.map((item, index) => (
                    //content
                    <div
                      className="table-row"
                      key={index}
                      id="product-select-table-data"
                    >
                      <div className="table-cell-second-pop-up">
                        {item.date}
                      </div>
                      <div className="table-cell-second-pop-up">
                        {item.firmName}
                      </div>
                      <div className="table-cell-second-pop-up">
                        {item.invoiceNo}
                      </div>
                      <div className="table-cell-second-pop-up">{item.qty}</div>
                      <div className="table-cell-second-pop-up">
                        {item.free}
                      </div>
                      <div className="table-cell-second-pop-up">
                        {item.tradeRate}
                      </div>
                      <div className="table-cell-second-pop-up">
                        {item.discPercent}
                      </div>
                      <div className="table-cell-second-pop-up">
                        {item.batchNo}
                      </div>
                      <div className="table-cell-second-pop-up">
                        {item.expiry}
                      </div>
                      <div className="table-cell-second-pop-up">{item.mrp}</div>
                      <div className="table-cell-second-pop-up">
                        {item.igst}
                      </div>
                      {/* <div className="table-cell-second-pop-up"></div> */}
                    </div>
                  ))
                ) : (
                  <h6>No data</h6>
                )}
              </div>

              {/* </div> */}

              {/* <p onClick={flipModal}>x</p> */}
            </div>
          )}
        </div>

        <div
          // className="recent-history-table"
          // style={{ top: "-33rem", left: "5rem", width: "37" }}
          style={{
            position: "absolute",
            // top: "55.7%",
            top:680,
            left: "27%",
            width: "40%",
            height: "auto",
          }}
        >
          {firmInvoiceSelect && (
            <>
            <div className="table"
                  style={{height:'13rem'}}
                  >
                    <div className="table-row header">
                      <div className="table-cell">Date</div>
                      <div className="table-cell">Firm Name</div>
                      <div className="table-cell">Invoice No.</div>
                      <div className="table-cell">Grand Total</div>
                    </div>
                    <div className="table-body">
                      {selectedFirmInvoiceHistory ? (
                        selectedFirmInvoiceHistory.map(function (item, index) {
                          return (
                            <div className="table-row" key={item.date}>
                               <div className="table-cell">{item.date}</div>
                              <div className="table-cell">{item.firmName}</div>
                              <div className="table-cell">{item.invoiceNO}</div>
                              <div className="table-cell">{item.grandTotal}</div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="table-row">
                          <div className="table-cell" colSpan="3">No entries</div>
                        </div>
                      )}
                    </div>
                  </div>

            </>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Entry;

// MyContext.js
import { createContext, useContext, useEffect, useState, } from 'react';
// import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import axios from "axios";

const AppContext = createContext();

const AppProvider = ({ children }) => {

    // const navigate = useNavigate();

    let token = localStorage.getItem("x-api-key");

    const [notifications, setNotifications] = useState([]);

    const [readNotification, setReadNotification] = useState(0);

    const [unreadNotification, setunReadNotification] = useState(0);

    const [page, setPage] = useState(1);

    // const [count, setCount] = useState(0);
    
    const [totalPages, setTotalPages] = useState(0);

    // const Get_notifications = async () => {
    //     //token
    //     let token = localStorage.getItem("x-api-key");
    //         await axios.post("api/user/get_all_users_notifications", {}, {
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 "x-api-key": token,  "x-api-plan": "SaaS"
    //             },
    //         }).then((response) => {
    //             setNotifications(response.data.data);
    //             checkIsReadNotification(response.data);
    //         }).catch((err) => {
    //             console.error(err.data.data.message);
    //         });
    // };


    const Get_notifications = async () => {
        try {
          // Get the token from local storage
          let token = localStorage.getItem("x-api-key");
          if (!token) {
            throw new Error("Token not found");
          }
      
          // Make the API call
          const response = await axios.post(
            "api/user/get_all_users_notifications",
            {},
            {
              headers: {
                "Content-Type": "application/json",
                "x-api-key": token,  "x-api-plan": "SaaS"
                
              },
            }
          );
      
          // Check for successful response
          if (response.status === 200) {
            // Set notifications if data is available
            if (response.data && response.data.data) {
              setNotifications(response.data.data);
              checkIsReadNotification(response.data);
            } else {
              throw new Error("Data not found in the response");
            }
          } else {
            throw new Error("Unexpected response status: " + response.status);
          }
        } catch (error) {
          // Handle errors appropriately
          if (error.response) {
            // The request was made and the server responded with a status code
            console.error("Server Error:", error.response.data);
          } else if (error.request) {
            // The request was made but no response was received
            console.error("Network Error:", error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.error("Error:", error.message);
          }
        }
      };

      

    const checkIsReadNotification = (notification_data) => {
        try{
        let isReadCount = 0;
        console.log("notification_data", notification_data);
        if (notification_data && notification_data.length>0) {
            notification_data.map((notification) => {
            if (notification.isRead === true) {
                isReadCount++;
            };
            });
            setReadNotification(isReadCount);
            setunReadNotification(notification_data.length - isReadCount);
        } else {
            setunReadNotification(0);
        }
      
        console.log("notification_data", notification_data);
        console.log("readNotification", readNotification);
            console.log("isReadCount", isReadCount);
        } catch (error) {
            console.error(error.message);
        }
        
    };

    function LoadingSpinner() {
    return (
        <button class="btn btn-primary" type="button" disabled>
            <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            <span role="status">Loading...</span>
        </button>   
        );
    };

    // Log out API;

    const handleLogout = async () => {
     
        let token = localStorage.getItem("x-api-key");
        // Call the logout API when the "Log Out" button is clicked;
        await axios.post("api/admin/auth/logout", {}, {
            // method: "POST",
            headers: {
                "Content-Type": "application/json",
                "x-api-key": token,  "x-api-plan": "SaaS"
              
            },
            // You may include authentication tokens if required
            // For example, if using JWT, you could include the token in the 'Authorization' header
        })
        .then((data) => {
            // Handle the response if needed (e.g., show a message to the user)
            console.log(data);

            // Optionally, perform any additional client-side cleanup if required

            // Redirect the user to the login page after successful logout
            // window.location.href = "/";
            localStorage.setItem("x-api-key", "");

            localStorage.setItem("isLoggedIn", false);
            // navigate("/");
        })
        .catch((error) => {
            console.error(error.data.data.message)
            // Handle error scenarios
        });
    };

    // paginated function

    function PaginationControlled() {
        const handleChange = (event, value) => {
            setPage(value);
            // setCurrentPage(page);
            console.log(10, page);
        }
        return (
            // <div className='pagination' style = {{display:"flex", justifyContent:"center", alignItems:"center"}}>
            //     <Stack spacing={2}>
            //         <Typography>Page: {page}</Typography>
            //         <Pagination count={totalPages} page={page} onChange={handleChange} />
            //     </Stack>
            // </div>
            <div 
              // className='pagination' 
              style={{
              position: 'fixed',
              bottom: '0',
              left: '53%',
              transform: 'translateX(-50%)',
              zIndex: '999',
              backgroundColor: 'white',
              padding: '10px',
              borderRadius: '5px',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 'fit-content',
          }}>
              <Stack direction="row" spacing={2} alignItems="center">
                  <Typography variant="body1">Page: {page}</Typography>
                  <Pagination count={totalPages} page={page} onChange={handleChange} />
              </Stack>
          </div>
          
          

        );
    };

  useEffect(() => {
  if (token) {
    Get_notifications();
    PaginationControlled();
  }
}, [token]);

    const contextValue = {
        notifications,
        readNotification,
        unreadNotification,
        // Get_notifications,
        checkIsReadNotification,
        LoadingSpinner,
        handleLogout,
        PaginationControlled,
        page,
        setTotalPages,
        // setCount,
    };

    return (
        <AppContext.Provider value = {contextValue}>
            {children}
        </AppContext.Provider>
    )
};

const useGlobalContext = () => {
    return useContext(AppContext);
};

export { AppProvider,useGlobalContext };

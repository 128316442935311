
/** @format */
import React, { useState, useEffect, useRef } from "react";
import "./SalesEntry.css";
import "./invoiceReceipt.css";
import "./saleInvoice.css";
import "./printModal.css";
import Swal from "sweetalert2";
import warningIcon from './warning-icon.png';
// import { SaleEntryHeader } from "./SaleEntryHeader";
import Layout from "../../Layout/Layout";
import { AiOutlineClose } from "react-icons/ai";
import { Switch, ConfigProvider } from "antd";
import hegan from "./heganB-Wsymbol.png";
import axios from "axios";
import userEvent from "@testing-library/user-event";
import WarningPopup from "./WarningPopup";
// import { nerdamer, set } from "nerdamer";
// import { Troubleshoot } from "@mui/icons-material";
const SalesEntry = () => {
  // create an object to fill empty data inside product row inputs;
  // ============== seller details api for print out ==================== //




  let [userDetails, setUserDetails] = useState({});
  const sellerDetails = async () => {
    try {
      //token
      let token = localStorage.getItem("x-api-key");

      let response = await axios.post(
        "api/user/getOneUser",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": token,
            "x-api-plan": "SaaS",
          },
        }
      );

      let result = response.data;
      setUserDetails(result.data);
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  // --------------------------------------Product Modal Code-----------------------------------//

  const [showProductModal, setshowProductModal] = useState(false);

  // for value update while changing on existing data in purchase entry array of object
  const [updateIndex, setUpdateIndex] = useState(false);
  const [updateState, setUpdateState] = useState(false);
  // const inputRef = useRef(null);


  let [ProductEntries, setProductEntries] = useState([]);

  const openProductModal = (event) => {
    setshowProductModal(true);
    if (event.keyCode === 13) {
      setshowProductModal(true);
    }
  };

  const closeProductModal = () => {
    setshowProductModal(false);
    mMRef.current.focus();
  };

  // --------------------firm modal code--------------------------------------------- //

  const [showFirmModal, setshowFirmModal] = useState(false);
  const [selectedFirm, setSelectedFirm] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");

  const handleSelectedFirm = (val) => {
    setSelectedFirm(val);
  };

  const handleSelectedProduct = (val) => {
    setSelectedProduct(val);
  };

  const inputRef = useRef(null);

  const openFirmModal = (event) => {
    setshowFirmModal(true);
    if (event.keyCode === 13) {
      setshowFirmModal(true);
    }
  };

  const closeFirmModal = () => {
    setshowFirmModal(false);
    dateRef.current.focus();
  };

  // top refs
  const firmNameRef = useRef(null);
  const dateRef = useRef(null);
  const regionRef = useRef(null);
  const typeRef = useRef(null);
  const invoiceRef = useRef(null);
  const creditNoteRef = useRef(null);
  // middle refs
  const mSnRef = useRef(null);
  const mProductNameRef = useRef(null);
  const mAfterProuductSelectRef = useRef(null);
  const mMRef = useRef(null);
  const mERef = useRef(null);
  const mGRef = useRef(null);
  const mUnitPackRef = useRef(null);
  const mQuantityRef = useRef(null);
  const mFreeRef = useRef(null);
  const mTradeRateRef = useRef(null);
  const mDiscPercentRef = useRef(null);
  const mBatchNoRef = useRef(null);
  const mExpiryRef = useRef(null);
  const mMrpRef = useRef(null);
  const mHsnSacRef = useRef(null);
  const mNetRateRef = useRef(null);
  const mCgstRef = useRef(null);
  const mSgstRef = useRef(null);
  const mIgstRef = useRef(null);
  const mCessRef = useRef(null);
  // bottom refs
  const bSubTotalBoxRef = useRef(null);
  const bDiscountAmtBoxRef = useRef(null);
  const bTotalTaxBoxRef = useRef(null);
  const bGoodsReturnBoxRef = useRef(null);
  const bCnVoucherBoxRef = useRef(null);
  const bPayableAmountBoxRef = useRef(null);
  // bottom buttons ref
  const bSaveButtonRef = useRef(null);
  const bPrintButtonRef = useRef(null);
  const bViewButtonRef = useRef(null);

  // print options ref
  const mainSalesInvoiceToggleRef = useRef(null);
  const mainInvoiceToggleRef = useRef(null);
  const estimateSalesInvoiceToggleRef = useRef(null);
  const estimateInvoiceToggleRef = useRef(null);
  const confirmChoicesRef = useRef(null);

  // invoice-close-button-ref
  const closeInvoiceBtnRef = useRef(null);



  //update array of object useRef name 
  //for inner update array of object ref for pop up 

  //Batch No. Expiry  Mrp HSN/SAC  CESS Net Rate IGST SGST CGST


  
  
  
  
 

 



  const handleCheckQuantity = () => {
    // alert('done')
    if(!qty){
    // if (mQuantityRef.current && mQuantityRef.current.value === '') {
      mQuantityRef.current.focus();
      alert('Please enter quantity first.');
    // }
  }
  };






  // const [showWarning, setShowWarning] = useState(false);
  // const [warningMessage, setWarningMessage] = useState('');

  // const handleCloseWarning = () => {
  //   setShowWarning(false);
  // };

  // // Function to trigger showing the warning popup
  // const handleShowWarning = (message) => {
  //   setWarningMessage(message);
  //   setShowWarning(true);
  // };


  //mDiscPercentRef

  const okButtonRef = useRef(null);
  
  const [showWarning, setShowWarning] = useState(false);
  const [warningMessage, setWarningMessage] = useState('');

  const handleShowWarning = (message) => {
    console.log('Opening warning');
    // if(showWarning && okButtonRef.current){
      // okButtonRef.current.focus();
      // alert('working');
    // }
     
    setWarningMessage(message);
    setShowWarning(true);
  };
  
  const handleCloseWarning = () => {
    // console.log('Closing warning');
    // if(showWarning && okButtonRef.current){
      // okButtonRef.current.focus();
      // alert('working');
    // }
    setShowWarning(false); 
  };

  //to check mandatory fields have values 
  const validateFields = (event) => {
    // if (event.key === "Enter" && !event.shiftKey ) {
    //   event.preventDefault();


    let isCheckSelected = isMChecked || isEChecked || isGChecked;

    if(!selectedFirm && mTradeRateRef.current){
      handleShowWarning('Please Select Firm Name');
      // firmNameRef.current.focus();
      return false;
    } 
    else if(!date && mTradeRateRef.current){
      handleShowWarning('Please Select A Date');
      dateRef.current.focus();
      return false;
    } 
    else if (!region && mTradeRateRef.current){
      handleShowWarning('Please Select A Region');
      
  //  alert('done to doneuhkj');
      // regionRef.current.focus();
      return false;
    }
     else if (!type && mTradeRateRef.current){
      handleShowWarning('Please Select A Type');
      typeRef.current.focus();
      return false;
    } else if (!selectedProduct && mTradeRateRef.current) {
      handleShowWarning('Please Select A Product');
      mProductNameRef.current.focus();
      return false;
    } else if(!isCheckSelected && mTradeRateRef.current){
      handleShowWarning('Please Check Atleast One Check Box')
      mMRef.current.focus();
      return false;
    } else if(!qty && mTradeRateRef.current || qty == 0){
      handleShowWarning('Please Enter Quantity')
      mQuantityRef.current.focus();
      return false;
    } else if(!tradeRate && mTradeRateRef.current || tradeRate == 0){
      handleShowWarning('Please Enter Trade Rate')
      mTradeRateRef.current.focus();
      return false;
      // alert('Please Enter Trade Rate');
    }
    else{
      // handleCloseWarning();
      return true;
    }
  // } 
};
  
  
  


  const handleEnter = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      switch (event.target) {
        case firmNameRef.current:
          dateRef.current.focus();
          break;
        case dateRef.current:
          regionRef.current.focus();
          break;
        case regionRef.current:
          typeRef.current.focus();
          break;
        case typeRef.current: {
          if (invoiceSelect) {
            invoiceRef.current.focus();
            break;
          }
          if (cnRefSelect) {
            creditNoteRef.current.focus();
            break;
          }
        }
        case invoiceRef.current:
          mSnRef.current.focus();
          break;
        case creditNoteRef.current:
          mSnRef.current.focus();
          break;
        case mSnRef.current:
          mProductNameRef.current.focus();
          break;
        case mProductNameRef.current:
          mAfterProuductSelectRef.current.focus();
          break;
//new added
          case mAfterProuductSelectRef.current:
            mMRef.current.focus();
            break;

          
        case mMRef.current:
          // mERef.current.focus();
          mGRef.current.focus();
          break;
        // case mERef.current:
        //   mGRef.current.focus();
        //   break;
        case mGRef.current:
          mUnitPackRef.current.focus();
          break;
        case mUnitPackRef.current:
          mQuantityRef.current.focus();
          break;
        case mQuantityRef.current:
          mFreeRef.current.focus();
          break;
        case mFreeRef.current:
          mTradeRateRef.current.focus();
          break;
        // case mTradeRateRef.current:
        //   mBatchNoRef.current.focus();
        //   break;
        case mDiscPercentRef.current: {
          FormulaOne('testing');
         
          // FormulaOne();
          // setIsEChecked(false); 
          // setIsMChecked(false);
          // setIsGChecked(false);
          // setActualG("");
          // setregisterAs("");
          mSnRef.current.focus();
          break;
        }
        case mBatchNoRef.current:
          mExpiryRef.current.focus();
          break;
        case mExpiryRef.current:
          mMrpRef.current.focus();
          break;
        case mMrpRef.current:
          mHsnSacRef.current.focus();
          break;
        case mHsnSacRef.current:
          mNetRateRef.current.focus();
          break;
        case mNetRateRef.current: {
          if (localSelect) {
            mCgstRef.current.focus();
            break;
          } else if (centerSelect) {
            mIgstRef.current.focus();
            break;
          } else {
            mCessRef.current.focus();
            break;
          }
        }
        case mCgstRef.current:
          mSgstRef.current.focus();
          break;
        case mSgstRef.current:
          mCessRef.current.focus();
          break;
        case mIgstRef.current:
          mCessRef.current.focus();
          break;
        case mCessRef.current: {
          mDiscPercentRef.current.focus();
          break;
        }

        case bSubTotalBoxRef.current:
          bDiscountAmtBoxRef.current.focus();
          break;
        case bDiscountAmtBoxRef.current:
          bTotalTaxBoxRef.current.focus();
          break;
        case bTotalTaxBoxRef.current:
          bGoodsReturnBoxRef.current.focus();
          break;
        case bGoodsReturnBoxRef.current:
          bCnVoucherBoxRef.current.focus();
          break;
        case bCnVoucherBoxRef.current:
          bPayableAmountBoxRef.current.focus();
          break;
        case bPayableAmountBoxRef.current:
          bSaveButtonRef.current.focus();
          break;
        case bSaveButtonRef.current:
          bPrintButtonRef.current.focus();
          break;
        case bPrintButtonRef.current:
          bViewButtonRef.current.focus();
          break;
        case mainSalesInvoiceToggleRef.current:
          mainInvoiceToggleRef.current.focus();
          break;
        case mainInvoiceToggleRef.current:
          estimateSalesInvoiceToggleRef.current.focus();
          break;
        case estimateSalesInvoiceToggleRef.current:
          estimateInvoiceToggleRef.current.focus();
          break;
        case estimateInvoiceToggleRef.current:
          confirmChoicesRef.current.focus();
      }
    } else if (event.key === "ArrowUp") {
      event.preventDefault();

      switch (event.target) {
        case dateRef.current:
          firmNameRef.current.focus();
          break;
        case regionRef.current:
          dateRef.current.focus();
          break;
        case typeRef.current:
          regionRef.current.focus();
          break;
        case invoiceRef.current:
          typeRef.current.focus();
          break;
        case creditNoteRef.current:
          typeRef.current.focus();
          break;
        case mSnRef.current: {
          if (invoiceSelect) {
            invoiceRef.current.focus();
            break;
          }
          if (cnRefSelect) {
            creditNoteRef.current.focus();
            break;
          }
        }
        case mProductNameRef.current:
          mSnRef.current.focus();
          break;
        case mMRef.current:
          mProductNameRef.current.focus();
          break;
        case mERef.current:
          mMRef.current.focus();
          break;
        case mGRef.current:
          mERef.current.focus();
          break;
        case mUnitPackRef.current:
          mGRef.current.focus();
          break;
        case mQuantityRef.current:
          mUnitPackRef.current.focus();
          break;
        case mFreeRef.current:
          mQuantityRef.current.focus();
          break;
        case mTradeRateRef.current:
          mFreeRef.current.focus();
          break;
        case mDiscPercentRef.current:
          mTradeRateRef.current.focus();
          break;
        case mBatchNoRef.current: {
          openModal();
          mTradeRateRef.current.focus();
          break;
        }

        case mExpiryRef.current:
          mBatchNoRef.current.focus();
          break;
        case mMrpRef.current:
          mExpiryRef.current.focus();
          break;
        case mHsnSacRef.current:
          mMrpRef.current.focus();
          break;
        case mNetRateRef.current:
          mHsnSacRef.current.focus();
          break;
        case mCessRef.current: {
          if (centerSelect) {
            mIgstRef.current.focus();
            break;
          } else if (localSelect) {
            mSgstRef.current.focus();
            break;
          } else {
            mNetRateRef.current.focus();
            break;
          }
        }
        case mSgstRef.current:
          mCgstRef.current.focus();
          break;
        case mCgstRef.current:
          mNetRateRef.current.focus();
          break;
        case mIgstRef.current:
          mNetRateRef.current.focus();
          break;
        case bSubTotalBoxRef.current:
          mSnRef.current.focus();
          break;
        case bDiscountAmtBoxRef.current:
          bSubTotalBoxRef.current.focus();
          break;
        case bTotalTaxBoxRef.current:
          bDiscountAmtBoxRef.current.focus();
          break;
        case bGoodsReturnBoxRef.current:
          bTotalTaxBoxRef.current.focus();
          break;
        case bCnVoucherBoxRef.current:
          bGoodsReturnBoxRef.current.focus();
          break;
        case bPayableAmountBoxRef.current:
          bCnVoucherBoxRef.current.focus();
          break;
        case bSaveButtonRef.current:
          bPayableAmountBoxRef.current.focus();
          break;
        case bPrintButtonRef.current:
          bSaveButtonRef.current.focus();
          break;
        case bViewButtonRef.current:
          bPrintButtonRef.current.focus();
          break;
        case mainInvoiceToggleRef.current:
          mainSalesInvoiceToggleRef.current.focus();
          break;
        case estimateSalesInvoiceToggleRef.current:
          mainInvoiceToggleRef.current.focus();
          break;
        case estimateInvoiceToggleRef.current:
          estimateSalesInvoiceToggleRef.current.focus();
          break;
        case confirmChoicesRef.current:
          estimateInvoiceToggleRef.current.focus();
          break;
      }
    }
  };


  //Product Name	M	E	G	Unit Pack	Qty	Free	Trade Rate	Disc%	
  //update Array of Object Enter Code 
  // update array of object enter code ******************************==******************************

const updateBatchNoRef = useRef(null);
const updateExpiryRef = useRef(null);
const updateMrpRef = useRef(null);
const updateHsnSacRef = useRef(null);
const updateNetRateRef = useRef(null);
const updateCESSRef = useRef(null);
const updateIGSTRef = useRef(null);
const updateSGSTRef = useRef(null);
const updateCGSTRef = useRef(null);
const updateButtonPopupRef = useRef(null);


const handleKeyDown2 = (event) => {
  if (event.key === "Enter" && !event.shiftKey) {
    event.preventDefault();
    if (event.target === updateBatchNoRef.current) {
      updateExpiryRef.current.focus();
    } else if (event.target === updateExpiryRef.current) {
      updateMrpRef.current.focus();
    } else if (event.target === updateMrpRef.current) {
      updateHsnSacRef.current.focus();
    } else if (event.target === updateHsnSacRef.current) {
      updateNetRateRef.current.focus();
    } else if (event.target === updateNetRateRef.current) {
        if (localSelect) {
          updateCGSTRef.current.focus();
        } else if (centerSelect) {
          updateIGSTRef.current.focus();
        } else {
          updateCESSRef.current.focus();
        }
      }
      else if(event.target === updateCGSTRef.current){
        updateSGSTRef.current.focus();
      }
      else if(event.target === updateSGSTRef.current){
        updateCESSRef.current.focus();
      }
      else if(event.target === updateIGSTRef.current){
        updateCESSRef.current.focus();
      }
      else if (event.target === updateCESSRef.current) {
        // mDiscPercentRef.current.focus();
        updateButtonPopupRef.current.focus();
      }
      else if (event.target === updateButtonPopupRef.current) {
              //onClickedDone
              //calling this function to send focus on discount in the current index number 
              handleKeyDown(event, onClickedDone, 6);
      }
   }
};


  //new this one for update array of object front values
  //to add enter to focus on next index in array of object functionlities
  const inputRefs = useRef([]);

  const handleKeyDown = (event, currentIndex, refNumber) => {

    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();

      const currentRowRefs = inputRefs.current[currentIndex];
      console.log('currentRowRefs',currentRowRefs[refNumber].current);
      if (currentRowRefs && currentRowRefs[refNumber] && currentRowRefs[refNumber].current) {
        const nextRef = currentRowRefs[refNumber + 1];
        if (nextRef) {
          nextRef.current.focus();
        }
      }
    }
  };

  // =================M,E,G Button logic======================//


  const [isMChecked, setIsMChecked] = useState(true);
  const [isEChecked, setIsEChecked] = useState(false);
  const [isGChecked, setIsGChecked] = useState(false);

  const handleMKeyDown = (event) => {
    if (!isEChecked) {
      if (event.key === "m" || event.key === "M") {
        setIsMChecked(!isMChecked);
      }
    }
  };

  const handleEKeyDown = (event) => {
    if (!isMChecked) {
      if (event.key === "e" || event.key === "E") {
        setIsEChecked(!isEChecked);
      }
    }
  };


  //for tab button behaviour change i want to open the pop up when tab press on traderate
  //default behaviour is tab send focus to next input discount 



  // const handleMclick = () => {
  //   // if(!registerAs){
  //   //   setregisterAs("M");
  //   // }else{
  //   //   alert('coming')
  //   //   setregisterAs("");
  //   // }

  //   if (!isEChecked && !registerAs) {
  //     setIsMChecked(!isMChecked);
  //     setregisterAs("M");
  //   } else {
  //     setregisterAs("");
  //     setIsMChecked(true);
  //     setIsEChecked(false);
  //   }
  // };

  // const handleEclick = () => {
  //   if (!isMChecked) {
  //     setIsEChecked(!isEChecked);
  //   } else {
  //     setIsEChecked(true);
  //     setIsMChecked(false);
  //   }
  // };




  //getting all firm details from the data base;
  const [firmData, setFirmData] = useState([]);
  // if user is updating ME or G in the product list
  const [goodChange, setgoodChange] = useState("");
  const [meregister, setMeregister] = useState("");

  // we are storing something in this varibale using update button to avoid new index creation in object while updating
  // this is for M F G checkbox
  // register as wheter m or e it depends on user
  const [registerAs, setregisterAs] = useState("M");
  const [g, setG] = React.useState("");
  const [actualG, setActualG] = useState("");

  const handleChangeG = (event) => {
    if (isGChecked) {
      setIsGChecked(false);
      setActualG("");
    } else {
      setIsGChecked(true);
      setActualG("G");
    }
    // if (event.key) {
    //   // alert('hi')
    //   if (event.key == "m" || event.key == "M") {
    //     if (actualG == "") {
    //       setActualG("G");
    //     } else {
    //       setActualG("");
    //     }
    //   }
    // } else {
    //   // alert('he')
    //   if (actualG == "") {
    //     setActualG("G");
    //   } else {
    //     setActualG("");
    //   }
    // }
    // } else {
    //   setActualG("");
    // }
  };

  const handleGKeyDown = (event) => {
    if (event.key === "g" || event.key === "G") {
      if (isGChecked) {
        setIsGChecked(false);
      } else {
        setIsGChecked(true);
      }
    }
  };

  // setting for subtotle which we are suign in line no 226;
  let [subCalculate, setSubCalculate] = useState("");
  let [discPercentAmount, setDiscountAmount] = useState("");
  let [FinallTax, setFinallTex] = useState(0);
  let [finalGoodReturn, setfinalGoodReturn] = useState(0);
  let [TotalSumPay, setTotalSumPay] = useState("");
  let [GoodsReturnSum, setGoodsReturnPay] = useState("");
  // for showing model and hiding model
  const [showModal, setShowModal] = React.useState(false);
  const [showModal2, setShowModal2] = React.useState(false);

  //calling this function from productentries.map
  //for every index trade rate qty disc ke liye
  const [everyIndex, setEveryIndex] = useState("");




  const [focusedInput, setFocusedInput] = useState(null);

  const handleFocus = (inputName) => {
    setFocusedInputUpdates('');
    setFocusedInput(inputName);
  };

  const [focusedInputUpdates, setFocusedInputUpdates] = useState(null);

  const handleFocusForUpdate = (inputName) => {
    console.log(inputName)
    setFocusedInputUpdates(inputName);
    setFocusedInput('');
  }

  const handleBlur = () => {
    setFocusedInput(null);
  };


  const handleTabKeyPress = (e) => {
  // alert('coming in tab function');
    if (e.key === 'Tab' && mTradeRateRef.current === document.activeElement) {
      e.preventDefault(); // Prevent default tab behavior
      
      // Show the popup
      setShowModal(function (item) {
       setShowModal(true)
      });
    }
  };



  
  useEffect(() => {
    if (registerAs === "M") {
      setIsMChecked(true);
      setIsEChecked(false);
    } else if (registerAs === "E") {
      setIsEChecked(true);
      setIsMChecked(false);
    }
  }, [registerAs]);

  const handleMclick = () => {
    if (!isEChecked) {
      setIsMChecked(!isMChecked);
      setregisterAs(isMChecked ? "" : "M");
      if (isMChecked) {
        setIsEChecked(false);
      }
    } else {
      setIsEChecked(false);
      setIsMChecked(true);
      setregisterAs("M");
    }
  };


  const handleEclick = () => {
    if (!isMChecked) {
      setIsEChecked(!isEChecked);
      setregisterAs(isEChecked ? "" : "E");
      if (isEChecked) {
        setIsMChecked(false);
      }
    } else {
      setIsMChecked(false);
      setIsEChecked(true);
      setregisterAs("E");
    }
  };


  const handleGclick = () => {
    if (isGChecked) {
      setIsGChecked(false);
    } else {
      setIsGChecked(true);
    }
  };

  //for open box which open while clicking on percentage
  const [onClickedDone, setOnClickedDone] = useState("");

  function openModal(id) {
    let newProdEntries = ProductEntries;
    if (id && id >= 0) {
      setOnClickedDone(id);
      if (ProductEntries[id].batchNo) {
        setBatch(ProductEntries[id].batchNo);
      }
      if (ProductEntries[id].expiry) {
        setexpiry(ProductEntries[id].expiry);
      }
      if (ProductEntries[id].hsn_sac) {
        sethsn_sac(ProductEntries[id].hsn_sac);
      }
      if (ProductEntries[id].mrp) {
        setMrp(ProductEntries[id].mrp);
      }
      if (ProductEntries[id].igst) {
        setigst(ProductEntries[id].igst);
      }
      if (ProductEntries[id].cgst) {
        setcgst(ProductEntries[id].cgst);
      }
      if (ProductEntries[id].sgst) {
        setsgst(ProductEntries[id].sgst);
      }
      if (ProductEntries[id].cess) {
        setcess(ProductEntries[id].cess);
      }
      setShowModal(function (item) {
        return !item;
      });
    } else {
      //  setShowModal(!showModal)
      setShowModal(function (item) {
        return !item;
      });
    }
  }

  function openModalWithEnter(event) {
    if (event.key === "Enter" || event.key === 'Tab') {
      setShowModal(function (item) {
        return !item;
      });
   }
  }

  // for deleting array of object product list
  // for 1 product delete
  function openModal2() {
    setShowModal2(function (item) {
      return !item;
    });
  }

  //firm name and firm id;
  const [firm, setFirm] = React.useState("");
  const [firmId, setFirmId] = useState("");

  //product id check line no 564
  const [ProductId, setProductId] = useState("");

  const [region, setRegion] = React.useState("");
  const [type, setType] = React.useState("");
  const regionSelect = ["Select Region", "Local", "Center"];
  const typeData = ["Select Type", "Cash", "Credit",
  //  "Credit Note"
  ];

  const [localSelect, setLocalSelect] = useState(false);
  const [centerSelect, setCenterSelect] = useState(false);

  function chekingdata(value) {
    for (let i = 1; i < regionSelect.length; i++) {
      if (regionSelect[i] === value) {
        if (value === "Local") {
          setLocalSelect(true);
          setCenterSelect(false);
        } else if (value === "Center") {
          setCenterSelect(true);
          setLocalSelect(false);
        }
      }
    }
  }

  const [invoiceSelect, setInvoiceSelect] = useState(true);
  const [cnRefSelect, setCnRefSelect] = useState(false);
  const [getInvoice, setGetInvoice] = useState([]);

  //calling  Auto generate invoice Api;

  const InvoiceOrCn = async () => {
    try {
      //token
      let token = localStorage.getItem("x-api-key");
      let response = await axios.post(
        "api/user/sell/generateAutoInvoice",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": token,
            "x-api-plan": "SaaS",
          },
        }
      );
      let result = response.data;
      setGetInvoice(result.data);
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  function settingInvoiceAndCN(value) {
    if (value === "Cash") {
      setInvoiceSelect(true);
      setCnRefSelect(false);
      setInvoice(getInvoice.originalInvoice);
    } else if (value === "Credit") {
      setInvoiceSelect(true);
      setCnRefSelect(false);
      setInvoice(getInvoice.originalInvoice);
    } else if (value === "CN") {
      setInvoiceSelect(false);
      setCnRefSelect(true);
      setCnReference(getInvoice.originalCnRef);
    }
  }

  const [date, setDate] = React.useState("");
  const [invoice, setInvoice] = React.useState("");
  const [cnReference, setCnReference] = React.useState("");

  const [address, setAddress] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [gst, setGst] = React.useState("");
  const [DL1, setDL1] = React.useState("");
  const [DL2, setDL2] = React.useState("");

  const [fssai, setFssai] = React.useState("");

  const [state1, setState1] = React.useState("");

  //all the final details of purchase entry
  const [subtotal, setSubtotal] = useState(0);
  const [disAmt, setDisAmt] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [goodReturn, setGoodReturn] = useState(0);
  const [cnVoucher, setCnVoucher] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [grandTotal2, setGrandTotal2] = useState(0);

  // select tag value check line 717

  const [selects, setSelects] = React.useState(false);

  //purchase product entry
  const [unitPack, setUnitPack] = useState(0);
  const [qty, setqty] = useState(0);
  const [free, setFree] = useState(0);
  const [tradeRate, setTradeRate] = useState(0);
  const [discPercent, setDiscount] = useState(0);
  const [amount, setAmount] = useState(0);

  let [batchNo, setBatch] = React.useState("");
  let [expiry, setexpiry] = React.useState("");
  let [mrp, setMrp] = React.useState(0);
  let [hsn_sac, sethsn_sac] = React.useState(0);
  let [netRate, setNetRate] = React.useState(0);
  let [igst, setigst] = useState(0);
  let [cgst, setcgst] = useState(0);
  let [sgst, setsgst] = useState(0);
  let [cess, setcess] = useState(0);





  
  //sell update entry in the coming entry 
  const [unitPackUpdateValue, setUnitPackUpdateValue] = useState("");
  const [qtyUpdateValue, setQtyUpdateValue] = useState("");
  const [freeUpdateValue, setFreeUpdateValue] = useState("");
  const [tradeRateUpdateValue, setTradeRateUpdateValue] = useState("");
  const [discPercentUpdateValue, setDiscountUpdateValue] = useState("");
  const [actualGUpdateValue,setActualGUpdateValue] = useState("");
  const [amountUpdateValue, setAmountUpdateValue] = useState(0);

  const [batchNoUpdateValue, setBatchUpdateValue] = useState("");
  const [expiryUpdateValue, setExpiryUpdateValue] = useState("");
  const [mrpUpdateValue, setMrpUpdateValue] = useState("");
  const [hsnSacUpdateValue, setHsnSacUpdateValue] = useState("");
  const [netRateUpdateValue, setNetRateUpdateValue] = useState("");

  const [igstUpdateValue, setIgstUpdateValue] = useState(0);
  const [cgstUpdateValue, setCgstUpdateValue] = useState(0);
  const [sgstUpdateValue, setSgstUpdateValue] = useState(0);
  const [cessUpdateValue, setCessUpdateValue] = useState(0);




    // net rate calcualte formula start 

// function calculateTradeRate(netRatein, quantityin, taxin) {
//   // Constants
//   console.log('calcualte trade rate based on net rate')
//   console.log('net rate',netRatein)
//   console.log('quantity',quantityin)
//   console.log('tax',taxin)

//   const netRate = parseFloat(netRatein)
//   const quantity = parseFloat(quantityin)
//   const tax = parseFloat(taxin)

//   const discount = 0; // Assuming no discount for this scenario
//   // Solve for tradeRate
//   const tradeRatein = (netRate - (quantity * discount)) / (quantity + (quantity * (tax/ 100)));
//   console.log('tradeRate',tradeRatein)
//   setTradeRate(tradeRatein.toFixed(2))
//   // return tradeRate;
// }

// let tradeRateResult = calculateTradeRate(netRate, quantity, tax);
// console.log("Trade Rate:", tradeRateResult.toFixed(2));



  //for vlaues updat in tax pop up 
  const [showModalUpdateValue,setShowModalUpdateValue] = useState(false);

  const UpdateStateForUpdatedValues  = () => {
    setShowModalUpdateValue(!showModalUpdateValue)
  }


  function openModalUpdateValue(id) {
    console.log("coming in open model for igst cgst sgst where we are storing id for uniqueness",id);
    if (id >= 0) {
      setOnClickedDone(id);
      console.log('coming in the ',id);
      if (ProductEntries[id].batchNo) {
        setBatchUpdateValue(ProductEntries[id].batchNo);
      }
      if (ProductEntries[id].expiry) {
        setExpiryUpdateValue(ProductEntries[id].expiry);
      }
      if (ProductEntries[id].hsn_sac) {
        setHsnSacUpdateValue(ProductEntries[id].hsn_sac);
      }
      if (ProductEntries[id].mrp) {
        setMrpUpdateValue(ProductEntries[id].mrp);
      }
      if (ProductEntries[id].igst) {
        setIgstUpdateValue(ProductEntries[id].igst);
      }
      if (ProductEntries[id].cgst) {
        setCgstUpdateValue(ProductEntries[id].cgst);
      }
      if (ProductEntries[id].sgst) {
        setSgstUpdateValue(ProductEntries[id].sgst);
      }
      if (ProductEntries[id].cess) {
        setCessUpdateValue(ProductEntries[id].cess);
      }
      UpdateStateForUpdatedValues();
    } else {
      UpdateStateForUpdatedValues();
    }
  }

  //for value update in pucrchase entry every array
  //purchase product entry
  const [unitPack2, setUnitPack2] = useState("");
  const [qty2, setqty2] = useState(false);
  const [free2, setFree2] = useState("");
  const [tradeRate2, setTradeRate2] = useState("");
  const [discPercent2, setDiscount2] = useState("");
  const [amount2, setAmount2] = useState("");

  //all product data for displaying product in the input field
  const [productData, setProductData] = useState([]);
  // storing product name in generatedData variable
  const [generatedData, setGeneratedData] = React.useState("");

  const [product, setProductName] = useState("");
  const [category, setCategory] = useState("");
  const [brand, setBrand] = useState("");
  const [description, setDescription] = useState("");
  const [unitpacking, setUnitPacking] = useState("");
  const [hsn_sac_code, setHsn_sac_Code] = useState("");

  // let [ProductEntries, setProductEntries] = useState([]);
  let [firmEntries, setfirmEntries] = useState([]);

  // this function for user's data update if user is updating something then this we be called
  function FormulaTwo(p) {
    console.log("update phase 2 formula")
      // formula one if we are getting free in 10+1 format
      const regex = /(\d+)\+(\d+)/;
      const freeGet = ProductEntries[p].free || "0"

      const match = freeGet.match(regex);
    if (match) {
        // alert('match')
        console.log('match in 10+!=1')
        let x = parseInt(match[1]); // Extracted x value
        let y = parseInt(match[2]); // Extracted y value
        console.log('x:', x);
        console.log('y:', y);
  
        x = parseInt(x);
        y = parseInt(y);
      //  if user select 10+1 like then we are making it in percentage 
        let freeT = (y * 100) / (x + y);
        freeT = freeT.toFixed(2);
        console.log('free trade Free',freeT);
        ProductEntries[p].freeDiscount = freeT;
        x =0
        y =0
       
        // if qty and trader discpernt are empty then use current vlaues from array 
        let subTotal = ProductEntries[p].qty * ProductEntries[p].tradeRate
        // here we are minus the free 10+1 like amount from total amount just like discount
        let FreeDiscountAmount = ( ProductEntries[p].freeDiscount / 100) * subTotal || 0;
        // here we are subtract the free discount amount from subtotal
        let afterFreeDiscountSubTotal =  subTotal  - FreeDiscountAmount
        let DiscountAmount = (ProductEntries[p].discPercent / 100) * afterFreeDiscountSubTotal;
         //here we are subtract the discount amount from the sub total
        let finalAmount = afterFreeDiscountSubTotal - DiscountAmount; 
        
  
        let tax =
        parseFloat(ProductEntries[p].igst) + parseFloat(ProductEntries[p].cess) + parseFloat(ProductEntries[p].sgst) + parseFloat(ProductEntries[p].cgst);
      let totalTax = (finalAmount * tax) / 100;
    
      //for single product
      let singleProductTax = (finalAmount * tax) / 100;
    
      setTotalTax(parseFloat(totalTax));
  
      let final = (totalTax + finalAmount).toFixed(2);
      setAmount(final);
      ProductEntries[p].amount = final;
      ProductEntries[p].productTax = singleProductTax
      ProductEntries[p].freeDiscount = freeT;
      AfterAddingAllValuesCalculationFunction() 
      return ;
      } 
    else{
      console.log('coming in else part')
      // if qty and trader discpernt are empty then use current vlaues from array 
      let data = ProductEntries[p].qty * ProductEntries[p].tradeRate
      let dis =  (ProductEntries[p].discPercent /100 ) * data;
      let AMt = data - dis;
  
      let tax =
      parseFloat(ProductEntries[p].igst) + parseFloat(ProductEntries[p].cess) + parseFloat(ProductEntries[p].sgst) + parseFloat(ProductEntries[p].cgst);
    let totalTax = (AMt * tax) / 100;
  
    //for single product
    let singleProductTax = (AMt * tax) / 100;
  
    setTotalTax(parseFloat(totalTax));
    let final = (totalTax + AMt).toFixed(2);
    setAmount(final);
    ProductEntries[p].amount = final;
    ProductEntries[p].productTax = singleProductTax
    ProductEntries[p].freeDiscount = 0; //if we are not getting 10+1 like values then making taht index empty
    AfterAddingAllValuesCalculationFunction() 
    return ;
    }
    }
  // formula one of the calculation's
  function FormulaOne(key) {
    // formula one if we are getting free in 10+1 format
    if (free) {
      for (let i = 0; i < free.length; i++) {
        if (free[i] === "+") {
          let x = "";
          let y = "";
          for (let i = 0; i < free.length; i++) {
            if (free[i] !== "+") {
              x += free[i];
            } else {
              break;
            }
          }
          for (let i = 0; i < free.length; i++) {
            if (free[i] !== "+") {
              y += free[i];
            } else {
              y = "";
            }
          }
          x = parseInt(x);
          y = parseInt(y);

          let freeT = (y * 100) / (x + y);
          freeT = freeT.toFixed(2);
          let data = qty * tradeRate;
          let data2 = (data * freeT) / 100;
          let data3 = data - data2;
          let dis = (discPercent / 100) * data3;
          let AMt = data3 - dis;
          if (!cess) {
            cess = 0;
          }
          if (!cgst) {
            cgst = 0;
          }
          if (!igst) {
            igst = 0;
          }
          if (!sgst) {
            sgst = 0;
          }
          let tax =
            parseFloat(igst) +
            parseFloat(cess) +
            parseFloat(sgst) +
            parseFloat(cgst);
          let totalTax = (AMt * tax) / 100;

          //for single product
          let singleProductTax = (AMt * tax) / 100;
          setTotalTax(parseFloat(totalTax));

          let final = (totalTax + AMt).toFixed(2);
          setAmount(final);
          if(key ==='testing'){

            handleClosePopup(final/qty);
            return 
          }else if (key === 'production'){
            arrayOfObject(final, singleProductTax,freeT);
          }
          return;
        }
      }
    }

    // formula two if we are not getting value in free like 10+1
    let data = qty * tradeRate;
    let dis = (discPercent / 100) * data;
    let AMt = data - dis;
    if (!cess) {
      cess = 0;
    }
    if (!cgst) {
      cgst = 0;
    }
    if (!igst) {
      igst = 0;
    }
    if (!sgst) {
      sgst = 0;
    }

    let tax =
      parseFloat(igst) + parseFloat(cess) + parseFloat(sgst) + parseFloat(cgst);
    let totalTax = (AMt * tax) / 100;

    //for single product
    let singleProductTax = (AMt * tax) / 100;

    setTotalTax(parseFloat(totalTax));
    let final = (totalTax + AMt).toFixed(2);
    setAmount(final);

    if(key ==='testing'){
      handleClosePopup(final/qty);
      return 
    }else if (key === 'production'){
      arrayOfObject(final, singleProductTax);
    }
    return;
  }















  useEffect(() => {
    getFirmData();
    getProductData();
    InvoiceOrCn();
    sellerDetails();
  }, []);

  // function addNewrow() {}

  const ele = productData
    ? productData.map(function (item) {
        return <tr></tr>;
      })
    : "no data";
  const [updateIndex2, setUpdateIndex2] = useState("");

  function arrayOfObject(props, tax,freeDiscount) {
    let firmObject = {
      firmName: firmId,
      region: region,
      type: type,
      data: date,
      invoice: invoice,
      subtotal: subtotal,
      disAmt: disAmt,
      totalTax: totalTax,
      grandTotal: grandTotal,
      cnVoucher: cnVoucher,
    };
    //!runArrayOfObject
    let ProductObj = {
      productName: generatedData,
      productVerTwoId: ProductId,
      sellType: registerAs,
      returnProduct: actualG,
      unitPack: unitPack,
      productTax: tax,
      freeDiscount : freeDiscount ? freeDiscount : 0,
      qty: parseInt(qty),
      qty: qty,
      free: free,
      tradeRate: tradeRate,
      discPercent: discPercent,
      amount: props,
      batchNo: batchNo,
      expiry: expiry,
      mrp: mrp,
      hsn_sac: hsn_sac,
      netRate: netRate,
      igst: parseFloat(igst),
      sgst: parseFloat(sgst),
      cess: parseFloat(cess),
      cgst: parseFloat(cgst),
    };

    ProductEntries.push(ProductObj);
    setProductEntries([...ProductEntries]);
    AfterAddingAllValuesCalculationFunction();
  }

  function AfterAddingAllValuesCalculationFunction() {
    let tax = 0;
    let subCalculate = 0;
    let discPercentAmount = 0;
    let FreeDiscountPercentage = 0;
    let finalGoodsReturn = 0;

    for (let i = 0; i < ProductEntries.length; i++) {
        const entry = ProductEntries[i];

        if (entry.returnProduct === "G") {
            const amount = parseFloat(entry.amount);

            if (!isNaN(amount)) {
                finalGoodsReturn += amount;
                console.log('Amount:', amount.toFixed(2));
                finalGoodsReturn = parseFloat(finalGoodsReturn.toFixed(2));
                console.log("Final Good Return:", finalGoodsReturn);
                setGoodReturn(finalGoodsReturn);
            } else {
                console.error('Error: Unable to parse amount');
            }
        }

        if (!entry.returnProduct) {
          tax += parseFloat(entry.productTax) || 0;
          subCalculate += entry.tradeRate * entry.qty || 0;
          let data = entry.qty * entry.tradeRate || 0;
          
          // Calculate FreeDiscountPercentage first
          FreeDiscountPercentage += (entry.freeDiscount / 100) * data || 0;
          console.log("FreeDiscountPercentage",FreeDiscountPercentage,entry.freeDiscount)
          // Calculate discPercentAmount using the updated data (after FreeDiscountPercentage calculation)
          let dataAfterFreeDiscount = data - FreeDiscountPercentage || 0;
          discPercentAmount += (entry.discPercent / 100) * dataAfterFreeDiscount || 0;
          console.log('discPercentAmount',discPercentAmount)
          data = 0;
        }
    }

    setTotalTax(parseFloat(tax.toFixed(2)));
    setSubtotal(parseInt(subCalculate));
    setDisAmt(parseFloat(discPercentAmount + FreeDiscountPercentage).toFixed(2));
    setGoodReturn(finalGoodsReturn);
    let TotalSumPay = 0;
    let GoodsReturnSum = 0;

    for (let i = 0; i < ProductEntries.length; i++) {
        const entry = ProductEntries[i];

        if (!entry.returnProduct) {
            let v = parseFloat(entry.amount).toFixed(2);
            TotalSumPay += parseFloat(v);
        }

        if (entry.returnProduct) {
            let v = parseFloat(entry.amount).toFixed(2);
            GoodsReturnSum += parseFloat(v);
        }
    }

    let sum = TotalSumPay - GoodsReturnSum;
    console.log("Sum TotalSumPay - GoodsReturnSum:", sum);
    // setGrandTotal(sum);
    let val =0
    if(cnVoucher){
      val = parseFloat(cnVoucher);
    }
    setGrandTotal(sum+val);
}


  function cnVoucherFunction(props) {
    var minus = grandTotal - props[0];

    // setGrandTotal2(minus);
    // totalGrand(minus);
  }
  // setting actual data for user
  function totalGrand(minus) {
    // setGrandTotal2(minus);
  }

  // getting all firm details from data base
  const getFirmData = async () => {
    try {
      //token
      let token = localStorage.getItem("x-api-key");
      let response = await axios.get("api/user/firm/getAllFirmDetails", {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": token,
          "x-api-plan": "SaaS",
        },
      });
      let result = response.data;
      if (result.status === false) {
        setFirmData([]);
      } else {
        setFirmData(result.data);
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  // getting all the product details from the data base;

  const getProductData = async () => {
    try {
      //token
      let token = localStorage.getItem("x-api-key");
      let response = await axios.get("api/user/addProductVertTwo/getAllProducts", {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": token,
          "x-api-plan": "SaaS",
        },
      });
      let result = response.data;
      console.log('getting product name in sell entry',result);
      setProductData(result.data);
      setViewedProducts(result.data);
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  const [prodIdArray, setProdIdArray] = useState([]);

  // // code for filtering firm according to search bar;
  // const [filteredProdList, setFilteredProdList] = new useState(productData);
  // const [filterProd, setFilterProd] = useState(false);

  // const filterProdListSearch = (event) => {
  //   setFilterProd(true);
  //   let updatedList = [...productData];
  //   updatedList = updatedList.filter((item) => {
  //     // we can add one more field to search like invoice number;
  //     return (
  //       item.productName
  //         .toLowerCase()
  //         .indexOf(event.target.value.toLowerCase()) !== -1 ||
  //       item.brand.toLowerCase().indexOf(event.target.value.toLowerCase()) !==
  //         -1
  //     );
  //   });
  //   setFilteredProdList(updatedList);
  // };

  const [viewedProducts, setViewedProducts] = useState(productData); // Initialize with original data

  const filterProdListSearch = (event) => {
    const userInput = event.target.value.toLowerCase().trim();
  
    if (userInput === '') {
      setViewedProducts(productData); // Show original data when the search query is empty
    } else {
      const updatedList = productData.filter(
        (item) =>
          item.productName.toLowerCase().includes(userInput) ||
          item.brand.toLowerCase().includes(userInput)
      );
      setViewedProducts(updatedList); // Show filtered data
    }
  };
  
  // Function to reset filter and clear search query
  const resetFilter = () => {
    setViewedProducts(productData); // Reset to show original data
  };
  

  const emptyProductSearch = () => {
    setProdIdArray([]);
    // setProduct_Data([]);
  };

  //Getting Recent Invoice details of selected firm
  const [invoiceHistory, setInvoiceHistory] = useState({});
  const [selectedFirmId, setSelectedFirmId] = useState("");

  const handleSelectedFirmId = (id) => {
    setSelectedFirmId((id) => {
      return id;
    });
    setButton(true);
    setShowProductSellHistory(false);
    setShowProductHistory(false);
    getInvoiceHistory(id);
  };

  // invoice history of a firm with the particular seller after selecting a firm on prseeing button select a firm.

  const [selectedFirmInvoiceHistory, setSelectedFirmInvoiceHistory] = useState(
    []
  );
  const getInvoiceHistory = async (selectedFirmId) => {
    try {
      //token
      let token = localStorage.getItem("x-api-key");

      let response = await axios.get(
        `api/user/sell/invoiceSList/${selectedFirmId}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": token,
            "x-api-plan": "SaaS",
          },
          // method: "get",
        }
      );
      let result = response.data;
      console.log(result);
      setSelectedFirmInvoiceHistory(result.data);
    } catch (error) {
      console.log(error.response.data.message);
    }
  };
  // history of product for seller to see from where he bought selected product and other details.
  const [productHistory, setProductHistory] = useState([]);
  const [showProductHistory, setShowProductHistory] = useState(false);

  const [purchaseHistoryNetRate,setPurchaseHistoryNetRate] = useState('');
  const [sellHistoryQty,setSellhistoryQty] = useState('');

  const [isLoss,setIsLoss] = useState('');
  const [NoLossNoProfit,setNoLossNoProfit] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  const proceedButtonRef = useRef(null);
  const cancelButtonRef = useRef(null);

  const handleClosePopup = (sellingNetRate) => {
    // alert('working');
    let flag = false;
    const isLoss = sellingNetRate < purchaseHistoryNetRate;
    let displayPopup = parseFloat(purchaseHistoryNetRate) == parseFloat(sellingNetRate.toFixed(2));
  console.log('purchaseHistoryNetRate',purchaseHistoryNetRate );
  console.log('sellingNetRate ',sellingNetRate)
    if(isLoss){
      flag=true;
      setIsLoss(isLoss);
    }else if(displayPopup){
      flag = true;
      setNoLossNoProfit(true);
    }
 
    if(flag){
      // alert(amount/qty);
      console.log('bina use state ke ',sellingNetRate)
      // alert(purchaseHistoryNetRate);
    setShowPopup(true);
    }else{
      FormulaOne('production');
      setIsEChecked(false); 
      // setIsMChecked(false);
      setIsGChecked(false);
      setActualG("");
      // setregisterAs("");
    }
  };


  
  const handleCancelKeyDown = (event) => {
    if (event.key === 'ArrowRight') {
      event.preventDefault();
      if (proceedButtonRef.current) {
        proceedButtonRef.current.focus();
      }
    }

    if (event.key === 'ArrowLeft') {
      event.preventDefault();
      if (cancelButtonRef.current) {
        cancelButtonRef.current.focus();
      }
    }
  };



  const productBuyingHistory = async (e, selectedProductId) => {
    try {
      //token
      // console.log(selectedProductId);
      setShowProductHistory(true);
      setButton(false);
      setShowProductSellHistory(false);
      let token = localStorage.getItem("x-api-key");
      // console.log(token);
      let response = await axios.post(
        `api/user/purchase/firmListByProduct/${selectedProductId}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": token,
            "x-api-plan": "SaaS",
          },
        }
      );
      let result = response.data;
      console.log('product history in sell data is here ',result.data);
      setProductHistory(result.data);
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  const productBuyingHistoryWithEnter = async (e, selectedProductId) => {
    try {
      //token
      // console.log(selectedProductId);
      if (e.key == "Enter") {
        setShowProductHistory(true);
        setButton(false);
        setShowProductSellHistory(false);
        let token = localStorage.getItem("x-api-key");
        // console.log(token);
        let response = await axios.post(
          `api/user/purchase/firmListByProduct/${selectedProductId}`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": token,
              "x-api-plan": "SaaS",
            },
          }
        );
        let result = response.data;

        setProductHistory(result.data);

        // console.log(selectedFirmInvoiceHistory);
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  const setTaxValues = (item) => {
    setigst(item.igst);
    setcgst(item.cgst);
    setsgst(item.sgst);
    setcess(item.cess);
    setBatch(item.batchNo);
    setexpiry(item.expiry);
    // setMrp(item.mrp);
    // setqty(item.qty)
    // setFree(item.free)
    // setTradeRate(item.tradeRate)
    // setDisAmt(item.discPercent)
  };


  //setting selected firmname in the select box
  //setting firmid to the variable to send the selected firm id in the backend
  function handleChange(e) {
    const enteredName = e.target.value;
    setFirm(enteredName);
    console.log(1388, enteredName);

    for (let i = 0; i < firmData.length; i++) {
      if (firmData[i].firmName == enteredName) {
        console.log("firmData[i].firmName", firmData[i].id);
        setFirmId(firmData[i].id);
        console.log("firmId", firmId);
      }
    }
    for (let i = 0; i < firmData.length; i++) {
      if (firmData[i].firmName === enteredName) {
        setAddress(firmData[i].fullAdd);
        setEmail(firmData[i].email);
        setMobile(firmData[i].mobileNo);
        setGst(firmData[i].gst);
        setDL1(firmData[i].dl1);
        setDL2(firmData[i].dl2);
        setFssai(firmData[i].fssai);
        console.log(1423, firmData[i].state);
      }
    }
  }

  //Delete Array of Object

  function DeleteArrayOfObject(index) {
    // ProductEntries.splice(index, 1);
    let newProdEntries = ProductEntries;
    newProdEntries.splice(index, 1);
    setProductEntries(newProdEntries);
    openModal2();
    AfterAddingAllValuesCalculationFunction();
  }

  // update product functioning;


  
function calculateTradeRate(netRatein, quantityin, taxin,index) {

 
  // Constants
  // console.log('calcualte trade rate based on net rate')
  // console.log('net rate',netRatein)
  // console.log('quantity',quantityin)
  // console.log('tax',taxin)

  const netRate = parseFloat(netRatein)
  const quantity = parseFloat(quantityin)
  const tax = parseFloat(taxin)
  const discount = 0; 

  console.log('onclick done ',onClickedDone);
  console.log('index coming ',index);
  //if we are getting changes from the array of object
  if(onClickedDone == index){
    // alert('coming')
    const quantity = parseFloat(ProductEntries[index].qty);
    // const tradeRatein = (netRate - ( quantity* discount)) / (quantity + (quantity * (tax/ 100)));
    const  tradeRatein = (netRate - (quantity * discount)) / (quantity + (quantity * (tax/ 100)));
   
    //to show update trade rate in arrya of object in that index
    setUpdateState(true);
    setTradeRateUpdateValue(tradeRatein.toFixed(2))

    ProductEntries[index].tradeRate = tradeRatein.toFixed(2);
    ProductEntries[index].discPercent = "0"
    ProductEntries[index].free = "0"

    setDiscount2("");
    // discPercentUpdateValue
    setDiscountUpdateValue("")
    setFreeUpdateValue("")
    setFree2("")
   

    console.log(ProductEntries[index].discount)
    console.log(ProductEntries[index].free)

    console.log('disocunt 2 ',discPercent2)
    console.log('free 2 ',free2)

    console.log('product entries',ProductEntries)

    return
  }else{
  // Solve for tradeRate
  if(!quantityin){
    alert('Please Enter Quantity');
    setTradeRate(0);
    setNetRate(0)
    return
  }
  const tradeRatein = (netRate - (quantity * discount)) / (quantity + (quantity * (tax/ 100)));
  console.log('tradeRate',tradeRatein)
  setTradeRate(tradeRatein.toFixed(2))
  setDiscount("");
  setFree("")
  return
  }
  // return tradeRate;
}


  function updateArrayofObject(p) {
    // console.log("aa gaya me to andar");
    // console.log(1393, p, onClickedDone, everyIndex);
    // if(onClickedDone != p && everyIndex != p) return;
    if (everyIndex == p) {
      console.log("every index", everyIndex);
      console.log(p);
     
      if (qtyUpdateValue && qty2 == p)  {
        ProductEntries[p].qty = qtyUpdateValue;
        console.log(qty);
      }
      if( ProductEntries[p].qty == 0){
        handleShowWarning('Please Enter Quantity Greater Than Zero');
      }
      if (unitPackUpdateValue && unitPack2 == p) {
        ProductEntries[p].unitPack = unitPackUpdateValue;
      }
      if (freeUpdateValue &&  freeUpdateValue.length > 0 &&  free2 == p) {
        ProductEntries[p].free = freeUpdateValue;
      }
      if (tradeRateUpdateValue && tradeRate2 == p) {
        ProductEntries[p].tradeRate = tradeRateUpdateValue;
      }

      if(ProductEntries[p].tradeRate == 0  && netRateUpdateValue == 0){
        handleShowWarning('Please Enter Trade Rate Greater Than Zero');
        // return;
      }

      if (discPercentUpdateValue && discPercent2 == p) {
        ProductEntries[p].discPercent = discPercentUpdateValue;
      }

      if (goodChange) {
        if(actualGUpdateValue && actualGUpdateValue != "G"){
          alert('For Good Return Please Enter Capital G');
          setActualGUpdateValue("");
          return
        }else{
        ProductEntries[p].returnProduct = actualGUpdateValue;
      }
    }
      if (meregister) {
        ProductEntries[p].purchaseType = registerAs;
      }
      if (generatedData) {
        ProductEntries[p].ProductName = generatedData;
      }
      FormulaTwo(p);
      // return
    }
    //
    console.log("update array of object ka index plus index");
    console.log(onClickedDone, p);
    if (onClickedDone == p) {
      console.log("onclick done", p);

      if (igstUpdateValue) {
        ProductEntries[p].igst = igstUpdateValue;
      }
      if (cgstUpdateValue) {
        ProductEntries[p].cgst = cgstUpdateValue;
      }
      if (sgstUpdateValue) {
        ProductEntries[p].sgst = sgstUpdateValue;
      }
      if (cessUpdateValue) {
        ProductEntries[p].cess = cessUpdateValue;
      }
      //
      if (batchNoUpdateValue) {
        ProductEntries[p].batchNo = batchNoUpdateValue;
      }
      if (expiryUpdateValue) {
        ProductEntries[p].expiry = expiryUpdateValue;
      }
      if (hsnSacUpdateValue) {
        ProductEntries[p].hsn_sac = hsnSacUpdateValue;
      }
      if (mrpUpdateValue) {
        ProductEntries[p].mrp = mrpUpdateValue;
      }
      FormulaTwo(p);
      return
    } else {
      // alert('else return')
      return;
    }
  }
  /// sending sells data to backend;

  //show and hide save button on response
  const [showSaveButton,setShowSaveButton] = useState(true);

  const [getIds, setGetIds] = useState("");

  const sendSalesData = async () => {
    try {

      setShowSaveButton(false)

      console.log('Sending Sales',{
        firmName: firm,
        firmId: firmId,
        region: region,
        type: type,
        date: date,
        invoiceNo: invoice,
        subTotal: subtotal,
        discAmt: disAmt,
        totalTax: totalTax,
        grandTotal: grandTotal,
        cnVoucher: cnVoucher,
        productEntries: ProductEntries,
      })

     
      //token
      let taxCgst = 0;
      let taxSgst = 0;
      let taxIgst = 0;
      for (let i = 0; i < ProductEntries.length; i++) {
        let curr = ProductEntries[i];
        if (region == "Local") {
          taxCgst = taxCgst + curr.cgst;
          taxSgst = taxSgst + curr.sgst;
        } else if (region == "Center") {
          taxIgst = taxIgst + curr.igst;
        }
      }
      let token = localStorage.getItem("x-api-key");
      let response = await axios.post(
        "api/user/sell/Sell",
        {
          firmName: firm,
          firmId: firmId,
          region: region,
          type: type,
          date: date,
          invoiceNo: invoice,
          subTotal: subtotal,
          discAmt: disAmt,
          totalTax: totalTax,
          grandTotal: grandTotal,
          cnVoucher: cnVoucher,
          productEntries: ProductEntries,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": token,
            "x-api-plan": "SaaS",
          },
        }
      );

      let result = response.data;
      setGetIds(result);
      console.log(1585, result);
      if (result.message === "created successfully") {
        setShowSaveButton(true)
        Swal.fire("Genereted!", "Your SalesEntry has been Created.", "success");
      } else {
        setShowSaveButton(true)
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: result.message,
        });
      }
    } catch (error) {
      setShowSaveButton(true)
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response.data.message,
      });
      console.error(error.response.data.message);
    }
  };

  const [button, setButton] = React.useState(false);
  // for updating producct list in purchase entry
  const [updateProductListName, setUpdateProductListName] = useState(false);

  // ---------------------- showing data in comments --------------------- //

  // const [generatedData, setGeneratedData] = React.useState("");
  // setting for output of product list
  // setting product id here
  // product name for showing product sell history to the selected firm

  const [prductNameforSellHistory, setPrductNameforSellHistory] = useState("");

  const handleProductNameForSellHistory = (value) => {
    setPrductNameforSellHistory(value);
    setPrductNameforSellHistory((value) => {
      return value;
    });
    // console.log(value);
  };

  const [SelectProductResultData, setSelectProductResultData] = useState([]);

  const [showProductSellHistory, setShowProductSellHistory] = useState(false);

  const generateData = async (selectedProductName) => {
    try {
      if (!selectedProductName) {
        return setSelectProductResultData([]);
      }
      // alert('coming inside this one ')
      console.log('on m e g click line no 1592')
      setShowProductHistory(false);
      setShowProductSellHistory(true);
      setButton(false);
      setGeneratedData(selectedProductName);
      console.log(1630, selectedProductName);
      let newProductId;
      console.log(productData);

      for (let i = 0; i < productData.length; i++) {
        if (productData[i].productName === selectedProductName) {
          //product id setting
          setProductId(productData[i].id);
          newProductId = productData[i].id;
          setProductName(productData[i].productName);
          setCategory(productData[i].category);
          setBrand(productData[i].brand);
          setDescription(productData[i].description);
          setUnitPacking(productData[i].unitPacking);
          setHsn_sac_Code(productData[i].hsn_sac_code);
        }
      }
      // console.log(selects);
      // setSelects(!selects);
      // console.log(1143,newProductId,ProductId,firmId);
      //token
      flipModal();
      let token = localStorage.getItem("x-api-key");
      let response = await axios.post(
        "api/user/sell/firmListWithProduct",
        {
          productVerTwoId: newProductId,
          firmNameId: firmId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": token,
            "x-api-plan": "SaaS",
          },
        }
      );
      let resultTable = response.data;
      console.log(resultTable);
      if (resultTable.status === 200) {
        setSelectProductResultData(resultTable.data);
      } else {
        setSelectProductResultData([]);
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

 

  function flipModal() {
    setSelects(!selects);
  }
  // COMMENTS TABLE
  function CommentsTable(props) {
    return (
      <div className="total--empty">
        <div className="save-print-view--button">
         {showSaveButton && <button
            className="save-button"
            onClick={sendSalesData}
            onKeyDown={handleEnter}
            ref={bSaveButtonRef}
          
          >
            Save (S)
          </button>}
          <button
            className="print-button"
            onKeyDown={(event) => {
              handleEnter(event);
              handlePShowPrintOptions(event);
            }}
            ref={bPrintButtonRef}
            onClick={(event) => {
              handleViewInvoice(
                checkedMainSalesValue,
                checkedEstimateSalesValue,
                true
              );
            }}
          >
            Print (P)
          </button>
          <button
            className="view-button"
            onKeyDown={(event) => {
              handleEnter(event);
              handleOpenInvoice(event);
              handleVOpenSalesInvoice(event);
            }}
            ref={bViewButtonRef}
            onClick={(event) => {
              // handleViewInvoice()
              // handleOpenInvoice(event);
              // handleOpenSalesInvoice(event);
              handleShowPrintOptions(event);
              // handleOpenSalesInvoice_2(event);
              // handleOpenInvoice_2(event)
            }}
          >
            View (V)
          </button>
        </div>
      </div>
    );
  }

  // modal firm values setting
  function handleOnClickModalFirmChange(event, e) {
    // console.log(firmData);
    // console.log(580, event, e);

    const enteredName = e;

    // console.log(e);
    setFirm(enteredName);

    for (let i = 0; i < firmData.length; i++) {
      if (firmData[i].firmName === e) {
        setFirmId(firmData[i].id);
        setAddress(firmData[i].fullAdd);
        setEmail(firmData[i].email);
        setMobile(firmData[i].mobileNo);
        setGst(firmData[i].gst);
        setDL1(firmData[i].dl1);
        setDL2(firmData[i].dl2);
        setFssai(firmData[i].fssai);
        setState1(firmData[i].state);
      }
    }

    closeFirmModal();
    emptyFirmSearch();
  }

  function handleModalFirmChange(event, e) {
    // console.log(firmData);
    // console.log(580, event, e);
    if (event.keyCode === 13) {
      const enteredName = e;

      // console.log(e);
      setFirm(enteredName);

      for (let i = 0; i < firmData.length; i++) {
        if (firmData[i].firmName === e) {
          setFirmId(firmData[i].id);
          setAddress(firmData[i].fullAdd);
          setEmail(firmData[i].email);
          setMobile(firmData[i].mobileNo);
          setGst(firmData[i].email);
          setDL1(firmData[i].dl1);
          setDL2(firmData[i].dl2);
          setFssai(firmData[i].fssai);
          setState1(firmData[i].state);
        }
      }

      closeFirmModal();
      emptyFirmSearch();
    }
  }
  // =====================Search firm modal logic======================== //

  // const [filteredList, setFirm_Data] = useState([]);

  const [idArray, setIdArray] = useState([]);
  const [filter, setFilter] = useState(false);

  // code for filtering firm according to search bar;
  const [filteredList, setFilteredList] = new useState(firmData);
  const handleFirmChange = (event) => {
    setFilter(true);
    let updatedList = [...firmData];
    updatedList = updatedList.filter((item) => {
      // we can add one more field to search like invoice number;
      return (
        item.firmName
          .toLowerCase()
          .indexOf(event.target.value.toLowerCase()) !== -1
      );
    });
    console.log("firm updatedList", updatedList);
    setFilteredList(updatedList);
  };

  const emptyFirmSearch = () => {
    setIdArray([]);
    // setFirm_Data([]);
    // setSelectedFirm('')
  };

  // ==================invoice receipt logic ===========================//
  //its for main invoice
  const [showInvoice, setShowInvoice] = useState(false);
  // its for estimate invoice
  const [showInvoiceForEst, setShowInvoiceForEst] = useState(false);

  const handleOpenInvoice = (event) => {
    setShowInvoice(true);
  };

  const handleOpenInvoice_2 = (event) => {
    setShowInvoiceForEst(true);
  };

  const handleVOpenInvoice = (event) => {
    if (event.key == "V" || event.key == "v") {
      setShowInvoice(true);
    }
  };

  const handleCloseInvoice = (event) => {
    setShowInvoice(false);
  };

  //========================= Sale Invoice Logic==========================//
  // this is for estimates invpopice
  const [showSalesInvoiceForEst, setShowSalesInvoiceForEst] = useState(false);

  // this is for main invoice
  const [showSalesInvoice, setShowSalesInvoice] = useState(false);

  const handleOpenSalesInvoice = (event) => {
    setShowSalesInvoice(true);
  };

  const handleOpenSalesInvoice_2 = (event) => {
    setShowSalesInvoiceForEst(true);
  };

  const handleVOpenSalesInvoice = (event) => {
    if (event.key == "V" || event.key == "v") {
      setShowSalesInvoice(true);
    }
  };

  const handleCloseSalesInvoice = (event) => {
    setShowSalesInvoice(false);
  };
  // =========================Print Modal Code ===============================//
  const [showPrintOptions, setShowPrintOptions] = useState(false);
  const [showMainSalesInvoice, setshowMainSalesInvoice] = useState(false);
  const [showMainInvoiceReceipt, setshowMainInvoiceReceipt] = useState(false);
  const [showEstimateSalesInvoice, setshowEstimateSalesInvoice] =
    useState(false);
  const [showEstimateInvoiceReceipt, setshowEstimateInvoiceReceipt] =
    useState(false);

  // open functions
  const handleShowPrintOptions = () => {
    setShowPrintOptions(!showPrintOptions);
  };

  const handlePShowPrintOptions = (event) => {
    if (event.key == "p" || event.key == "P") {
      setShowPrintOptions(true);
    }
  };

  const handleMainSalesInvoice = () => {
    setshowMainSalesInvoice(!showMainSalesInvoice);
  };

  const handleMainInvoiceReceipt = () => {
    setshowMainSalesInvoice(!showMainInvoiceReceipt);
  };

  const handleEstimateSalesInvoice = () => {
    setshowMainSalesInvoice(!showEstimateSalesInvoice);
  };

  const handleEstimateInvoiceReceipt = () => {
    setshowMainSalesInvoice(!showEstimateInvoiceReceipt);
  };

  // y and n button states and functions

  const [checkedMainSalesValue, setcheckedMainSalesValue] = useState(false);
  const [checkedMainInvoiceValue, setcheckedMainInvoiceValue] = useState(false);
  const [checkedEstimateSalesValue, setcheckedEstimateSalesValue] =
    useState(false);
  const [checkedEstimateInvoiceValue, setcheckedEstimateInvoiceValue] =
    useState(false);

  const handleYNMainSales = (event) => {
    if (event.key == "y" || event.key == "Y") {
      setcheckedMainSalesValue(true);
    } else if (event.key == "n" || event.key == "N") {
      setcheckedMainSalesValue(false);
    }
  };

  const handleYNMainInvoice = (event) => {
    if (event.key == "y" || event.key == "Y") {
      setcheckedMainInvoiceValue(true);
    } else if (event.key == "n" || event.key == "N") {
      setcheckedMainInvoiceValue(false);
    }
  };

  const handleYNEstimateSales = (event) => {
    if (event.key == "y" || event.key == "Y") {
      setcheckedEstimateSalesValue(true);
    } else if (event.key == "n" || event.key == "N") {
      setcheckedEstimateSalesValue(false);
    }
  };

  const handleYNEstimateInvoice = (event) => {
    if (event.key == "y" || event.key == "Y") {
      setcheckedEstimateInvoiceValue(true);
    } else if (event.key == "n" || event.key == "N") {
      setcheckedEstimateInvoiceValue(false);
    }
  };
  // selection for main invoice from confirm choices;
  const handleMainSales = (event) => {
    setcheckedMainSalesValue(!checkedMainSalesValue);
  };

  const handleMainInvoice = (event) => {
    setcheckedMainInvoiceValue(!checkedMainInvoiceValue);
  };
  // selection for estimate invoice from confirm choices;
  const handleEstimateSales = (event) => {
    setcheckedEstimateSalesValue(!checkedEstimateSalesValue);
  };

  const handleEstimateInvoice = (event) => {
    setcheckedEstimateInvoiceValue(!checkedEstimateInvoiceValue);
  };

  // ========================== Escape Key Logic =====================//

  useEffect(() => {
    if (showFirmModal) {
      inputRef.current.focus();
    }
    function handleEscapeKey(event) {
      if (event.keyCode === 27) {
        if (showFirmModal) {
          closeFirmModal();
        }
        if (showProductModal) {
          closeProductModal();
        }

        switch (event.target) {
          case mSnRef.current:
            bSubTotalBoxRef.current.focus();
            break;
        }
        // its for main invoice
        if (showInvoice) {
          handleCloseInvoice();
        }

        // its for estimate invoice

        if (showInvoiceForEst) {
          handleCloseInvoice();
        }
        // its for main invoice
        if (showSalesInvoice) {
          handleCloseSalesInvoice();
        }
        // this is for estimate
        if (showSalesInvoiceForEst) {
          handleCloseSalesInvoice();
        }

        if (showPrintOptions) {
          handleShowPrintOptions();
        }
      }
    }

    window.addEventListener("keydown", handleEscapeKey);

    return () => {
      window.removeEventListener("keydown", handleEscapeKey);
    };
  }, [
    showFirmModal,
    showProductModal,
    showInvoice,
    showSalesInvoice,
    showPrintOptions,
    showInvoiceForEst,
    showSalesInvoiceForEst,
  ]);

  // Print Logic
  // const handlePrint = (main, estimate) => {
  //   let printContents;
  //   let printContents1;
  //   let printContents2;
  //   let printContents3;
  //   let printContents4;

  //   if (main && estimate) {

  //     // Check if the element with ID "print-section-1" exists
  //     const section1Element = document.getElementById("print-section-1");
  //     if (section1Element) {
  //       printContents1 = section1Element.innerHTML;
  //     }

  //     // Check if the element with ID "print-section-2" exists
  //     const section2Element = document.getElementById("print-section-2");
  //     if (section2Element) {
  //       printContents2 = section2Element.innerHTML;
  //     }

  //     // Check if the element with ID "print-section-3" exists
  //     const section3Element = document.getElementById("print-section-3");
  //     if (section3Element) {
  //       printContents3 = section3Element.innerHTML;
  //     }

  //     // Check if the element with ID "print-section-4" exists
  //     const section4Element = document.getElementById("print-section-4");
  //     if (section4Element) {
  //       printContents4 = section4Element.innerHTML;
  //     }

  //     // Combine the contents of all sections
  //     printContents = `
  //   <div class="print-container">
  //     <div  class="print-content_1">${printContents1}</div>
  //     <div class="print-content_2" style="margin-top: 4rem;">${printContents2}</div>
  //     <div class="print-content_3" style="margin-top: 4rem;>${printContents3}</div>
  //     <div class="print-content_4" style="margin-top: 4rem;">${printContents4}</div>
  //   </div>
  // `;
  //   } else if (estimate) {

  //     // Check if the element with ID "print-section-1" exists
  //     const section3Element = document.getElementById("print-section-3");
  //     if (section3Element) {
  //       printContents1 = section3Element.innerHTML;
  //     }

  //     // Check if the element with ID "print-section-2" exists
  //     const section4Element = document.getElementById("print-section-4");
  //     if (section4Element) {
  //       printContents2 = section4Element.innerHTML;
  //     }

  //     // Print only the "estimate" sections
  //     printContents = `
  //     <div class="print-container">
  //     <div>${printContents3}</div>
  //     <div class="print-content" style="margin-top: 4rem;">${printContents4}</div>
  //     </div>
  // `;
  //   } else if (main) {

  //     // Check if the element with ID "print-section-1" exists
  //     const section1Element = document.getElementById("print-section-1");
  //     if (section1Element) {
  //       printContents1 = section1Element.innerHTML;
  //     }

  //     // Check if the element with ID "print-section-2" exists
  //     const section2Element = document.getElementById("print-section-2");
  //     if (section2Element) {
  //       printContents2 = section2Element.innerHTML;
  //     }

  //     // Print only the "main" sections
  //     printContents = `
  //     <div class="print-container">
  //     <div>${printContents1}</div>
  //     <div class="print-content" style="margin-top: 4rem;">${printContents2}</div>
  //     </div>
  // `;
  //   } else {
  //     // Invalid selection, do nothing
  //     return;
  //   }

  //   // Get the HTML content to print
  //   let printWindow = window.open('', '_blank');
  //   // }
  //   if (printWindow) {
  //     // Set the content to print in the new window
  //     printWindow.document.write(`
  //     <html>
  //     <head>
  //       <title>Print Sales Invoice</title>
  //       <style>
  //         /* Your custom styles for printing */
  //         @media print {
  //           /* Define styles for the main container */
  //           .saleInvoice-container {
  //             margin-top: 50px;
  //             margin-left: 10px;
  //             font-family: Arial, sans-serif;
  //             /* Add other styles as needed */
  //           }

  //           /* Define styles for Seller Details and Buyer Details sections */
  //           .saleInvoice-details {
  //             width: 50%;
  //             display: inline-block;
  //             vertical-align: top;
  //             /* Add other styles as needed */
  //           }

  //           .saleInvoice-details h6 {
  //             margin-bottom: 5px;
  //           }

  //           .saleInvoice-details table {
  //             width: 100%;
  //             border-collapse: collapse;
  //             /* Add other styles as needed */
  //           }

  //           .saleInvoice-details table td {
  //             border: 1px solid #ccc;
  //             padding: 5px;
  //           }

  //           .saleInvoice-headings {
  //             display: flex;
  //             justify-content: space-around;
  //           }

  //           /* Add other custom styles as needed for different elements */

  //           /* Hide elements not needed in the printed version */
  //           .hide-for-print {
  //             display: none;
  //           }
  //           .saleInvoice--costDetails{
  //             display: flex;
  //             justify-content: space-between;
  //           }
  //           .saleInvoice-costDetails {
  //           display: table;
  //           }
  //           .saleInvoice-amountList {
  //           display: table-row;
  //            ]}
  //           .saleInvoice-amounts,
  //          .saleInvoice-rectangle {
  //           display: table-cell;
  //           padding: 5px;
  //          }
  //          /* Add any desired styling here */
  //       .saleInvoice-totalCost {
  //           display: table;
  //           width: 100%;
  //       }
  //       .saleInvoice-innerCostDiv {
  //           display: table-row;
  //       }
  //       .saleInvoice-innerCostDiv label,
  //       .saleInvoice-innerCostDiv input {
  //           display: table-cell;
  //           padding: 5px;
  //       }
  //       .saleInvoice-innerCostDiv label {
  //           font-weight: bold;
  //       }
  //       .saleInvoice-innerCostDiv input {
  //           width: 100%;
  //           box-sizing: border-box;
  //           border: 1px solid #ccc;
  //           padding: 5px;
  //       }
  //       .invoice-firmDetails{
  //           display: flex;
  //           justify-content: space-between;
  //           margin-top: 10px;
  //       }
  //         }
  //       </style>
  //     </head>
  //     <body>
  //     ${printContents}
  //     </body>
  //     </html>
  //   `);

  //     // Close the document after writing to prevent potential resource leaks
  //     printWindow.document.close();
  //     // Print the contents in the new window
  //     printWindow.print();
  //   }
  // };

  // =================//

  function unitpackfunction(e) {
    let v = e;

    for (let i = 0; i < productData.length; i++) {
      if (productData[i].productName == v.target.value) {
        setUnitPack(productData[i].unitPacking);
      }
    }
  }

  // view print api calling;

  let [viewPrint, setViewPrint] = useState({});

  const viewPrintapi = async () => {
    try {
      //token
      let token = localStorage.getItem("x-api-key");

      let response = await axios.post(
        "api/user/sell/viewPrint",
        {
          addSellInvoiceM: getIds.addSellInvoiceM,
          addSellInvoiceE: getIds.addSellInvoiceE,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": token,
            "x-api-plan": "SaaS",
          },
        }
      );
      let result = response.data;
      setViewPrint(result);
      return viewPrint;
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  // I added this code;
  const handleViewInvoice = async (main, estimate, isPrint) => {
    try {
      // Your multiple contents to display in the new tab or window;

      let viewPrint = await viewPrintapi();

      let { viewM, viewE } = viewPrint;

      let mProductdata = [];

      let eProductdata = [];

      if (viewM) {
        mProductdata = viewM.mProductdata;
      }
      if (viewE) {
        eProductdata = viewE.eProductdata;
      }

      const productRowsE = [];

      const productRowsM = [];

      if (mProductdata.length > 0 && main) {
        mProductdata.forEach((item, index) => {
          // If only "main" is true and sellType is "M", add to productRowsM array
          productRowsM.push(`
      <div>
      <tr key="${index}">
        <td className="tdDiv SD" style="border-right: 1px solid #ccc; padding: 3px;">${
          index + 1
        }</td>
        <td className="FD" style="border-right: 1px solid #ccc; padding: 3px;">${
          item.productName
        }</td>
        <td className="FD" style="border-right: 1px solid #ccc; padding: 3px;">${
          item.batchNo
        }</td>
        <td className="FD" style="border-right: 1px solid #ccc; padding: 3px;">${
          item.qty
        }</td>
        <td className="FD" style="border-right: 1px solid #ccc; padding: 3px;">${
          item.free
        }</td>
        <td className="FD" style="border-right: 1px solid #ccc; padding: 3px;">${
          item.tradeRate
        }</td>
        <td className="AD" style="border-right: 1px solid #ccc; padding: 3px;">${
          item.discPercent
        }</td>
        <td className="AD" style="border-right: 1px solid #ccc; padding: 3px;">${
          item.productTax
        }</td>
        <td className="FD" style="border-right: 1px solid #ccc; padding: 3px;">${
          item.netRate
        }</td>
        <td className="FD" style="border-right: 1px solid #ccc; padding: 3px;">${
          item.amount
        }</td>
        <td className="FD" style="border-right: 1px solid #ccc; padding: 3px;">${
          item.mrp
        }</td>
        <td className="FD" style="border-right: 1px solid #ccc; padding: 3px;">${
          item.expiry
        }</td>
      </tr>
      </div>
    `);
        });
      }
      if (eProductdata.length > 0 && estimate) {
        eProductdata.forEach((item, index) => {
          // If only "estimate" is true and sellType is "E", add to productRowsE array
          productRowsE.push(`
      <tr key="${index}">
        <td className="tdDiv SD" style="border-right: 1px solid #ccc; padding: 3px;" >${
          index + 1
        }</td>
        <td className="FD" style="border-right: 1px solid #ccc; padding: 3px;">${
          item.productName
        }</td>
        <td className="FD" style="border-right: 1px solid #ccc; padding: 3px;">${
          item.batchNo
        }</td>
        <td className="FD" style="border-right: 1px solid #ccc; padding: 3px;">${
          item.qty
        }</td>
        <td className="FD" style="border-right: 1px solid #ccc; padding: 3px;">${
          item.free
        }</td>
        <td className="FD" style="border-right: 1px solid #ccc; padding: 3px;">${
          item.tradeRate
        }</td>
        <td className="AD" style="border-right: 1px solid #ccc; padding: 3px;">${
          item.discPercent
        }</td>
        <td className="AD" style="border-right: 1px solid #ccc; padding: 3px;">${
          item.productTax
        }</td>
        <td className="FD" style="border-right: 1px solid #ccc; padding: 3px;">${
          item.netRate
        }</td>
        <td className="FD" style="border-right: 1px solid #ccc; padding: 3px;">${
          item.amount
        }</td>
        <td className="FD" style="border-right: 1px solid #ccc; padding: 3px;">${
          item.mrp
        }</td>
        <td className="FD" style="border-right: 1px solid #ccc; padding: 3px;">${
          item.expiry
        }</td>
      </tr>
    `);
        });
      }

      let content1 = "";
      // Seller and buyer both invoice;

      if (viewM) {
        content1 = `
          <div className="invoiceReceipt-container" style="max-width: 760px; margin: 0 auto; padding: 20px; box-sizing: border-box; font-family: Arial, sans-serif;border: 1px solid #ccc;">
            <div className="invoice-header" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <div className="invoice-main-heading" style={{ fontSize: "18px", fontWeight: "bold" }}>
                <h5>Invoice Receipt</h5>
              </div>
              <div>
                <AiOutlineClose
                  className="invoice-closebtn"
                  size={28}
                  onClick={handleCloseInvoice}
                  style={{ cursor: "pointer", fontWeight: "bold" }}
                  ref={closeInvoiceBtnRef}
                />
              </div>
            </div>

              <div style="margin-top: 20px;" class="invoiceDetails">
                <ul style="list-style-type: none; padding: 0; margin: 0;" class="invoiceList">
                  <li style="margin-bottom: 5px;">Date: ${viewM.date}</li>
                  <li style="margin-bottom: 5px;">Type: ${viewM.type}</li>
                  <li style="margin-bottom: 5px;">Invoice No: ${viewM.properInvoice}</li>
                  <li style="margin-bottom: 5px;">Amount: ${viewM.grandTotal}</li>
                </ul>
              </div>
              <div style="margin-top: 10px; display: flex; justify-content: space-between;">
                <ul style="list-style-type: none; padding: 0; margin: 0;">
                  <li style="margin-bottom: 5px;">For : ${userDetails.firmName}</li>
                  <li  style = "margin-top: 1rem;">Authorised Signatory</li>
                </ul>
                <ul style="list-style-type: none; padding: 0; margin: 0;">
                  <li style="margin-bottom: 5px;">For : ${viewM.firmName}</li>
                  <li  style = "margin-top: 1rem;">Authorised Signatory</li>
                </ul>
              </div>

          </div>
    `;
      }
      // Seller and buyer both reciept;

      let content2 = "";

      if (viewM) {
        content2 = `
        <body>
          <div id="print-section-2" style="max-width: 800px; margin: 0 auto; padding: 20px; box-sizing: border-box; font-family: Arial, sans-serif;">
            <div class="saleInvoice-container" style="margin-bottom: 1rem;">
              <div class="saleInvoice-head" style="margin-bottom: 1rem; font-size : 12px; ">
                <ul class="saleInvoice-headings" style="list-style-type: none; padding: 0; margin: 0; display: flex; justify-content: space-between; align-items: center;">
                  <li>Date: ${viewM.date}</li>
                  <li>Invoice No.: ${viewM.properInvoice}</li>
                  <li class="hide-for-E" style="margin-left: 10px; margin-right: 10px;">
                    <img src=${hegan} alt="hegan logo" style="height: 20px;"></img> hegan.in
                  </li>
                  <li class="hide-for-E" style="margin-left: 10px; margin-right: 10px;">Tax Invoice</li>
                  <li class="hide-for-E" style="margin-left: 10px; margin-right: 10px;">Original For Buyer</li>
                </ul>
              </div>
              <div style="background: white; width: 100%; page-break-inside: avoid;">
                <div class="saleInvoice-tables" style="display: flex;">
                  <table class="saleInvoice-details" style="width: 50%; height: 10px; border-collapse: collapse; font-size:12px">
                    <tbody>
                      <tr>
                        <th class="saleInvoice-tableHeading" colspan="2" style="background-color: #f0f0f0; text-align: center; border: 1px solid #ccc; padding: 8px;">Seller Details</th>
                      </tr>
                      <div>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">Firm Name-</td>
                        <td style="background: white; border: 1px solid #ccc;">${
                          userDetails.firmName
                        }</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">D.L(1)-</td>
                        <td style="background: white; border: 1px solid #ccc;">${
                          userDetails.dl_no_20b
                        }</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">D.L(2)-</td>
                        <td style="background: white; border: 1px solid #ccc;">${
                          userDetails.dl_no_21b
                        }</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">Phone No.-</td>
                        <td style="background: white; border: 1px solid #ccc;">${
                          userDetails.mobile
                        }</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">Address-</td>
                        <td style="background: white; border: 1px solid #ccc;">${
                          userDetails.address
                        }</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">State-</td>
                        <td style="background: white; border: 1px solid #ccc;">${
                          userDetails.state
                        }</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">GST-</td>
                        <td style="background: white; border: 1px solid #ccc;">${
                          userDetails.gst_no
                        }</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">FSSAI-</td>
                        <td style="background: white; border: 1px solid #ccc;">${
                          userDetails.fssai_no
                        }</td>
                      </tr>
                    </tbody>
                  </table>
                  <table class="saleInvoice-details" style="width: 50%; border-collapse: collapse; font-size:12px">
                    <tbody>
                      <tr>
                        <th class="saleInvoice-tableHeading" colspan="2" style="height: 22px; background-color: #f0f0f0; text-align: center; border: 1px solid #ccc; padding: 8px;">Buyer Details</th>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">Firm Name-</td>
                        <td style="background: white; border: 1px solid #ccc;">${
                          viewM.firmName
                        }</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">D.L(1)-</td>
                        <td style="background: white; border: 1px solid #ccc;">${DL1}</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">D.L(2)-</td>
                        <td style="background: white; border: 1px solid #ccc;">${DL2}</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">Phone No.-</td>
                        <td style="background: white; border: 1px solid #ccc;">${mobile}</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">Address-</td>
                        <td style="background: white; border: 1px solid #ccc;">${address}</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">State-</td>
                        <td style="background: white; border: 1px solid #ccc;">${state1}</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">GST-</td>
                        <td style="background: white; border: 1px solid #ccc;">${gst}</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">FSSAI--</td>
                        <td style="background: white; border: 1px solid #ccc;">${fssai}</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">TYPE-</td>
                        <td style="background: white; border: 1px solid #ccc;">${
                          viewM.type
                        }</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="saleInvoice-productDetails" style="background: white; page-break-inside: avoid; margin-top: 20px; font-size:12px">
                <table style="width: 100%; border-collapse: collapse;">
                <thead style="overflowX: auto; font-size:12px">
                <tr>
                <th style="text-align: center; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 8px;">S.No</th>
                <th style="text-align: center; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 8px;">Product</th>
                <th style="text-align: center; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 8px;">Batch</th>
                <th style="text-align: center; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 8px;">Qty</th>
                <th style="text-align: center; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 8px;">Free</th>
                <th style="text-align: center; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 8px;">T.rate</th>
                <th style="text-align: center; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 8px;">Disc%</th>
                <th style="text-align: center; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 8px;">Tax</th>
                <th style="text-align: center; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 8px;">N.Rate</th>
                <th style="text-align: center; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 8px;">Amt</th>
                <th style="text-align: center; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 8px;">MRP</th>
                <th style="text-align: center; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 8px;">EXP</th>
              </tr>
            </thead>
            <tbody style="font-size: 12px; text-align: center;">
              ${productRowsM.join("")}
            </tbody>
          </table>
        </div>
        <div class="saleInvoice-footer" style="margin-top: auto;  page-break-inside: avoid; border-top: 1px solid #ccc; page-break-inside: avoid;">
        </div>
        <div class="saleInvoice-footer" style="background: white; margin-top: 20px;">
          <div class="saleInvoice--costDetails" style="display: flex;font-size: smaller; justify-content: space-between; margin-top: 20px; padding: 10px; page-break-inside: avoid;">
            <div class="saleInvoice-costDetails">
              <div class="taxes-align" style="display: flex; line-height : 2rem; margin-bottom: 1rem;">
                <div class="saleInvoice-amountList" style="width: 80px;">
                  <label class="saleInvoice-amounts" style="font-weight: bold;">CGST</label>
                 <div>
                  <span style = "margin-left: 0rem; border: 2px solid #ccc;">${
                    viewM.cgst
                  }</span>
                 </div>
                </div>

                <div class="saleInvoice-amountList" style="width: 80px;" >
                  <label class="saleInvoice-amounts" style="font-weight: bold;">SGST</label>
                   <div>
                  <span style = "margin-left: 0rem; border: 2px solid #ccc;">${
                    viewM.sgst
                  }</span>
                   </div>
                </div>
                <div class="saleInvoice-amountList" style="width: 80px;">
                  <label class="saleInvoice-amounts" style="font-weight: bold;">IGST</label>
                   <div>
                  <span style = "margin-left: 0rem; border: 2px solid #ccc;">${
                    viewM.igst
                  }</span>
                   </div>
                </div>
                <div class="saleInvoice-amountList" style="width: 80px;" >
                  <label class="saleInvoice-amounts" style="font-weight: bold;">CESS</label>
                   <div>
                  <span style = "margin-left: 0rem; border: 2px solid #ccc;">${
                    viewM.cess ? viewM.cess : 0.0
                  }</span>
                   </div>
                </div>
              </div>

            </div>

           <div class="saleInvoice-totalCost" style="line-height:2rem; page-break-inside: avoid;">
              <div class="saleInvoice-innerCostDiv">
                <label style = "font-weight:bold; margin-right: 10rem">Sub Total</label>
                <span style = "border: 2px solid #ccc;">${viewM.subTotal}</span>
              </div>
              <div class="saleInvoice-innerCostDiv">
                <label style = "font-weight:bold; margin-right: 10rem">Disc Amt.</label>
                <span style = "border: 2px solid #ccc;">${viewM.discAmt}</span>
              </div>
              <div class="saleInvoice-innerCostDiv">
                <label style = "font-weight:bold; margin-right: 10rem">Tax Amt.</label>
                <span style = "border: 2px solid #ccc; margin-left: 6px">${parseFloat(
                  viewM.totalTax
                )}</span>
              </div>
              <div class="saleInvoice-innerCostDiv">
                <label style = "font-weight:bold; margin-right: 10rem">G.R.</label>
                <span style = "border: 2px solid #ccc;margin-left: 33px">${
                  viewM.goodReturnAmount
                }</span>
              </div>
              <div class="saleInvoice-innerCostDiv">
                <label style = "font-weight:bold; margin-right: 10rem">CN Amt.</label>
                <span style = "border: 2px solid #ccc; margin-left: 9px;">${
                  viewM.cnVoucher
                }</span>
              </div>
              <div class="saleInvoice-innerCostDiv">
                <label style = "font-weight:bold; margin-right: 10rem">Payable Amount</label>
                <span style = "border: 2px solid #ccc; position: relative;right: 44px">${
                  viewM.grandTotal
                }</span>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  `;
      }

      let content3 = "";
      if (viewE) {
        content3 = `
          <div className="invoiceReceipt-container" style="max-width: 760px; margin: 0 auto; padding: 20px; box-sizing: border-box; font-family: Arial, sans-serif;border: 1px solid #ccc;">
            <div className="invoice-header" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <div className="invoice-main-heading" style={{ fontSize: "18px", fontWeight: "bold" }}>
                <h5>Invoice Receipt</h5>
              </div>
              <div>
                <AiOutlineClose
                  className="invoice-closebtn"
                  size={28}
                  onClick={handleCloseInvoice}
                  style={{ cursor: "pointer", fontWeight: "bold" }}
                  ref={closeInvoiceBtnRef}
                />
              </div>
            </div>
              <div style="margin-top: 20px;" class="invoiceDetails">
                <ul style="list-style-type: none; padding: 0; margin: 0;" class="invoiceList">
                  <li style="margin-bottom: 5px;">Date: ${viewE.date}</li>
                  <li style="margin-bottom: 5px;">Type: ${viewE.type}</li>
                  <li style="margin-bottom: 5px;">Amount: ${viewE.grandTotal}</li>
                </ul>
              </div>
              <div style="margin-top: 10px; display: flex; justify-content: space-between;">
                <ul style="list-style-type: none; padding: 0; margin: 0;">
                  <li style="margin-bottom: 5px;">For : ${viewE.firmName}</li>
                  <li style = "margin-top: 1rem;">Authorised Signatory</li>
                </ul>
              </div>

          </div>
    `;
      }

      // only buyer reciept;

      let content4 = "";
      if (viewE) {
        content4 = `
        <body>
          <div id="print-section-2" style="max-width: 800px; margin: 0 auto; padding: 20px; box-sizing: border-box; font-family: Arial, sans-serif;">
            <div class="saleInvoice-container" style="margin-bottom: 1rem;">
              <div class="saleInvoice-head" style="margin-bottom: 1rem; font-size : 12px; page-break-inside: avoid;">
                <ul class="saleInvoice-headings" style="list-style-type: none; padding: 0; margin: 0; display: flex; justify-content: space-between; align-items: center;">
                  <li>Date: ${viewE.date}</li>
                  <li class="hide-for-E" style="margin-left: 10px; margin-right: 10px;">Original For Buyer</li>
                </ul>
              </div>
              <div style="background: white; width: 100%; page-break-inside: avoid;">
                <div class="saleInvoice-tables" style="display: flex;">
                  <table class="saleInvoice-details" style="width: 100%; border-collapse: collapse; font-size:12px">
                    <tbody>
                      <tr>
                        <th class="saleInvoice-tableHeading" colspan="2" style="height: 22px; background-color: #f0f0f0; text-align: center; border: 1px solid #ccc; padding: 8px; width : 100%">Buyer Details</th>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">Firm Name-</td>
                        <td style="background: white; border: 1px solid #ccc;">${
                          viewE.firmName
                        }</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">D.L(1)-</td>
                        <td style="background: white; border: 1px solid #ccc;">${DL1}</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">D.L(2)-</td>
                        <td style="background: white; border: 1px solid #ccc;">${DL2}</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">Phone No.-</td>
                        <td style="background: white; border: 1px solid #ccc;">${mobile}</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">Address-</td>
                        <td style="background: white; border: 1px solid #ccc;">${address}</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">State-</td>
                        <td style="background: white; border: 1px solid #ccc;">${state1}</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">GST-</td>
                        <td style="background: white; border: 1px solid #ccc;">${gst}</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">FSSAI--</td>
                        <td style="background: white; border: 1px solid #ccc;">${fssai}</td>
                      </tr>
                      <tr>
                        <td style="background: white; border: 1px solid #ccc;">TYPE-</td>
                        <td style="background: white; border: 1px solid #ccc;">${
                          viewE.type
                        }</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="saleInvoice-productDetails" style="background: white; margin-top: 20px; font-size:12px">
                <table style="width: 100%; border-collapse: collapse;">
                <thead style="overflowX: auto; font-size:12px">
                <tr>
                <th style="text-align: center; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 8px;">S.No</th>
                <th style="text-align: center; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 8px;">Product</th>
                <th style="text-align: center; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 8px;">Batch</th>
                <th style="text-align: center; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 8px;">Qty</th>
                <th style="text-align: center; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 8px;">Free</th>
                <th style="text-align: center; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 8px;">T.rate</th>
                <th style="text-align: center; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 8px;">Disc%</th>
                <th style="text-align: center; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 8px;">Tax</th>
                <th style="text-align: center; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 8px;">N.Rate</th>
                <th style="text-align: center; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 8px;">Amt</th>
                <th style="text-align: center; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 8px;">MRP</th>
                <th style="text-align: center; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 8px;">EXP</th>
              </tr>
            </thead>
            <tbody style="font-size: 12px; text-align: center;">
              ${productRowsE.join("")}
            </tbody>
          </table>
        </div>
        <div class="saleInvoice-footer" style="margin-top: auto; border-top: 1px solid #ccc; page-break-inside: avoid;">
        </div>
        <div class="saleInvoice-footer" style="background: white; margin-top: 20px;">
          <div class="saleInvoice--costDetails" style="display: flex;font-size: smaller; justify-content: space-between; margin-top: 20px; padding: 10px; page-break-inside: avoid;">
            <div class="saleInvoice-costDetails">
              <div class="taxes-align" style="display: flex; line-height : 2rem; margin-bottom: 1rem;">
                <div class="saleInvoice-amountList" style="width: 80px;">
                  <label class="saleInvoice-amounts" style="font-weight: bold;">CGST</label>
                  <span style = "border: 2px solid #ccc;">${viewE.cgst}</span>
                </div>
                <div class="saleInvoice-amountList" style="width: 80px;" >
                  <label class="saleInvoice-amounts" style="font-weight: bold;">SGST</label>
                  <span style = "border: 2px solid #ccc;">${viewE.sgst}</span>
                </div>
                <div class="saleInvoice-amountList" style="width: 80px;">
                  <label class="saleInvoice-amounts" style="font-weight: bold;">IGST</label>
                  <div>
                  <span style = "border: 2px solid #ccc;">${viewE.igst}</span>
                  </div>
                </div>
                <div class="saleInvoice-amountList" style="width: 80px;" >
                  <label class="saleInvoice-amounts" style="font-weight: bold;">CESS</label>
                  <div>
                  <span style = "border: 2px solid #ccc;">${
                    viewE.cess ? viewE.cess : 0.0
                  }</span>
                  </div>
                </div>
              </div>

            </div>

           <div class="saleInvoice-totalCost" style="line-height:2rem; page-break-inside: avoid;">
              <div class="saleInvoice-innerCostDiv">
                <label style = "font-weight:bold; margin-right: 10rem">Sub Total</label>
                <span style = "border: 2px solid #ccc;">${viewE.subTotal}</span>
              </div>
              <div class="saleInvoice-innerCostDiv">
                <label style = "font-weight:bold; margin-right: 10rem">Disc Amt.</label>
                <span style = "border: 2px solid #ccc;">${viewE.discAmt}</span>
              </div>
              <div class="saleInvoice-innerCostDiv">
                <label style = "font-weight:bold; margin-right: 10rem">Tax Amt.</label>
                <span style = "border: 2px solid #ccc; margin-left: 6px">${parseFloat(
                  viewE.totalTax
                )}</span>
              </div>
              <div class="saleInvoice-innerCostDiv">
                <label style = "font-weight:bold; margin-right: 10rem">G.R.</label>
                <span style = "border: 2px solid #ccc;margin-left: 33px">${
                  viewE.goodReturnAmount
                }</span>
              </div>
              <div class="saleInvoice-innerCostDiv">
                <label style = "font-weight:bold; margin-right: 10rem">CN Amt.</label>
                <span style = "border: 2px solid #ccc; margin-left: 9px;">${
                  viewE.cnVoucher
                }</span>
              </div>
              <div class="saleInvoice-innerCostDiv">
                <label style = "font-weight:bold; margin-right: 10rem">Payable Amount</label>
                <span style = "border: 2px solid #ccc; position: relative;right: 44px">${
                  viewE.grandTotal
                }</span>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  `;
      }

      if (!main) {
        content1 = "";
        content2 = "";
      }
      if (!estimate) {
        content3 = "";
        content4 = "";
      }

      if (viewM.type == "Cash" || viewE.type == "Cash") {
        content1 = "";
        content3 = "";
      }

      const combinedContent = `
    <html>
      <head>
        <title>Invoice Receipt</title>
        <style>
          /* Add your CSS styles here */
          body {
            font-family: Arial, sans-serif;
            margin: 20px;
          }
          .saleInvoice-container,
          .invoiceReceipt-container {
            border: 1px solid #ccc;
            padding: 10px;
            margin-bottom: 20px;
            page-break-inside: avoid;
          }
          .saleInvoice-headings {
            display: flex;
            list-style: none;
            padding: 0;
            margin: 0;
          }
          /* Add more styles as needed for other elements */

          /* Define print media styles */
          @media print {
            body {
              /* Adjust print-specific styles for the entire document */
              font-family: Times New Roman, serif;
              margin: 0;
              padding: 0;
            }
            
            .saleInvoice-container,
            .invoiceReceipt-container {
              /* Adjust container styles for print */
              border: none;
              padding: 0;
              margin: 0;
            }
            
            .saleInvoice-headings {
              /* Adjust headings styles for print */
              font-size: 14px;
            }
            
            /* Add more print-specific styles here */
          }
        </style>
      </head>
      <body>
        ${content2}
        ${content1}
        ${content4}
        ${content3}
      </body>
    </html>`;

      //===========I added this ========== //

      if (isPrint) {
        // Print functionality
        const printWindow = window.open("", "_blank");
        printWindow.document.open();
        printWindow.document.write(combinedContent);
        printWindow.document.close();
        printWindow.print();
        printWindow.close();
      } else {
        // View functionality
        const viewWindow = window.open("", "_blank");
        viewWindow.document.open();
        viewWindow.document.write(combinedContent);
        viewWindow.document.close();
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };









  const generateTableRowsData = () => {
    { return  ProductEntries
      ? ProductEntries.map(function (item, index) {
        inputRefs.current[index] = Array.from({ length: 8 }, () => React.createRef());
          return (
            <tr 
            key={index}
            // ref={(ref) => (inputRefs.current[index] = ref)}
            >
               
            <td
              className={
                (index + 1) % 2 === 1 ? "white" : "grey"
              }
            >
              <input 
              value={index+1}
              className="span-width"
              style={{width:60}}
              />
              {/* <span className="span-width">{index + 1}</span> */}
            </td>
                
            <td>
              {/* sdfgh dvfghjkl;lkjhgfghjkl;kjhgfdsfghjkl;lkujyhtgrfertyuioilukyjthrgefwrtyuiouytrewertyuiopiuytrertyuioiuytrertyuioiuytrertyuiouytr*/}
              <input
                value={item.productName}
                // onClick={() => {
                //   setUpdateProductListName(true);
                //   setUpdateIndex(index);
                // }}

                onChange={(e) => {
                  generateData(e);
                  unitpackfunction(e);
                }}
                // className="ProductName"
                onClick={ (event) => {
                  openProductModal(event);
                  // alert('done');
                  setUpdateIndex(index);
                  // generateData(event)
                  // mProductNameRef.current.style.outline = '';
                }}
                // onKeyDown={(e) => handleEnterKey(e, index,0)}
                // ref={(ref) => (inputRefs.current[index] = ref)}
                // ref={inputRefs.current[index][0]}
                // ref={updateProductNameRef}
                ref={inputRefs.current[index][0] }
                onKeyDown={(e) => handleKeyDown(e, index, 0)} 
                // ref={(ref) => setRef(ref, index, 0)} 
                // onFocus={ (event) => {
                //   openProductModal(event)
                //   mProductNameRef.current.style.outline = '';
                // }}
               




                onFocus={() => handleFocusForUpdate(`productName${index}`)}
                style={{ 
                  width: "303px",
                  border: focusedInputUpdates === `productName${index}` ? '2px solid #015998' : '1px solid #ccc',
                 }}
              />
            </td>

            {/* <select
            onChange={generateData}
            className={
              updateProductListName && updateIndex === index
                ? "ProductName"
                : "none"
            }
          >
            Product Name
            <br />
            {productData
              ? productData.map((item, index) => (
                  <option
                    placeholder="First Name"
                    onChange={(e) =>
                      setGeneratedData(e.target.value)
                    }
                  >
                    {item.productName}
                  </option>
                ))
              : "no data"}
          </select> */}

            <td >
              <td>
                <input
                  value={
                    // meregister && updateIndex === index
                    //   ? registerAs
                    //   : 
                    item.sellType
                  }
                  // onChange={(e) => {
                  //   setMeregister("good");
                  //   setregisterAs(e.target.value);
                  //   setUpdateIndex(index);
                  // }}
                  onFocus={() => handleFocusForUpdate(`registerAs${index}`)}
                  style={{ 
                    position: "relative",
                    width:29,
                    border: focusedInputUpdates === `registerAs${index}` ? '2px solid #015998' : '1px solid #ccc',
                  }}

                  // onKeyDown={handleEnterUpdate}
                  // onKeyDown={(e) =>{ handleEnterUpdate(e, index)}}
                  // ref={updateMERef}
                  // onKeyDown={(e) => handleEnterKey(e, index,1)}
                  // ref={(ref) => { 
                  //   updateMERef =ref  && focusedInputUpdates === `registerAs${index}`
                  //   console.log('ref',ref)
                  //   console.log(' inputRefs.current[index]', inputRefs.current[index])
                  //   // alert(ref) 
                  // }}
                  // ref={inputRefs.current[index][1]}
                  // ref={updateMERef}
                  ref={inputRefs.current[index][1] }
                  onKeyDown={(e) => handleKeyDown(e, index, 1)} 
                  // ref={(ref) => setRef(ref, index, 0)} 
                  // ref={(ref) => (inputRefs.current[index][1] = ref.parentNode)}
                />
              </td>
              <td >
                <input
                  className="inputbox-goodsreturn"
                  value={
                    // goodChange && 
                    updateIndex === index
                      ? actualGUpdateValue
                      : item.returnProduct
                  }
                  onChange={(e) => {
                    setgoodChange("good");
                    // setActualG(e.target.value);  ()
                    setEveryIndex(index)            
                    setActualGUpdateValue(e.target.value)
                    setUpdateIndex(index);
                  }}
                  onFocus={() => handleFocusForUpdate(`actualGUpdateValue${index}`)}
                  style={{
                     position: "relative",
                     width:30,
                     border: focusedInputUpdates === `actualGUpdateValue${index}` ? '2px solid #015998' : '1px solid #ccc',
                    }}

                    // onKeyDown={handleEnterUpdate}
                    // onKeyDown={(e) => handleEnterUpdate(e,index)}
                    // ref={updateGRef} 
                    // onKeyDown={(e) => handleEnterKey(e, index,2)}
                    // ref={(ref) => (inputRefs.current[index] = ref)}
                    // ref={updateGRef}
                    // ref={inputRefs.current[index][2]}
                    ref={inputRefs.current[index][2]}
                    onKeyDown={(e) => handleKeyDown(e, index, 2)} 
                    // ref={(ref) => setRef(ref, index, 0)} 
                    // ref={(ref) => (inputRefs.current[index] = ref.parentNode)}
                />
              </td>
            </td>
            <td >
              <input
                value={
                  // unitPackUpdateValue &&
                  unitPack2 == index &&
                  updateState == false
                    ? unitPackUpdateValue
                    : item.unitPack
                }
                onChange={(e) => {
                  setUnitPackUpdateValue(e.target.value);
                  setUpdateIndex(index);
                  setUnitPack2(index);
                  setEveryIndex(index);
                  setUpdateState(false);
                }}
                onFocus={() => handleFocusForUpdate(`unitPackUpdateValue${index}`)}
                style={{
                  border: focusedInputUpdates === `unitPackUpdateValue${index}` ? '2px solid #015998' : '1px solid #ccc',
               }}
              //  onKeyDown={handleEnterUpdate}
              // onKeyDown={(e) =>{ handleEnterUpdate(e, index)}}
              //  ref={updateUnitPackRef}
              // onKeyDown={(e) => handleEnterKey(e, index)}
              // onKeyDown={(e) => handleEnterKey(e, index,3)}
              // ref={(ref) => (inputRefs.current[index] = ref)}
              // ref={updateUnitPackRef}
              // ref={inputRefs.current[index][3]}
              ref={inputRefs.current[index][3]}
              onKeyDown={(e) => handleKeyDown(e, index, 3)} 
              // ref={(ref) => setRef(ref, index, 0)} 
              // ref={(ref) => (inputRefs.current[index] = ref.parentNode)}
                //  onClick={unitPack2.push(index)}
              />
            </td>
            <td>
              <input
                //this is line 816
                value={
                  // qtyUpdateValue &&
                  qty2 === index &&
                  updateState == false
                    ? qtyUpdateValue
                    : item.qty
                }
                onChange={(e) => {
                  setQtyUpdateValue(e.target.value);
                  setqty2(index);
                  setUpdateIndex(index);
                  setEveryIndex(index);
                  setUpdateState(false);
                }}
                onFocus={() => handleFocusForUpdate(`qtyUpdateValue${index}`)}
                style={{
                  border: focusedInputUpdates === `qtyUpdateValue${index}` ? '2px solid #015998' : '1px solid #ccc',
               }}
              //  onKeyDown={(e) => handleEnterKey(e, index,4)}
              //  ref={updateQuantityRef}
              // ref={inputRefs.current[index][4]}
              ref={inputRefs.current[index][4]}
              onKeyDown={(e) => handleKeyDown(e, index, 4)} 
              // ref={(ref) => setRef(ref, index, 0)} 
              //  ref={(ref) => (inputRefs.current[index] = ref)}
              ></input>
            </td>
            <td>
              <input
                value={
                  // freeUpdateValue &&
                  free2 === index &&
                  updateState == false
                    ? freeUpdateValue
                    : item.free
                }
                onChange={(e) => {
                  setFreeUpdateValue(e.target.value);
                  setFree2(index);
                  setUpdateIndex(index);
                  setEveryIndex(index);
                  setUpdateState(false);
                }}
                onFocus={() => handleFocusForUpdate(`freeUpdateValue${index}`)}
                style={{
                  border: focusedInputUpdates === `freeUpdateValue${index}` ? '2px solid #015998' : '1px solid #ccc',
               }}
              //  onKeyDown={(e) => handleEnterKey(e, index,5)}
              //  ref={updateFreeRef}
              // ref={inputRefs.current[index][5]}
              ref={inputRefs.current[index][5]}
              onKeyDown={(e) => handleKeyDown(e, index, 5)} 
              // ref={(ref) => setRef(ref, index, 0)} 
              //  ref={(ref) => (inputRefs.current[index] = ref)}
              />
            </td>
            <td>
              <input
                value={
                  // tradeRateUpdateValue &&
                  tradeRate2 === index &&
                  updateState == false
                    ? tradeRateUpdateValue
                    : item.tradeRate
                }
                onChange={(e) => {
                  setTradeRateUpdateValue(e.target.value);
                  setNetRate(0);
                  setTradeRate2(index);
                  setUpdateIndex(index);
                  setEveryIndex(index);
                  setUpdateState(false);
                }}
                onFocus={() => handleFocusForUpdate(`tradeRateUpdateValue${index}`)}
                style={{
                  border: focusedInputUpdates === `tradeRateUpdateValue${index}` ? '2px solid #015998' : '1px solid #ccc',
               }}
               ref={inputRefs.current[index][6]}
               onKeyDown={(e) => {
                handleKeyDown(e, index, 6);
                if (e.key === 'Enter' && !e.shiftKey || e.key ==='Tab' && !e.shiftKey) {
                  // event.preventDefault();
                openModalUpdateValue(index);
                }
              }} 
              />
            </td>
            <td>
              <input
                value={
                  // discPercentUpdateValue &&
                  discPercent2 === index &&
                  updateState == false
                    ? discPercentUpdateValue
                    : item.discPercent
                }
                onChange={(e) => {
                  setDiscountUpdateValue(e.target.value);
                  setDiscount2(index);
                  setUpdateIndex(index);
                  setEveryIndex(index);
                  setUpdateState(false);
                }}
                onClick={() => {
                  console.log(2586, index,discPercent2);
                  openModalUpdateValue(index);
                }}
                onFocus={() => handleFocusForUpdate(`discPercentUpdateValue${index}`)}
                style={{
                  border: focusedInputUpdates === `discPercentUpdateValue${index}` ? '2px solid #015998' : '1px solid #ccc',
               }}
               ref={inputRefs.current[index][7]}
               onKeyDown={(e) => handleKeyDown(e, index, 7)} 
              />
            </td>
            <td>
            <input
            value ={item.amount}
            />
             </td>
             <td>
            <div className="">
              <button
                // id="arrayofobject-update"
                onClick={(event) => {
                  // alert(index);
                  updateArrayofObject(index);
                }}
                style={{
                  marginTop: "2px",
                  marginLeft: "2.5px",
                  borderRadius: "3px",
                  backgroundColor: "darkgreen",
                  color: "white",
                  width: "63px",
                  height: "30px",
                  display:"flex",
                  alignItems:"center",
                  justifyContent:"center"
                }}

              >
                Update
              </button>
              <button
                onClick={() => {
                  DeleteArrayOfObject(index);
                }}
                style={{
                  marginTop: "0px",
                  borderRadius: "3px",
                  backgroundColor: "red",
                  color: "white",
                  width: "63px",
                  height: "30px",
                  display:"flex",
                  alignItems:"center",
                  justifyContent:"center"
                }}
              >
                Delete
              </button>
            </div>
            </td>
    
          </tr>
          );
        })
      : "no data"}
  }

  return (
    <Layout heading_1="Sale" heading_2="Sale Entry">
      <div className="main--div">
        {/* <SaleEntryHeader /> */}
        {showFirmModal && (     
         
         <>
         <div class="overlay">
          <div id="firmContainer-sales">

        <div className="topSection">
        <h3 className="productText">Firm List</h3>

        <div>
            <input
              className="searchBar2Sales"
              type="text"
              placeholder="Search by Firm Name"
              onChange={(event) => {
                handleFirmChange(event);
              }}
              defaultValue=""
              ref={inputRef}
              autoFocus={true}
            />
          </div>

        <div className="search-closeDivSales">
          <div>
            <AiOutlineClose
              className="iconsDivSales"
              type="button"
              color="whiteSmoke"
              size={23}
              onClick={(event) => {
                closeFirmModal();
                emptyFirmSearch();
              }}
            />
          </div>
        </div>
        </div>
       


                    <div className="table"
                    // style={{backgroundColor:'#015998'}}
                    >
                    <div className="table-row header">
                    <div 
                    className="table-cell"
                    style={{maxWidth:'50px'}}
                    >S.No</div>
                  <div className="table-cell">Firm Name</div>
                  <div className="table-cell">Address</div>
                  <div className="table-cell">Action</div>
                    </div>
                    <div className="table-body">
                      {/* Table Body */}
        {filter ? (
          filteredList.map((item, index) => (
            <div className="table-row" key={index}>
              <div className="table-cell"
              style={{maxWidth:'50px'}}>{index + 1}</div>
            <div className="table-cell">{item.firmName}</div>
            <div className="table-cell">{item.fullAdd}</div>

            <div className="table-cell">
                <button
                  onClick={(event) => {
                    handleOnClickModalFirmChange(event, item.firmName);
                    closeFirmModal();
                    emptyFirmSearch();
                    handleSelectedFirm(item.firmName);
                    handleSelectedFirmId(item.id);
                  }}
                  onKeyDown={(event) => {
                    handleModalFirmChange(event, item.firmName);
                    handleSelectedFirm(item.firmName);
                    handleSelectedFirmId(item.id);
                  }}
                  style={{
                    background: "#015998",
                    color: "whitesmoke",
                    borderRadius: "3px",
                  }}
                  ref={firmNameRef}
                >
                  Select Firm 
                </button>
              </div>
            </div>
          ))
        ) : (
          firmData &&
          firmData.map((item, index) => (
            // <div className="tableRow" key={index}>
                 <div className="table-row" key={index}>
                <div className="table-cell"
                style={{maxWidth:'50px'}}>{index + 1}</div>
                <div className="table-cell">{item.firmName}</div>
                <div className="table-cell">{item.fullAdd}</div>
                <div className="table-cell">
                <button
                  onClick={(event) => {
                    handleOnClickModalFirmChange(event, item.firmName);
                    closeFirmModal();
                    emptyFirmSearch();
                    handleSelectedFirm(item.firmName);
                    handleSelectedFirmId(item.id);
                  }}
                  onKeyDown={(event) => {
                    handleModalFirmChange(event, item.firmName);
                    handleSelectedFirm(item.firmName);
                    handleSelectedFirmId(item.id);
                  }}
                  style={{
                    background: "#015998",
                    color: "whitesmoke",
                    borderRadius: "3px",
                  }}
                  ref={firmNameRef}
                >
                  Select Firm
                </button>
              </div>
            </div>
          ))
        )}
                    </div>
                  </div>
                  
                </div>
            </div>
      </>
        )}


{/* show selected product  */}
        {showProductModal && (
          <div class="overlay">
          <div 
          id="firmContainer-sales"
          >
        
            <div 
            className="belowNavbar"
            >
              <div className="firmListHeadingDiv">
                <h3 className="productText">Product List</h3>
              </div>

              <div className="search-closeDiv">
                <div>
                  <input
                    className="searchBar2"
                    type="text"
                    placeholder="Search by Product Name"
                    onChange={(event) => {
                      filterProdListSearch(event);
                    }}
                    defaultValue=""
                    ref={inputRef}
                    autoFocus={true}
                  />
                </div>
                <div>
                  <AiOutlineClose
                    className="iconsDiv"
                    type="button"
                    color="whiteSmoke"
                    size={23}
                    onClick={(event) => {
                      closeProductModal();
                      resetFilter()
                      emptyProductSearch();
                    }}
                  />
                </div>
              </div>
            </div>

            {/* <div className="productTable"> */}

            <div className="table-Third-pop-up-meg-table"
              style={{
                // width:'32rem',
                overflow:'auto',
              }}
            >
          {/* head  */}
         <div className="Table-header-content">
         <div 
        //  className="table-cell-for-third-table" 
         id="third-table-header-sno">S.No</div>
         <div className="table-cell-for-third-table" id="third-table-header">Product Name</div>
         <div className="table-cell-for-third-table" id="third-table-header">Brand</div>
         <div className="table-cell-for-third-table" id="third-table-header">Category</div>
         <div className="table-cell-for-third-table" id="third-table-header">HSN/SAC Code</div>
         <div className="table-cell-for-third-table" id="third-table-header">Action</div>
      </div>

      <div
      //  className="productsellTableBody"
      >
        {viewedProducts
          && viewedProducts.length > 0 &&  viewedProducts.map(function (item, index) {
              return (
                // <div className="productsellTableRow" key={index}>
                <div className="table-row" key={index}>
                  <div 
                 id="third-table-header-sno-1"   style={{maxWidth:'5rem'}}>{index + 1}</div>
                  <div className="table-cell-for-third-table">{item.productName}</div>
                  <div className="table-cell-for-third-table">{item.brand}</div>
                  <div className="table-cell-for-third-table">{item.category}</div>
                  <div className="table-cell-for-third-table">{item.hsn_sac_code}</div>
                  {/* <div className="table-cell-for-third-table"> */}
                    <button
                    // className="productsellSelectButton"
                    style={{maxWidth:'10rem'}}
                    // className="table-cell-for-third-table"

                      onClick={(e) => {
                        if(  mAfterProuductSelectRef.current){
                          mAfterProuductSelectRef.current.focus();
                        }
                      
                         
                        setProductId(item.id);
                        closeProductModal();
                        emptyProductSearch();
                        handleProductNameForSellHistory(item.productName);
                        setGeneratedData(item.productName);
                        productBuyingHistory(e, item.id);
                        if(updateIndex || updateIndex === 0){
                        ProductEntries[updateIndex].productName = item.productName;
                        ProductEntries[updateIndex].productVerTwoId = item.id;
                        }else{
                          handleSelectedProduct(item.productName);
                        }
                        //here reseting index to reuse same pop for first time and update time 
                        setUpdateIndex(false)
                        //when user click on searched product then we are reseting it 
                        //next time it will be updated
                        resetFilter();
                      }}
                      onKeyDown={(e) => {
                        handleSelectedProduct(item.productName);
                        handleProductNameForSellHistory(item.productName);
                        productBuyingHistoryWithEnter(e, item.id);
                      }}
                    >
                      Select Product
                    </button>
                  {/* </div> */}
                </div>
              );
            })  }
      
      </div>
    </div>

             
            {/* </div> */}
          </div>
          </div>
        )}

        {/* remove it  */}

        {/* remove it till here */}

        {showPrintOptions && (
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: "#015998",
              },
            }}
          >
            {/* the code is given below for choice invoices */}
           <div className="overlay">
            <div className="printInvoiceContainer">
            <div className="overlay">
              <div 
              // style={{height:'10%',width:'10%'}}
              >
              <div className=" printInnerBoxDiv" style={{ height: "53px" }}>
                <div className="printHeadingDiv">
                  <h6>Main Sales Invoice</h6>
                </div>
                <div className="printIconDiv">
                  <Switch
                    onChange={handleMainSalesInvoice}
                    checkedChildren="Y"
                    unCheckedChildren="N"
                    ref={mainSalesInvoiceToggleRef}
                    autoFocus
                    onKeyDown={(event) => {
                      handleEnter(event);
                      handleYNMainSales(event);
                    }}
                    onClick={(event) => {
                      handleMainSales(event);
                    }}
                    checked={checkedMainSalesValue}
                  />
                </div>
              </div>
              {/* <div className=" printInnerBoxDiv" style={{ height: "53px" }}>
              <div className=" printHeadingDiv">
                <h6>Main Invoice Receipt</h6>
              </div>
              <div className="printIconDiv">
                <Switch
                  checkedChildren="Y"
                  unCheckedChildren="N"
                  ref={mainInvoiceToggleRef}
                  onKeyDown={(event) => {
                    handleEnter(event);
                    handleYNMainInvoice(event);
                  }}
                  onClick={(event) => {
                    handleMainInvoice(event);
                  }}
                  checked={checkedMainInvoiceValue}
                />
              </div>
            </div> */}

              {/* <div className=" printInnerBoxDiv" style={{ height: "53px" }}>
                <div className=" printHeadingDiv">
                  <h6>Estimate Sales Invoice</h6>
                </div>
                <div className="printIconDiv">
                  <Switch
                    checkedChildren="Y"
                    unCheckedChildren="N"
                    ref={estimateSalesInvoiceToggleRef}
                    onKeyDown={(event) => {
                      handleEnter(event);
                      handleYNEstimateSales(event);
                    }}
                    onClick={(event) => {
                      handleEstimateSales(event);
                    }}
                    checked={checkedEstimateSalesValue}
                  />
                </div>
              </div> */}

              {/* <div className=" printInnerBoxDiv" style={{ height: "53px" }}>
              <div className=" printHeadingDiv">
                <h6>Estimate Invoice Receipt</h6>
              </div>
              <div className="printIconDiv">
                <Switch
                  checkedChildren="Y"
                  unCheckedChildren="N"
                  ref={estimateInvoiceToggleRef}
                  onKeyDown={(event) => {
                    handleEnter(event);
                    handleYNEstimateInvoice(event);
                  }}
                  onClick={(event) => {
                    handleEstimateInvoice(event);
                  }}
                  checked={checkedEstimateInvoiceValue}
                />
              </div>
            </div> */}

              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  style={{
                    background: "#015998",
                    color: "whitesmoke",
                    borderRadius: "5px",
                  }}
                  onClick={(event) => {
                    handleShowPrintOptions(event);
                    handleViewInvoice(
                      checkedMainSalesValue,
                      checkedEstimateSalesValue,
                      false
                    );
                  }}
                  onKeyDown={(event) => {
                    handleEnter(event);
                  }}
                  ref={confirmChoicesRef}
                >
                  Confirm Choices
                </button>
              </div>
            </div>
          </div>
          </div>
          </div>
          </ConfigProvider>
        )}

        {/* <div className="div-header" style={{ height: "34px" }}>
          <h4>Sale Entry</h4>
        </div> */}

        <div className="first-half-sale">
          <div className="top-two-sale">
            <form className="input-box--sale">
              <div className="input-box-name--sale firmNameDiv">
                <label className="FirmName_Sale" style={{ color: "black" }}>
                  {" "}
                  Firm Name
                </label>

                <input
                  htmlFor="firmName"
                  placeholder="Select Firm Name"
                  onClick={handleChange}
                  className="FirmName"
                  style={{ 
                    width: "25rem",
                    height:'2rem',
                    border: focusedInput === 'FirmName' ? '2px solid black' : '1px solid #ccc',
                  }}
                  onKeyDown={(event) => {
                    handleEnter(event);
                    closeFirmModal();
                  }}
                  onFocus={ (event) =>{ 
                    openFirmModal(event);
                    handleFocus('FirmName')
                  }}
                  ref={firmNameRef}
                  value={selectedFirm}
                />
                {/* Firm Name
                {firmData
                  ? firmData.map((item, index) => (
                      <option type="button" placeholder="First Name">
                        {item.firmName}
                      </option>
                    ))
                  : "no data"} */}
                {/* </input> */}
              </div>

              <div className="input-box-date--sale">
                <label htmlFor="date" style={{ color: "black" }}>
                  Date
                </label>

                <input
                  id="date"
                  type="date"
                  value={date}
                  placeholder="Date"
                  onChange={(e) => setDate(e.target.value)}
                  onKeyDown={handleEnter}
                  onFocus={() => handleFocus('date')}
                  ref={dateRef}
                  style={{
                    marginTop: "8px",
                    boxShadow: "0px 5px 5px 0px rgba(0, 0, 0, 0.2)",
                    borderRadius: "5px",
                    width: "97%",
                    border: focusedInput === 'date' ? '2px solid black' : '1px solid #ccc',
                  }}
                />
              </div>

              <div 
              // className="input-box-region-sale regionDiv"
              style={{
               width: '20%',
               marginLeft:'5%'
              }}
              >
                <label
                //  className="FirmName-sale"
                  style={{ color: "black" }}>
                  {" "}
                  Region 
                </label>
                <select
                  onChange={(e) => {
                    setRegion(e.target.value);
                    chekingdata(e.target.value);
                  }}
                  // className="FirmName-sale"
                  placeholder="select something"
                  onFocus={() => handleFocus('Region')}
                  onBlur={handleBlur}
                  style={{
                    width: "8rem",
                    // boxShadow: "0px 5px 5px 0px rgba(0, 0, 0, 0.2)",
                    borderRadius: "5px",
                    border: focusedInput === 'Region' ? '2px solid black' : '1px solid #ccc',
                    background:'white'
                  }}
                  onKeyDown={handleEnter}
                  ref={regionRef}
                >
                  type
                  {regionSelect
                    ? regionSelect.map((item, index) => (
                        <option placeholder="select something">{item}</option>
                      ))
                    : "no data"}
                </select>
              </div>

              <div className="input-box-name--sale regionDiv">
                <labeL className="FirmName-sale"> Type</labeL>
                <select
                  onChange={(e) => {
                    if (e.target.value == "Credit Note") {
                      setType("CN");
                      settingInvoiceAndCN("CN");
                    } else if (e.target.value == "Cash") {
                      setType(e.target.value);
                      settingInvoiceAndCN(e.target.value);
                    } else if (e.target.value == "Credit") {
                      setType(e.target.value);
                      settingInvoiceAndCN(e.target.value);
                    }
                  }}
                  className="FirmName-sale"
                  // placeholder="select something"
                  style={{
                    width: "8rem",
                    boxShadow: "0px 5px 5px 0px rgba(0, 0, 0, 0.2)",
                    borderRadius: "5px",
                    border: focusedInput === 'Type' ? '2px solid black' : '1px solid #ccc',
                  }}
                  onKeyDown={handleEnter}
                  ref={typeRef}
                  onFocus={() => handleFocus('Type')}
                >
                  type
                  {typeData
                    ? typeData.map((item, index) => (
                        <option placeholder="select something">{item}</option>
                      ))
                    : "no data"}
                </select>
              </div>

              {invoiceSelect && (
                <div
                  className="input-box-invoice-sale regionDiv"
                  style={{ position: "relative", bottom: "10px" }}
                >
                  <label htmlFor="invoiceNo" className="FirmName-sale">
                    Invoice No.
                  </label>

                  <input
                    // id="invoiceNo"
                    type="name"
                    name="invoice"
                    value={invoice}
                    onChange={(e) => setInvoice(e.target.value)}
                    onKeyDown={handleEnter}
                    onFocus={() => handleFocus('invoiceNo')}
                    ref={invoiceRef}
                    style={{
                      boxShadow: "0px 5px 5px 0px rgba(0, 0, 0, 0.2)",
                      borderRadius: "5px",
                      height: 20,
                      border: focusedInput === 'invoiceNo' ? '2px solid black' : '1px solid #ccc',
                    }}
                  />
                </div>
              )}

              {cnRefSelect && (
                <div className="cnRef-box-invoice-sale">
                  <label htmlFor="cnRefNo">Credit Note Ref No. </label>

                  <input
                    id="cnrefNo"
                    type="name"
                    name="cnReference"
                    value={cnReference}
                    onChange={(e) => setCnReference(e.target.value)}
                    onKeyDown={handleEnter}
                    onFocus={() => handleFocus('cnrefNo')}
                    style={{
                      border: focusedInput === 'cnrefNo' ? '2px solid black' : '1px solid #ccc',
                    }}
                    ref={creditNoteRef}
                  />
                </div>
              )}
            </form>

            {/* here product list entries getting product and dialing entry for products */}

            <div className="quantity--box">
              <div 
               className="product"
               style={{ overflow: "auto", height: "100%" }}
              >
                <div 
                // className="quantity--box-top"
                >
                  <table
                    // id="customers--purchase"
                    id="customers-purchase-entry"
                    // style={{ width: "60rem", marginTop: "12px" }}
                  >
                    <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>Product Name</th>
                      <th className="innerMEG">
                        <th>
                          <th className="M">M</th>
                        </th>
                        {/* <th>
                          <th className="E">E</th>{" "}
                        </th> */}
                        <th style={{ paddingLeft: "14px" }} className="G">
                          G
                        </th>
                      </th>
                      <th>Unit Pack</th>
                      <th>Qty</th>
                      <th>Free</th>
                      <th>Trade Rate</th>
                      <th>Disc%</th>
                      <th>Amt</th>
                      <th>Action</th>
                    </tr>
                    <tr>{selects}</tr>

                    <tr>
                      <td>
                        <input
                          type="text"
                          value={"0"}
                          ref={mSnRef}
                          onKeyDown={handleEnter}
                        />
                      </td>
                      <td>
                        <input
                          onChange={(e) => {
                            generateData(e);
                            unitpackfunction(e);
                          }}
                          // className="ProductName"
                          onClick={ (event) => {
                            openProductModal(event);
                            alert('done');
                            mProductNameRef.current.style.outline = '';
                          }}
                          onFocus={ (event) => {
                            openProductModal(event)
                            mProductNameRef.current.style.outline = '';
                          }}
                          onKeyDown={handleEnter}
                          value={selectedProduct}
                          style={{ width: "19rem" }}
                          ref={mProductNameRef}
                        />
                        {/* Product Name */}
                        <br />
                        {/* <select>
                      {productData
                          ? productData.map((item, index) => (
                              <option
                                placeholder="First Name"
                                onChange={(e) =>
                                  setGeneratedData(e.target.value)
                                }
                              >
                                {item.productName}
                              </option>
                            ))
                          : "no data"}
                      </select> */}
                      </td>
                      <td 
                      className="checkbox-td"
                      >
                        <td
                          // onClick={() => {
                          //   // setregisterAs("M");
                          // }}
                          // style ={{
                          //   padding: '5px', // Adjust padding as needed
                          //   border: '2px solid red', // Border size and color
                          //   textAlign: 'center' // Optional: Center align content
                          // }}
                        >
                          <input
                            className="checkbox-1"
                            style ={{
                              width:30,
                              marginLeft:1
                            }}
                            type="checkbox"
                            // value={registerAs}
                            checked={isMChecked}
                            // checked={registerAs !== ""}
                            onClick={(event) => {
                              // handleMclick(event);
                              generateData(prductNameforSellHistory);
                        
                                mMRef.current.style.outline = ''; // Reset outline to default
                           
                            }}
                            onKeyDown={(event) => {
                              // handleMKeyDown(event);
                              handleEnter(event);
                              generateData(prductNameforSellHistory);
                            }}
                            ref={mMRef}
                            // style={{ outline: "none" }}
                          />
                        </td>
                        {/* <td>
                          <input
                            className="checkbox-2"
                            type="checkbox"
                            style ={{
                              width:30,
                              marginLeft:2
                            }}
                            // value={registerAs}
                            checked={isEChecked}
                            onClick={(event) => {
                              handleEclick(event);
                              generateData(prductNameforSellHistory);
                            
                                mMRef.current.style.outline = ''; // Reset outline to default
                              
                            }}
                            onKeyDown={(event) => {
                              handleEKeyDown(event);
                              handleEnter(event);
                              generateData(prductNameforSellHistory);
                            }}
                            ref={mERef}
                          
                          />
                        </td> */}
                        <td>
                          <input
                            // className="checkbox-3"
                            type="checkbox"
                            style ={{
                              width:30,
                              marginLeft:1,
                              marginright:5
                            }}
                            disabled={cnRefSelect ? true : false}
                            // value={registerAs}
                            checked={isGChecked}
                            onClick={(event) => {
                              // handleGclick(event);
                              handleChangeG(event);

                              mMRef.current.style.outline = ''; // Reset outline to default
                            }}
                            onKeyDown={(event) => {
                              handleGKeyDown(event);
                              handleEnter(event);
                              handleChangeG(event);
                            }}
                            ref={mGRef}
                            // style={{ outline: "none" }}
                          />
                        </td>
                      </td>
                      <td>
                        <input
                          type="text"
                          value={
                            unitPack
                          }
                          onFocus={() => handleFocus('unitpack')}
                          style={{
                            border: focusedInput === 'unitpack' ? '2px solid #015998' : '1px solid #ccc',
                          }}
                          onChange={(e) => {
                            setUnitPack(e.target.value);
                            setUpdateState(true);
                          }}
                          ref={mUnitPackRef}
                          onKeyDown={handleEnter}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={qty }
                          onFocus={() => handleFocus('qty')}
                          style={{
                            border: focusedInput === 'qty' ? '2px solid #015998' : '1px solid #ccc',
                          }}
                          onChange={(e) => {
                           
                            setqty(e.target.value);
                            setUpdateState(true);
                            // FormulaOne();
                          }}
                          ref={mQuantityRef}
                          onKeyDown={handleEnter}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={free}
                          onFocus={() => handleFocus('free')}
                          style={{
                            border: focusedInput === 'free' ? '2px solid #015998' : '1px solid #ccc',
                          }}
                          onChange={(e) => {
                            // handleCheckQuantity();
                            if(!selectedProduct){
                               setFree('');
                               alert('Please Select Product Name First')
                               mProductNameRef.current.style.outline = '2px solid red';
                            }
                            else if (!registerAs && !actualG) {
                              mMRef.current.focus();
                              mMRef.current.style.outline = '2px solid red'; // Highlighting border
                              alert("Please select one option from M, E, or G.");
                              setFree('');
                            }
                            else if (!qty) {
                              mQuantityRef.current.focus();
                              alert('Please enter quantity first.');
                              setFree('');
                            } else {
                              setFree(e.target.value);
                            }
                                setUpdateState(true);
                          }}
                          ref={mFreeRef}
                          onKeyDown={handleEnter}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={tradeRate}
                          onFocus={() => handleFocus('traderate')}
                          style={{
                            border: focusedInput === 'traderate' ? '2px solid #015998' : '1px solid #ccc',
                          }}
                          onChange={(e) => {
                            // setTradeRate(e.target.value);
                            if(!selectedProduct){
                              setTradeRate('');
                              alert('Please Select Product Name First')
                              mProductNameRef.current.style.outline = '2px solid red';
                           }
                           else if (!registerAs && !actualG) {
                             mMRef.current.focus();
                             mMRef.current.style.outline = '2px solid red'; // Highlighting border
                             alert("Please select one option from M, E, or G.");
                             setTradeRate('');
                           }
                           else if (!qty) {
                             mQuantityRef.current.focus();
                             alert('Please enter quantity first.');
                             setTradeRate('');
                           } else {
                            setTradeRate(e.target.value);
                           }
                            setUpdateState(true);
                          }}
                          onKeyDown={(event) => {
                            // if(event.key === 'Enter'){
                              if(event.key === 'Enter' && mTradeRateRef.current === document.activeElement){
                            if (validateFields(event)) {
                              // Your logic when all fields are filled
                              handleEnter(event);
                             
                              openModalWithEnter(event);
                              handleTabKeyPress(event);
                              // alert('submitted')
                              // console.log('Form submitted successfully');
                            } else {
                              //  alert('else is')
                               setShowWarning(!showWarning);
                              // Handle the case where fields are not filled
                              // alert('Please fill in all required fields')
                              // console.log('Please fill in all required fields');
                            }
                          }
                            // mBatchNoRef.current.focus();
                          }}

                          ref={mTradeRateRef}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={discPercent}
                          onFocus={() => handleFocus('discountpercent')}
                          style={{
                            border: focusedInput === 'discountpercent' ? '2px solid #015998' : '1px solid #ccc',
                          }}
                          onChange={(e) => {
                            // setDiscount(e.target.value);
                            if(!selectedProduct){
                              setDiscount('');
                              alert('Please Select Product Name First')
                              mProductNameRef.current.style.outline = '2px solid red';
                           }
                           else if (!registerAs && !actualG) {
                             mMRef.current.focus();
                             mMRef.current.style.outline = '2px solid red'; // Highlighting border
                             alert("Please select one option from M, E, or G.");
                             setDiscount('');
                           }
                           else if (!qty) {
                             mQuantityRef.current.focus();
                             alert('Please enter quantity first.');
                             setDiscount('');
                           } else {
                            setDiscount(e.target.value);
                           }
                            setUpdateState(true);
                          }}
                          onClick={(event) =>{

                            if (validateFields(event)) {
                              openModal()
                            } else{
                              // alert('fill out mandatory fileds')
                            }
                            
                          //   if(!selectedProduct){
                          //     setDiscount('');
                          //     alert('Please Select Product Name First')
                          //     mProductNameRef.current.style.outline = '2px solid red';
                          //  }
                          //  else if (!registerAs && !actualG) {
                          //    mMRef.current.focus();
                          //    mMRef.current.style.outline = '2px solid red'; // Highlighting border
                          //    alert("Please select one option from M, E, or G.");
                          //    setDiscount('');
                          //  }
                          //  else if (!qty) {
                          //    mQuantityRef.current.focus();
                          //    alert('Please enter quantity first.');
                          //    setDiscount('');
                          //  } else {
                          //   openModal()
                          //  } 
                            }}

                          onKeyDown={(event) => {
                            if(event.key === 'Enter' && mDiscPercentRef.current === document.activeElement){
                            if (validateFields(event)) {
                              handleEnter(event);
                            } else{
                              // alert('fill out mandatory fileds')
                            }
                          }
                           
                            if(event.key === 'Tab' && mDiscPercentRef.current === document.activeElement){
                              // alert('working')
                              if (validateFields(event)) {            
                              event.preventDefault();
                              FormulaOne('testing');
                            
                              setIsEChecked(false); 
                              setActualG("");
                              // setregisterAs("");
                              // setIsMChecked(false);
                              setIsGChecked(false);
                            } else{
                              // alert('fill out mandatory fileds')
                            }
                              // mDiscPercentRef.current.focus();
                            }

                          }}
                          ref={mDiscPercentRef}
                        />
                      </td>
                      <td>
                        <input type="text" value={amount} />
                      </td>
                      <td
                       style={{
                        color: "white",
                        width:'4.7rem',
                      }}
                      >
                      <td
                        style={{
                          backgroundColor: "#015998",
                          color: "white",
                          borderRadius: "5px",
                          width:'8rem',
                          height:'1rem'
                        }}
                      >
                        Action
                      </td>
                      </td>
                    </tr>
                    </thead>
                    <tbody>
                      {ProductEntries ? generateTableRowsData()  : 'no Data'}
                      </tbody>
                  </table>
                </div>
              </div>

              {/* batchNo number and all the other information which is used by the user while purchasing the product */}

              <div className="quantity--box-bottom">
                
                {showModal && (

                  <div className="modal-background">
                                  
                  <div
                  className="qty-comments-purchase-entry"
                  >
                <button 
                id="close-button" 
                onClick={() => {
                  // UpdateStateForUpdatedValues()
                  openModal()
                }}
                style={{
                  height:'.5rem',
                  width:'.5rem',
                  display:'flex',
                  justifyContent:"center",
                  alignItems:"center",
                  marginLeft:'400px',
                  marginTop:-10,
                }}
                >X</button>
                    <div className="box">
                      <div className="label--for-second">
                        <div className="BatchNo">
                          <label
                            htmlFor="batchNo"
                            style={{ fontWeight: "bold" }}
                          >
                            Batch No.
                          </label>
                          <input
                            id="BatchNo"
                            value={batchNo}
                            type="text"
                            onChange={(e) => setBatch(e.target.value)}
                            onFocus={() => handleFocus('batchNo')}
                            style={{
                              border: focusedInput === 'batchNo' ? '2px solid #015998' : '1px solid #ccc',
                              // height: "2rem", borderColor: "grey"
                            }}
                            autoFocus
                            ref={mBatchNoRef}
                            onKeyDown={handleEnter}
                            // style={{  }}
                          />
                        </div>

                        <div className="expiry">
                          <label
                            htmlFor="expiry"
                            style={{ fontWeight: "bold" }}
                          >
                            Expiry
                          </label>
                          <input
                            id="expiry"
                            value={expiry}
                            type="date"
                            onChange={(e) => setexpiry(e.target.value)}
                            ref={mExpiryRef}
                            onKeyDown={handleEnter}
                            style={{
                              height: "2rem",
                              // borderColor: "grey",
                              width: "6.5rem",
                              border: focusedInput === 'expiry' ? '2px solid #015998' : '1px solid #ccc',
                            }}
                            onFocus={() => handleFocus('expiry')}    
                          />
                        </div>
                        <div className="mrp">
                          <label htmlFor="mrp" style={{ fontWeight: "bold" }}>
                            Mrp
                          </label>
                          <input
                            id="mrp"
                            value={mrp}
                            type="text"
                            onChange={(e) => setMrp(e.target.value)}
                            ref={mMrpRef}
                            onKeyDown={handleEnter}
                            onFocus={() => handleFocus('mrp')}
                            style={{ 
                              height: "2rem",
                               borderColor: "grey",
                               border: focusedInput === 'mrp' ? '2px solid #015998' : '1px solid #ccc',
                              }}
                          />
                        </div>
                        <div className="hsn_sac">
                          <label htmlFor="hsn" style={{ fontWeight: "bold" }}>
                            HSN/SAC
                          </label>
                          <input
                            id="hsn_sac"
                            value={hsn_sac}
                            type="text"
                            onChange={(e) => sethsn_sac(e.target.value)}
                            ref={mHsnSacRef}
                            onKeyDown={handleEnter}
                            onFocus={() => handleFocus('hsn_sac')}
                            style={{ 
                              height: "2rem", 
                              borderColor: "grey",
                              border: focusedInput === 'hsn_sac' ? '2px solid #015998' : '1px solid #ccc',
                             }}
                          />
                        </div>
                      </div>

                      <div className="label-for-four">
                        <div className="NetRate"></div>
                        <div className="NetRate">
                          <label style={{ fontWeight: "bold" }}>Net Rate</label>
                          <input
                            value={netRate}
                            type="text"
                            onChange={(e) => {
                              setNetRate(e.target.value);
                              calculateTradeRate(e.target.value, qty, parseFloat(sgst) + parseFloat(cess) + parseFloat(igst) + parseFloat(cgst) );
                            }}
                            ref={mNetRateRef}
                            onKeyDown={handleEnter}
                            onFocus={() => handleFocus('netRate')}
                            style={{ 
                              height: "2rem", 
                              borderColor: "grey",
                              border: focusedInput === 'netRate' ? '2px solid #015998' : '1px solid #ccc',
                            }}
                          />
                        </div>

                        <div className="igst"></div>

                        {centerSelect && (
                          <div className="igst">
                            <label style={{ fontWeight: "bold" }}>IGST</label>
                            <input
                              value={igst}
                              type="text"
                              onChange={(e) => {
                                setigst(e.target.value);
                              }}
                              ref={mIgstRef}
                              onKeyDown={handleEnter}
                              onFocus={() => handleFocus('igst')}
                              style={{ 
                                height: "2rem", 
                                borderColor: "grey",
                                border: focusedInput === 'igst' ? '2px solid #015998' : '1px solid #ccc',
                               }}
                            />
                          </div>
                        )}

                        {localSelect && (
                          <div className="cgst">
                            <label
                              htmlFor="cgst"
                              style={{ fontWeight: "bold" }}
                            >
                              CGST
                            </label>
                            <input
                              id="cgst"
                              value={cgst}
                              type="text"
                              onChange={(e) => setcgst(e.target.value)}
                              ref={mCgstRef}
                              onKeyDown={handleEnter}
                              onFocus={() => handleFocus('cgst')}
                              style={{ 
                                height: "2rem", 
                                borderColor: "grey",
                                border: focusedInput === 'cgst' ? '2px solid #015998' : '1px solid #ccc',
                               }}
                            />
                          </div>
                        )}

                        {localSelect && (
                          <div className="sgst">
                            <label
                              htmlFor="Net Rate"
                              style={{ fontWeight: "bold" }}
                            >
                              SGST
                            </label>
                            <input
                              id="Net Rate"
                              className="NetRate"
                              value={sgst}
                              type="text"
                              onChange={(e) => setsgst(e.target.value)}
                              ref={mSgstRef}
                              onKeyDown={handleEnter}
                              onFocus={() => handleFocus('sgst')}
                              style={{ 
                                height: "2rem", 
                                borderColor: "grey",
                                border: focusedInput === 'sgst' ? '2px solid #015998' : '1px solid #ccc',
                               }}
                            />
                          </div>
                        )}

                        <div className="cess">
                          <label
                            htmlFor="Net Rate"
                            style={{ fontWeight: "bold" }}
                          >
                            CESS
                          </label>
                          <input
                            id="Net Rate"
                            value={cess}
                            type="text"
                            onChange={(e) => {
                              setcess(e.target.value);
                            }}
                            ref={mCessRef}
                            onKeyDown={(event) => {
                              if(event.key === 'Enter'){
                                setNetRate(0);
                              }
                              handleEnter(event);
                              openModalWithEnter(event);
                           
                              if(event.key === 'Tab' && mCessRef.current === document.activeElement){
                                // alert('working')
                                setNetRate(0);
                                event.preventDefault();
                                mDiscPercentRef.current.focus();
                              }
                            }}
                            onFocus={() => handleFocus('cess')}
                            style={{ 
                              height: "2rem", 
                              borderColor: "grey",
                              border: focusedInput === 'cess' ? '2px solid #015998' : '1px solid #ccc',
                            }}
                          />
                        </div>
                      </div>
                      {/* <button
                      className="submit-firm"
                      onClick={() => {
                        // arrayOfObject();
                        setUpdateIndex2("");
                        FormulaOne();
                        addNewrow();
                        openModal();
                      }}
                    >
                      Add Product
                    </button> */}
                    </div>
                  </div>
                 </div>
                )}


                {showModalUpdateValue && (
                  // <>
                   <div className="modal-background">
                  <div
                   className="qty-comments-purchase-entry"
                   >
                <button 
                id="close-button" 
                onClick={() => {
                  UpdateStateForUpdatedValues()
                }}
                style={{
                  height:'.5rem',
                  width:'.5rem',
                  display:'flex',
                  justifyContent:"center",
                  alignItems:"center",
                  marginLeft:'400px',
                  marginTop:-10,
                }}
                >X</button>
                    <div className="box">
                
                      <div className="label-for-purchase-second">
                    
                        <div
                          className="BatchNo"
                          style={{ marginLeft: "0.8rem" }}
                        >
                          <label
                            htmlFor="batchNo"
                            style={{ fontWeight: "bold" }}
                          >
                            Batch No.
                          </label>
                          <input
                            id="BatchNo"
                            value={batchNoUpdateValue}
                            type="text"
                            onChange={(e) => {
                              setBatchUpdateValue(e.target.value);
                              // setBatch2(index)
                            }}
                            autoFocus
                            // ref={mBatchNoRef}
                            onFocus={() => handleFocusForUpdate(`BatchNo${onClickedDone}`)}
                            // onKeyDown={handleTaxEnter}
                            style={{ 
                              height: "2rem", 
                              borderColor: "grey",
                              border: focusedInputUpdates === `BatchNo${onClickedDone}` ? '2px solid #015998' : '1px solid #ccc',
                           }}
                            ref={updateBatchNoRef}
                            onKeyDown={handleKeyDown2}

                          />
                        </div>

                        <div
                          className="expiry"
                          style={{ marginLeft: "0.8rem" }}
                        >
                          <label
                            htmlFor="expiry"
                            style={{ fontWeight: "bold" }}
                          >
                            Expiry
                          </label>
                          <input
                            id="expiry"
                            value={expiryUpdateValue}
                            type="date"
                            onChange={(e) => setExpiryUpdateValue(e.target.value)}
                            // ref={mExpiryRef}
                            // onKeyDown={handleEnter}
                            // onKeyDown={handleTaxEnter}
                            onFocus={() => handleFocusForUpdate(`expiry${onClickedDone}`)}
 
                            style={{
                              height: "2rem",
                              borderColor: "grey",
                              width: "6.5rem",
                              border: focusedInputUpdates === `expiry${onClickedDone}` ? '2px solid #015998' : '1px solid #ccc',
                            }}
                            ref={updateExpiryRef}
                            onKeyDown={handleKeyDown2}
                          />
                        </div>

                        <div className="mrp" style={{ marginLeft: "0.8rem" }}>
                          <label htmlFor="mrp" style={{ fontWeight: "bold" }}>
                            MRP
                          </label>
                          <input
                            id="mrp"
                            value={mrpUpdateValue}
                            type="text"
                            onChange={(e) => setMrpUpdateValue(e.target.value)}
                            // ref={mMrpRef}
                            // onKeyDown={handleEnter}
                            onFocus={() => handleFocusForUpdate(`mrp${onClickedDone}`)}
                            style={{ 
                              height: "2rem", 
                              borderColor: "grey",
                              border: focusedInputUpdates === `mrp${onClickedDone}` ? '2px solid #015998' : '1px solid #ccc',
                             }}
                             ref={updateMrpRef}
                             onKeyDown={handleKeyDown2}
                          />
                        </div>
                        <div
                          className="hsn_sac"
                          style={{ marginLeft: "0.8rem" }}
                        >
                          <label htmlFor="hsn" style={{ fontWeight: "bold" }}>
                            HSN/SAC
                          </label>
                          <input
                            id="hsn_sac"
                            value={hsnSacUpdateValue}
                            type="text"
                            onChange={(e) => setHsnSacUpdateValue(e.target.value)}
                            // ref={mHsnSacRef}
                            // onKeyDown={handleEnter}
                            onFocus={() => handleFocusForUpdate(`hsn_sac${onClickedDone}`)}
                            style={{ 
                              height: "2rem", 
                              borderColor: "grey",
                              border: focusedInputUpdates === `hsn_sac${onClickedDone}` ? '2px solid #015998' : '1px solid #ccc',
                             }}
                             ref={updateHsnSacRef}
                             onKeyDown={handleKeyDown2}
                          />
                        </div>
                      </div>

                      <div className="label-for-four-purchase">
                        <div className="NetRate"></div>

                        {/* <label className="TAX" style={{ fontWeight: "bold" }}>
                        
                      </label> */}

                      <div className="NetRate"></div>
                        <div className="NetRate">
                          <label style={{ fontWeight: "bold" }}>Net Rate</label>
                          <input
                            value={netRate}
                            type="text"
                            onChange={(e) => {
                              setNetRate(e.target.value);
                              calculateTradeRate(e.target.value, qtyUpdateValue, parseFloat(sgstUpdateValue) + parseFloat(cessUpdateValue) + parseFloat(igstUpdateValue) + parseFloat(cgstUpdateValue),onClickedDone );
                            }}
                            // ref={mNetRateRef}
                            // onKeyDown={handleEnter}
                            onFocus={() => handleFocusForUpdate(`netRate${onClickedDone}`)}
                            style={{ 
                              height: "2rem",
                              borderColor: "grey",
                              border: focusedInputUpdates === `netRate${onClickedDone}` ? '2px solid #015998' : '1px solid #ccc',
                            }}
                            ref={updateNetRateRef}
                            onKeyDown={handleKeyDown2}
                          />
                        </div>


                        {region == 'Center' && (
                          <div className="igst">
                            <label
                              htmlFor="Net Rate"
                              style={{ fontWeight: "bold", marginLeft: "2rem" }}
                            >
                              IGST
                            </label>
                            <input
                              value={igstUpdateValue}
                              type="text"
                              onChange={(e) => {
                                setIgstUpdateValue(e.target.value);
                                // FormulaOne();
                              }}
                              // ref={mIgstRef}
                              // onKeyDown={handleEnter}
                              onFocus={() => handleFocusForUpdate(`IGST${onClickedDone}`)}
                              style={{ 
                                height: "2rem", 
                                borderColor: "grey",
                                border: focusedInputUpdates === `IGST${onClickedDone}` ? '2px solid #015998' : '1px solid #ccc',
                               }}
                               ref={updateIGSTRef}
                               onKeyDown={handleKeyDown2}
                            />
                          </div>
                        )}

                        {region == 'Local' && (
                          <div className="cgst">
                            <label
                              htmlFor="cgst"
                              style={{ fontWeight: "bold", marginLeft: "2rem" }}
                            >
                              CGST
                            </label>

                            <input
                              id="cgst"
                              value={cgstUpdateValue}
                              type="text"
                              onChange={(e) => setCgstUpdateValue(e.target.value)}           
                              // ref={mCgstRef}
                              // onKeyDown={handleEnter}
                              onFocus={() => handleFocusForUpdate(`cgst${onClickedDone}`)}
                              style={{
                                 height: "2rem", 
                                 borderColor: "grey",
                                 border: focusedInputUpdates === `cgst${onClickedDone}` ? '2px solid #015998' : '1px solid #ccc',
                                 }}
                                 ref={updateCGSTRef}
                                 onKeyDown={handleKeyDown2}
                            />
                          </div>
                        )}

                        {region == 'Local' && (
                          <div className="sgst">
                            <label
                              htmlFor="Net Rate"
                              style={{ fontWeight: "bold", marginLeft: "2rem" }}
                            >
                              SGST
                            </label>
                            <input
                              id="Net Rate"
                              className="NetRate"
                              value={sgstUpdateValue}
                              type="text"
                              onChange={(e) => setSgstUpdateValue(e.target.value)}
                              // ref={mSgstRef}
                              // onKeyDown={handleEnter}
                              onFocus={() => handleFocusForUpdate(`SGST${onClickedDone}`)}
                              style={{ 
                                height: "2rem", 
                                borderColor: "grey",
                                border: focusedInputUpdates === `SGST${onClickedDone}` ? '2px solid #015998' : '1px solid #ccc',
                               }}
                               ref={updateSGSTRef}
                               onKeyDown={handleKeyDown2}
                            />
                          </div>
                        )}
                        <div className="cess">
                          <label
                            htmlFor="Net Rate"
                            style={{ fontWeight: "bold", marginLeft: "2rem" }}
                          >
                            CESS
                          </label>
                          <input
                            id="Net Rate"
                            value={cessUpdateValue}
                            type="text"
                            onChange={(e) => {
                              setCessUpdateValue(e.target.value);
                            }}
                            // onKeyDown={(event) => {
                            //   // arrayOfObject();
                            //   // FormulaOne();
                            //   // openModal();
                            //   // handleTaxEnter(event);
                            //   handleEnter(event);
                            // }}
                            onFocus={() => handleFocusForUpdate(`CESS${onClickedDone}`)}
                            // ref={mCessRef}
                            style={{ 
                              height: "2rem", 
                              borderColor: "grey",
                              border: focusedInputUpdates === `CESS${onClickedDone}` ? '2px solid #015998' : '1px solid #ccc',
                             }}
                             ref={updateCESSRef}
                             onKeyDown={handleKeyDown2}
                          />
                        </div>
                        <button
                        onClick={ () => { 
                          updateArrayofObject(onClickedDone);
                          UpdateStateForUpdatedValues();
                          // calculateTradeRate(netRate, qty, parseFloat(sgst) + parseFloat(cess) + parseFloat(igst) + parseFloat(cgst),onClickedDone );
                          setNetRate(0);
                        }}
                        onKeyDown={(e)=>{
                            if(e.key === "Enter" && !e.shiftKey){
                  
                              updateArrayofObject(onClickedDone);
                              UpdateStateForUpdatedValues();
                              // calculateTradeRate(netRate, qty, parseFloat(sgst) + parseFloat(cess) + parseFloat(igst) + parseFloat(cgst),onClickedDone );
                              setNetRate(0);
                              handleKeyDown2(e)
                            }
                        }}
                        ref={updateButtonPopupRef}
                        >Update</button>
                      </div>
                      {/* <button
                      className="submit-firm"
                      onClick={() => {
                        // arrayOfObject();
                        FormulaOne();
                        openModal();
                      }}
                    >
                      Add Product
                    </button> */}
                    </div>
                  </div>
                 </div>
                  // </>
                )}



{showPopup && (
<div class="overlay">   
<div className="parent-container-Profit-loss-pop-up"

>
          <div className="popup-content"
          >
            <div className="popup-header">
              {isLoss ? 'Loss' : 'No Profit No Loss'}
            </div>
            <div className="popup-body">
              {isLoss ? `You are selling this product in loss.` : 'No profit and no loss.'}
            </div>
            <div className="popup-buttons">
              <button 
              // handleClosePopup
              ref={cancelButtonRef}
              onKeyDown={handleCancelKeyDown}
              onClick={ () => {setShowPopup(false)}}
              autoFocus={true}
              onFocus={() => handleFocusForUpdate(`cancelButtonRef${"index"}`)}
                style={{ 
                  // width: "303px",
                  backgroundColor: focusedInputUpdates === `cancelButtonRef${"index"}` ?  "red" : '#015998',
                  // color:  focusedInputUpdates === `cancelButtonRef${"index"}` ? "black" : 'white',
                  border: focusedInputUpdates === `cancelButtonRef${"index"}` ? '2px solid #015998' : '1px solid #ccc',
                 }}
              >
                Cancel
              </button>
              <button
                // autoFocus={true}
                onKeyDown={handleCancelKeyDown}
                ref={proceedButtonRef}
                onClick={() =>{
                FormulaOne('production');
                setIsEChecked(false); 
                // setIsMChecked(false);
                setIsGChecked(false);
                setActualG("");
                // setregisterAs("");
                setShowPopup(false);
              }}
              onFocus={() => handleFocusForUpdate(`proceedButtonRef${"index"}`)}
              style={{ 
                // width: "303px",
                backgroundColor: focusedInputUpdates === `proceedButtonRef${"index"}` ?  "green" : '#015998',
                // color:  focusedInputUpdates === `proceedButtonRef${"index"}` ? "black" : 'white',
                border: focusedInputUpdates === `proceedButtonRef${"index"}` ? '2px solid #015998' : '1px solid #ccc',
               }}
              >Proceed</button>
            </div>
          {/* </div> */}
        </div>
    </div>
    </div> 
    )}


    {/* show warning pop up if any mandatory filed is empty */}
    {
      //showWarning && 
      //(
    //     <div class="overlay">
    //       <div className="parentWarningPopup">
    //        {/*  first half */}
    //        <div class="top">
    //        <span class="close-icon" 
    //        onClick={ () =>{ 
    //         setShowWarning(false);
    //       }}
    //         >&times;</span>
    //        </div>

    //         {/* second half */}
    //         <img src={warningIcon} alt="Warning Icon" class="warning-icon" />
    //         <div class="bottom">
            
    //         <div class="warning-title">Warning!</div>
    //         <div class="warning-message">{warningMessage}</div>
    //        <div
    //        style={{
    //         alignItems:"center",
    //         justifyContent:"center",
    //         display:"flex",
    //         alignSelf:"center",
    //        }}
    //        >
    //        <button
    //         style={{
    //           backgroundColor:'#cc2328',
    //           height:30,
    //           width:70,
    //           alignItems:"center",
    //           justifyContent:"center",
    //           display:"flex",
    //           alignSelf:"center",
    //         }}
    //         ref={okButtonRef}
    //         tabIndex={0} 
    //         autoFocus={true}
    //         // autoFocus // Focuses on the button when it appears
    //         onKeyDown={(event) =>{
    //              if(event.key == 'Enter' || event.key == 'Tab'){
    //               // handleCloseWarning();
    //               // alert('enter pressed')
    //               //  firmNameRef.current.focus();
    //              }
    //         }}
    //         onClick={ () =>{
    //           setShowWarning(false);
    //           }}
    //         > 
    //         Ok
    //         {/* <p style={{justifyContent:'center',display:'flex'}}>Ok</p> */}
    //         </button>
    //          </div>
    //       </div>   
          
    //     </div>
        
    // </div>
      //)
    }


      <WarningPopup
        showWarning={showWarning}
        handleCloseWarning={handleCloseWarning}
        warningMessage={warningMessage}
      />



                {/* qty details  */}
                <div 
                className="qty--details-firm-select"
                >
                  {/* first pop up  */}
                {button && (
                  <div className="table"
                  style={{height:'13rem',marginTop:80,maxWidth:500,marginLeft:200}}
                  >
                    <div className="table-row header">
                      <div className="table-cell">Date</div>
                      <div className="table-cell">Invoice No.</div>
                      <div className="table-cell">Amt</div>
                    </div>
                    <div className="table-body">
                      {selectedFirmInvoiceHistory ? (
                        selectedFirmInvoiceHistory.map(function (item, index) {
                          return (
                            <div className="table-row" key={item.id}>
                              <div className="table-cell">{item.date}</div>
                              <div className="table-cell">{item.properInvoice}</div>
                              <div className="table-cell">{item.grandTotal}</div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="table-row">
                          <div className="table-cell" colSpan="3">No entries</div>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                  {/* second-pop-up  */}
              {showProductHistory && (
                      <div className="table"
                      style={{
                        height:'13rem',
                        marginRight:10,
                        width:'32rem', 
                        overflow:'auto',
                        marginTop:90,
                        marginLeft:180,
                      }}
                      >
                        {/* head */}
                      <div className="table-row header"
                      id='product-select-table-header'>
                      <div className="table-cell-second-pop-up">Date</div>
                      <div className="table-cell-second-pop-up">FirmName</div>
                      <div className="table-cell-second-pop-up">ProductName</div>
                      <div className="table-cell-second-pop-up">Quantity</div>
                      <div className="table-cell-second-pop-up">Free</div>
                      <div className="table-cell-second-pop-up">Trade Rate</div>
                      <div className="table-cell-second-pop-up">Disc%</div>
                      <div className="table-cell-second-pop-up">M.R.P</div>
                      <div className="table-cell-second-pop-up">Tax</div>
                      <div className="table-cell-second-pop-up">Expiry</div>
                      <div className="table-cell-second-pop-up">Invoice No.</div>
                      <div className="table-cell-second-pop-up">Action</div>
                      </div>

                      <div className="table-body">
              {/* Product History */}
              {productHistory ? (
                productHistory.map((item, index) => (
                  //content
                  <div className="table-row" key={index}
                  id='product-select-table-data'
                  >
                     <div className="table-cell-second-pop-up">{item.date}</div>
                     <div className="table-cell-second-pop-up">{item.firmName}</div>
                     <div className="table-cell-second-pop-up">{item.productName}</div>
                      <div className="table-cell-second-pop-up">{item.qty}</div>
                      <div className="table-cell-second-pop-up">{item.free}</div>
                      <div className="table-cell-second-pop-up">{item.tradeRate}</div>
                      <div className="table-cell-second-pop-up">{item.discPercent}</div>
                      <div className="table-cell-second-pop-up">{item.mrp}</div>
                      <div className="table-cell-second-pop-up">{item.totalTax}</div>
                      <div className="table-cell-second-pop-up">{item.expiry}</div>
                      <div className="table-cell-second-pop-up">{item.invoiceNo}</div>
                      <div className="table-cell-second-pop-up">
                      <button
                      autoFocus={true}                 
                      ref={mAfterProuductSelectRef}
                          onClick={() => {
                            setTaxValues(item);
                            setUnitPack(item.unitPack);
                            setBatch(item.batchNo);
                            setexpiry(item.expiry)
                            setigst(item.igst)
                            setsgst(item.sgst)
                            setcess(item.cess)
                            setMrp(item.mrp)
                            setHsn_sac_Code(item.hsn_sac)
                            setTradeRate(item.tradeRate);
                            setFree(item.free)
                            //amount ot compare profit and lost
                            // console.log('net rate',item.amount/item.qty)
                            setPurchaseHistoryNetRate(item.amount/item.qty)
                            // setSellhistoryQty
                          
                            //setting focus to quantity
                            mQuantityRef.current.focus();
                        console.log('line no 4522')
                        }}
                      >
                        Select
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <h6>No data</h6>
              )}
              </div>

              {/* </div> */}


                        {/* <p onClick={flipModal}>x</p> */}
                      </div>
                  )}

                  {showProductSellHistory && (
        <div
        style={{
          width:'32rem',
          overflow:'auto',
          backgroundColor:'white',

          height:'13rem',
          marginRight:10,
          width:'32rem', 
          overflow:'auto',
          marginTop:90,
          marginLeft:180,
          
         }}
        >
        <div className="table-Third-pop-up-meg-table">
          {/* head  */}
         <div className="Table-header-content">
                {/* <div className="table-cell-for-third-table" id="third-table-header">Date</div> */}
                {/* <div className="table-cell-for-third-table" id="third-table-header">Firm Name</div> */}
                {/* <div className="table-cell-for-third-table" id="third-table-header">Product Name</div> */}
                <div className="table-cell-for-third-table" id="third-table-header">Invoice No.</div>
                <div className="table-cell-for-third-table" id="third-table-header">Qty</div>
                <div className="table-cell-for-third-table" id="third-table-header">Free</div>
                <div className="table-cell-for-third-table" id="third-table-header">Trade rate</div>
                <div className="table-cell-for-third-table" id="third-table-header">Disc %</div>
                <div className="table-cell-for-third-table" id="third-table-header">Batch No.</div>
                <div className="table-cell-for-third-table" id="third-table-header">Expiry</div>
                <div className="table-cell-for-third-table" id="third-table-header">M.R.P</div>
                <div className="table-cell-for-third-table" id="third-table-header">Tax</div>
                {/* <div className="table-cell-for-third-table" id="third-table-header">Action</div> */}

          </div>
        {/* body */}
          <div>
          {SelectProductResultData &&
            SelectProductResultData.map(function (item,index) {
              return (
                <div className="table-row" key={index}>
                    {/* <div className="table-cell-for-third-table">{item.date}</div> */}
                    {/* <div className="table-cell-for-third-table">{item.firmName}</div> */}
                    {/* <div className="table-cell-for-third-table">{item.productName}</div> */}
                    <div className="table-cell-for-third-table">{item.invoiceNo}</div>
                    <div className="table-cell-for-third-table">{item.qty}</div>
                    <div className="table-cell-for-third-table">{item.free}</div>
                    <div className="table-cell-for-third-table">{item.tradeRate}</div>
                    <div className="table-cell-for-third-table">{item.discPercent}</div>
                    <div className="table-cell-for-third-table">{item.batchNo}</div>
                    <div className="table-cell-for-third-table">{item.expiry}</div>
                    <div className="table-cell-for-third-table">{item.mrp}</div>
                    <div className="table-cell-for-third-table">{item.igst}</div>
            
                    {/* <button
                      onClick={() => {
                        setTaxValues(item);
                        // alert("this is active");
                      }}
                      className="table-cell-for-third-table"
                    >
                      Select
                    </button> */}
                
                </div>
              );
            })}
        </div>
      </div>
      </div>  
        )}
       

              </div>

       


              </div>
            </div>
          </div>

          {/* here all firm name genereator detials which we are pre filling from the data which we are getting from the backend firm list     */}

          <div 
          // className="contact-box--sale"
          className='contact-box--purchase'
          >
            {/* <div className="c/ontact-box-input"> */}
            <label htmlFor="add" style={{ fontWeight: "bold", color: "black" }}>
              Address
            </label>
            <input
              id="email"
              value={address}
              // style={{
              //   borderColor: "grey",
              //   boxShadow: "0px 5px 5px 0px rgba(0, 0, 0, 0.2)",
              // }}
            />
            <label
              htmlFor="email"
              style={{ fontWeight: "bold", color: "black" }}
            >
              Email
            </label>
            <input
              id="email"
              value={email}
              // style={{
              //   height: "2rem",
              //   borderColor: "grey",
              //   boxShadow: "0px 5px 5px 0px rgba(0, 0, 0, 0.2)",
              // }}
            />
            <label
              htmlFor="mobile"
              style={{ fontWeight: "bold", color: "black" }}
            >
              Mobile No.
            </label>
            <input
              id="mobile"
              value={mobile}
              // style={{
              //   height: "2rem",
              //   borderColor: "grey",
              //   boxShadow: "0px 5px 5px 0px rgba(0, 0, 0, 0.2)",
              // }}
            />
            <label htmlFor="gst" style={{ fontWeight: "bold", color: "black" }}>
              GSTIN
            </label>
            <input
              id="gst"
              value={gst}
              // style={{
              //   height: "2rem",
              //   borderColor: "grey",
              //   boxShadow: "0px 5px 5px 0px rgba(0, 0, 0, 0.2)",
              // }}
            />

            <label style={{ fontWeight: "bold", color: "black" }}>D.L.1</label>
            <input
              id="DL2"
              value={DL1}
              // style={{
              //   height: "2rem",
              //   borderColor: "grey",
              //   boxShadow: "0px 5px 5px 0px rgba(0, 0, 0, 0.2)",
              // }}
            />
            <label style={{ fontWeight: "bold", color: "black" }}>D.L.2</label>
            <input
              id="DL2"
              value={DL2}
              // style={{ height: "2rem", borderColor: "grey" }}
            />
            <label style={{ fontWeight: "bold", color: "black" }}>
              FSSAI No.
            </label>
            <input
              id="fssai"
              value={fssai}
              // style={{
              //   height: "2rem",
              //   borderColor: "grey",
              //   boxShadow: "0px 5px 5px 0px rgba(0, 0, 0, 0.2)",
              // }}
            />
          </div>
        </div>

        <div className="bottom--half">
          <div 
          className="total--box"
          // className="total-box-purchase"
          >
            <CommentsTable
              generatedData={{
                product: generatedData,
                cat: category,
                brand: brand,
                unit: unitpacking,
                hac: hsn_sac_code,
              }}
            />
            {/* <div className="total-values"> */}
            <div 
            // className="total-values--button"
            className="total-values-button--purchase"
            >
              <button 
              // className="sub-total"
              >Sub. Total</button>
              <button 
              // className="disc-amount"
              >Disc. Amount</button>
              <button 
              // className="total-tax"
              >Total Tax</button>
              <button 
              // className="goods-return"
              > Goods Return(R)</button>
              <button 
              // className="cn-voucher"
              >CN. Voucher </button>
              <button 
              // className="payable-amount"
              >Payable Amount</button>
            </div>
            {/* </div> */}
          </div>
          <div 
          className="blank--box"
        //  className="blank-box-purchase"
          >
            <input
              value={subtotal}
              onKeyDown={handleEnter}
              ref={bSubTotalBoxRef}
            />
            <input
              value={disAmt}
              onKeyDown={handleEnter}
              ref={bDiscountAmtBoxRef}
              // style={{ position: "relative", top: "6px" }}
            />
            <input
              value={totalTax}
              onKeyDown={handleEnter}
              ref={bTotalTaxBoxRef}
              // style={{ position: "relative", top: "8px" }}
            />
            <input
              value={ProductEntries.length > 0 ? goodReturn : 0}
              onKeyDown={handleEnter}
              ref={bGoodsReturnBoxRef}
              // style={{ position: "relative", top: "12px" }}
            />
            <input
              value={cnVoucher}
              onChange={(e) => {
                setCnVoucher(e.target.value);
                // cnVoucherFunction([e.target.value]);

                const newCnVoucher = e.target.value;
                const previousCnVoucher = cnVoucher || 0; // Use 0 if cnVoucher is falsy

                // Update cnVoucher state
                setCnVoucher(newCnVoucher);

                // Convert to integers for more accurate calculations (assuming currency)
                const parsedNewCnVoucher = parseFloat(newCnVoucher, 10) || 0;
                const parsedPreviousCnVoucher = parseFloat(previousCnVoucher, 10) || 0;

                // Calculate the difference to update the grandTotal
                const diff = parsedNewCnVoucher - parsedPreviousCnVoucher;

                // Update grandTotal by adding the difference
                const updatedGrandTotal = parseFloat(grandTotal) + diff;
                setGrandTotal(updatedGrandTotal.toFixed(2));

              }}
              onKeyDown={handleEnter}
              ref={bCnVoucherBoxRef}
              // style={{ position: "relative", top: "14px" }}
            />
            <input
              value={grandTotal}
              onKeyDown={handleEnter}
              ref={bPayableAmountBoxRef}
              // style={{ position: "relative", top: "17px" }}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SalesEntry;

/** @format */
import React, { useState } from "react";
import Swal from "sweetalert2";
import { useParams, useNavigate } from "react-router-dom";
import "./firmgenrator.css";
import Data from "./state";
import axios from "axios";
import Layout from "../../Layout/Layout";
import { useGlobalContext } from "components/Context";

const FirmNameGenerator = () => {
  //const params = useParams();
  const navigate = useNavigate();

  let { LoadingSpinner } = useGlobalContext();

  const [firmName, setfirmName] = useState("");
  const [personName, setpersonName] = useState("");
  const [fullAdd, setfullAdd] = useState("");
  const [mobileNo, setmobileNo] = useState("");
  const [email, setEmail] = useState("");
  const [pinCode, setpinCode] = useState("");
  const [state, setState] = useState("");
  const [dl1, setdl1] = useState("");
  const [dl2, setdl2] = useState("");
  const [gst, setgst] = useState("");
  const [fssai, setfssai] = useState("");
  // const [error, setError] = useState(false);

  // radio button ke liye
  // registerAs
  const [registerAs, setregisterAs] = useState("");

  //  for bank details
  const [accountName, setAccountName] = useState("");
  const [accountNo, setAccountNumber] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [branch, setBranch] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const collectData = async () => {
    // Input validation for mobile number
    const isValidPhone = (mobileNo) => {
      return /^[6-9]\d{9}$/.test(mobileNo);
    };

    let mobresult = isValidPhone(mobileNo);

    if (!mobresult) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter a valid mobile number!",
      });
      return;
    }

    try {
      // Get token from local storage for headers
      let token = localStorage.getItem("x-api-key");

      // Prepare the data to send
      const data = {
        firmName: firmName.trim(),
        personName,
        fullAdd,
        mobileNo,
        email,
        pinCode,
        state,
        dl1,
        dl2,
        gst,
        fssai,
        registerAs,
        accountName,
        accountNo,
        ifsc,
        branch,
      };

      // Set loading state to true;

      setIsLoading(true);

      // Make the Axios POST request
      const response = await axios.post("api/user/firm/createfirmName", data, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": token,
          "x-api-plan": "SaaS",
        },
      });
      console.log(response.data.message, "response");

      // Handle the response data

      const result = response.data;

      const a = response.status;

      if (result.status === true) {
        Swal.fire("Created!", "Your firm has been created.", "success");
        //Navigate to a different page
        navigate("/user/list");
      } else if (result.status === false) {
        // Show an error message using Swal
        return Swal.fire({
          icon: "error",
          title: "Oops...",
          text: result.message,
        });
      }
    } catch (error) {
      // console.error("Error:", error.response.message);
      // Handle the error more gracefully, e.g., display an error message to the user
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response.data.message,
      });
    } finally {
      // Set loading state to false in both success and error cases
      setIsLoading(false);
    }
  };

  // Get all the input fields, textarea, and the Submit button
  const inputFields = Array.from(
    document.querySelectorAll(
      'input[type="text"],input[type="email"], input[type="radio"],textarea ,select'
    )
  );
  const submitButton = document.getElementById("button-sumbit-firm"); // Get the Submit button by its ID
  // Add an event listener to each input field, select, textarea, and the Submit button
  // Check if 'submitButton' is not null before pushing it into the 'inputFields' array

  if (submitButton) {
    inputFields.push(submitButton);
  }

  console.log(inputFields);

  inputFields.forEach((input, index) => {
    input.addEventListener("keyup", (event) => {
      if (event.key === "Enter") {
        // Prevent the default behavior of the Enter key
        event.preventDefault();

        // If there's a next input field, set focus to it
        if (index < inputFields.length - 1) {
          inputFields[index + 1].focus();
        } else {
          // If the current input is the last one, focus on the Submit button
          submitButton.focus();
        }
      }
    });
  });

  return (
    <Layout heading_1="Firm Name" heading_2="Firm Name Generator">
      <div className="form-box-firmGen" style={{ overflow: "hidden" }}>
        {/* <Header /> */}
        <div className="c-form-firm-gen-1">
          <div class="two-main">
            <fieldset className="top-row-firmname">
              <label
                id="c-form-required-firmname"
                className="c-form-label-top-label"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Firm Name
              </label>
              <input
                id="input1"
                type="text"
                className="firmnameinut inputFeild"
                placeholder="Enter Name"
                required="true"
                value={firmName}
                // placeholder="Enter firm name"
                onChange={(e) => setfirmName(e.target.value)}
              />
            </fieldset>
          </div>
          <div class="two-columns-three">
            <fieldset className="firmgenerate-fieldset-name">
              <label className="c-form-label">Contact Person Name</label>
              <input
                id="input2"
                type="text"
                className="c-form-input-firm inputFeild"
                required
                placeholder="Enter Contact Person Name"
                value={personName}
                onChange={(e) => setpersonName(e.target.value)}
              />
            </fieldset>
            <fieldset>
              <label className="c-form-label">Address</label>
              <input
                id="input3"
                type="text"
                className="c-form-input-firm inputFeild"
                required
                placeholder="Enter Address"
                value={fullAdd}
                onChange={(e) => setfullAdd(e.target.value)}
              />
            </fieldset>
          </div>
          <div className="two-columns-three">
            <fieldset>
              <label className="c-form-label">Mobile No.</label>
              <input
                id="input4"
                placeholder="Enter Mobile No."
                type="text"
                className="c-form-input-firm inputFeild"
                required
                value={mobileNo}
                onChange={(e) => setmobileNo(e.target.value)}
              />
            </fieldset>
            <fieldset>
              <label className="c-form-label">Email Address</label>
              <input
                id="input5"
                placeholder="Enter Email Address"
                type="email"
                className="c-form-input-firm inputFeild"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </fieldset>
          </div>
          <div className="two-columns-three">
            <fieldset>
              <label className="c-form-label">Pin Code</label>
              <input
                id="input6"
                placeholder="Enter Pincode"
                type="text"
                className="c-form-input-firm  inputFeild"
                required
                value={pinCode}
                onChange={(e) => setpinCode(e.target.value)}
              />
            </fieldset>
            <fieldset style={{ width: "27.1rem" }}>
              <label style={{ fontWeight: "bold" }} className="c-form-label-">
                State
              </label>
              <select
                id="input7"
                placeholder="Select State"
                type="select"
                className="c-form-select-firm-state  inputFeild"
                required
                value={state}
                onChange={(e) => setState(e.target.value)}
                style={{ height: "auto" }}
              >
                {Data.map((item, index) => (
                  <option
                    id={item.value}
                    value={item.label}
                    onChange={(e) => setState(e.target.value)}
                  >
                    {" "}
                    {item.label}
                  </option>
                ))}
              </select>
            </fieldset>
          </div>
          <label
            className="radiolabel-1"
            style={{ fontSize: "20px", fontWeight: "bold" }}
          >
            {" "}
            Register As
          </label>
          <div className="radiobutton-1">
            <div
              onClick={() => {
                setregisterAs("Manufacturer");
              }}
            >
              <input
                id="input8"
                className="inputradio-button "
                type="radio"
                value={registerAs}
                checked={registerAs === "Manufacturer"}
              />
              <label> Manufacturer</label>
            </div>
            <div
              onClick={() => {
                setregisterAs("Distributor");
              }}
            >
              <input
                id="input9"
                className="inputradio-button"
                type="radio"
                value={registerAs}
                checked={registerAs === "Distributor"}
              />
              <label> Distributor </label>
              <br />
            </div>
            <div
              onClick={() => {
                setregisterAs("Wholesaler");
              }}
            >
              <input
                id="input10"
                className="inputradio-button"
                type="radio"
                value={registerAs}
                checked={registerAs === "Wholesaler"}
              />
              <label> Wholesaler</label>
              <br />
            </div>

            <div
              onClick={() => {
                setregisterAs("Retailer");
              }}
            >
              <input
                id="input11"
                className="inputradio-button"
                type="radio"
                value={registerAs}
                name="Retailer"
                checked={registerAs === "Retailer"}
              />
              <label> Retailer</label>
              <br />
            </div>

            <div
              onClick={() => {
                setregisterAs("Hospital");
              }}
            >
              <input
                id="input12"
                className="inputradio-button"
                type="radio"
                value={registerAs}
                checked={registerAs === "Hospital"}
              />
              <label> Hospital</label>
            </div>
          </div>

          <div className="two-columns-three">
            <fieldset>
              <label className="c-form-label">DL. No. (1)</label>
              <input
                id="input13"
                placeholder="Enter DL No.(1)"
                type="text"
                className="c-form-input-firm inputFeild"
                required
                value={dl1}
                onChange={(e) => setdl1(e.target.value)}
              />
            </fieldset>

            <fieldset>
              <label className="c-form-label">DL. No.(2)</label>
              <input
                id="input14"
                placeholder="Enter D.L No 2"
                type="text"
                className="c-form-input-firm  inputFeild"
                required
                value={dl2}
                onChange={(e) => setdl2(e.target.value)}
              />
            </fieldset>
          </div>
          <div className="two-columns-three">
            <fieldset>
              <label className="c-form-label">GSTIN.</label>
              <input
                id="input15"
                placeholder="Enter GSTIN "
                type="text"
                className="c-form-input-firm  inputFeild"
                required
                value={gst}
                onChange={(e) => setgst(e.target.value)}
              />
            </fieldset>

            <fieldset>
              <label className="c-form-label" for="last-name">
                FSSAI No.
              </label>
              <input
                id="input16"
                placeholder="Enter FASSAI No."
                type="text"
                className="c-form-input-firm  inputFeild"
                required
                value={fssai}
                onChange={(e) => setfssai(e.target.value)}
              />
            </fieldset>
          </div>
          {/* //for bank details  */}
          <label
            className="banklabel-01-bank"
            style={{ fontSize: "20px", fontWeight: "bold" }}
          >
            Bank Details
          </label>
          <div className="spacing"></div>
          <div className="two-columns-three">
            <fieldset>
              <label className="c-form-label">Account Name</label>
              <input
                id="input17"
                placeholder="Enter Account Name"
                type="text"
                className="c-form-input-firm inputFeild"
                required
                value={accountName}
                onChange={(e) => setAccountName(e.target.value)}
              />
            </fieldset>

            <fieldset>
              <label className="c-form-label" for="last-name">
                Account No.
              </label>
              <input
                id="input18"
                placeholder="Enter Account No."
                type="text"
                className="c-form-input-firm inputFeild"
                required
                value={accountNo}
                onChange={(e) => setAccountNumber(e.target.value)}
              />
            </fieldset>
          </div>

          <div className="two-columns-three">
            <fieldset>
              <label className="c-form-label">IFSC Code</label>
              <input
                id="input19"
                placeholder="Enter IFSC Code"
                type="text"
                className="c-form-input-firm  inputFeild"
                required
                value={ifsc}
                onChange={(e) => setIfsc(e.target.value)}
              />
            </fieldset>

            <fieldset>
              <label className="c-form-label" for="last-name">
                Branch
              </label>
              <input
                id="input20"
                placeholder="Enter Branch Name"
                type="text"
                className="c-form-input-firm  inputFeild"
                required
                value={branch}
                onChange={(e) => setBranch(e.target.value)}
              />
            </fieldset>
          </div>
          <div className="firm-gen-submit-button">
            <button
              id="button-sumbit-firm"
              onClick={collectData}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "3rem",
                fontWeight: "bold",
              }}
            >
              {isLoading ? (
                <div style={{ marginBottom: "40px" }}>
                  <LoadingSpinner />
                </div>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FirmNameGenerator;

import "./StockList.css";

import { React, useEffect, useState, useRef } from "react";
import { FaRegFilePdf } from "react-icons/fa";
import { FaFileCsv, FaRegCopy } from "react-icons/fa";
import axios from "axios";
import Swal from "sweetalert2";

// import StockListHeader from "./StockListHeader";

import Layout from "../Layout/Layout";

import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { CSVLink } from "react-csv";

//============== Headers for csv file======================= //
const headers = [
  { label: "Product Name.", key: "productName" },
  { label: "Firm Name", key: "firmName" },
  { label: "Qty Purchase", key: "qtyPurchase" },
  { label: "Qty Sold", key: "qtySold" },
  { label: "Available Stocks", key: "availableStocks" },
];

export default function StockList() {
  const [stocks, setStocks] = useState([]);
  // const [stocks, setPurchaseListData] = useState("");

  useEffect(function () {
    stockApi();
  }, []);

  // useEffect(function () {
  //   if(filteredStockListId.length>0 && filteredStockListData.length>0){
  //   }
  // }, [filteredStockListId,filteredStockListData]);

  const stockApi = async () => {
    try {
      //token
      let token = localStorage.getItem("x-api-key");
      let response = await axios.post(
        "api/user/stocks/stocks",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": token,
            "x-api-plan": "SaaS",
          },
        }
      );
      let result = response.data;
      if (result.data) {
        setStocks(result.data);
        console.log(result);
      } else {
        setStocks([]);
      }
    } catch (error) {
      console.error(error.response.data.message);
    }
  };

  // code for filtering firm according to search bar;
  const [filteredList, setFilteredList] = new useState(stocks);
  const [filter, setFilter] = useState(false);
  const filterStockSearch = (event) => {
    setFilter(true);
    let updatedList = [...stocks];
    updatedList = updatedList.filter((item) => {
      return (
        item.firmName
          .toLowerCase()
          .indexOf(event.target.value.toLowerCase()) !== -1 ||
        item.productName
          .toLowerCase()
          .indexOf(event.target.value.toLowerCase()) !== -1
      );
    });
    setFilteredList(updatedList);
  };

  //==========  converting data to pdf=========== //
  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 350;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Stock List";
    const headers = [
      [
        "S.No",
        "Product Name",
        "Firm Name",
        "Qty Purchase",
        "Qty Sold",
        "Available Stocks",
      ],
    ];
    // console.log(filteredPurchaseListData);
    let data;
    if (filter) {
      data = filteredList.map((elt, index) => [
        index + 1,
        elt.productName,
        elt.firmName,
        elt.qtyPurchase,
        elt.qtySold,
        elt.availableStocks,
      ]);
    } else {
      data = stocks.map((elt, index) => [
        index + 1,
        elt.productName,
        elt.firmName,
        elt.qtyPurchase,
        elt.qtySold,
        elt.availableStocks,
      ]);
    }

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    autoTable(doc, content);
    doc.save("Stock List.pdf");
  };

  //========= converting filtered data to csv============
  const csvDownloadRef = useRef(null);
  const convertToCsv = () => {
    setTimeout(() => {
      csvDownloadRef.current.link.click();
    }, 500);
  };

  const ele = filter
    ? filteredList.map(function (item, index) {
        return (
          <tr key={item.id}>
            <td>
              <span className="span--width" style={{minWidth:'2rem'}}>{index + 1}</span>
            </td>
            <td>
              <span className="span--width" style={{minWidth:'10rem'}}>{item.productName}</span>
            </td>
            <td>
              <span className="span--width" style={{minWidth:'10rem'}}>{item.firmName}</span>
            </td>
            <td>
              <span className="span--width" style={{minWidth:'10rem'}}>{item.qtyPurchase}</span>
            </td>
            <td>
              <span className="span--width" style={{minWidth:'10rem'}}>{item.qtySold}</span>
            </td>
            <td>
              <span className="span--width" style={{minWidth:'10rem'}}>{item.availableStocks}</span>
            </td>
          </tr>
        );
      })
    : stocks.map(function (item, index) {
        return (
          <tr key={item.id}>
            <td>
              <span className="span--width">{index + 1}</span>
            </td>
            <td>
              <span className="span--width">{item.productName}</span>
            </td>
            <td>
              <span className="span--width">{item.firmName}</span>
            </td>
            <td>
              <span className="span--width">{item.qtyPurchase}</span>
            </td>
            <td>
              <span className="span--width">{item.qtySold}</span>
            </td>
            <td>
              <span className="span--width">{item.availableStocks}</span>
            </td>
          </tr>
        );
      });

  return (
    <Layout heading_1="Stocks" heading_2="Stock List">
      <div className="main-stocklist-file">
        <div className="stocklist-main">
          {/* <StockListHeader /> */}
          <div className="stocklist">
            {/* <div className="stocklist-header" style={{ height: "34px" }}>
            <h4>Stock List</h4>
          </div> */}

            <div className="table-top-stocklist">
              <div className="show-name-stocklist">
                <label htmlFor="show">Show : </label>
                <select
                  id="show showSelect"
                  style={{
                    height: "36px",
                    width: "69px",
                    marginLeft: "1rem",
                    marginRight: "1rem",
                    borderRadius: "5px",
                  }}
                >
                  <option>All</option>
                </select>
                <FaRegFilePdf
                  className="pdf-Selllist"
                  size={22}
                  style={{ cursor: "pointer" }}
                  onClick={(event) => {
                    exportPDF();
                  }}
                />
                <CSVLink
                  data={filter ? filteredList : stocks}
                  headers={headers}
                  filename="Stock List.csv"
                  className="hidden"
                  ref={csvDownloadRef}
                  target="_blank"
                />
                <FaFileCsv
                  className="csv-Selllist"
                  size={22}
                  style={{ cursor: "pointer" }}
                  onClick={(event) => {
                    convertToCsv(event);
                  }}
                />
                 <div className="search-name-stocklist">
                <input
                style={{
                    height: "36px",
                    width: "200px",
                    marginLeft: "1rem",
                    marginRight: "1rem",
                    borderRadius: "5px",
                }}
                  placeholder="Search by Product, Firm Name"
                  onChange={filterStockSearch}
                />
              </div>

              </div>
             
            </div>
            <div className="table-customer-stocklist"
              style={{
                // backgroundColor:'blue',
              }}
            >
              <table id="customers-stocklist"
            
              >
                <thead>
                  <tr className="table-customer-heading-stocklist">
                    <th style={{minWidth:'5rem'}}>S.No</th>
                    <th style={{minWidth:'10rem'}}>Product Name</th>
                    <th style={{minWidth:'10rem'}}>Firm Name</th>
                    <th style={{minWidth:'10rem'}}>Qty Purchase</th>
                    <th style={{minWidth:'10rem'}}>Qty Sold</th>
                    <th style={{minWidth:'14rem'}}>Available Stock</th>
                  </tr>
                  </thead>
                  <tbody>
                  {ele}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

import Layout from "components/Layout/Layout";
import React, { useEffect, useState } from "react";

const Desktop = () => {
//  heading_1="Firm Name" heading_2="Firm Update"
    return (
        <div>
        <Layout heading_1="Desktop-app" heading_2="Desktop-app">
          <div
            className="desktop-app"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "80vh",
              width: '160vh',
              padding: "20px", // Add padding as needed
              boxSizing: "border-box",
            }}
          >
            <div style={{ textAlign: "center", maxWidth: "100%" }}>
            <h1 style={{ color: "#015998", fontSize: "5vw", alignSelf:"center" }}>
            Coming Soon!
            </h1>

            </div>
          </div>
        </Layout>
      </div>
      
      
      
    );
};

export default Desktop;

import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';

const PrivateRouter = () => {
    const isLoggedIn = localStorage.getItem('isLoggedIn');

    if (isLoggedIn === 'true') {
        return <Outlet />;
    } else {
        return <Navigate to="/" />;
    }
}
export default PrivateRouter;


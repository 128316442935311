import React, { useState, useEffect } from 'react';

import "./PaymentCalculator.css";

import axios from 'axios';

import logoImage from '../login/heganv.png'

import Button from '@mui/material/Button';

import Swal from 'sweetalert2';

import Layout from "../Layout/Layout";

import hegan_text from "../../images/Logo-Hegan.png";

function PaymentCalculator() {

    const [restOfDays, setRestOfDays] = useState(null);

    const [amountToPay, setAmountToPay] = useState(0);

    const [finalAmount, setFinalAmount] = useState(0);

    const [userExpiry, setUserExpiry] = useState('');

    const [renewal, setRenewal] = useState('');

    const [gst, setGst] = useState(0);  // gst amount percetage
    const [gstAmount, setGstAmount] = useState('')

    const [finalAmountAfterCoupon, setFinalAmountAfterCoupon] = useState('');

    const [userName,setUserName] = useState('');
    const [userEmail,setUserEmail] = useState('');
    const [userMobile,setUserMobile] = useState('');

    const [paymentSuccess,setPaymentSuccess] = useState('');
    const [transactionId,setTransactionId] = useState('');

    console.log(window)

    //getting user details from backed to send in razor pay details prefill

    const userDetailsGet = async () => {
        try {
            let token = localStorage.getItem("x-api-key");
            const response = await axios.post('api/user/getOneUser',
            {},
            {
                headers: {
                    "Content-Type": "application/json",
                    "x-api-key": token,  "x-api-plan": "SaaS"
                },
            });
            console.log('getting loged in user details',response.data.data);
            setUserName(response.data.data.firstName)
            setUserEmail(response.data.data.email)
            setUserMobile(response.data.data.mobile)
            
        } catch (error) {
            console.log(error);
        }
    }



    const afterPaymentSuccess = async () => {
        try{
            let body = {
                restOfDays: restOfDays,
                amountToPay: amountToPay,
                amount: finalAmount,
                gst: gst,
            };
                
                
            let token = localStorage.getItem("x-api-key");
                
            const { data } = await axios.post(
                "api/user/createPayment",
                body,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": token,  "x-api-plan": "SaaS"
                    },
                }
            );
            console.log("this is response after paymet in frontend",data);
            if( data && data.status === true){
                Swal.fire({
                    icon:'success',
                    title: data.message,
                    showConfirmButton: false,
                    timer: 1000
                })
            }
    
        }catch(error){
          console.log('error in payment upgrade',error.message)
        }
    }

    const handlePaymentSuccess = (response) => {
        // Perform actions after successful payment
        console.log('Payment successful!', response);
        setTransactionId(response.razorpay_payment_id)
        setPaymentSuccess('success')
        afterPaymentSuccess();

         // Call fetchUserDetails after 2 seconds
        setTimeout(() => {
            fetchUserDetails();
            window.location.reload();
        }, 2000);
        // Redirect or trigger actions based on payment success
        // window.location.href = "https://saas.hegan.in/user/dashboard"; // Redirect to the specified URL
      };

      // Function to handle payment failure
const handlePaymentError = (error) => {
    // Perform actions after payment failure
    console.error('Payment failed!', error);
    alert(error)
    // Add logic to inform the user about payment failure or take necessary actions
    // For example, display an error message to the user
  };

    let payNow = async function () {
        try {
        let options = {
            key: 'rzp_test_pxrlDvmVludYqJ', 
            // key: 'rzp_live_WjrHP9XjM1v083', //live api key 
            amount: finalAmountAfterCoupon*100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            currency: "INR",
            name: "Hegan.in",
            description: "Test Transaction",
            image:logoImage,
            // order_id: data.id,
            // callback_url: "https://testingadmin.hegan.in/dashboard/approved-registrations",
            handler: handlePaymentSuccess, 
            prefill: {
                name: userName,
                email: userEmail,
                contact: userMobile,
            },
            notes: {
                address: "Razorpay Corporate Office"
            },
            theme: {
                color: "#3399cc",
            },  modal: {
                ondismiss: function() {
                  handlePaymentError('Payment cancelled by user');
                },
                onclose: function() {
                  handlePaymentError('Payment modal closed');
                },
                onpaymenterror: function(error) {
                  handlePaymentError(error);
                }
              }
        };

        let rezorpayment = new window.Razorpay(options);

        rezorpayment.open();

        console.log('razorpayment done',rezorpayment);
            
        } catch (error) {
            console.error(error.message);
        }
    };


    // end

    // Simulate fetching user payment details from an API

    // Replace this with actual API call

    const fetchUserDetails = async () => {

        let token = localStorage.getItem("x-api-key");

        await axios.post("api/user/paymentDetails", {}, {
            headers: {
                "Content-Type": "application/json",
                "x-api-key": token,  "x-api-plan": "SaaS"
            },
        }
        ).then((response) => {
            console.log('response in getting all data for user payment', response);
            if (response.data.status === 200 && response.data.data) {
                setUserExpiry(response.data.data.expiryDate);
                setRestOfDays(response.data.data.restOfDays);
                setAmountToPay(response.data.data.paybleAmount);
                setFinalAmountAfterCoupon(response.data.data.finalAmount)
                setFinalAmount(response.data.data.finalAmount);
                setGst(response.data.data.gst);
                setGstAmount(response.data.data.gst)
                setRenewal(response.data.data.renewal)
            }if (response.data.data2){
                setUserExpiry(response.data.data2.expiryDate);
            }
        }).catch((error) => {
            console.error("Error:", error.response.data.message);
        })
    };

    useEffect(() => {
        fetchUserDetails();
        userDetailsGet();
    }, []);

    const couponArray = [
        {
          name: "Coupon 1",
          expiryDate: "2023-12-31",
          amount: 50,
        },
        {
          name: "Coupon 2",
          expiryDate: "2023-11-30",
          amount: 75,
        },
        // Add more coupon objects if needed
      ];


      const gstPercentage = 18; // Assuming GST is 18%

      const handleCouponSelection = (event) => {
          const selectedCouponIndex = event.target.value;
          if (selectedCouponIndex === "none") {
              const gstAmount = amountToPay * (gstPercentage / 100);
              setGstAmount(gstAmount.toFixed(2));
              setFinalAmountAfterCoupon(amountToPay + gstAmount);
          } else {
              const selectedCoupon = couponArray[selectedCouponIndex];
              const couponDiscount = selectedCoupon.amount;
              const gstAmount = (amountToPay - couponDiscount) * (gstPercentage / 100);
              setGstAmount(gstAmount.toFixed(2));
              const updatedAmount = amountToPay - couponDiscount + gstAmount;
              setFinalAmountAfterCoupon(updatedAmount);
          }
      };


      // if user expiry is 31st then making this 
      function getNextYearMarch31st(providedDate) {
        // Extracting the year from the provided date
        let year = Number(providedDate.substring(0, 4)); // Extracting the year part and converting it to a number
      
        // Creating a new date for March 31st of the next year
        let nextYearDate = new Date(year + 1, 2, 31); // Month in JavaScript is 0-indexed, so March is represented by 2
      
        // Formatting the next year's March 31st date in the same format
        let formattedNextYearDate = `${nextYearDate.getFullYear()}-${(nextYearDate.getMonth() + 1)
          .toString()
          .padStart(2, '0')}-${nextYearDate.getDate().toString().padStart(2, '0')} 00:00:00`;
      
        return `Your Plan Validity Ends On 31st March ${year}. Renew Before Plan Expires`
        // to ${nextYearDate.getFullYear()} 31st March. Next billing date: ${formattedNextYearDate}`;
      }
    

    return (
        <div
         style={{ width: "150vh" }}
        >
            <Layout heading_1="Upgrade" heading_2="Upgrade Your Plan">
               { !paymentSuccess ? ( <div className="payment-container">
                    <h3>User Payment Details</h3>
                    {restOfDays ? (
                        <div className="user-details">
                            <ul>
                                {/* <li><strong>Name:</strong> {userName}</li> */}
                                <li><strong>Amount:</strong> ₹{amountToPay}</li>
                              
                                <li>
                                    {/* <strong>Coupon Code</strong> <input></input>  */}
                                    <li><strong>Coupon Code:</strong> 
                                    <li>
                  
                    <select 
                    onChange={handleCouponSelection}
                    
                    >
                        <option value="none">Select a coupon</option>
                        {couponArray.map((coupon, index) => (
                            <option key={index} value={index}>
                                {coupon.name} - Expires: {coupon.expiryDate} - Amount: ₹{coupon.amount}
                            </option>
                        ))}
                    </select>
                </li>
                </li>
                                </li>
                                <li><strong>GST-18%:</strong> ₹{gstAmount}</li>
                                <li><strong>Amount to Pay:</strong> ₹{finalAmountAfterCoupon}</li>
                            </ul>
                        </div>
                    ) : (
                        <>
                        <p>Billing Cycle For Every Year Starts From 1st April - 31st March</p>
                        <p>{getNextYearMarch31st(userExpiry)}</p>
                        </>
                    )}
                    <div style={{ display: "flex", justifyContent: "center",marginTop:10 }}>
                  
                         <button
        onClick={() => { payNow() }}
        variant="contained"
        size="small"
        color="success"
        style={{
          pointerEvents: restOfDays ? 'auto' : 'none', // Enable or disable pointer events
          opacity: restOfDays ? 1 : 0.5, // Adjust opacity based on restOfDays
          // Add other styles as needed
        }}
        disabled={!restOfDays} // Disable the button based on restOfDays
      >
        {renewal ? 'Renewal' : 'Pay Now'}
      </button>
                    </div>
                </div> ) : (
                     <div style={{ display: 'flex', width: '100%', height: '100vh', backgroundColor: 'rgba(0, 0, 0, 0.5)', position: 'fixed', top: 0, left: 0, zIndex: 999 }}>
                     <div style={{ display: 'flex', width: '70%', marginTop: 10, justifyContent: 'center', alignItems: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
                       <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                         <div style={{ width: '300px', height: '450px', backgroundColor: '#fff', borderRadius: '15px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                           <h1 style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '20px' }}>Payment Success</h1>
                           <div style={{ backgroundColor: 'green', padding: '20px', borderRadius: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                             <p style={{ color: '#fff', fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Plan Upgraded Successfully</p>
                             <span style={{ fontSize: '30px', color: '#fff' }}>&#10004;</span>
                           </div>
                           <p>Transaction Id : {transactionId}</p>
                           <button onClick={() => { setPaymentSuccess('')}} style={{ padding: '10px 20px', marginTop: '20px', fontSize: '16px', borderRadius: '5px', backgroundColor: '#3399cc', color: '#fff', border: 'none', cursor: 'pointer',width:'30%' }}>OK</button>
               
                         </div>
                     </div>
                     </div>
                   </div>
                )}
            </Layout>
        </div>
    );
}

export default PaymentCalculator;

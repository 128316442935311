import React, { useEffect, useState } from "react";
import axios from "axios";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  LineElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler,
} from "chart.js/auto";
ChartJS.register(
  Title,
  Tooltip,
  LineElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler
);

function Graph() {
  // setting info for graph

  const [graphLabel, setGraphLabel] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [datasets, setDatasets] = useState([
    {
      indexAxis: "y",
      label: "Sale of Products",
      // data: [70, 59, 80, 90, 56],
      data: [],
      fill: true,
      backgroundColor: ["#0A9AE3", "#E30A0A", "#E3990A", "#0A20E3", "#C451ED"],
      scales: {
        xAxes: [
          {
            gridLines: {
              color: "white",
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              color: "rgba(0, 0, 0, 0)",
            },
          },
        ],
      },

      borderWidth: 1,
    },
  ]);

  const updateGraph = (value, limit) => {
    for (let i = 0; i < limit; i++) {
      graphLabel.push(value[i].productName);
      setGraphLabel(graphLabel);
      graphData.push(value[i].qtySold);
      setGraphData(graphData);
    }
    const labels = graphLabel;
    graphLabel.length = limit;
    graphData.length = limit;
    // console.log(graphData,graphLabel);

    setDatasets([...datasets, (datasets[0].data = graphData)]);

    setProductData({ ...productData, labels, datasets });
  };

  const [productData, setProductData] = React.useState({
    // labels:["Zandu Nityam Tablet","Charun","Ashwagandha","Zandu Pancharishta","Triphala Tablet" ],
    labels: [],
    datasets: [
      {
        indexAxis: "y",
        label: "Sale of Products",
        // data: [70, 59, 80, 90, 56],
        data: [],
        fill: true,
        backgroundColor: [
          "#0A9AE3",
          "#E30A0A",
          "#E3990A",
          "#0A20E3",
          "#C451ED",
        ],
        scales: {
          xAxes: [
            {
              gridLines: {
                color: "white",
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                color: "rgba(0, 0, 0, 0)",
              },
            },
          ],
        },

        borderWidth: 1,
      },
    ],
    options: {
      scales: {
        x: {
          gridLines: {
            display: false,
          },
        },
        y: {
          gridLines: {
            display: false,
          },
        },
      },
    },
  });

  // getting  top selling products from backend.

  const getTopSellingProducts = async () => {
    try {
      let token = localStorage.getItem("x-api-key");
      const response = await axios.post(
        "api/user/dashboard/maximumSellProduct",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": token,
            "x-api-plan": "SaaS",
          },
        }
      );
      console.log(149, response.data);

      const responseData = response.data;
      // Call the updateGraph function with the response data
      updateGraph(responseData.data, responseData.data.length);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Call the function to get the top selling products data;

  useEffect(() => {
    getTopSellingProducts();
  }, []);

  return (
    <div className="graph2">
      <p>Sale of Products</p>
      <Bar data={productData}>Hello</Bar>
    </div>
  );
}

export default Graph;

import React, { useState, useEffect } from "react";
import "./ImageCarousel.css"; // Make sure to import the CSS file
import axios from "axios";

const ImageCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [images, setImages] = useState([]);

  useEffect(() => {
    getImages();
  }, []);

  const getImages = async () => {
    try {
      let token = localStorage.getItem("x-api-key");
      let response = await axios.post(
        "api/admin/slider/getAllv2LoginSliderImg",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": token,
            "x-api-plan": "SaaS",
          },
        }
      );

      let result = response.data;
      setImages(result.data);
      console.log("image length", result.data.length);
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  useEffect(() => {
    if (images.length > 1) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) =>
          prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
      }, 2000);

      return () => clearInterval(interval);
    }
  }, [images]);

  return (
    <div className="carousel-container">
      <div className="image-container">
        {images && images.length ? (
          images.map((image, index) => (
            <img
              key={index}
              src={image.url}
              alt={`Slide ${index + 1}`}
              className={index === currentIndex ? "active" : ""}
            />
          ))
        ) : (
          <h1>No images found</h1>
        )}
      </div>
    </div>
  );
};

export default ImageCarousel;

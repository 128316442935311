import React, { useState, useEffect } from "react";
import Swal from "sweetalert2"; // Import Swal if needed
import Layout from "../Layout/Layout"; // Import Layout if needed
import "./profile.css";
import heganLogo from "../../images/Logo-Hegan.png";
import checkMark from "../../images/check-mark.png";
import { useLocation } from "react-router-dom";
import axios from "axios";
const Profile = () => {
  const location = useLocation();
  const [isEditMode, setIsEditMode] = useState(false);
  const [formData, setFormData] = useState({}); // State for form data
  const [userDetails, setUserDetails] = useState({});
  const [userId, setUserId] = useState("");
  const [otp, setOtp] = useState("");
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isOtpVerifiedCss, setIsOtpVerifiedCss] = useState(false);

  const updateUserdata = async () => {
    let upateObj = {};
    if (formData.mobile != userDetails.mobile) {
      upateObj["mobile"] = formData.mobile;
    }
    if (formData.firstName != userDetails.firstName) {
      upateObj["firstName"] = formData.firstName;
      localStorage.setItem("userName", formData.firstName);
    }
    if (formData.lastName != userDetails.lastName) {
      upateObj["lastName"] = formData.lastName;
    }
    if (formData.email != userDetails.email) {
      upateObj["email"] = formData.email;
    }
    return upateObj;
  };

  const [showResend, setShowResend] = useState(false);
  const [timer, setTimer] = useState(120);

  const startTimer = () => {
    let timeLeft = 120;
    const countdown = setInterval(() => {
      if (timeLeft > 0) {
        timeLeft--;
        setTimer(timeLeft);
      } else {
        clearInterval(countdown);
        setShowResend(true);
      }
    }, 1000);
  };

  const handleSendOtp = async () => {
    // Generate an OTP and send it to the user's mobile number
    // Send the OTP via SMS or your preferred method here
    // Set the state to indicate that OTP has been sent
    let token = localStorage.getItem("x-api-key");
    const response = await axios.post(
      "api/user/otp",
      { mobile: formData.mobile },
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": token,
          "x-api-plan": "SaaS",
        },
      }
    );

    let result = response.data;

    // console.log("response", result);

    if (result.message === "success") {
      setIsOtpSent(true);
      startTimer();
      Swal.fire(
        "Succefully!",
        `Your OTP has been sent to ${formData.mobile} number.`,
        "success"
      );
    } else {
      //  Swal.fire("Invalid Mobile!", `Please Enter Correct Mobile Number`, "failed");
      // alert('Invalid OTP. Please try again.');
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: result.message,
      });
    }
  };

  //handleVerifyMobile;

  const handleVerifyMobile = async () => {
    try {
      // Make an API request to verify the OTP
      if (!otp) {
        return Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please Enter Otp",
        });
      }
      let token = localStorage.getItem("x-api-key");
      const response = await axios.post(
        "api/user/otpVerify",
        { otp },
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": token,
            "x-api-plan": "SaaS",
          },
        }
      );

      let result = response.data;

      if (result.status === true) {
        setIsOtpVerified(true);
        setIsOtpVerifiedCss(true);
        Swal.fire("Succefully!", `Your OTP has been verified.`, "success");
        // alert('Mobile Verified Successfully');
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response.data.message,
      });
      console.error(error.message);
    }
  };

  useEffect(() => {
    user();
  }, []);

  const user = async () => {
    //token
    let token = localStorage.getItem("x-api-key");

    let response = await axios.post(
      "api/user/getOneUser",
      {},
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": token,
          "x-api-plan": "SaaS",
        },
        // method: "post",
      }
    );

    let result = response.data;
    if (result.message == "Please Login")
      Swal.fire({
        title: "Please Login",
        text: "Session Expire",
        icon: "error", // 'info', 'success', 'error', 'warning', etc.
        // confirmButtonText: 'OK',
      });
    console.log(580, result);
    setUserDetails(result.data);
    setUserId(result.data.id);
    setFormData({
      firstName: result.data.firstName,
      lastName: result.data.lastName,
      email: result.data.email,
      mobile: result.data.mobile,
    });
  };
  // Function to handle changes in the input fields;
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(98, name, value);
    setFormData({ ...formData, [name]: value });
  };

  // Function to handle form submission;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isEditMode) {
      if (formData.mobile != userDetails.mobile && !isOtpVerified) {
        return Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please verify your mobile number with otp verification",
        });
      }
      // Handle form submission to update profile on the server
      // You can use your existing code here to send the updated data to the server
      // After successful submission, switch back to view mode
      let token = localStorage.getItem("x-api-key");
      await axios
        .post(
          "api/user/updateUservtwo",
          { data: await updateUserdata() },
          {
            // method: 'POST',
            // body: JSON.stringify({ mobile: formData.mobile,  firstName: formData.firstName, lastName: formData.lastName, email: formData.email,}),
            // body: JSON.stringify(await updateUserdata()),
            headers: {
              "Content-Type": "application/json",
              "x-api-key": token,
              "x-api-plan": "SaaS",
            },
          }
        )
        .then((response) => {
          if (response.data.status == 200) {
            Swal.fire(
              "Successully!",
              "Your Profile has been Updated.",
              "success"
            );
            setTimeout(function () {
              window.location.reload();
            }, 1000);
            setIsEditMode(false);
            setIsOtpVerified(false);
            setIsOtpSent(false);
            setIsOtpVerifiedCss(false);
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.message,
          });
        });
    } else {
      // Switch to edit mode if not already in edit mode
      setIsEditMode(true);
    }
  };

  return (
    <div>
      <Layout heading_1="Profile" heading_2="My Profile">
        {/* <div class="form-box-firmGen-profile">
  <div class="c-form-firm-gen-1-profile">
    <h4 class="heading-firm-gen-1-profile">Profile Information</h4>
    <div class="top-text">
      <button class="edit-profile-button" onClick={handleSubmit}>
        {isEditMode ? "Save Profile" : "Edit Profile"}
      </button>
    </div>
    <form onSubmit={handleSubmit} class="one-columns">
      <div class="two-columns-three-profile">
        <div class="inner-two-columns-three-profile">
          <div class="key-value-pair">
            <label>First Name:</label>
            {isEditMode ? (
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                placeholder="Enter your first name"
              />
            ) : (
              <p>{userDetails.firstName}</p>
            )}
          </div>
          <div class="key-value-pair">
            <label>Last Name:</label>
            {isEditMode ? (
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                placeholder="Enter your last name"
              />
            ) : (
              <p>{userDetails.lastName}</p>
            )}
          </div>
        </div>
        <div class="inner-two-columns-three-profile">
          <div class="key-value-pair">
            <label>Email:</label>
            {isEditMode ? (
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter your email"
              />
            ) : (
              <p>{userDetails.email}</p>
            )}
          </div>
          <div class="key-value-pair">
            <label>Mobile No.:</label>
            {isEditMode && (
              <>
                <input
                  type="text"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  placeholder="Enter your mobile number"
                />
                {isOtpSent && isOtpVerified && (
                  <>
                    <img src={heganLogo} alt="Phone Icon" width="25" height="25" />
                    <img src={checkMark} alt="Second Icon" width="25" height="25" />
                  </>
                )}
                {isOtpSent && !isOtpVerified && (
                  <>
                    <input
                      type="text"
                      name="otp"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      placeholder="Enter OTP"
                    />
                    <button type="button" onClick={handleVerifyMobile}>
                      Verify
                    </button>
                  </>
                )}
                {!isOtpSent && !isOtpVerified && (
                  <button type="button" onClick={handleSendOtp}>
                    Send OTP
                  </button>
                )}
              </>
            )}
            {!isEditMode && (
              <div>
                <p>{userDetails.mobile}</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div class="two-columns-three-profile">
        <div class="key-value-pair">
          <label>Firm Name:</label>
          <p>{userDetails.firmName}</p>
        </div>
        <div class="key-value-pair">
          <label>User Code:</label>
          <p>{userDetails.userName}</p>
        </div>
        <div class="key-value-pair">
          <label>Address:</label>
          <p>{userDetails.address}</p>
        </div>
        <div class="key-value-pair">
          <label>State:</label>
          <p>{userDetails.state}</p>
        </div>
      </div>
      <hr />
      <div class="two-columns-three-profile">
        <div class="key-value-pair">
          <label>DL No.1:</label>
          <p>{userDetails.dl_no_20b}</p>
        </div>
        <div class="key-value-pair">
          <label>DL No.2:</label>
          <p>{userDetails.dl_no_21b}</p>
        </div>
        <div class="key-value-pair">
          <label>GST No.:</label>
          <p>{userDetails.gst_no}</p>
        </div>
        <div class="key-value-pair">
          <label>FSSAI No.:</label>
          <p>{userDetails.fssai_no}</p>
        </div>
      </div>
      <hr />
      <div class="two-columns-three-profile-s">
        <label>Register As:</label>
        <p>{userDetails.registerType}</p>
      </div>
      <div>
        <h5>Bank Details</h5>
      </div>
      <div class="two-columns-three-profile">
        <div class="key-value-pair">
          <label>Account Name:</label>
          <p>{userDetails.account_name}</p>
        </div>
        <div class="key-value-pair">
          <label>Account No.:</label>
          <p>{userDetails.account_no}</p>
        </div>
        <div class="key-value-pair">
          <label>Branch:</label>
          <p>{userDetails.branch}</p>
        </div>
        <div class="key-value-pair">
          <label>IFSC Code:</label>
          <p>{userDetails.ifsc_code}</p>
        </div>
      </div>
      <hr />
    </form>
  </div>
</div> */}

        <div className="form-box-firmGen-profile">
          <div className="c-form-firm-gen-1-profile">
            {/* <h4 className="heading-firm-gen-1-profile">Profile Information</h4> */}

            <table className="user-profile-table">
              <tbody>
                <tr>
                  <td>First Name:</td>
                  <td>
                    {isEditMode ? (
                      <input
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        placeholder="Enter your first name"
                      />
                    ) : (
                      <p>{userDetails.firstName}</p>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Last Name:</td>
                  <td>
                    {isEditMode ? (
                      <input
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        placeholder="Enter your last name"
                      />
                    ) : (
                      <p>{userDetails.lastName}</p>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Email:</td>
                  <td>
                    {isEditMode ? (
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Enter your email"
                      />
                    ) : (
                      <p>{userDetails.email}</p>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Mobile No.:</td>
                  <td>
                    {isEditMode && (
                      <>
                        <input
                          type="text"
                          name="mobile"
                          value={formData.mobile}
                          onChange={handleChange}
                          placeholder="Enter your mobile number"
                        />
                        {isOtpSent && isOtpVerified && (
                          <>
                            <img
                              src={heganLogo}
                              alt="Phone Icon"
                              width="25"
                              height="25"
                            />
                            <img
                              src={checkMark}
                              alt="Second Icon"
                              width="25"
                              height="25"
                            />
                          </>
                        )}
                        {isOtpSent && !isOtpVerified && (
                          <>
                            <input
                              type="text"
                              name="otp"
                              value={otp}
                              onChange={(e) => setOtp(e.target.value)}
                              placeholder="Enter OTP"
                            />
                            <button type="button" onClick={handleVerifyMobile}>
                              Verify
                            </button>
                          </>
                        )}
                        {showResend && (
                          <button
                            onClick={() => {
                              setShowResend(false);
                              handleSendOtp();
                            }}
                          >
                            Resend OTP
                          </button>
                        )}
                        {timer > 0 && <p>Time left: {timer} seconds</p>}

                        {!isOtpSent && !isOtpVerified && (
                          <button type="button" onClick={handleSendOtp}>
                            Send OTP
                          </button>
                        )}
                      </>
                    )}
                    {!isEditMode && <p>{userDetails.mobile}</p>}
                  </td>
                </tr>
                <tr>
                  <td>Firm Name:</td>
                  <td>{userDetails.firmName}</td>
                </tr>
                <tr>
                  <td>User Code:</td>
                  <td>{userDetails.userName}</td>
                </tr>
                <tr>
                  <td>Address:</td>
                  <td>{userDetails.address}</td>
                </tr>
                <tr>
                  <td>State:</td>
                  <td>{userDetails.state}</td>
                </tr>
                <tr>
                  <td>DL No.1:</td>
                  <td>{userDetails.dl_no_20b}</td>
                </tr>
                <tr>
                  <td>DL No.2:</td>
                  <td>{userDetails.dl_no_21b}</td>
                </tr>
                <tr>
                  <td>GST No.:</td>
                  <td>{userDetails.gst_no}</td>
                </tr>
                <tr>
                  <td>FSSAI No.:</td>
                  <td>{userDetails.fssai_no}</td>
                </tr>
                <tr>
                  <td>Register As:</td>
                  <td>{userDetails.registerType}</td>
                </tr>
                <tr>
                  <td>Account Name:</td>
                  <td>{userDetails.account_name}</td>
                </tr>
                <tr>
                  <td>Account No.:</td>
                  <td>{userDetails.account_no}</td>
                </tr>
                <tr>
                  <td>Branch:</td>
                  <td>{userDetails.branch}</td>
                </tr>
                <tr>
                  <td>IFSC Code:</td>
                  <td>{userDetails.ifsc_code}</td>
                </tr>
              </tbody>
            </table>

            <div className="top-text">
              <button className="edit-profile-button" onClick={handleSubmit}>
                {isEditMode ? "Save Profile" : "Edit Profile"}
              </button>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Profile;

import React, { useState, useEffect, useRef } from "react";
import "./LedgerList.css";
import { FaFileCsv, FaRegFilePdf, FaRegCopy } from "react-icons/fa";
import { useNavigate, Link } from "react-router-dom";
import Swal from "sweetalert2";
import Nav2 from "../../navbar/Nav2";
import axios from "axios";
// import { LedgerListHeader } from "./LedgerListHeader";
import Layout from "../../Layout/Layout";

import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { CSVLink } from "react-csv";

//============== Headers for csv file======================= //
const headers = [
  { label: "A/C Name", key: "bankName" },
  { label: "Region", key: "region" },
  { label: "Date", key: "currDate" },
  { label: "OpeningBalance", key: "openingBalance" },
  { label: "CurrentBalance", key: "currentBalance" },
  { label: "Credit", key: "totalAmtReceive" },
  { label: "Debit", key: "totalAmtPaid" },
];

const LedgerList = () => {
  useEffect(() => {
    getLedgerEntry();
  }, []);

  // getting all ledger details from data base

  const [ledger, setLedgerData] = useState([]);

  const getLedgerEntry = async () => {
    let token = localStorage.getItem("x-api-key");
    await axios
      .post(
        "api/user/ledger/list",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": token,
            "x-api-plan": "SaaS",
          },
        }
      )
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.length > 0
        ) {
          console.log("getting data from server", response.data.data);
          setLedgerData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  // code for filtering firm according to search bar;
  const [filteredList, setFilteredList] = new useState(ledger);
  const [filter, setFilter] = useState(false);
  const filterListSearch = (event) => {
    setFilter(true);
    let updatedList = [...ledger];
    updatedList = updatedList.filter((item) => {
      // we can add one more field to search like invoice number;
      return (
        item.bankName
          .toLowerCase()
          .indexOf(event.target.value.toLowerCase()) !== -1
      );
    });
    console.log("updatedList", updatedList);
    setFilteredList(updatedList);
  };

  //==========  converting data to pdf===========//
  // const exportPDF = () => {
  //   const unit = "pt";
  //   const size = "A4"; // Use A1, A2, A3 or A4
  //   const orientation = "portrait"; // portrait or landscape

  //   const marginLeft = 350;
  //   const doc = new jsPDF(orientation, unit, size);

  //   doc.setFontSize(15);

  //   const title = "Ledger-List";
  //   const headers = [
  //     ["S.No.", "A/C Name", "Region", "Date.", "Opening Balance.","Current Balance" ,"Credit", "Debit"],
  //   ];

  //   let data;
  //   if (filter && filteredList && filteredList.length > 0) {
  //     data = filteredList.map((elt, index) => [
  //       index + 1,
  //       elt.bankName,
  //       elt.region,
  //       elt.currDate,
  //       elt.openingBalance,
  //       elt.currentBalance,
  //       elt.totalAmtReceive,
  //       elt.totalAmtPaid,
  //     ]);
  //   } else {
  //     data = ledger.map((elt, index) => [
  //       index + 1,
  //       elt.bankName,
  //       elt.region,
  //       elt.currDate,
  //       elt.openingBalance,
  //       elt.currentBalance,
  //       elt.totalAmtReceive,
  //       elt.totalAmtPaid,
  //     ]);
  //   }

  //   let content = {
  //     startY: 50,
  //     head: headers,
  //     body: data,
  //   };

  //   doc.text(title, marginLeft, 40);
  //   autoTable(doc, content);
  //   doc.save("Ledger-List.pdf");
  // };

  //date format
  const dateformat = (dateString) => {
    // alert('coming')
    // const dateString = '2023-11-16 12:00:57';
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Adding 1 because getMonth() returns zero-based months (0 for January)
    const day = date.getDate();

    console.log("Year:", year);
    console.log("Month:", month);
    console.log("Day:", day);
    return `${day}/${month}/${year}`;
  };

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Ledger-List";
    const titleWidth =
      (doc.getStringUnitWidth(title) * doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const pageWidth = doc.internal.pageSize.getWidth();
    const titleX = (pageWidth - titleWidth) / 2; // Calculate the X position for the centered title

    const headers = [
      [
        "S.No.",
        "A/C Name",
        "Region",
        "Date.",
        "Opening Balance.",
        "Current Balance",
        "Credit",
        "Debit",
      ],
    ];

    let data;
    if (filter && filteredList && filteredList.length > 0) {
      data = filteredList.map((elt, index) => [
        index + 1,
        elt.bankName,
        elt.region,
        elt.currDate,
        elt.openingBalance,
        elt.currentBalance,
        elt.totalAmtReceive,
        elt.totalAmtPaid,
      ]);
    } else {
      data = ledger.map((elt, index) => [
        index + 1,
        elt.bankName,
        elt.region,
        elt.currDate,
        elt.openingBalance,
        elt.currentBalance,
        elt.totalAmtReceive,
        elt.totalAmtPaid,
      ]);
    }

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, titleX, 40);
    autoTable(doc, content);
    doc.save("Ledger-List.pdf");
  };

  //========= converting filtered data to csv============ //
  const csvDownloadRef = useRef(null);
  const convertToCsv = () => {
    setTimeout(() => {
      csvDownloadRef.current.link.click();
    }, 500);
  };

  const ele = filter
    ? filteredList.map(function (item, index) {
        return (
          <tr key={index}>
            <td>
              <span className="span-width">{index + 1}</span>
            </td>
            <td>
              <span className="span-width">{item.bankName}</span>
            </td>
            <td>
              <span className="span-width">{item.region}</span>
            </td>
            <td>
              <span className="span-width">{item.currDate}</span>
            </td>
            <td>
              <span className="span-width">{item.openingBalance}</span>
            </td>
            <td>
              <span className="span-width">{item.currentBalance}</span>
            </td>
            <td>
              <span className="span-width">{item.totalAmtReceive}</span>
            </td>
            <td>
              <span className="span-width">{item.totalAmtPaid}</span>
            </td>
          </tr>
        );
      })
    : ledger.map(function (item, index) {
        return (
          <tr key={index}>
            <td>
              <span className="span-width">{index + 1}</span>
            </td>
            <td>
              <span className="span-width">{item.bankName}</span>
            </td>
            <td>
              <span className="span-width">{item.region}</span>
            </td>
            <td>
              <span className="span-width">{item.currDate}</span>
            </td>
            <td>
              <span className="span-width">{item.openingBalance}</span>
            </td>
            <td>
              <span className="span-width">{item.currentBalance}</span>
            </td>
            <td>
              <span className="span-width">{item.totalAmtReceive}</span>
            </td>
            <td>
              <span className="span-width">{item.totalAmtPaid}</span>
            </td>
          </tr>
        );
      });

  function DeleteLedgerlist(id) {
    var id = id;
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: false,
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteProduct = async (id) => {
          //token
          let token = localStorage.getItem("x-api-key");
          await axios
            .delete(`api/user/ledger/delete/${id}`, {
              headers: {
                "Content-Type": "application/json",
                "x-api-key": token,
                "x-api-plan": "SaaS",
              },
            })
            .then((response) => {
              Swal.fire("Deleted!", "Your file has been deleted.", "success");
              // window.location.reload();
              // calling get all ledger list function for refresh of the list
              getLedgerEntry();
            })
            .catch((error) => {
              console.log(error.message);
            });
        };
        deleteProduct(id);
      }
    });
  }

  return (
    <Layout heading_1="Ledger" heading_2="Ledger List">
      <div className="main-ledger-div">
        {/* <div className="header-ledger-list" style={{ height: "36px" }}>
          <h4>Ledger List</h4>
        </div> */}

        <div className="table-top-Productlist">
          <div className="show-name-Productlist">
            <label htmlFor="show">Show : </label>
            <select
              id="show showSelect"
              style={{
                height: "36px",
                width: "69px",
                marginLeft: "1rem",
                marginRight: "1rem",
                borderRadius: "5px",
              }}
            >
              <option>All</option>
            </select>
            <FaRegFilePdf
              className="pdf-Ledger-List"
              size={22}
              style={{ cursor: "pointer" }}
              onClick={(event) => {
                exportPDF();
              }}
            />
            <CSVLink
              data={filter ? filteredList : ledger}
              headers={headers}
              filename="Ledger-List.csv"
              className="hidden"
              ref={csvDownloadRef}
              target="_blank"
            />
            <FaFileCsv
              className="csv-Ledger-List"
              style={{ cursor: "pointer" }}
              size={22}
              onClick={(event) => {
                convertToCsv(event);
              }}
            />
          </div>

          <div className="search-name-Productlist">
            <input
              placeholder="Search by Firm, A/C Name..."
              onChange={filterListSearch}
            />
          </div>
        </div>

        <div className="third-ledger-list">
          <table className="table-ledger--list">
            <tr>
              <th>S.No</th>
              <th>A/C Name</th>
              <th>Region</th>
              <th>Date</th>
              <th>Opening Balance</th>
              <th>Current Balance</th>
              <th>Credit</th>
              <th>Debit</th>
              <th>Action</th>
            </tr>

            {filter
              ? filteredList.map(function (item, index) {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item.bankName}</td>
                      <td>{item.region}</td>
                      <td>{item.currDate}</td>
                      <td>{item.openingBalance}</td>
                      <td>{item.currentBalance}</td>
                      <td>{item.totalAmtReceive}</td>
                      <td>{item.totalAmtPaid}</td>
                      <td className="action-button-Purchase-table">
                        <button className="view-sale-list">
                          {/* <Link to={"/ledger/view/" + item.id}>View </Link> */}

                          <Link to={`/user/ledger/view/${item.id}`}>View</Link>

                          {/* ledger/view/:id" */}
                        </button>
                        <button className="view-sale-list">
                          <Link to={"/user/ledger-update/" + item.id}>
                            Edit{" "}
                          </Link>
                        </button>
                        <button
                          className="del-sale-list"
                          onClick={() => {
                            DeleteLedgerlist(item.id);
                          }}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })
              : ledger && ledger.length > 0 ? ( ledger.map(function (item, index) {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item.bankName}</td>
                      <td>{item.region}</td>
                      <td>{dateformat(item.currDate)}</td>
                      <td>{item.openingBalance}</td>
                      <td>{item.currentBalance}</td>
                      <td>{item.totalAmtReceive}</td>
                      <td>{item.totalAmtPaid}</td>
                      <td className="action-button-Purchase-table">
                        <button className="view-sale-list">
                          {/* <Link to={"/ledger/view/" + item.id}>View </Link> */}

                          <Link to={`/user/ledger/view/${item.id}`}>View</Link>
                        </button>
                        <button className="view-sale-list">
                          <Link to={"/user/ledger-update/" + item.id}>
                            Edit{" "}
                          </Link>
                        </button>
                        <button
                          className="del-sale-list"
                          onClick={() => {
                            DeleteLedgerlist(item.id);
                          }}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })) : <h6 style={{alignSelf:"center",justifyContent:"center",alignItems:"center"}}>no data found in ledger list</h6> }
          </table>
        </div>
      </div>
    </Layout>
  );
};

export default LedgerList;

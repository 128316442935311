/** @format */

import React from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  useProSidebar,
} from "react-pro-sidebar";

import Swal from "sweetalert2";

import "./Header.css";
import { HiBars3 } from "react-icons/hi2";
import { useParams, useNavigate, Link } from "react-router-dom";
import hegan from "../images/Logo-Hegan.png";
import hegan_text from "../images/Hegan_text.png";
import dashboardlogo from "../images/Dashboard.png";
import firmdash from "../images/Firm-dash.png";
import product from "../images/product_name.png";
import purchase from "../images/Purchase.png";
import sale from "../images/Sell.png";
import reports from "../images/Business_Report2.png";
import stocks from "../images/stock.png";
import bank from "../images/Bank_Account.png";
import account from "../images/account.png";
import settings from "../images/Settings.png";
import refer from "../images/refer.png";
import menubar from "../images/menubar.png";

import { useState, useEffect } from "react";
import axios from "axios";
import { useGlobalContext } from "components/Context";
const Header = () => {
  let { handleLogout } = useGlobalContext();

  const { collapseSidebar } = useProSidebar();

  const [showLogo, setShowLogo] = useState(true);
  const handleToggleSidebar = () => {
    collapseSidebar();
    setShowLogo(!showLogo);
  };

  // this code is for F5 button functionalities;
  const navigate = useNavigate();

  const [showMenuItem, setShowMenuItem] = useState(
    sessionStorage.getItem("showMenuItem") === "true"
  );

  useEffect(() => {
    const handleF5KeyPress = (event) => {
      // event.preventDefault();
      if (event.keyCode === 116) {
        // F5 key has keycode 116
        setShowMenuItem((prevShowMenuItem) => !prevShowMenuItem);
        sessionStorage.setItem("showMenuItem", !showMenuItem);
      }
    };

    window.addEventListener("keydown", handleF5KeyPress);

    return () => {
      window.removeEventListener("keydown", handleF5KeyPress);
    };
  }, [showMenuItem]);

  // refer and earn;

  // Define a state to keep track of whether the share link is visible or not
  const [showShareLink, setShowShareLink] = useState(false);

  // Replace these with your actual referral link and referral ID
  const referralLink = "http://hegan.in/partner-register";
  const referralId = "YOUR_REFERRAL_ID";

  // Custom text to be shared
  const customText = `Join our referral program and earn rewards! Your referral ID: ${referralId}`;

  // Function to handle the share button click
  const handleShareClick = () => {
    if (navigator.share) {
      const shareData = {
        title: "Refer and Earn",
        text: customText,
        url: `${referralLink}?referralId=${referralId}`, // Include referralId as a query parameter
      };

      navigator
        .share(shareData)
        .then(() => console.log("Shared successfully"))
        .catch((error) => console.error("Error sharing:", error));
    } else {
      setShowShareLink(true);
    }
  };

  // Backup API ;

  const backupData = () => {
    let token = localStorage.getItem("x-api-key");
    axios
      .post(
        "api/user/zipfile",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": token,
            "x-api-plan": "SaaS",
          },
          responseType: "blob", // Add this line to specify the response type
        }
      )
      .then((response) => {
        // Create a temporary anchor element to initiate the download
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "backupFile.zip");
        document.body.appendChild(link);
        link.click();

        // Clean up the temporary URL and anchor element
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.error("Error:", error));
  };

  // Log out API;
  function handleLogoutButton() {
    // Display the confirmation dialog
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, logout",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        // User clicked the "Yes, logout" button, call the API
        handleLogout();
        setTimeout(() => {
          navigate("/");
        }, 1000);
      }
    });
  }

  return (
    <div className="header-bar-main">
      <div className="triple-dot-slide">
        <span className="sb-button">
          <HiBars3 className="navbar-logo-size" style={{ marginTop: "0rem" }} />
        </span>
      </div>
      <div className="sidebar" style={{ marginTop: "-3rem" }}>
        <Sidebar transitionDuration={1000} className="mainsidebar">
          <Menu>
            <div className="mainpage-app-left-logo">
              {showLogo && (
                <div className="mainpage-app-left-logo">
                  <MenuItem
                    icon={
                      <img
                        // onClick={() => collapseSidebar()}
                        className="logo-hegan"
                        src={hegan}
                        style={{
                          marginTop: "4.5rem",
                          width: "30px",
                          height: "30px",
                          marginRight: 20,
                        }}
                      />
                    }
                  ></MenuItem>
                  <MenuItem
                    icon={
                      <img
                        className="logo-hegan"
                        src={hegan_text}
                        style={{
                          width: "70px",
                          height: "19px",
                          marginTop: "72px",
                          marginRight: "80px",
                        }}
                      />
                    }
                  ></MenuItem>
                </div>
              )}
              <MenuItem
                icon={
                  <img
                    onClick={() => handleToggleSidebar()}
                    className="logo-hegan"
                    src={menubar}
                    style={{
                      width: "70px",
                      height: "19px",
                      marginTop: "72px",
                      marginRight: "80px",
                      alignSelf: "center",
                    }}
                  />
                }
              ></MenuItem>
            </div>
          </Menu>
          <div style={{ overflowY: "auto" }}>
            <Menu>
              <MenuItem
                component={<Link to="/user/dashboard" />}
                icon={
                  <img
                    className="dashboardlogo"
                    src={dashboardlogo}
                    style={{ height: "23px", width: "23px" }}
                  />
                }
                // style={{ fontWeight: "bold" }}
                style={{ fontWeight: "bold", color: "black" }}
              >
                Dashboard
              </MenuItem>
            </Menu>
            <Menu className="mainmenu">
              <SubMenu
                className="firmsubmenu"
                icon={<img className="firmdash" src={firmdash} />}
                label="Firm Name"
                style={{ fontWeight: "bold", color: "black" }}
              >
                <MenuItem
                  style={{ color: "black" }}
                  component={<Link to="/user/firmgenerate" />}
                >
                  Generate
                </MenuItem>
                <MenuItem
                  style={{ color: "black" }}
                  component={<Link to="/user/list" />}
                >
                  {" "}
                  Firm List
                </MenuItem>
              </SubMenu>
              <SubMenu
                icon={
                  <img
                    className="firmdash productImage"
                    src={product}
                    style={{ height: "23px", width: "23px" }}
                  />
                }
                label="Product Name"
                style={{ fontWeight: "bold", color: "black" }}
              >
                <MenuItem
                  style={{ color: "black" }}
                  component={<Link to="/user/productgenerate" />}
                >
                  Product Generate
                </MenuItem>
                <MenuItem
                  style={{ color: "black" }}
                  component={<Link to="/user/productlist" />}
                >
                  {" "}
                  Product List
                </MenuItem>
              </SubMenu>
              <SubMenu
                icon={
                  <img
                    className="purchase"
                    src={purchase}
                    style={{ height: "23px", width: "23px", color: "black" }}
                  />
                }
                label="Purchase"
                style={{ fontWeight: "bold", color: "black" }}
              >
                <MenuItem
                  style={{ color: "black" }}
                  component={<Link to="/user/purchaseentry" />}
                >
                  {" "}
                  Purchase Entry
                </MenuItem>
                <MenuItem
                  style={{ color: "black" }}
                  component={<Link to="/user/purchase-list-main" />}
                >
                  {" "}
                  Purchase List-Main
                </MenuItem>
                {showMenuItem && (
                  <MenuItem
                    style={{ color: "black" }}
                    component={<Link to="/user/purchase-list-estimate" />}
                  >
                    {" "}
                    Purchase List-Estimate
                  </MenuItem>
                )}
              </SubMenu>
              <SubMenu
                icon={
                  <img
                    className="sale"
                    src={reports}
                    style={{ width: "23px", height: "23px", color: "black" }}
                    alt="Settings Icon"
                  />
                }
                label="Purchase Report"
                style={{ fontWeight: "bold", color: "black" }}
              >
                <MenuItem
                  style={{ color: "black" }}
                  component={<Link to="/user/purchase-report" />}
                >
                  {" "}
                  Purchase Report
                </MenuItem>
                <MenuItem
                  style={{ color: "black" }}
                  component={<Link to="/user/purchase-goods-report" />}
                >
                  {" "}
                  Goods Return Report
                </MenuItem>
                <MenuItem
                  style={{ color: "black" }}
                  component={<Link to="/user/purchase-voucher-report" />}
                >
                  Credit Note Voucher Report
                </MenuItem>
              </SubMenu>
              <SubMenu
                icon={
                  <img
                    className="sale"
                    src={sale}
                    style={{ width: "23px", height: "23px" }}
                    alt="Settings Icon"
                  />
                }
                label="Sale"
                style={{ fontWeight: "bold", color: "black" }}
              >
                <MenuItem
                  style={{ color: "black" }}
                  component={<Link to="/user/sales-entry" />}
                >
                  {" "}
                  Sale Entry
                </MenuItem>
                <MenuItem
                  style={{ color: "black" }}
                  component={<Link to="/user/sales-list-main" />}
                >
                  {" "}
                  Sale List-Main
                </MenuItem>

                {showMenuItem && (
                  <MenuItem
                    style={{ color: "black" }}
                    component={<Link to="/user/sales-list-estimate" />}
                  >
                    {" "}
                    Sale List-Estimate
                  </MenuItem>
                )}
              </SubMenu>
              <SubMenu
                icon={
                  <img
                    className="sale"
                    src={reports}
                    style={{ width: "23px", height: "23px" }}
                    alt="Settings Icon"
                  />
                }
                label="Sale Report"
                style={{ fontWeight: "bold", color: "black" }}
              >
                <MenuItem
                  style={{ color: "black" }}
                  component={<Link to="/user/sales-report" />}
                >
                  {" "}
                  Sale Report
                </MenuItem>
                <MenuItem
                  style={{ color: "black" }}
                  component={<Link to="/user/sales-goods-report" />}
                >
                  {" "}
                  Goods Return Report
                </MenuItem>
                <MenuItem
                  style={{ color: "black" }}
                  component={<Link to="/user/sales-voucher-report" />}
                >
                  Credit Note Voucher Report
                </MenuItem>
              </SubMenu>
              <SubMenu
                icon={
                  <img
                    className="stocks"
                    src={stocks}
                    style={{ height: "23px", width: "23px" }}
                  />
                }
                label="Stock"
                style={{ fontWeight: "bold", color: "black" }}
              >
                <MenuItem
                  style={{ color: "black" }}
                  component={<Link to="/user/stock-list" />}
                >
                  {" "}
                  Stock Available
                </MenuItem>
              </SubMenu>
              <SubMenu
                icon={
                  <img
                    className="bank"
                    src={bank}
                    style={{ height: "23px", width: "23px" }}
                  />
                }
                label="Bank"
                style={{ fontWeight: "bold", color: "black" }}
              >
                <MenuItem
                  style={{ color: "black" }}
                  component={<Link to="/user/bank-view" />}
                >
                  {" "}
                  Bank Details
                </MenuItem>
              </SubMenu>
              <SubMenu
                icon={
                  <img
                    className="account"
                    src={account}
                    style={{ height: "20px", width: "20px" }}
                  />
                }
                label="Accounts"
                style={{ fontWeight: "bold", color: "black" }}
              >
                <MenuItem
                  style={{ color: "black" }}
                  component={<Link to="/user/ledger" />}
                >
                  {" "}
                  Ledger Entry{" "}
                </MenuItem>
                <MenuItem
                  style={{ color: "black" }}
                  component={<Link to="/user/ledgerList" />}
                >
                  {" "}
                  Ledger List{" "}
                </MenuItem>
              </SubMenu>
              <SubMenu
                icon={
                  <img
                    style={{ width: "23px", height: "23px" }}
                    className="refer"
                    src={refer}
                    alt="Refer Icon"
                  />
                }
                label="Refer And Earn"
                style={{ fontWeight: "bold", color: "black" }}
              >
                <MenuItem
                  style={{ color: "black" }}
                  component={<Link to="/user/refferal" />}
                >
                  Refer and Earn
                </MenuItem>
              </SubMenu>
              <SubMenu
                icon={
                  <img
                    style={{ width: "23px", height: "23px" }}
                    className="settings"
                    src={settings}
                    alt="Settings Icon"
                  />
                }
                label="Setting"
                style={{ fontWeight: "bold", color: "black" }}
              >
                <MenuItem
                  style={{ color: "black" }}
                  component={<Link to="/user/invoice-assign" />}
                >
                  {" "}
                  Invoice Assign{" "}
                </MenuItem>
                <MenuItem
                  style={{ color: "black" }}
                  onClick={
                    localStorage.getItem("isLoggedIn") == "true"
                      ? backupData
                      : null
                  }
                >
                  Backup
                </MenuItem>

                {/* <MenuItem style={{ fontWeight: "bold" }} component={<Link to="/user/Profile" />}>
                  {" "}
                  Profile{" "}
                </MenuItem> */}
                <MenuItem
                  style={{ color: "black" }}
                  component={<Link to="/user/coupons" />}
                >
                  {" "}
                  Coupons{" "}
                </MenuItem>
                <MenuItem
                  style={{ color: "black" }}
                  component={<Link to="/user/marketplace" />}
                >
                  {" "}
                  Export To Marketplace{" "}
                </MenuItem>
                <MenuItem
                  onClick={handleLogoutButton}
                  style={{ color: "black" }}
                  component={<Link to="/" />}
                >
                  {" "}
                  Log Out{" "}
                </MenuItem>
              </SubMenu>
            </Menu>
          </div>
        </Sidebar>
      </div>
    </div>
  );
};

export default Header;

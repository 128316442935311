import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useParams, useNavigate } from "react-router-dom";
import "./upgrade.css";

import Layout from "../Layout/Layout";

const UpgradePlan = () => {
  const navigate = useNavigate();

  const proceedPayment = () => {
    return navigate("/user/paymentCalculator");
  };

  // useEffect(() => {
  //     user();
  // }, [])
  // // ============== seller details api for print out ==================== //
  // let [userDetails, setUserDetails] = useState({});
  // const user = async () => {
  //     //token
  //     let token = localStorage.getItem("x-api-key");

  //     let result = await fetch("https://hegan-v2-backend-3.onrender.com/api/user/getOneUser", {
  //         headers: {
  //             "Content-Type": "application/json",
  //             "x-api-key": token,  "x-api-plan": "SaaS"
  //         },
  //         method: "post",
  //     });
  //     result = await result.json();
  //     console.log(580, result);
  //     setUserDetails(result.data);
  // };

  // if (!userDetails.ifsc_code) {
  //     userDetails.ifsc_code = "NA"
  // }
  // if (!userDetails.branch) {
  //     userDetails.branch = "NA"
  // }
  // if (!userDetails.account_no) {
  //     userDetails.account_no = "NA"
  // }
  // if (!userDetails.account_name) {
  //     userDetails.account_name = "NA"
  // }
  // if (!userDetails.fssai_no) {
  //     userDetails.fssai_no = "NA"
  // }
  // if (!userDetails.gst_no) {
  //     userDetails.gst_no = "NA"
  // }
  // if (!userDetails.dl_no_21b) {
  //     userDetails.dl_no_21b = "NA"
  // }
  // if (!userDetails.dl_no_20b) {
  //     userDetails.dl_no_20b = "NA"
  // };

  return (
    <div style={{ width: "68rem" }}>
      <Layout heading_1="Upgrade" heading_2="Upgrade Your Plan">
        <div className="upgrade_plan" style={{ overflow: "hidden" }}>
          <div className="text-upgrade">
            <h4>Hegan SaaS - Your Billing Software - Yearly Plan </h4>
          </div>
          <div className="paragraph-1">
            <p>
              We hope you've enjoyed your three-month trial experience with
              Hegan SaaS - Billing Software! Now that your trial period is
              coming to an end, we invite you to continue enjoying the benefits
              of our powerful billing software by renewing your subscription.
              With a full plan, you'll unlock even more features, priority
              support, and uninterrupted access to our platform. Renew today and
              let us continue to be your trusted partner in managing your
              billing needs.
            </p>
          </div>
          <div className="text-upgrade">
            <h4>
              We Have Outlined The Details Of Hegan SaaS Annual Plan Below:
            </h4>
          </div>
          <div className="list-rate">
            <ul>
              <li>
                <span class="label">Product Name:</span>
                <span class="value">Hegan SaaS - Your Billing Software</span>
              </li>
              <li>
                <span class="label">Subscription Type:</span>
                <span class="value">Annual</span>
              </li>
              <li>
                <span class="label">Subscription Cost:</span>
                <span class="value">7999/- Yearly + GST</span>
              </li>
              <li>
                <span class="label">Billing Cycle:</span>
                <span class="value">1st April - 31st March</span>
              </li>
            </ul>
          </div>
          <div className="text-upgrade">
            <h4>Benefits, If You Upgrade!</h4>
          </div>
          <div className="list-footer">
            <ul>
              <li>
                <span class="label">Priority Support:</span>
                <span class="content">
                  We value our subscribers and prioritize their needs. Get
                  dedicated, lightning-fast support whenever you need it.
                </span>
              </li>
              <li>
                <span class="label">Unlimited Access:</span>
                <span class="content">
                  Say goodbye to limitations. With our plan, you'll have
                  unlimited access to all our premium features and tools.
                </span>
              </li>
              <li>
                <span class="label">Exclusive Features:</span>
                <span class="content">
                  Gain access to exclusive features and updates that are
                  available to our subscribers. Stay ahead of the competition.
                </span>
              </li>
              <li>
                <span class="label">Enhanced Security:</span>
                <span class="content">
                  We take data security seriously. Renewing your subscription
                  ensures that your data remains protected with the latest
                  security measures.
                </span>
              </li>
              <li>
                <span class="label">Cost Savings:</span>
                <span class="content">
                  By choosing the Annual Renewal option, you can benefit from
                  cost savings compared to a monthly subscription.
                </span>
              </li>
              <li>
                <span class="label">Access to Updates:</span>
                <span class="content">
                  You will continue to receive regular software updates and
                  enhancements, keeping your billing software at the forefront
                  of industry standards.
                </span>
              </li>
            </ul>
          </div>
          <div class="proceed-button">
            <button onClick={proceedPayment}>PROCEED</button>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default UpgradePlan;

import { React } from "react";
import { useGlobalContext } from "components/Context";
import './NotificationList.css';
import Layout from "../Layout/Layout";
export function NotificationList() {
    let { notifications } = useGlobalContext();
    return (
        <div style={{ width: "68rem" }}>
            {/* Use the Layout component with appropriate props */}
            <Layout heading_1="Notifications" heading_2="Notifications List"> 
                <div className="notification-popup-container">
                    {notifications.map(notification => (
                        <div key={notification.id} className="notification-popup">
                            <div className="notification-header">
                                <h2 className='text-h2'>{notification.admin_notification.heading}</h2>
                            </div>
                            <div className="notification-content">
                                <h2 className='text-h2'>{notification.admin_notification.message}</h2>
                            </div>
                            <div className="notification-content">
                                <h2 className='text-h2'>{notification.admin_notification.valid}</h2>
                            </div>
                        </div>
                    ))}
                </div>
            </Layout> {/* Closing tag for Layout component */}
        </div>
    );
};

export default NotificationList;


import "./saleslist.css";
// import Header from "./SalesListMainHeader";
import Layout from "../../Layout/Layout.js";
import Swal from "sweetalert2";
import { React, useEffect, useState, useRef } from "react";
import { FaRegFilePdf } from "react-icons/fa";
import { FaFileCsv, FaRegCopy } from "react-icons/fa";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { CSVLink } from "react-csv";

//============== Headers for csv file=======================//
const headers = [
  { label: "Date", key: "date" },
  { label: "Invoice N0.", key: "properInvoice" },
  { label: "Firm Name", key: "firmName" },
  { label: "Region", key: "region" },
  { label: "Type", key: "type" },
  { label: "Amount", key: "amount" },
];

export default function SalesListMain() {
  const [SalesListMainData, setSalesListMainData] = useState([]);

  useEffect(() => {
    getSalesListMainDataFunction();
  }, []);

  const getSalesListMainDataFunction = async () => {
    try {
      let token = localStorage.getItem("x-api-key");
      let response = await axios.post(
        "api/user/sell/sellMList",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": token,
            "x-api-plan": "SaaS",
          },
        }
      );
      let result = response.data;
      if (result.status === false) {
        setSalesListMainData([]);
      } else {
        setSalesListMainData(result.data);
      }
      console.log("51", SalesListMainData);
    } catch (error) {
      console.error(error.response.data.message);
    }
  };

  // code for filtering firm according to search bar;
  const [filteredList, setFilteredList] = new useState(SalesListMainData);
  const [filter, setFilter] = useState(false);
  const filterListSearch = (event) => {
    setFilter(true);
    let updatedList = [...SalesListMainData];
    updatedList = updatedList.filter((item) => {
      // we can add one more field to search like invoice number;
      return (
        item.firmName
          .toLowerCase()
          .indexOf(event.target.value.toLowerCase()) !== -1 ||
        item.properInvoice
          .toLowerCase()
          .indexOf(event.target.value.toLowerCase()) !== -1
      );
    });
    console.log("updatedList", updatedList);
    setFilteredList(updatedList);
  };

  //==========  converting data to pdf=========== //
  const exportPDF = () => {
    try {
      const unit = "pt";
      const size = "A4"; // Use A1, A2, A3 or A4
      const orientation = "portrait"; // portrait or landscape

      const marginLeft = 350;
      const doc = new jsPDF(orientation, unit, size);

      doc.setFontSize(15);

      const title = "Sell List-Main";
      const headers = [
        [
          "S.No",
          "Date",
          "Invoice No.",
          "Firm Name",
          "Region",
          "Type",
          "Amount",
        ],
      ];
      // console.log(filteredPurchaseListData);
      let data;
      if (filter) {
        data = filteredList.map((elt, index) => [
          index + 1,
          elt.date,
          elt.properInvoice,
          elt.firmName,
          elt.region,
          elt.type,
          elt.amount,
        ]);
      } else {
        data = SalesListMainData.map((elt, index) => [
          index + 1,
          elt.date,
          elt.properInvoice,
          elt.firmName,
          elt.region,
          elt.type,
          elt.amount,
        ]);
      }

      let content = {
        startY: 50,
        head: headers,
        body: data,
      };

      doc.text(title, marginLeft, 40);
      autoTable(doc, content);
      doc.save("Sell List-Main.pdf");
    } catch (error) {
      console.error(error.messege);
    }
  };

  //========= converting filtered data to csv============
  const csvDownloadRef = useRef(null);
  const convertToCsv = () => {
    setTimeout(() => {
      csvDownloadRef.current.link.click();
    }, 500);
  };

  // delete product function
  function DeleteSalesListMainFunction(id) {
    var id = id;
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: false,
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    })
      .then((result) => {
        if (result.isConfirmed) {
          const deleteProduct = async (id) => {
            //token
            let token = localStorage.getItem("x-api-key");
            let response = await axios.delete(
              `api/user/sell/deleteSellM/${id}`,
              {
                // method: "Delete",
                headers: {
                  "Content-Type": "application/json",
                  "x-api-key": token,
                  "x-api-plan": "SaaS",
                },
              }
            );
            let result = response.data;
            if (result) {
              Swal.fire("Deleted!", "Your file has been deleted.", "success");
              // calling get all purchase list function for refresh of the list
              getSalesListMainDataFunction();
            }
          };
          deleteProduct(id);
        }
      })
      .catch((error) => {
        console.error(error.result.data.message);
      });
  }

  const ele = filter
    ? filteredList.map(function (item, index) {
        return (
          <tr>
            <td>
              <span className="span-width">{index + 1}</span>
            </td>
            <td>
              <span className="span-width">{item.date}</span>
            </td>
            <td>
              <span className="span-width">{item.properInvoice}</span>
            </td>
            <td>
              <span className="span-width">{item.firmName}</span>
            </td>
            <td>
              <span className="span-width">{item.region}</span>
            </td>
            <td>
              <span className="span-width">{item.type}</span>
            </td>
            <td>
              <span className="span-width">{item.grandTotal}</span>
            </td>
            <td className="action-button-Purchase-table">
              {/* <button className="edit-sale-list">
              <Link to={"/user/sales-update/" + item.id}>Edit</Link>
            </button> */}
              <button className="edit-sale-list">
                <Link
                  to={{
                    pathname: "/user/sales-update/" + item.id,
                    search: "?editType=main",
                  }}
                >
                  Edit
                </Link>
              </button>
              <button className="view-sale-list">
                <Link to={"/user/sales-view-main/" + item.id}>View </Link>
              </button>
              {/* <button onClick={(() => {navigateToSaleListView(item.id);})} className='view-sale-list'>View</button> */}
              <button
                className="del-sale-list"
                onClick={() => {
                  DeleteSalesListMainFunction(item.id);
                }}
              >
                Delete
              </button>
            </td>
          </tr>
        );
      })
    : SalesListMainData.map(function (item, index) {
        return (
          <tr>
            <td>
              <span className="span-width">{index + 1}</span>
            </td>
            <td>
              <span className="span-width">{item.date}</span>
            </td>
            <td>
              <span className="span-width">{item.properInvoice}</span>
            </td>
            <td>
              <span className="span-width">{item.firmName}</span>
            </td>
            <td>
              <span className="span-width">{item.region}</span>
            </td>
            <td>
              <span className="span-width">{item.type}</span>
            </td>
            <td>
              <span className="span-width">{item.grandTotal}</span>
            </td>
            <td className="action-button-Purchase-table">
              {/* <button className="edit-sale-list">
              <Link to={"/user/sales-update/" + item.id}>Edit</Link>
            </button> */}
              <button className="edit-sale-list">
                <Link
                  to={{
                    pathname: "/user/sales-update/" + item.id,
                    search: "?editType=main",
                  }}
                >
                  Edit
                </Link>
              </button>

              <button className="view-sale-list">
                <Link to={"/user/sales-view-main/" + item.id}>View </Link>
              </button>
              {/* <button onClick={(() => {navigateToSaleListView(item.id);})} className='view-sale-list'>View</button> */}
              <button
                className="del-sale-list"
                onClick={() => {
                  DeleteSalesListMainFunction(item.id);
                }}
              >
                Delete
              </button>
            </td>
          </tr>
        );
      });

  return (
    <Layout heading_1="Sales" heading_2="Sales List Main">
      <div className="main-SalesListMain-file">
        <div className="SalesListMain-main">
          {/* <Header /> */}
          <div className="SalesListMain">
            {/* <div className="SalesListMain-header" style={{ height: "34px" }}>
            <h4>Sales List Main</h4>
          </div> */}

            <div className="table-top-SalesListMain">
              <div className="show-name-SalesListMain">
                <label htmlFor="show">Show : </label>
                <select
                  id="show showSelect"
                  style={{
                    height: "36px",
                    width: "69px",
                    marginLeft: "1rem",
                    marginRight: "1rem",
                    borderRadius: "5px",
                  }}
                >
                  <option>All</option>
                </select>
                <FaRegFilePdf
                  className="pdf-Selllist"
                  size={22}
                  style={{ cursor: "pointer" }}
                  onClick={(event) => {
                    exportPDF();
                  }}
                />
                <CSVLink
                  data={filter ? filteredList : SalesListMainData}
                  headers={headers}
                  filename="Sell List-Main.csv"
                  className="hidden"
                  ref={csvDownloadRef}
                  target="_blank"
                />
                <FaFileCsv
                  className="csv-Selllist"
                  size={22}
                  style={{ cursor: "pointer" }}
                  onClick={(event) => {
                    convertToCsv(event);
                  }}
                />
              </div>

              <div className="search-name-SalesListMain">
                <input
                  placeholder="Search by Firm,Invoice No."
                  onChange={filterListSearch}
                />
              </div>
            </div>
            <div className="table-customer-SalesListMain">
              <table id="customers-SalesListMain">
                <tr className="table-customer-heading-SalesListMain">
                  <th>S.No</th>
                  <th>Date</th>
                  <th>Invoice No.</th>
                  <th>Firm Name </th>
                  <th>Region</th>
                  <th>Type</th>
                  <th>Amount </th>
                  <th>Action</th>
                </tr>
                {ele}
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

import "./GoodsReport.css";
import { React, useEffect, useState, useRef } from "react";
import { FaRegFilePdf } from "react-icons/fa";
import { FaFileCsv, FaRegCopy } from "react-icons/fa";
// import GoodsReportHeader from "./GoodsReportHeader";
import Layout from "../../Layout/Layout";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { CSVLink } from "react-csv";
import axios from "axios";
import { useGlobalContext } from "components/Context";

//============== Headers for csv file=======================//
const headers = [
  { label: "Date", key: "date" },
  { label: "Firm Name", key: "firmName" },
  { label: "Invoice No.", key: "invoiceNo" },
  { label: "Region", key: "region" },
  { label: "Product Name", key: "productName" },
  { label: "Disc %", key: "discPercent" },
  { label: "SGST", key: "sgst" },
  { label: "CGST", key: "cgst" },
  { label: "IGST", key: "igst" },
  { label: "CESS", key: "cess" },
  { label: "Total Tax", key: "totalTax" },
  { label: "Amount", key: "amount" },
];

export default function Goodsreport() {
  const pageSize = 10;

  let { PaginationControlled, page, setTotalPages } = useGlobalContext();

  useEffect(() => {
    getPurchaseGoodsReport();
  }, [page]);

  const [PurchaseGoodsReport, setPurchaseGoodsReport] = useState([]);

  // useEffect(() => {
  //   getPurchaseGoodsReport();
  // }, []);

  const getPurchaseGoodsReport = async () => {
    //token
    let token = localStorage.getItem("x-api-key");
    let response = await axios.post(
      "api/user/purchase/goodReturnsPurchase",
      {},
      {
        // method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": token,
          "x-api-plan": "SaaS",
        },
      }
    );
    let result = response.data;
    if (result.data.length > 0) {
      setPurchaseGoodsReport(result.data);
    } else {
      setPurchaseGoodsReport([]);
    }
    // setPurchaseGoodsReport(result.data);
    // console.log("PurchaseGoodsReport", result.data);
  };

  // code for filtering firm according to search bar;

  const [filteredList, setFilteredList] = new useState(PurchaseGoodsReport);
  const [filter, setFilter] = useState(false);
  const filterPurchaseReportSearch = (event) => {
    setFilter(true);
    let updatedList = [...PurchaseGoodsReport];
    updatedList = updatedList.filter((item) => {
      return (
        item.firmName
          .toLowerCase()
          .indexOf(event.target.value.toLowerCase()) !== -1 ||
        item.productName
          .toLowerCase()
          .indexOf(event.target.value.toLowerCase()) !== -1
      );
    });
    setFilteredList(updatedList);
  };

  //==========  converting data to pdf=========== //
  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 350;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Goods-Return Report";
    const headers = [
      [
        "S.No.",
        "Date",
        "Firm Name",
        "Invoice No.",
        "Region",
        "Product Name",
        "Discount Amt.",
        "SGST",
        "CGST",
        "IGST",
        "CESS.",
        "Total Tax Amt.",
        "Amount",
      ],
    ];
    // console.log(filteredGoodsReturnReport);
    let data;
    if (filter) {
      data = filteredList.map((elt, index) => [
        index + 1,
        elt.date,
        elt.firmName,
        elt.invoiceNo,
        elt.region,
        elt.productName,
        elt.discPercent,
        elt.sgst,
        elt.cgst,
        elt.igst,
        elt.cess,
        elt.totalTax,
        elt.amount,
      ]);
    } else {
      data = PurchaseGoodsReport.map((elt, index) => [
        index + 1,
        elt.date,
        elt.firmName,
        elt.invoiceNo,
        elt.region,
        elt.productName,
        elt.discPercent,
        elt.sgst,
        elt.cgst,
        elt.igst,
        elt.cess,
        elt.totalTax,
        elt.amount,
      ]);
    }

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    autoTable(doc, content);
    doc.save("Goods-Return Report.pdf");
  };

  //========= converting filtered data to csv============
  const csvDownloadRef = useRef(null);
  const convertToCsv = () => {
    setTimeout(() => {
      csvDownloadRef.current.link.click();
    }, 500);
  };

  // start

  // Your data (Firm) and other variables

  // Calculate the start and end index for the current page

  const startIndex = (page - 1) * pageSize;

  const endIndex = startIndex + pageSize;

  // Filter the data based on the current page;

  let displayedData = (
    filter
      ? filteredList.slice(startIndex, endIndex)
      : PurchaseGoodsReport.slice(startIndex, endIndex)
  ).map((item, index) => ({
    ...item,
    displayIndex: (page - 1) * pageSize + index + 1,
  }));

  // Calculate the total number of pages;

  const totalPages = Math.ceil(
    (filter ? filteredList.length : PurchaseGoodsReport.length) / pageSize
  );

  setTotalPages(totalPages);

  // Handle page change;
  // const handleChange = (event, value) => {
  //   setPage(value);
  // };

  const ele = filter
    ? displayedData.map(function (item, index) {
        return (
          <tr key={index}>
            <td>
              <span className="span-width">{item.displayIndex}</span>
            </td>
            <td>
              <span className="span-width">{item.date}</span>
            </td>
            <td>
              <span className="span-width">{item.firmName}</span>
            </td>
            <td>
              <span className="span-width">{item.invoiceNo}</span>
            </td>
            <td>
              <span className="span-width">{item.region}</span>
            </td>
            <td>
              <span className="span-width">{item.productName}</span>
            </td>
            <td>
              <span className="span-width">{item.discPercent}</span>
            </td>
            <td>
              <span className="span-width">{item.sgst}</span>
            </td>
            <td>
              <span className="span-width">{item.cgst}</span>
            </td>
            <td>
              <span className="span-width">{item.igst}</span>
            </td>
            <td>
              <span className="span-width">{item.cess}</span>
            </td>
            <td>
              <span className="span-width">{item.totalTax}</span>
            </td>
            <td>
              <span className="span-width">{item.amount}</span>
            </td>
            {/* <td>
            <span className="span-width">{item.discAmt}</span>
          </td>   */}
            {/* <td>
            <span className="span-width">{item.totalTax}</span>
          </td>
          <td>
            <span className="span-width">{item.sgst}</span>
          </td>
          <td>
            <span className="span-width">{item.cgst}</span>
          </td>
          <td>
            <span className="span-width">{item.igst}</span>
          </td>
          <td>
            <span className="span-width">{item.cess}</span>
          </td>
          <td>
            <span className="span-width">{item.amount}</span>
          </td> */}

            {/* <td><span className="span-width">{item.cnamt}</span></td>
          <td>
            <span className="span-width">{item.tax}</span>
          </td>
          <td>
            <span className="span-width">{item.tax}</span>
          </td>
          <td><span className="span-width">{item.grandTotal}</span></td> */}
          </tr>
        );
      })
    : displayedData.map(function (item, index) {
        return (
          <tr key={index}>
            <td>
              <span className="span-width">{item.displayIndex}</span>
            </td>
            <td>
              <span className="span-width">{item.date}</span>
            </td>
            <td>
              <span className="span-width">{item.firmName}</span>
            </td>
            <td>
              <span className="span-width">{item.invoiceNo}</span>
            </td>
            <td>
              <span className="span-width">{item.region}</span>
            </td>
            <td>
              <span className="span-width">{item.productName}</span>
            </td>
            <td>
              <span className="span-width">{item.discPercent}</span>
            </td>
            <td>
              <span className="span-width">{item.sgst}</span>
            </td>
            <td>
              <span className="span-width">{item.cgst}</span>
            </td>
            <td>
              <span className="span-width">{item.igst}</span>
            </td>
            <td>
              <span className="span-width">{item.cess}</span>
            </td>
            <td>
              <span className="span-width">{item.totalTax}</span>
            </td>
            <td>
              <span className="span-width">{item.amount}</span>
            </td>
            {/* <td>
            <span className="span-width">{item.discAmt}</span>
          </td>   */}
            {/* <td>
            <span className="span-width">{item.totalTax}</span>
          </td>
          <td>
            <span className="span-width">{item.sgst}</span>
          </td>
          <td>
            <span className="span-width">{item.cgst}</span>
          </td>
          <td>
            <span className="span-width">{item.igst}</span>
          </td>
          <td>
            <span className="span-width">{item.cess}</span>
          </td>
          <td>
            <span className="span-width">{item.amount}</span>
          </td> */}

            {/* <td><span className="span-width">{item.cnamt}</span></td>
          <td>
            <span className="span-width">{item.tax}</span>
          </td>
          <td>
            <span className="span-width">{item.tax}</span>
          </td>
          <td><span className="span-width">{item.grandTotal}</span></td> */}
          </tr>
        );
      });

  return (
    <Layout heading_1="Purchase" heading_2="Goods Report">
      <div className="main-Goodsreport-file">
        <div className="Goodsreport-main">
          {/* <GoodsReportHeader /> */}
          <div className="Goodsreport">
            {/* <div className="Goodsreport-header" style={{ height: "34px" }}>
            <h4>Purchase Goods Return Report</h4>
          </div> */}

            <div className="table-top-Goodsreport">
              <div className="show-name-Goodsreport">
                <label htmlFor="show">Show : </label>
                <select
                  id="show showSelect"
                  style={{
                    height: "36px",
                    width: "69px",
                    marginLeft: "1rem",
                    marginRight: "1rem",
                    borderRadius: "5px",
                  }}
                >
                  <option>All</option>
                </select>
                <FaRegFilePdf
                  className="pdf-Goodsreport"
                  style={{ cursor: "pointer" }}
                  onClick={(event) => {
                    exportPDF();
                  }}
                />
                <CSVLink
                  data={filter ? filteredList : PurchaseGoodsReport}
                  headers={headers}
                  filename="Goods-Return Report.csv"
                  className="hidden"
                  ref={csvDownloadRef}
                  target="_blank"
                />
                <FaFileCsv
                  className="csv-Goodsreport"
                  style={{ cursor: "pointer" }}
                  onClick={(event) => {
                    convertToCsv(event);
                  }}
                />
              </div>

              <div className="search-name-Goodsreport">
                <input
                  placeholder="Search by Firm Name, Product Name"
                  onChange={filterPurchaseReportSearch}
                />
              </div>
            </div>
            <div className="table-customer-Goodsreport">
              <table id="customers-Goodsreport">
                <tr className="table-customer-heading-Goodsreport">
                  <th>S.No</th>
                  <th>Date</th>
                  <th>Firm Name </th>
                  <th>Invoice No.</th>
                  <th>Region</th>
                  <th>Product Name</th>
                  <th>Discount Amt.</th>
                  <th>SGST</th>
                  <th>CGST</th>
                  <th>IGST</th>
                  <th>CESS</th>
                  <th>Total Tax Amt</th>
                  <th>Amount </th>
                </tr>

                {ele}
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

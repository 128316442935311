import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Nav2 from "../../navbar/Nav2";
import Layout from "../../Layout/Layout";
import axios from "axios";
import "./firmedit.css";
// import "../firmGenerate/firmgenrator.css";
import Data from "./state";
const FirmNameGenerator = () => {
  const [firmName, setfirmName] = useState("");
  const [personName, setpersonName] = useState("");
  const [fullAdd, setfullAdd] = useState("");
  const [mobileNo, setmobileNo] = useState("");
  const [email, setEmail] = useState("");
  const [pinCode, setpinCode] = useState("");
  const [state, setState] = useState("");
  const [dl1, setdl1] = useState("");
  const [dl2, setdl2] = useState("");
  const [gst, setgst] = useState("");
  const [fssai, setfssai] = useState("");
  const [error, setError] = useState(false);

  // radio button ke liye
  // registerAs
  const [registerAs, setregisterAs] = useState("");

  //  for bank details
  const [accountName, setAccountName] = useState("");
  const [accountNo, setAccountNumber] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [branch, setBranch] = useState("");

  const params = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    getProductDetails();
  }, []);

  const getProductDetails = async () => {
    try {
      //token
      let token = localStorage.getItem("x-api-key");

      const response = await axios.get(`api/user/firm/getOne/${params.id}`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": token,
          "x-api-plan": "SaaS",
        },
      });

      const result = response.data;
      // Extract data from the result
      const {
        firmName,
        personName,
        fullAdd,
        mobileNo,
        email,
        pinCode,
        state,
        dl1,
        dl2,
        gst,
        fssai,
        registerAs,
        accountName,
        accountNo,
        ifsc,
        branch,
      } = result.data;

      // Set state with the extracted data
      setfirmName(firmName);
      setpersonName(personName);
      setfullAdd(fullAdd);
      setmobileNo(mobileNo);
      setEmail(email);
      setpinCode(pinCode);
      setState(state);
      setdl1(dl1);
      setdl2(dl2);
      setgst(gst);
      setfssai(fssai);
      setregisterAs(registerAs);
      setAccountName(accountName);
      setAccountNumber(accountNo);
      setIfsc(ifsc);
      setBranch(branch);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Call the function to get product details
  // getProductDetails();

  const handleSubmit = async () => {
    try {
      // Token
      let token = localStorage.getItem("x-api-key");

      // Prepare the data to send
      const data = {
        firmName,
        personName,
        fullAdd,
        mobileNo,
        email,
        pinCode,
        state,
        dl1,
        dl2,
        gst,
        fssai,
        registerAs,
        accountName,
        accountNo,
        ifsc,
        branch,
      };

      // Make the Axios PUT request
      const response = await axios.put(
        `api/user/firm/updateFirmDetails/${params.id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": token,
            "x-api-plan": "SaaS",
          },
        }
      );

      // Display a success message
      Swal.fire("Updated!", "Your Firm Details Has Been Updated.", "success");

      // Navigate to a different page
      navigate("/user/list");

      // Reload product details (if needed)
      getProductDetails();

      // Handle the response data (if needed)
      // const result = response.data;
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Layout heading_1="Firm Name" heading_2="Firm Update">
      <div className="container">
        {" "}
        <div className="form-box-firmGen">
          <Nav2 />
          <div
            className="c-form-firm-gen-1"
            // name="c-form"
            // action="#"
            // method="post"
          >
            <div class="two-main">
              {/* <label className="heading-firm-update">
                Firm Name Update details
              </label> */}
              <fieldset>
                <label
                  id="c-form-required-firmname"
                  className="c-form-label-top-label"
                  style={{ color: "black", fontWeight: "bold" }}
                >
                  Firm Name
                </label>
                <input
                  type="text"
                  className="firmnameinut inputFeild"
                  required="true"
                  value={firmName}
                  placeholder="Enter firm name"
                  onChange={(e) => setfirmName(e.target.value)}
                />
              </fieldset>
            </div>
            <div class="two-columns-three">
              <fieldset>
                <label className="c-form-label">
                  Contact Person Name<span className="c-form-required"> *</span>
                </label>
                <input
                  type="text"
                  className="c-form-input-firm inputFeild"
                  required
                  placeholder="Enter Contact Person Name"
                  value={personName}
                  onChange={(e) => setpersonName(e.target.value)}
                />
              </fieldset>

              <fieldset>
                <label className="c-form-label">
                  Address<span className="c-form-required"> *</span>
                </label>
                <input
                  type="text"
                  className="c-form-input-firm inputFeild"
                  required
                  placeholder="Enter address"
                  value={fullAdd}
                  onChange={(e) => setfullAdd(e.target.value)}
                />
              </fieldset>
            </div>
            <div className="two-columns-three">
              <fieldset>
                <label className="c-form-label">
                  Mobile No<span className="c-form-required"> *</span>
                </label>
                <input
                  placeholder="Enter Mobile No"
                  type="text"
                  className="c-form-input-firm inputFeild"
                  required
                  value={mobileNo}
                  onChange={(e) => setmobileNo(e.target.value)}
                />
              </fieldset>

              <fieldset>
                <label className="c-form-label">
                  Enter Email id<span className="c-form-required"> *</span>
                </label>
                <input
                  placeholder="Enter email id"
                  type="email"
                  className="c-form-input-firm inputFeild"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </fieldset>
            </div>
            <div className="two-columns-three">
              <fieldset>
                <label className="c-form-label">
                  Pin Code<span className="c-form-required"> *</span>
                </label>
                <input
                  placeholder="Enter Pincode"
                  type="text"
                  className="c-form-input-firm inputFeild"
                  required
                  value={pinCode}
                  onChange={(e) => setpinCode(e.target.value)}
                />
              </fieldset>

              <fieldset>
                <label className="c-form-label">State</label>
                <select
                  placeholder="Select State"
                  type="select"
                  className="c-form-select-firm-state  inputFeild"
                  required
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                >
                  {Data.map((item) => (
                    <option
                      id={item.value}
                      value={item.label}
                      onChange={(e) => setState(e.target.value)}
                    >
                      {" "}
                      {item.label}
                    </option>
                  ))}
                </select>
              </fieldset>
            </div>

            <label
            //  className="radiolabel-firm-edit"
             className="radiolabel-1"
             style={{ fontSize: "20px", fontWeight: "bold" }}
             > 
             Register As</label>

            <div className="radiobutton-1">
              <div
                onClick={() => {
                  setregisterAs("Manufacturer");
                }}
              >
                <input
                  id="input8"
                  className="inputradio-button "
                  type="radio"
                  value={registerAs}
                  checked={registerAs === "Manufacturer"}
                />
                <label>Manufacturer</label>
              </div>

              <div
                onClick={() => {
                  setregisterAs("Distributor");
                }}
              >
                <input
                  id="input8"
                  className="inputradio-button "
                  type="radio"
                  value={registerAs}
                  checked={registerAs === "Distributor"}
                />
                <label> Distributor </label>
                <br />
              </div>

              <div
                onClick={() => {
                  setregisterAs("Wholesaler");
                }}
              >
                <input
                  id="input8"
                  className="inputradio-button "
                  type="radio"
                  value={registerAs}
                  checked={registerAs === "Wholesaler"}
                />
                <label> Wholesaler</label>
                <br />
              </div>

              <div
                onClick={() => {
                  setregisterAs("Retailer");
                }}
              >
                <input
                  id="input8"
                  className="inputradio-button "
                  type="radio"
                  value={registerAs}
                  name="Retailer"
                  checked={registerAs === "Retailer"}
                />
                <label> Retailer</label>
                <br />
              </div>

              <div
                onClick={() => {
                  setregisterAs("Hospital");
                }}
              >
                <input
                  id="input8"
                  className="inputradio-button "
                  type="radio"
                  value={registerAs}
                  checked={registerAs === "Hospital"}
                />
                <label>Hospital</label>
              </div>
            </div>

            <div className="two-columns-three">
              <fieldset>
                <label className="c-form-label">
                  DL. No. (1)<span className="c-form-required"> *</span>
                </label>
                <input
                  placeholder="Enter DL No.(1)"
                  type="text"
                  className="c-form-input-firm inputFeild"
                  required
                  value={dl1}
                  onChange={(e) => setdl1(e.target.value)}
                />
              </fieldset>

              <fieldset>
                <label className="c-form-label">
                  DL. No.(2)<span className="c-form-required"> *</span>
                </label>
                <input
                  placeholder="Enter D.L No 2"
                  type="text"
                  className="c-form-input-firm inputFeild"
                  required
                  value={dl2}
                  onChange={(e) => setdl2(e.target.value)}
                />
              </fieldset>
            </div>
            <div className="two-columns-three">
              <fieldset>
                <label className="c-form-label">
                  GSTIN<span className="c-form-required"> *</span>
                </label>
                <input
                  placeholder="Enter GSTIN "
                  type="text"
                  className="c-form-input-firm inputFeild"
                  required
                  value={gst}
                  onChange={(e) => setgst(e.target.value)}
                />
              </fieldset>

              <fieldset>
                <label className="c-form-label" for="last-name">
                  Enter FSSAI Number<span className="c-form-required"> *</span>
                </label>
                <input
                  placeholder="enter FASSAI no"
                  type="text"
                  className="c-form-input-firm inputFeild"
                  required
                  value={fssai}
                  onChange={(e) => setfssai(e.target.value)}
                />
              </fieldset>
            </div>
            {/* //for bank details  */}
            <label className="banklabel-firm-edit-details">Bank Details</label>
            <div className="spacing"></div>
            <div className="two-columns-three">
              <fieldset>
                <label className="c-form-label">
                  Account Name<span className="c-form-required"> *</span>
                </label>
                <input
                  placeholder="Enter Account Name"
                  type="text"
                  className="c-form-input-firm inputFeild"
                  required
                  value={accountName}
                  onChange={(e) => setAccountName(e.target.value)}
                />
              </fieldset>

              <fieldset>
                <label className="c-form-label" for="last-name">
                  Account No<span className="c-form-required"> *</span>
                </label>
                <input
                  placeholder="Enter Account No"
                  type="text"
                  className="c-form-input-firm inputFeild"
                  required
                  value={accountNo}
                  onChange={(e) => setAccountNumber(e.target.value)}
                />
              </fieldset>
            </div>

            <div className="two-columns-three">
              <fieldset>
                <label className="c-form-label">
                  IFSC Code<span className="c-form-required"> *</span>
                </label>
                <input
                  placeholder="Enter IFSC Code"
                  type="text"
                  className="c-form-input-firm inputFeild"
                  required
                  value={ifsc}
                  onChange={(e) => setIfsc(e.target.value)}
                />
              </fieldset>

              <fieldset>
                <label className="c-form-label" for="last-name">
                  Branch<span className="c-form-required"> *</span>
                </label>
                <input
                  placeholder="Enter Branch Name"
                  type="text"
                  className="c-form-input-firm inputFeild"
                  required
                  value={branch}
                  onChange={(e) => setBranch(e.target.value)}
                />
              </fieldset>
            </div>

            <div className="firm-gen-submit-button">
              <button
                id="button-sumbit-firm"
                onClick={handleSubmit}
                style={{
                  marginTop: "3rem",
                  width: "15rem",
                  marginBottom: "3rem",
                  height: "3rem",
                  fontSize: "18px",
                }}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FirmNameGenerator;

import "./app.css";
import PrivateRouter from "./components/PrivateRoute";
import Dash from "./components/dashboard/Dash";
import Header from "./Bar/Header";
import { ProSidebarProvider } from "react-pro-sidebar";
import { useLocation } from "react-router-dom";
import { BrowserRouter, Routes, Route, Navigate, Link } from "react-router-dom";

// import { NotificationsProvider } from './components/NotificationList/NotificationList'

import hegan from "./images/Logo-Hegan.png";

import Coupon from "./components/Coupon/Coupon";

import { NotificationList } from "./components/NotificationList/NotificationList";

// import refferal page

import ShareContent from "./components/shareContent/ShareContent";

// Desktop App;

import Desktop from "./components/DesktopApp/Desktop";

// upgrade plan

import UpgradePlan from "./components/UpgradePlan/UpgradePlan";

import PaymentCalculator from "./components/UpgradePlan/PaymentCalculator";
// Profile import;

import Profile from "./components/Profile/Profile";

// all firm's import
import FirmNameGenerator from "./components/firm/firmGenerate/FirmNameGenerator";
import Firmlist from "./components/firm/FirmList/FirmListInner/Firmlist";
import FirmEdit from "./components/firm/FirmEdit/FirmEdit";
import FirmView from "./components/firm/Firmview/FirmView";

// all product's import
import ProductGenerate from "./components/Products/ProductGenerater/ProductGenerate";
import ProductList from "./components/Products/ProductList/Productlist";
import ProductView from "./components/Products/productView/ProductView";
import ProductUpdate from "./components/Products/ProductUpdate/ProductUpdate";

// import ProductModal from './components/Print/ProductModal'

//purchase
import PurchaseEntry from "./components/Purchase/PurchaseEntry/Entry";
import PurchaseList from "./components/Purchase/PurchaseListMain/PurchaseListMain";
import PurchaseUpdate from "./components/Purchase/PurchaseUpdate/PurchaseUpdate";

import PurchaseViewMain from "./components/Purchase/PurchaseViewMain/PurchaseViewMain";
import PurchaseViewEstimate from "./components/Purchase/PurchaseViewEstimate/PurchaseViewEstimate";

import PurchaseListMain from "./components/Purchase/PurchaseListMain/PurchaseListMain";
import PurchaseListEstimate from "./components/Purchase/PurchaseListEstimate/PurchaseListEstimate";
// ledger entry pages

import LedgerEntry from "./components/ledger/ledgerEntry/LedgerEntry";
// import LedgerList from './components/ledger/ledgerList/LedgerList'
import LedgerList from "components/ledger/ledgerList/LedgerList";
import LedgerView from "./components/ledger/Ledgerview/LedgerView";
import LedgerUpdate from "./components/ledger/LedgerUpdate/LedgerUpdate";

// navbar
import Nav from "./components/navbar/Nav";

// SALES page

import SalesEntry from "./components/Sales/SalesEntry/SalesEntry";

import SalesListViewMain from "./components/Sales/SalesViewMain/SalesViewMain";
import SalesListViewEstimate from "./components/Sales/SalesViewEstimate/SalesListViewEstimate";

import SalesListMain from "./components/Sales/SalesListMain/SalesListMain";
import SalesListEstimate from "./components/Sales/SalesListEstimate/SalesListEstimate";

import SaleUpdate from "./components/Sales/SalesUpdate/SaleUpdate";

// REPORT pages
// sales reports
import SalesReport from "./components/SalesReport/SalesReport/SalesReport";
import SalesGoodsReport from "./components/SalesReport/GoodsReport/GoodsReport";
import SalesVoucherReport from "./components/SalesReport/SalesVoucherReport/SalesVoucherReport";

//Purchase Reports
import PurchaseReport from "./components/PurchaseReport/PurchaseReport/PurchaseReport";
import PurchaseGoodsReturn from "./components/PurchaseReport/GoodsReport/GoodsReport";
import PurchaseVoucherReport from "./components/PurchaseReport/VoucherReport/VoucherReport";

// BANK pages
import BankList from "./components/Bank/BankList/BankList";

import UpdateBank from "./components/Bank/BankList/BankUpdate";

// STOCK page
import StockList from "./components/Stock/StockList";

import Login from "./components/login/Login";
// Settings;
import { InvoiceAssign } from "./components/Settings/InvoiceAssign/InvoiceAssign";

import axios from "axios";

import Marketplace from "./components/Marketplace/Marketplace";

import ProductsImport from "./components/Marketplace/ProductsImport";
import { useEffect, useRef, useState } from "react";

// ===================  axios ====================//

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_PATH;

// axios.defaults.baseURL = "https://hegan.in";
// axios.defaults.headers.post['Content-Type'] = 'application/json';
// axios.defaults.headers.post['Accept'] = 'application/json';
// axios.defaults.withCredentials = true;
// axios.interceptors.request.use(function (config) {
//   const token = localStorage.getItem('x-api-key');
//   config.headers.Authorization = token ? `Bearer ${token}` : '';
//   return config;
// });

// import { io } from "socket.io-client";
// import { useEffect } from 'react';

function App() {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  }, []);

  const [maintance, setUnderMaintence] = useState(false);
  const [loading, setLoading] = useState(true); // New loading state

  const gettingVersionCode = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_PATH}/api/admin/slider/getAppVersion`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        console.log("response");
        setUnderMaintence(false);
        // }
      } else {
        setUnderMaintence(true);
      }
    } catch (error) {
      setUnderMaintence(true);
    } finally {
      // Set loading to false after 3 seconds
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  };

  useEffect(() => {
    gettingVersionCode();
  }, []);

  //to detect webiste is open on mobile or not
  const [isMobile, setIsMobile] = useState(false);

  // useEffect(() => {
  //   setIsMobile(/Mobi|Android/i.test(window.navigator.userAgent));
  // }, []);

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    console.log(userAgent);
    // setIsMobile(/Mobi|Android|Tablet|iPad|Touch/i.test(userAgent) === true);
    setIsMobile(/Mobi|Android|Tablet|iPad|Touch/i.test(userAgent));
  }, []);

  const preventTouch = (event) => {
    event.preventDefault();
  };

  let isLoggedIn = localStorage.getItem("isLoggedIn");
  // let isLoggedIn = true

  const location = useLocation();

  // useEffect(() => {
  //   const socket = io("http://localhost:5000");
  //   const messageToSend = "Hello, server!";

  //   // Emit the message to the server
  //   socket.emit("sendMessage", messageToSend);

  //   // Listen for the response from the server
  //   socket.on("messageResponse", (response) => {
  //     console.log("Server response:", response);
  //   });

  //   console.log(128, socket);
  // }, []);

  // Replace 'yourMessage' with the actual message you want to send

  // const socket = io("http://localhost:3000");

  // Manage authentication state;

  return (
    <div>
      {loading ? ( // Display loading circle if loading is true
        <div className="loading-container">
          <div className="loader"></div>
        </div>
      ) : maintance ? (
        <>
          <div className="under-maintenance-container">
            <div className="under-maintenance-message">
              <h2>We are currently under maintenance</h2>
              <p>
                Sorry for the testing delay in your work. We are just
                temporarily down due to regular maintenance. We will be back up
                again soon!
              </p>
              <p>Please check back later.</p>
            </div>
          </div>
        </>
      ) : !isMobile ? (
        <div className="app-container" style={{ display: "flex" }}>
          {/* // <div className="app-container" style={isLoggedIn === true ? { display: 'flex', marginLeft: '249px' } : { display: 'flex' }}> */}
          <ProSidebarProvider>
            {localStorage.getItem("isLoggedIn") == "true" &&
              location.pathname !== "/" && <Nav />}
            {/* {localStorage.getItem('isLoggedIn') === 'true' && location.pathname !== '/registration' && <Nav />} */}

            <Routes>
              {/* <Route path='/' element={<Login />} /> */}

              <Route
                path="/"
                element={
                  isLoggedIn == "true" ? (
                    <Navigate to="/user/dashboard" />
                  ) : (
                    <Login />
                  )
                }
              />

              <Route path="/user/*" element={<PrivateRouter />}>
                <Route path="dashboard" element={<Dash />} />
                {/* purchase entry  */}
                <Route path="purchaseentry" element={<PurchaseEntry />}></Route>
                <Route path="purchase-list" element={<PurchaseList />}></Route>
                <Route
                  path="purchase-update/:id"
                  element={<PurchaseUpdate />}
                ></Route>
                <Route
                  path="purchase-view-main/:id"
                  element={<PurchaseViewMain />}
                ></Route>
                <Route path="purchase-list-main" element={<PurchaseListMain />}>
                  {" "}
                </Route>
                <Route
                  path="purchase-list-estimate"
                  element={<PurchaseListEstimate />}
                ></Route>
                <Route
                  path="purchase-view-estimate/:id"
                  element={<PurchaseViewEstimate />}
                ></Route>
                {/* all product route is here */}
                <Route
                  path="productgenerate"
                  element={<ProductGenerate />}
                ></Route>
                <Route path="productlist" element={<ProductList />}>
                  {" "}
                </Route>
                <Route path="viewproduct/:id" element={<ProductView />}>
                  {" "}
                </Route>
                <Route path="updateproduct/:id" element={<ProductUpdate />}>
                  {" "}
                </Route>

                {/* all firm route here */}
                <Route path="firmgenerate" element={<FirmNameGenerator />} />
                <Route path="list" element={<Firmlist />}></Route>
                <Route path="updateFirm/:id" element={<FirmEdit />} />
                <Route path="viewFirm/:id" element={<FirmView />} />

                {/* // ledger location */}
                <Route path="ledger" element={<LedgerEntry />} />
                <Route path="ledgerList" element={<LedgerList />} />
                <Route path="ledger/view/:id" element={<LedgerView />} />
                <Route path="ledger-update/:id" element={<LedgerUpdate />} />

                {/* Desktop App */}
                <Route path="Desktop" element={<Desktop />} />

                {/*UpgradePlan */}
                <Route path="upgrade" element={<UpgradePlan />} />
                {/*paymentCalculator */}
                <Route
                  path="paymentCalculator"
                  element={<PaymentCalculator />}
                />
              
                {/* // Profile Route */}
                <Route path="Profile" element={<Profile />} />

                <Route path="marketplace" element={<Marketplace />} />

                <Route path="productsImport" element={<ProductsImport />} />

                {/* // Purchase ENTRY PRODUCTNAME ROUTE */}
                {/* <Route path="/product-name-list" element={<ProductModal />} /> */}

                {/* NAVBAR TEST */}

                {/* <Route path="nav" element={<Nav />} /> */}

                {/* for sales */}

                <Route path="sales-entry" element={<SalesEntry />} />
                <Route path="sales-report" element={<SalesReport />} />

                <Route path="sales-list-main" element={<SalesListMain />} />
                <Route
                  path="sales-list-estimate"
                  element={<SalesListEstimate />}
                />

                <Route
                  path="sales-view-estimate/:id"
                  element={<SalesListViewEstimate />}
                ></Route>
                <Route
                  path="sales-view-main/:id"
                  element={<SalesListViewMain />}
                ></Route>
                <Route path="sales-update/:id" element={<SaleUpdate />}></Route>

                {/*Sales Report PAGE */}
                <Route path="sales-report" element={<SalesReport />} />
                <Route
                  path="sales-goods-report"
                  element={<SalesGoodsReport />}
                />
                <Route
                  path="sales-voucher-report"
                  element={<SalesVoucherReport />}
                />

                {/*Purchase Report PAGE */}
                <Route path="purchase-report" element={<PurchaseReport />} />
                <Route
                  path="purchase-goods-report"
                  element={<PurchaseGoodsReturn />}
                />
                <Route
                  path="purchase-voucher-report"
                  element={<PurchaseVoucherReport />}
                />

                {/* Bank page */}

                <Route path="bank-view" element={<BankList />} />

                <Route path="updateBank/:id" element={<UpdateBank />} />

                {/* Stock page */}
                <Route path="stock-list" element={<StockList />} />

                {/* Invoice Assign */}
                <Route path="invoice-assign" element={<InvoiceAssign />} />

                {/* reffrel page */}
                <Route path="refferal" element={<ShareContent />} />
                {/* coupon page */}
                <Route path="coupons" element={<Coupon />} />

                <Route path="notifications" element={<NotificationList />} />
              </Route>
            </Routes>
          </ProSidebarProvider>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width: "100vw",
            position: "fixed",
            top: 0,
            left: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            color: "white",
            fontSize: "24px",
            textAlign: "center",
          }}
          onTouchStart={preventTouch}
          onTouchMove={preventTouch}
          onTouchEnd={preventTouch}
        >
          <p>Please open this website on a laptop for the best experience.</p>
        </div>
      )}
    </div>
  );
}
export default App;

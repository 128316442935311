/** @format */

import "./saleslist.css";
// import Header from "./SalesListEstimateHeader";
import Layout from "../../Layout/Layout.js";
import Swal from "sweetalert2";
import { React, useEffect, useState, useRef } from "react";
import { FaRegFilePdf } from "react-icons/fa";
import { FaFileCsv, FaRegCopy } from "react-icons/fa";
import { useNavigate, Link } from "react-router-dom";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { CSVLink } from "react-csv";
import axios from "axios";

//============== Headers for csv file=======================//
const headers = [
  { label: "Date", key: "date" },
  { label: "Invoice N0.", key: "properInvoice" },
  { label: "Firm Name", key: "firmName" },
  { label: "Region", key: "region" },
  { label: "Type", key: "type" },
  { label: "Amount", key: "amount" },
];

export default function SalesListEstimate() {
  const navigate = useNavigate();

  // function navigateToSaleListView(){
  //   navigate('/purchase-view')
  // }

  function navigateToSaleListEdit() {
    navigate("/user/purchase-update");
  }

  const [SalesListEstimateData, setSalesListEstimateData] = useState([]);

  useEffect(() => {
    getSalesListEstimateDataFunction();
  }, []);

  const getSalesListEstimateDataFunction = async () => {
    try {
      // alert("this is good")
      //token
      let token = localStorage.getItem("x-api-key");
      let response = await axios.post(
        "api/user/sell/sellElist",
        {},
        {
          // method: "post",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": token,
            "x-api-plan": "SaaS",
          },
        }
      );
      let result = response.data;
      if (result.data) {
        setSalesListEstimateData(result.data);
      } else if ((result.message = "data not found")) {
        setSalesListEstimateData([]);
      }
      console.log(SalesListEstimateData, result);
    } catch (error) {
      console.error(error.response.data.message);
    }
  };

  // code for filtering firm according to search bar;
  const [filteredList, setFilteredList] = new useState(SalesListEstimateData);
  const [filter, setFilter] = useState(false);
  const filterListSearch = (event) => {
    setFilter(true);
    let updatedList = [...SalesListEstimateData];
    updatedList = updatedList.filter((item) => {
      // we can add one more field to search like invoice number;
      return (
        item.firmName
          .toLowerCase()
          .indexOf(event.target.value.toLowerCase()) !== -1 ||
        item.properInvoice
          .toLowerCase()
          .indexOf(event.target.value.toLowerCase()) !== -1
      );
    });
    console.log("updatedList", updatedList);
    setFilteredList(updatedList);
  };

  //==========  converting data to pdf=========== //
  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 350;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Sale List Estimate";
    const headers = [
      ["S.No", "Date", "Invoice No.", "Firm Name", "Region", "Type", "Amount"],
    ];
    // console.log(filteredPurchaseListData);
    let data;
    if (filter) {
      data = filteredList.map((elt, index) => [
        index + 1,
        elt.date,
        elt.properInvoice,
        elt.firmName,
        elt.region,
        elt.type,
        elt.amount,
      ]);
    } else {
      data = SalesListEstimateData.map((elt, index) => [
        index + 1,
        elt.date,
        elt.properInvoice,
        elt.firmName,
        elt.region,
        elt.type,
        elt.amount,
      ]);
    }

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    autoTable(doc, content);
    doc.save("Sale List Estimate.pdf");
  };

  //========= converting filtered data to csv============ //
  const csvDownloadRef = useRef(null);
  const convertToCsv = () => {
    setTimeout(() => {
      csvDownloadRef.current.link.click();
    }, 500);
  };

  // delete product function
  function DeleteSalesListEstimateFunction(id) {
    var id = id;
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: false,
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    })
      .then((result) => {
        if (result.isConfirmed) {
          const deleteProduct = async (id) => {
            //token
            let token = localStorage.getItem("x-api-key");
            let response = await axios.delete(
              `api/user/sell/deleteSellE/${id}`,
              {
                // method: "Delete",
                headers: {
                  "Content-Type": "application/json",
                  "x-api-key": token,
                  "x-api-plan": "SaaS",
                },
              }
            );
            let result = response.data;
            if (result) {
              Swal.fire("Deleted!", "Your file has been deleted.", "success");
              // calling get all purchase list function for refresh of the list
              getSalesListEstimateDataFunction();
            }
          };
          deleteProduct(id);
        }
      })
      .catch((error) => {
        console.error(error.result.data.message);
      });
  }

  const ele = filter
    ? filteredList.map(function (item, index) {
        return (
          <tr>
            <td>
              <span className="span-width">{index + 1}</span>
            </td>
            <td>
              <span className="span-width">{item.date}</span>
            </td>
            <td>
              <span className="span-width">{item.properInvoice}</span>
            </td>
            <td>
              <span className="span-width">{item.firmName}</span>
            </td>
            <td>
              <span className="span-width">{item.region}</span>
            </td>
            <td>
              <span className="span-width">{item.type}</span>
            </td>
            <td>
              <span className="span-width">{item.grandTotal}</span>
            </td>
            <td className="action-button-Purchase-table">
              {/* <button className="edit-sale-list">
              <Link to={"/user/sales-update/" + item.id}>Edit</Link>
            </button> */}
              <button className="edit-sale-list">
                <Link
                  to={{
                    pathname: "/user/sales-update/" + item.id,
                    search: "?editType=est",
                  }}
                >
                  Edit
                </Link>
              </button>
              <button className="view-sale-list">
                <Link to={"/user/sales-view-estimate/" + item.id}>View</Link>{" "}
              </button>
              <button
                className="del-sale-list"
                onClick={() => {
                  DeleteSalesListEstimateFunction(item.id);
                }}
              >
                Delete
              </button>
            </td>
          </tr>
        );
      })
    : SalesListEstimateData.map(function (item, index) {
        return (
          <tr>
            <td>
              <span className="span-width">{index + 1}</span>
            </td>
            <td>
              <span className="span-width">{item.date}</span>
            </td>
            <td>
              <span className="span-width">{item.properInvoice}</span>
            </td>
            <td>
              <span className="span-width">{item.firmName}</span>
            </td>
            <td>
              <span className="span-width">{item.region}</span>
            </td>
            <td>
              <span className="span-width">{item.type}</span>
            </td>
            <td>
              <span className="span-width">{item.grandTotal}</span>
            </td>
            <td className="action-button-Purchase-table">
              {/* <button className="edit-sale-list">
              <Link to={"/user/sales-update/" + item.id}>Edit</Link>
            </button> */}
              <button className="edit-sale-list">
                <Link
                  to={{
                    pathname: "/user/sales-update/" + item.id,
                    search: "?editType=est",
                  }}
                >
                  Edit
                </Link>
              </button>

              <button className="view-sale-list">
                <Link to={"/user/sales-view-estimate/" + item.id}>View</Link>{" "}
              </button>
              <button
                className="del-sale-list"
                onClick={() => {
                  DeleteSalesListEstimateFunction(item.id);
                }}
              >
                Delete
              </button>
            </td>
          </tr>
        );
      });

  return (
    <Layout heading_1="Sales" heading_2="Sales List Estimate">
      <div className="main-SalesListEstimate-file">
        <div className="SalesListEstimate-sale-main">
          {/* <Header /> */}
          <div className="SalesListEstimate">
            {/* <div className="SalesListEstimate-header" style={{ height: "34px" }}>
            <h4>Sales List Estimate</h4>
          </div> */}

            <div className="table-top-SalesListEstimate">
              <div className="show-name-SalesListEstimate">
                <label htmlFor="show">Show : </label>
                <select
                  id="show showSelect"
                  style={{
                    height: "36px",
                    width: "69px",
                    marginLeft: "1rem",
                    marginRight: "1rem",
                    borderRadius: "5px",
                  }}
                >
                  <option>All</option>
                </select>
                <FaRegFilePdf
                  className="pdf-Selllist"
                  size={22}
                  style={{ cursor: "pointer" }}
                  onClick={(event) => {
                    exportPDF();
                  }}
                />
                <CSVLink
                  data={filter ? filteredList : SalesListEstimateData}
                  headers={headers}
                  filename="Sale List Estimateimate.csv"
                  className="hidden"
                  ref={csvDownloadRef}
                  target="_blank"
                />
                <FaFileCsv
                  className="csv-Selllist"
                  size={22}
                  style={{ cursor: "pointer" }}
                  onClick={(event) => {
                    convertToCsv(event);
                  }}
                />
              </div>

              <div className="search-name-SalesListEstimate">
                <input
                  placeholder="Search by Invoice No, Firm Name"
                  onChange={filterListSearch}
                />
              </div>
            </div>
            <div className="table-customer-SalesListEstimate">
              <table id="customers-SalesListEstimate">
                <tr className="table-sale-customer-heading-SalesListEstimate">
                  <th>S.No</th>
                  <th>Date</th>
                  <th>Invoice No.</th>
                  <th>Firm Name </th>
                  <th>Region</th>
                  <th>Type</th>
                  <th>Amount </th>
                  <th>Action</th>
                </tr>
                {ele}
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

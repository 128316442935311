import React from 'react'
import './nav.css'

// import { HiBars3 } from "react-icons/hi2";
import Header from '../../Bar/Header';
import { useNavigate } from 'react-router-dom';


const Nav = (props) => {

  const [showHeader, setShowHeader] = React.useState(true)

  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth)

  React.useEffect(function () {

    window.addEventListener("resize", function () {
      setWindowWidth(window.innerWidth)
      if (this.window.innerWidth < 943) {
        setShowHeader(true)
      }
    })
  }, [])

  function openHeader() {
    setShowHeader(!showHeader)
  }

  const [toDash, setToDash] = React.useState(false)
  const navigate = useNavigate()


  function showDashboard() {
    navigate('/')
  }
  return (
    <div className='left-side-nav-bar'>
      {/* <div className='navbar-main'>
        {/* <div className='navbar-left-side'>
        </div> */}
      {/* </div> */}
      {showHeader && <Header />}
    </div>
  )
}

export default Nav


// ===============added it ===========//
import "./LedgerEntry.css";
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import Layout from "../../Layout/Layout"; // Update the import path
const LedgerEntry = () => {
  let [finalGoodReturn, setfinalGoodReturn] = useState(0);
  // let [TotalSumPay, setTotalSumPay] = useState("");
  let [GoodsReturnSum, setGoodsReturnPay] = useState(0);
  // for showing model and hiding model
  const [showModal2, setShowModal2] = React.useState(false);
  function openModal2() {
    setShowModal2(function (item) {
      return !item;
    });
  }

  let [ProductEntries, setProductEntries] = useState([]);

  // formula one of the calculation's

  const [firmName, setFirmName] = useState("");
  const [dateOfTrans, setdateOfTrans] = useState("");
  const [mode, setMode] = useState("");
  const [checqueNo, setchecqueNo] = useState(0);
  const [paid, setpaid] = useState(0);
  const [recieve, setrecieve] = useState(0);
  const [remarks,setRemarks] = useState();
  //
  const [currAmt, setCurrAmt] = useState(0);
  const [currAmt2,setCurrAmt2] = useState(0);
  //total
  const [totalAmtReceive, settotalAmtReceive] = useState(0);
  const [totalAmtPaid, settotalAmtPaid] = useState(0);
  //higher
  const [bankName, setBankName] = useState("");
  const [region, setRegion] = useState(["Select Option", "Local", "Center"]);

  const [selectRegion, setSelectRegion] = useState("");

  const [customOrder,setCustomOrder] = useState(0);

  //for pop up show and hide
 const [showPopup, setShowPopup] = useState(false);

  const handleOpenPopup = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    FormulaOne();
  };
  // const showHideClassName = showPopup ? 'input-remarks-modal display-block' : 'input-remarks-modal display-none';
 
  //setting current date for the sstem

  let tempDate = new Date();
  let ddate =
    tempDate.getFullYear() +
    "-" +
    (tempDate.getMonth() + 1) +
    "-" +
    tempDate.getDate() +
    " " +
    tempDate.getHours() +
    ":" +
    tempDate.getMinutes() +
    ":" +
    tempDate.getSeconds();
  const [currDate, setcurrDate] = useState(ddate);
  const [openingBalance, setOpeningBalance] = useState(0);
  const [openingBalance2,setOpeningBalance2] =useState(0);
  // const [currentBalance, setcurrentBaklance] = useState(0);

  let modeData = [
    {
      mode: "Select Option",
    },
    {
      mode: "Cash",
    },
    {
      mode: "Online",
    },
  ];

  function FormulaOne() {
    arrayOfObject();
  }

  useEffect(() => {
    getFirmData();
    generateData();
    getAmount();
  }, []);

 
  function arrayOfObject() {
    // arry of object for products
    let ProductObj = {
      dateOfTrans: dateOfTrans,
      paid: +paid,
      recieve: +recieve,
      firmName: firmName,
      mode: mode,
      checqueNo: checqueNo ? checqueNo : 0,
      remarks : remarks,
      customOrder: customOrder
    };
    ProductEntries.push(ProductObj);
    console.log('checking customorder in frontend',ProductEntries)
    // customOrder++
    setCustomOrder(prevOrder => prevOrder + 1);

    let totalAmt;
    ProductEntries.forEach((item) => {
      totalAmt = 0;
      totalAmt += parseFloat(item.recieve);
      totalAmt -= parseFloat(item.paid);
    });
    let finalAmt = totalAmt + currAmt;
    console.log('final amt: ' + finalAmt);
    // let totalAmount = parseFloat(finalAmt) + parseFloat(openingBalance)
    setCurrAmt(finalAmt);
    

    //calling this function for further calculation of product array of object
    AfterAddingAllValuesCalculationFunction();
    console.log("calling function");
  }

  function AfterAddingAllValuesCalculationFunction() {
    for (let i = 0; i < ProductEntries.length; i++) {
      // total recieve calculations
      if (ProductEntries[i].recieve) {
        let v = 0;
        v = ProductEntries[i].recieve;
        v = parseFloat(v);
        // finalGoodReturn = parseFloat(finalGoodReturn);
        finalGoodReturn += v;
        // finalGoodReturn = finalGoodReturn.toFixed(2);
      }
    } // for loop close
    // console.log("finalGoodReturn", finalGoodReturn);
    settotalAmtReceive(finalGoodReturn);

    for (let i = 0; i < ProductEntries.length; i++) {
      // total paid
      if (ProductEntries[i].paid) {
        let v = ProductEntries[i].paid;
        v = parseFloat(v);
        GoodsReturnSum += v;
        // GoodsReturnSum = parseFloat(GoodsReturnSum);
      }
    }
    console.log("GoodsReturnSum", GoodsReturnSum);
    settotalAmtPaid(GoodsReturnSum);
  }
  //function is closed

  // getting all firm details from data base
  const [firmData, setFirmData] = useState("");

  const getFirmData = async () => {
    let token = localStorage.getItem("x-api-key");
    await axios
      .get("api/user/firm/getAllFirmDetails", {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": token,
          "x-api-plan": "SaaS",
        },
      })
      .then((response) => {
        console.log(response.data.data);
        setFirmData(response.data.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  // getting account name list;

  const getAmount = async () => {
    let token = localStorage.getItem("x-api-key");
    await axios
      .post(
        "api/user/ledger/totalAmt",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": token,
            "x-api-plan": "SaaS",
          },
        }
      )
      .then((response) => {
        console.log(163, response.data.data);
        setCurrAmt(response.data.data);
        console.log('opening balance', response.data.data)
        setOpeningBalance2(response.data.data)
      })
      .catch((error) => {
        console.log('server error for gettinge curent balance')
        console.log(error.message);
      });
  };

  //Delete Array of Object;

  function DeleteArrayOfObject(index) {
    let deleteItem = ProductEntries[index];
    ProductEntries.splice(index, 1);
    console.log(deleteItem);
    let rec = deleteItem.recieve;
    let pay = deleteItem.paid;
    let totalAmt = 0;
    totalAmt -= parseFloat(rec);
    totalAmt += parseFloat(pay);
    let finalAmt = totalAmt + currAmt;
    // let totalAmount = parseFloat(finalAmt) - parseFloat(openingBalance)
    setCurrAmt(finalAmt);
    openModal2();
    AfterAddingAllValuesCalculationFunction();
  }

  const sendLedgerEntryData = async () => {
    let token = localStorage.getItem("x-api-key");
    console.log("ProductEntries", ProductEntries);
    await axios
      .post(
        "api/user/ledger/create",
        {
          bankName: bankName,
          region: selectRegion,
          currDate: currDate,
          totalAmtReceive: parseFloat(totalAmtReceive),
          totalAmtPaid: parseFloat(totalAmtPaid),
          openingBalance: openingBalance ? openingBalance : openingBalance2,
          currentBalance: openingBalance ? parseFloat(openingBalance)  + parseFloat(currAmt) : currAmt,
          paymentEntries: ProductEntries,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": token,
            "x-api-plan": "SaaS",
          },
        }
      )
      .then((response) => {
        console.log(response.data);
       
        if (response.data.data === "created successfully") {
          Swal.fire(
            "Genereted!",
            "Your Ledger Entry has been Created.",
            "success"
          );
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
        console.log(error.message);
      });
  };

  // --- showing data in comments ---//
  // setting for output of product list
  //setting product id here
  const [SelectProductResultData, setSelectProductResultData] = useState("");

  const generateData = async (event) => {
    await axios
      .post(
        "api/user/sell/firmListByProduct",
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setSelectProductResultData(response.data.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };



  return (
    <Layout heading_1="Ledger" heading_2="Ledger Entry">
      <>
            <div className="main-div-ledger-entry">
        <div className="center-values-ledger-entry">
         {/* accound name and region section */}
          <div className="AC-RegionDiv-ledger-entry">
            
          <div className="dateBalanceInfoDivdateDiv">
              <label className="dateLabeldocLabel">Date</label>
              <input
                className="dateLabeldocLabelinput"
                value={currDate}
              ></input>
            </div>

            <div className="acntDiv feildsDiv">
              <label className="acname">A/C Name</label>
              <select
                onChange={(e) => setBankName(e.target.value)}
                className="Bal-Amt"
                style={{
                  marginRight: "94px",
                  borderRadius: "4px",
                  fontSize: "16px",
                  marginLeft: 10,
                  width: "200px",
                  height: "30px",
                  border: "1px solid #ccc",
                }}
              >
                Firm Name
                <br />
                <option value="">Choose A/C Name</option>
                {firmData
                  ? firmData.map((item, index) => (
                      <option
                        placeholder="A/C Name"
                        onChange={(e) => setBankName(item.accountName)}
                      >
                        {item.accountName}
                      </option>
                    ))
                  : "no data"}
              </select>
            </div>
            <div className="regionDiv feildsDiv">
              <label>Region</label>
              {/* type */}
              <select
                className="inputDiv regionInput"
                onChange={(e) => {
                  console.log("272", e.target.value);
                  setSelectRegion(e.target.value);
                }}
                style={{ fontSize: "16px", borderRadius: "5px" }}
              >
                {region && region.map((item, index) => <option>{item}</option>)}
              </select>
            </div>
          </div>
          {/* date and balance  */}
          <div className="dateAndBalanceDiv">
           
            <div className="dateBalanceInfoDiv openingBalanceDiv">
              <label className="openingBalanceLabel docLabel">
                Opening Balance
              </label>
              <input
                className="dateLabeldocLabelinput"
                value={openingBalance ? openingBalance : openingBalance2}
                onChange={(e) => setOpeningBalance(e.target.value)}
              />
            </div>
            <div className="dateBalanceInfoDiv currBalanceDiv">
              <label className="currentBalanceLabel docLabel">
                Current Balance
              </label>
              <input className="dateLabeldocLabelinput" value={ parseFloat(currAmt) + parseFloat(openingBalance) } />
            </div>
          </div>
        </div>

        <div 
        className="quantity-box-top-top-ledger-create"
        >
          <table
           id="customers-ledger-entry"
           >
          
          <thead className="ledger-entry-headings">
           
            <tr>
              <th>S.No.</th>
              <th>Firm Name</th>
              <th>Date</th>
              <th>Mode</th>
              <th>Cheque No</th>
              <th>Credit</th>
              <th>Debit</th>
              <th>Action</th>
            </tr>

            
            <tr className="ledger-entry-value-heading-input"
            >
              <td>
                <span className="span-width">0</span>
              </td>
              <td>
                <select
                  onChange={(e) => setFirmName(e.target.value)}
                  className="ProductName-entry"
                  style={{ width: "10rem" }}
                >
                  Firm Name
                  <br />
                  <option value="">Choose Firm Name</option>
                  {firmData
                    ? firmData.map((item, index) => (
                        <option
                          placeholder="First Name"
                          onChange={(e) => setFirmName(e.target.value)}
                        >
                          {item.firmName}
                        </option>
                      ))
                    : "no data"}
                </select>
              </td>
              <td>
                <input
                  type="Date"
                  value={dateOfTrans}
                  onChange={(e) => setdateOfTrans(e.target.value)}
                />
              </td>
              <td>
                <select onChange={(e) => setMode(e.target.value)}>
                  Firm Name
                  <br />
                  {modeData
                    ? modeData.map((item, index) => (
                        <option
                          placeholder="First Name"
                          onChange={(e) => setFirmName(e.target.value)}
                        >
                          {item.mode}
                        </option>
                      ))
                    : "no data"}
                </select>
              </td>
              <td>
                <input
                  type="text"
                  value={checqueNo}
                  onChange={(e) => {
                    setchecqueNo(e.target.value);
                  }}
                />
              </td>
              <td>
                <input
                  type="Number"
                  value={recieve}
                  onChange={(e) => {
                    setrecieve(e.target.value);
                  }}
                />
              </td>
              <td>
                <input
                  type="Number"
                  value={paid}
                  onChange={(e) => {
                    setpaid(e.target.value);
                  }}
                />
              </td>
              <td>
                <button
                  onClick={() => {
                    handleOpenPopup();
                   
                  }}
                  // onClick={FormulaOne}
                  style={{
                    backgroundColor: "rgb(1, 89, 152)",
                    color: "white",
                    borderRadius: "7px",
                    fontSize: "16px",
                    padding: "2px 12px",
                  }}
                >
                  Save
                </button>
              </td>
            </tr>
            </thead>
            
              
            <tbody className="payment-entries-scroll">
            {ProductEntries ? (
              ProductEntries.map(function (item, index) {
                return (
                
                  <tr 
                  key={item.id}
                  
                  >
                    <td className={(index + 1) % 2 === 1 ? "white" : "grey"}>
                      <span className="span-width">{index + 1}</span>
                    </td>
                    <td>
                      <input value={item.firmName} />
                    </td>
                    <td>
                      <input value={item.dateOfTrans} />
                    </td>
                    <td>
                      <input
                        className="inputbox-goodsreturn"
                        value={item.mode}
                      />
                    </td>
                    <td>
                      <input value={item.checqueNo} />
                    </td>
                    <td>
                      <input value={item.recieve} />
                    </td>
                    <td>
                      <input value={item.paid} />
                    </td>
                    <button
                      onClick={() => {
                        DeleteArrayOfObject(index);
                      }}
                      style={{
                        backgroundColor: "rgb(1, 89, 152)",
                        color: "white",
                        borderRadius: "7px",
                        fontSize: "16px",
                        padding: "2px 8px",
                        marginTop: 15,
                      }}
                    >
                      {" "}
                      Delete{" "}
                    </button>
                  </tr>
                 
                );
              })
            ) : (
              <h3>no Product selected</h3>
            )}
            </tbody>
          </table>
        </div>

        <div id="amount-receive">
          <div>
            <label id="amount-receive-total">Total</label>
          </div>
          <div>
            <label style={{ marginRight: 20 }}>Credit</label>
            <input id="inputid" value={totalAmtReceive} />
          </div>

          <div>
            <label style={{ marginRight: 20 }}>Debit</label>
            <input id="inputid" value={totalAmtPaid} />
          </div>

          <div style={{ width: 100 }}>
            <button onClick={sendLedgerEntryData}>Save</button>
          </div>
        </div>

      

      </div>

    { showPopup &&  <div>
      <div className="input-remarks-modal">
        <div className="input-remarks-modal-main">
          <textarea 
          value={remarks}
          onChange={(event) => setRemarks(event.target.value)}
          placeholder="Enter remarks here..." />
          <button onClick={handleClosePopup}>OK</button>
        </div>
      </div>
    </div>}
      </>
     
     </Layout>
  );
};
export default LedgerEntry;

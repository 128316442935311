import React, { useState } from "react";
import "./login.css";
import Swal from "sweetalert2";
import SplashScreenImage from "../../images/flashScreen.gif";
import {
  useNavigate,
  redirect,
  Navigate,
  NavLink,
  useLocation,
} from "react-router-dom";
import axios from "axios";
import ImageCarousel from "./ImageCarousel/ImageCarousel";

import loginLogoImage from './login3.png'

const Login = () => {
  const location = useLocation();
  const [showSplashScreen, setShowSplashScreen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const [isOtpSent, setIsOtpSent] = useState(false);

  const validateMobile = (input) => {
    const mobileRegex = /^[6-9]\d{9}$/;
    return mobileRegex.test(input);
  };

  //if user is registered in hegan v1 but not registered on v2 then we are giving acces of v2 
  //by asking to user 
  const sendingUserIdForHeganV2Proceed = async (id) => {
    await  axios.post(
      `api/user/update_Saas_Plan/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      console.log("response after permission for login in v2",response.data);
      if(response.data.status === 200){
      // if (response.data.status === 200) {
      Swal.fire({  
      title: "ok",
      text: response.data.message,
      icon: "success",
      confirmButtonText: "OK",
    });
      }
    })
    .catch((error) => {
      console.log(error.message)
      return Swal.fire({
        title: "Alert",
        text: error.response.data.message,
        icon: "error",
        confirmButtonText: "OK",
      });
    });
  }




  //sending otp for login 
  const handleSendOTP = async () => {
    if (validateMobile(mobile)) {
      startTimer();

      let numberString = mobile.toString();

      let maskedNumber = "XXXXXX" + numberString.slice(-4);

      // Simulate sending OTP;

      await axios
        .post(
          "api/admin/auth/userLogin_v2",
          {
            mobile: mobile,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          console.log("response", response.data);
          if (response.data.status === 200) {
            // const apiKey = response.data.token;
            setOtpSent(true);
            console.log("Sending OTP to", mobile);
            setIsOtpSent(true);
            // window.localStorage.setItem('x-api-key', apiKey);
            window.localStorage.setItem("mobile_No", mobile);
            window.localStorage.setItem("userName", response.data.userName);
            return Swal.fire(
              "Successfully!",
              `Your OTP has been sent to ${maskedNumber} number.`,
              "success"
            );
            } else if(response.data.status === 401){
              console.log('respone for not registered in v2 ',response.data)
             return Swal.fire({
                // title: " The entered number is already registered on Hegan Marketplace, do you wish to use the same registered details for access to Hegan SaaS Platform?",
                // width: 1000,
                // heigt : 400,
                html: `
                <div style="background-color: #f4f4f4; padding: 20px;">
                  <h2 style="color: #333; font-size: 1.5rem; margin-bottom: 10px;">Welcome To Hegan SaaS</h2>
                  <p style="color: #555; font-size: 1rem;">
                    The entered number is already registered on Hegan Marketplace. Do you wish to use the same registered details for access to Hegan SaaS Platform?
                  </p>
                  <div style="display: flex; justify-content: space-around; margin-top: 20px;">
                 </div>
                </div>
              `,
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: "Yes",
                denyButtonText: `No`,
              
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                //if user confirm to register into v2 
                sendingUserIdForHeganV2Proceed(response.data.data);
                return
                } else if (result.isDenied) {
                  Swal.fire("Changes are not saved", "", "info");
                }
              });
          } else {
            return Swal.fire({
              title: "Alert",
              text: response.data.message,
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        })
        .catch((error) => {
          console.log(error.message)
          return Swal.fire({
            title: "Alert",
            text: error.response.data.message,
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text:  "Invalid mobile number. Please enter a valid 10-digit mobile number.",
      });
      setMobile("");
    }
  };

  const handleLogin = async () => {
    let mobile = localStorage.getItem("mobile_No");
    await axios
      .post(
        "api/admin/auth/verifyOtp_2",
        {
          otp: otp,
          mobile: mobile,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        // verifyOtp_2
        // Simulate OTP verification
        if (response.data.status === true) {
          console.log("response", response);
          // Implement your OTP verification logic here
          // For this example, let's assume OTP verification is successful
          console.log("OTP verified successfully");
          localStorage.setItem("isLoggedIn", true);
          const apiKey = response.data.token;
          window.localStorage.setItem("x-api-key", apiKey);
          Swal.fire("Successfully!", `Logged In`, "success");
          setTimeout(() => {
            setIsLoggedIn(true);
            return navigate("/user/dashboard");
          }, 1000);
          // Redirect to the dashboard page if isLoggedIn is true
          if (isLoggedIn) {
            return navigate('/user/dashboard');
            // console.log('Redirecting to dashboard')
            // return window.location.href = `${location}`
          };
        }
      })
      .catch((error) => {
        console.error(error.response.data.message);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  };

 
  const [otpSent, setOtpSent] = useState(false);
  const [showResend, setShowResend] = useState(false);
  const [timer, setTimer] = useState(120);

  const startTimer = () => {
    let timeLeft = 120;
    const countdown = setInterval(() => {
      if (timeLeft > 0) {
        timeLeft--;
        setTimer(timeLeft);
      } else {
        clearInterval(countdown);
        setShowResend(true);
      }
    }, 1000);
  };

  return (
    <div
    style={{
      background: "linear-gradient( #C2C7FE , #4EC5F6, #8BF1D6)",
    }}
    >
  

        <div
          className="login-container"
        >

      <div className="left-container">

      <div className="login-main-container">
      <img src={loginLogoImage} alt="myiamge" className="your-image-class" 
      style={{height:80}}
      />
</div>

        <div className="left-content">

          
     


          {/* Insert your 3D image carousel here */}
          <ImageCarousel />
        </div>
      </div>

      <div className="right-container">
    
        <div className="right-content">
         
       
        {/* <img src={loginLogoImage} alt="myiamge" className="your-image-class" 
         style={{height:60,top:100}}
        /> */}

          <h2>Welcome to the Hegan SaaS Platform</h2>
          <p style={{fontSize:30}} >Your Billing Software </p>
          <p> Login To Your Account</p>

          {!otpSent ? (
            <>
              <input
                type="text"
                placeholder="Mobile No"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
              />
              <button
                //  onClick={handleSendOtp}
                onClick={handleSendOTP}
              >
                Send OTP
              </button>{" "}
            </>
          ) : null}

          {otpSent && (
            <div className="otp-container">
              <input
                type="number"
                placeholder="Enter OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
              <button onClick={handleLogin}>Verify</button>
              {showResend && (
                <button onClick={() => setShowResend(false)}>Resend OTP</button>
              )}
              {timer > 0 && <p>Time left: {timer} seconds</p>}
            </div>
          )}

          <div className="form-note text-center">
            <a
              href="https://hegan.in/partner-register"
              target="_blank"
              rel="noopener noreferrer"
              className="register-link"
            >
              
              New to Hegan? Register (User / Partner)
            </a>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default Login;

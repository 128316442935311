import Header from "../../Bar/Header";
import React, { useState, useEffect } from "react";
import { addDays } from "date-fns";
import Graph from "./Graph";
import Graph2 from "./Graph2";
import Cookies from "js-cookie";
import axios from 'axios';

import {
  FaFileCsv,
  FaRegFilePdf,
  FaRegCopy,
  FaCoins,
  FaBandAid,
  FaUserMinus,
  FaFileAlt,
} from "react-icons/fa";
import { RiBarChartFill } from "react-icons/ri";
import { FiUsers, FiUserPlus } from "react-icons/fi";
import { TfiBag } from "react-icons/tfi";
import { GiMedicines } from "react-icons/gi";
import "../dashboard/dash.css";
// import DashboardHeader from "./DashboardHeader";
import Layout from "../Layout/Layout";

import { DateRangePicker } from "rsuite";

import "rsuite/dist/rsuite-rtl.css";

// import Nav from "../navbar/Nav";
// import { Dashboard } from "@mui/icons-material";
// import SplashScreen from "../../Bar/Splashscreen";

const Dash = (props) => {
  useEffect(() => {
    GettingSalesData();
  }, []);

  //getting todays sales data from database
  const [SalesData, setSalesData] = useState("");
  const [saleIncrese, setSaleincrease] = useState("");
  const [user, setTodayuser] = useState("");
  const [todayuserIncrease, setTodayuserIncrease] = useState("");
  const [totalOrder, setTotalOrders] = useState("");
  const [Revenue, setRevenue] = useState("");
  const [totalMedicine, setTotalMedicine] = useState("");
  const [medicineshortage, setMedicineShortage] = useState("");

  const GettingSalesData = async () => {
    // let token = localStorage.getItem("x-api-key");
    const getTodaySale = async () => {
      try {
        let token = localStorage.getItem("x-api-key");
        const response = await axios.get("api/user/dashBoard/todaySale", {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": token,  "x-api-plan": "SaaS"
            
          },
        });

        if (response.data && response.data.message === "success") {
          setSalesData(response.data.data);
        } else {
          setSalesData(0);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    // Call the function to get today's sale data
    getTodaySale();

    // today's sale increase or decrease or no change data
    const getTodayInc = async () => {
      try {
        let token = localStorage.getItem("x-api-key");
        const response = await axios.get("api/user/dashBoard/todayInc", {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": token,  "x-api-plan": "SaaS"
            
          },
        });

        setSaleincrease(response.data.data);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    // Call the function to get today's sale increase data
    getTodayInc();

    // today's user  change data
    const getCountUser = async () => {
      try {
        let token = localStorage.getItem("x-api-key");
        const response = await axios.get("api/user/dashBoard/countUser", {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": token,  "x-api-plan": "SaaS"
          },
        });

        if (response) {
          console.log(108, response.data)
          setTodayuser(response.data.data);
        } else {
          setTodayuser(0);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    // Call the function to get the count of users
    getCountUser();
    // today's user increase or decrease or no change data

    const getUserChange = async () => {
      try {
        let token = localStorage.getItem("x-api-key");
        const response = await axios.get("api/user/dashBoard/userChange", {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": token,  "x-api-plan": "SaaS"
          },
        });
        console.log(148, response.data)
        if (response.data.data && response.data.message === "Increasing") {

          setTodayuserIncrease(response.data.data);

        } else {
          setTodayuserIncrease(0);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    // Call the function to get the user change data
    getUserChange();

    // today's user increase or decrease or no change data
    const getTotalOrders = async () => {
      try {
        let token = localStorage.getItem("x-api-key");
        const response = await axios.get("api/user/dashBoard/totalOrder", {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": token,  "x-api-plan": "SaaS"
          },
        });
        console.log(156, response.data);
        if (response.data.data) {
          setTotalOrders(response.data.data);
        } else {
          setTotalOrders(0);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    // Call the function to get the total orders data
    getTotalOrders();


    // today' revenue
    const getRevenue = async () => {
      try {
        let token = localStorage.getItem("x-api-key");
        const response = await axios.get("api/user/dashBoard/revenue", {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": token,  "x-api-plan": "SaaS"
          },
        });

        if (response.message === "success" && response.data.data.grandTotal) {
          console.log(182, response.data);
          setRevenue(response.data.data.grandTotal);
        } else {
          setRevenue(0);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    // Call the function to get the revenue data
    getRevenue();

    // user get TotalMedicine
    const getTotalMedicine = async () => {
      try {
        let token = localStorage.getItem("x-api-key");
        const response = await axios.get("api/user/dashBoard/totalMedicine", {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": token,  "x-api-plan": "SaaS"
          },
        });
        console.log(210, response.data);
        if (response.data.message === "success" && response.data.data.availableStocks) {

          setTotalMedicine(response.data.data.availableStocks);
        } else {
          setTotalMedicine(0);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    // Call the function to get the total medicine data

    getTotalMedicine();

    // today' medicine shortage
    const getMedicineShortage = async () => {
      try {
        let token = localStorage.getItem("x-api-key");
        const response = await axios.get("api/user/dashBoard/medicineShortage", {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": token,  "x-api-plan": "SaaS"
          },
        });

        if (response.data.message === "success") {
          console.log(234, response.data);
          setMedicineShortage(response.data.data);
        } else {
          setMedicineShortage(0);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    // Call the function to get the medicine shortage data
    getMedicineShortage();

  };

  return (
    <Layout heading_1="Dashboard" heading_2="Dashboard">
      <div className="dashboard-div-dashboard">
        {/* <DashboardHeader /> */}
        <div className="button-flex-layout">
          <div>
            <div className="total-overview-dashboard">
              <h3 style={{ marginLeft: "1rem" }}>Overview</h3>
              <div className="top-row-dashboard">
                <div className="boxes-dashboard">
                  <div>
                    <p>Today's Sales</p>
                    <div className="sales-color-dashboard">
                      <h2>{SalesData}</h2>
                      <p>+{saleIncrese}%</p>
                    </div>
                  </div>
                  <div>
                    <FaCoins className="logo-color-dashboard" />
                  </div>
                </div>
                <div className="boxes-dashboard">
                  <div>
                    <p>Today’s users</p>
                    <div className="user-color-dashboard">
                      <h2>{user}</h2>
                      <p>+{todayuserIncrease}%</p>
                    </div>
                  </div>
                  <div>
                    <FiUsers className="logo-color-dashboard" />
                  </div>
                </div>
                <div className="boxes-dashboard">
                  <div>
                    <p>Total Orders</p>
                    <div className="order-color-dashboard">
                      <h2>{totalOrder}</h2>
                      {/* <p>+30%</p> */}
                    </div>
                  </div>
                  <div>
                    <TfiBag className="logo-color-dashboard" />
                  </div>
                </div>
              </div>
              <div className="top-row-dashboard">
                <div className="boxes-dashboard">
                  <div>
                    <p>Revenue</p>
                    <div className="revenue-color-dashboard">
                      <h2>{Revenue}</h2>
                    </div>
                  </div>
                  <div>
                    <FaFileAlt className="logo-color-dashboard" />
                  </div>
                </div>
                <div className="boxes-dashboard">
                  <div>
                    <p>Total Medicine</p>
                    <div className="mAvail-color-dashboard">
                      <h2>{totalMedicine}</h2>
                    </div>
                  </div>
                  <div>
                    <GiMedicines className="logo-color-dashboard" />
                  </div>
                </div>
                <div className="boxes-dashboard">
                  <div>
                    <p>Medicine Shortage</p>
                    <div className="mShortage-color-dashboard">
                      <h2>{medicineshortage}</h2>
                    </div>
                  </div>
                  <div>
                    <FaUserMinus className="logo-color-dashboard" />
                  </div>
                </div>
              </div>
            </div>

            <div className="middle-main-dashboard">
              <div className="middle-main-dashboard-date-input">
                <p
                  style={{ marginBottom: "20px", cursor: "pointer" }}
                // onClick={handleDate}
                >
                  Date Range{" "}
                </p>
                {/* {showDate && (
                <DateRangePicker
                  onChange={(item) => {
                    setDate([item.selection]);
                    console.log(date);
                  }}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  ranges={date}
                  direction="horizontal"
                />
              )} */}
                <DateRangePicker
                  onOk={(event) => {
                    // console.log(event);
                  }}
                />
              </div>

              <div className="dash-test-1">
                <h3>
                  <RiBarChartFill />
                  Analytics
                </h3>
              </div>
              <div className="graphs-dashboard">
                <Graph2 />
                <Graph />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout >
  );
};

export default Dash;

import { React, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "../../Products/ProductUpdate/productupdate.css";
import Swal from "sweetalert2";
import axios from 'axios';
import Layout from "../../Layout/Layout";

const BankUpdate = () => {

    const [formData, setFormData] = useState({});

    useEffect(() => {
        getBankDetails();
    }, []);

    // on change function;

    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log(98, name, value)
        setFormData({ ...formData, [name]: value });
    };

    const params = useParams();

    const navigate = useNavigate();

    const getBankDetails = async () => {
        try {
            // let token = JSON.parse(localStorage.getItem("user"));
            //token
            let token = localStorage.getItem('x-api-key');
            let response = await axios.get(`api/user/firm/getOne/${params.id}`, {
                // method: "get",
                headers: {
                    "Content-Type": "application/json",
                    'x-api-key': token
                },
            }
            );
            let result = response.data;
            let res = result.data;
            console.log(res);
            setFormData({ firmName: res.firmName, branch: res.branch, accountNo: res.accountNo, accountName: res.accountName, ifsc: res.ifsc, fullAdd: res.fullAdd, })

        } catch (error) {
            console.log(error.response.data.message);
        }
    };

    const handleSubmit = async () => {
        try {

            //token

            let token = localStorage.getItem('x-api-key');

            let response = await axios.put(`api/user/firm/updateFirmDetails/${params.id}`,
                formData,
                {
                    headers: {
                        "Content-Type": "application/json",
                        'x-api-key': token
                    },
                }
            );

            let result = response.data;
            if (result.message) {
                Swal.fire(
                    'Updated!',
                    'Your Product has been Updated.',
                    'success'
                )
                navigate("/user/productlist");
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: result.message,
                });
                getBankDetails();
                navigate("/user/productlist")
            };
        } catch (error) {
            console.log(error.response.data.message);
        }
    };

    return (
        <Layout heading_1="Bank" heading_2="Bank Update">
            <div className="container-product-generate">
                <div className="form-box-product-generate">
                    <div className="c-form-product-generate">
                        <div class="two-columns-product-generate">
                            <fieldset>
                                <label className="c-form-label"> Account Name</label>
                                <input
                                    type="text"
                                    name="accountName"
                                    value={formData.accountName}
                                    onChange={handleChange}
                                />
                            </fieldset>
                            <fieldset>
                                <label className="c-form-label">Account Number</label>
                                <input
                                    type="text"
                                    name="accountNo"
                                    value={formData.accountNo}
                                    onChange={handleChange}
                                />
                            </fieldset>
                        </div>
                        <div className="two-columns-product-generate">
                            <fieldset>
                                <label className="c-form-label">Branch</label>
                                <textarea
                                    type="text"
                                    name="branch"
                                    value={formData.branch}
                                    onChange={handleChange}
                                />
                            </fieldset>
                            <fieldset>
                                <label className="c-form-label">IFSC Code</label>
                                <input
                                    type="text"
                                    name="ifsc"
                                    value={formData.ifsc}
                                    onChange={handleChange}
                                />
                            </fieldset>
                        </div>
                        <div className="two-columns-product-generate">
                            <fieldset>
                                <label className="c-form-label">Full Address</label>
                                <input
                                    type="text"
                                    name="fullAdd"
                                    value={formData.fullAdd}
                                    onChange={handleChange}
                                />
                            </fieldset>
                            <fieldset>
                                <label className="c-form-label">Firm Name</label>
                                <input
                                    type="text"
                                    name="firmName"
                                    value={formData.firmName}
                                    onChange={handleChange}
                                />
                            </fieldset>
                        </div>
                        <button className="button-sumbit-purchase-gen" onClick={handleSubmit}>
                            Update
                        </button>
                    </div>
                </div>
            </div>
        </Layout >
    );
};

export default BankUpdate;

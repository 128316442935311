import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

import "./ListComponent.css";

const ListComponent = ({ displayedData}) => {

     const [displayedData2,setDisplayedData] =useState(displayedData)

     useEffect( () =>{
        setDisplayedData(displayedData)
     })

    function Promptdelete(id) {
        var id = id;
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: false,
          showConfirmButton: true,
          showCloseButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
          .then((result) => {
            if (result.isConfirmed) {
              const deleteep = async (id) => {
                //getting token and setting in headers
                let token = localStorage.getItem("x-api-key");
                await axios
                  .delete(`api/user/firm/deleteFirmDetails/${id}`, {
                    headers: {
                      "Content-Type": "application/json",
                      "x-api-key": token,
                      "x-api-plan": "SaaS",
                    },
                  })
                  .then((response) => {
                    console.log(220, response.data);
                    Swal.fire("Deleted!", "Your file has been deleted.", "success");
                    setDisplayedData(displayedData.filter((item) => item.id !== id));
                    // window.location.reload();
                  })
                  .catch((error) => {
                    console.log(222, error.message);
                  });
              };
              deleteep(id);
            }
          })
          .catch((error) => {
            console.error(error.result.data.message);
          });
      }
    
  return (
    <div className="table-container">
      <div className="header list-item">
        <div className="sno" >S.No.</div>
        <div>Firm Name</div>
        {/* <div>Contact Name</div> */}
        <div>Mobile No.</div>
        {/* <div>Email</div> */}
        {/* <div>State</div> */}
        {/* <div>Address</div> */}
        <div>D.L. No.(1)</div>
        <div>D.L. No.(2)</div>
        <div>GSTIN</div>
        <div>FSSAI No.</div>
        <div 
        className="action-header"
        >Action</div>
      </div>
      { displayedData2 && displayedData2.length > 0 ? displayedData2.map((item) => (
        <div 
        className="list-item" 
        key={item.id}
        style={{
            backgroundColor: item.displayIndex % 2 === 0 ? '#f9f9f9' : 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '8px',
            borderBottom: '2px solid #ddd',
          }}
        >
          <div className="sno">{item.displayIndex}</div>
          <div>{item.firmName}</div>
          {/* <div>{item.personName}</div> */}
          <div>{item.mobileNo}</div>
          {/* <div>{item.email}</div> */}
          {/* <div>{item.state}</div> */}
          {/* <div>{item.fullAdd}</div> */}
          <div>{item.dl1}</div>
          <div>{item.dl2}</div>
          <div>{item.gst}</div>
          <div>{item.fssai}</div>

          <div className="action-buttons">
        <button 
        style={{ backgroundColor: '#015998'}} 
        id="view-sale-list">
          <Link     style={{color:'white' }}  to={`/user/viewFirm/${item.id}`}>View</Link>
        </button>
        <button style={{ backgroundColor: 'green' }} id="edit-sale-list">
          <Link  style={{color:'white' }} to={`/user/updateFirm/${item.id}`}>Edit</Link>
        </button>
        <button  style={{ backgroundColor: 'red' }} id="del-sale-list" onClick={() => Promptdelete(item.id)}>
          Delete
        </button>
      </div>
        </div>
      )) : <h6  style={{display:'flex',justifyContent:'center',alignItems:'center'}} >no data found</h6>}
    </div>
  );
      };
      
      export default ListComponent;
      
import "./GoodsReport.css";

import { React, useEffect, useState, useRef } from "react";
import { FaRegFilePdf } from "react-icons/fa";
import { FaFileCsv } from "react-icons/fa";

// import Header from "./GoodsReportHeader";
import Layout from "../../Layout/Layout.js";

import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { CSVLink } from "react-csv";
import axios from "axios";

//============== Headers for csv file======================= //
const headers = [
  { label: "Firm Name", key: "firmName" },
  { label: "Date", key: "date" },
  { label: "Invoice No.", key: "invoiceNo" },
  { label: "Region", key: "region" },
  { label: "Type", key: "type" },
  { label: "Product Name", key: "productName" },
  { label: "Qty", key: "qty" },
  { label: "Free", key: "free" },
  { label: "Trade Rate", key: "tradeRate" },
  { label: "Disc %", key: "discPercent" },
  { label: "Total Tax", key: "totalTax" },
  { label: "SGST", key: "sgst" },
  { label: "CGST", key: "cgst" },
  { label: "IGST", key: "igst" },
  { label: "CESS", key: "cess" },
  { label: "Amount", key: "amount" },
];

export default function Goodsreport() {
  const [GoodsReportDatas, setGoodsReportData] = useState([]);

  useEffect(() => {
    GoodsReportData();
  }, []);

  const GoodsReportData = async () => {
    try {
      //token
      let token = localStorage.getItem("x-api-key");
      let response = await axios.post(
        "api/user/sell/goodReturnsSell",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": token,
            "x-api-plan": "SaaS",
          },
        }
      );

      let result = response.data;
      if (result.data) {
        setGoodsReportData(result.data);
      } else {
        setGoodsReportData([]);
      }
      console.log(result, "result");
    } catch (error) {
      console.error(error.response.data.message);
    }
  };

  // code for filtering firm according to search bar;
  const [filteredList, setFilteredList] = new useState(GoodsReportDatas);
  const [filter, setFilter] = useState(false);
  const filterListSearch = (event) => {
    setFilter(true);
    let updatedList = [...GoodsReportDatas];
    updatedList = updatedList.filter((item) => {
      // we can add one more field to search like invoice number;
      return (
        item.firmName
          .toLowerCase()
          .indexOf(event.target.value.toLowerCase()) !== -1 ||
        item.productName
          .toLowerCase()
          .indexOf(event.target.value.toLowerCase()) !== -1
      );
    });
    console.log("updatedList", updatedList);
    setFilteredList(updatedList);
  };

  //==========  converting data to pdf===========//
  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 350;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Goods-Return Report";
    const headers = [
      [
        "S.No.",
        "Date",
        "Firm Name",
        "Invoice No.",
        "Region",
        "Product Name",
        "Discount Amt.",
        "SGST",
        "CGST",
        "IGST",
        "CESS.",
        "Total Tax Amt.",
        "Amount",
      ],
    ];
    // console.log(GoodsReportDatas);
    let data;
    if (filter) {
      data = filteredList.map((elt, index) => [
        index + 1,
        elt.firmName,
        elt.invoiceNo,
        elt.region,
        elt.productName,
        elt.discPercent,
        elt.discAmt,
        elt.sgst,
        elt.cgst,
        elt.igst,
        elt.cess,
        elt.cess,
        elt.totalTax,
        elt.amount,
      ]);
    } else {
      data = GoodsReportDatas.map((elt, index) => [
        index + 1,
        elt.firmName,
        elt.invoiceNo,
        elt.region,
        elt.productName,
        elt.discPercent,
        elt.discAmt,
        elt.sgst,
        elt.cgst,
        elt.igst,
        elt.cess,
        elt.cess,
        elt.totalTax,
        elt.amount,
      ]);
    }

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    autoTable(doc, content);
    doc.save("Goods-Return Report.pdf");
  };

  //========= converting filtered data to csv============ //
  const csvDownloadRef = useRef(null);
  const convertToCsv = () => {
    setTimeout(() => {
      csvDownloadRef.current.link.click();
    }, 500);
  };

  const ele = filter
    ? filteredList.map(function (item, index) {
        return (
          <tr key={index}>
            <td>
              <span className="span-width">{index + 1}</span>
            </td>
            <td>
              <span className="span-width">{item.date}</span>
            </td>
            <td>
              <span className="span-width">{item.firmName}</span>
            </td>
            <td>
              <span className="span-width">{item.properInvoice}</span>
            </td>
            <td>
              <span className="span-width">{item.region}</span>
            </td>
            <td>
              <span className="span-width">{item.productName}</span>
            </td>
            <td>
              <span className="span-width">{item.discPercent}</span>
            </td>
            <td>
              <span className="span-width">{item.sgst}</span>
            </td>
            <td>
              <span className="span-width">{item.cgst}</span>
            </td>
            <td>
              <span className="span-width">{item.igst}</span>
            </td>
            <td>
              <span className="span-width">{item.cess}</span>
            </td>
            <td>
              <span className="span-width">{item.totalTax}</span>
            </td>
            <td>
              <span className="span-width">{item.amount}</span>
            </td>
            {/* <td>
          <span className="span-width">{item.discAmt}</span>
        </td>   */}
            {/* <td>
          <span className="span-width">{item.totalTax}</span>
        </td>
        <td>
          <span className="span-width">{item.sgst}</span>
        </td>
        <td>
          <span className="span-width">{item.cgst}</span>
        </td>
        <td>
          <span className="span-width">{item.igst}</span>
        </td>
        <td>
          <span className="span-width">{item.cess}</span>
        </td>
        <td>
          <span className="span-width">{item.amount}</span>
        </td> */}

            {/* <td><span className="span-width">{item.cnamt}</span></td>
        <td>
          <span className="span-width">{item.tax}</span>
        </td>
        <td>
          <span className="span-width">{item.tax}</span>
        </td>
        <td><span className="span-width">{item.grandTotal}</span></td> */}
          </tr>
        );
      })
    : GoodsReportDatas.map(function (item, index) {
        return (
          <tr key={index}>
            <td>
              <span className="span-width">{index + 1}</span>
            </td>
            <td>
              <span className="span-width">{item.date}</span>
            </td>
            <td>
              <span className="span-width">{item.firmName}</span>
            </td>
            <td>
              <span className="span-width">{item.properInvoice}</span>
            </td>
            <td>
              <span className="span-width">{item.region}</span>
            </td>
            <td>
              <span className="span-width">{item.productName}</span>
            </td>
            <td>
              <span className="span-width">{item.discPercent}</span>
            </td>
            <td>
              <span className="span-width">{item.sgst}</span>
            </td>
            <td>
              <span className="span-width">{item.cgst}</span>
            </td>
            <td>
              <span className="span-width">{item.igst}</span>
            </td>
            <td>
              <span className="span-width">{item.cess}</span>
            </td>
            <td>
              <span className="span-width">{item.totalTax}</span>
            </td>
            <td>
              <span className="span-width">{item.amount}</span>
            </td>
            {/* <td>
          <span className="span-width">{item.discAmt}</span>
        </td>   */}
            {/* <td>
          <span className="span-width">{item.totalTax}</span>
        </td>
        <td>
          <span className="span-width">{item.sgst}</span>
        </td>
        <td>
          <span className="span-width">{item.cgst}</span>
        </td>
        <td>
          <span className="span-width">{item.igst}</span>
        </td>
        <td>
          <span className="span-width">{item.cess}</span>
        </td>
        <td>
          <span className="span-width">{item.amount}</span>
        </td> */}

            {/* <td><span className="span-width">{item.cnamt}</span></td>
        <td>
          <span className="span-width">{item.tax}</span>
        </td>
        <td>
          <span className="span-width">{item.tax}</span>
        </td>
        <td><span className="span-width">{item.grandTotal}</span></td> */}
          </tr>
        );
      });

  return (
    <Layout heading_1="Sales" heading_2="Sale Goods Return Report">
      <div 
      // className="main-Goodsreport-file"
      >
        <div className="Goodsreport-main">
          {/* <Header /> */}
          <div className="Goodsreport">
            {/* <div className="Goodsreport-header" style={{ height: "34px" }}>
            <h4>Sale Goods Return Report</h4>
          </div> */}

            <div className="table-top-Goodsreport">
              <div className="show-name-Goodsreport">
                <label htmlFor="show">Show : </label>
                <select
                  id="show showSelect"
                  style={{
                    height: "36px",
                    width: "69px",
                    marginLeft: "1rem",
                    marginRight: "1rem",
                    borderRadius: "5px",
                  }}
                >
                  <option>All</option>
                </select>
                <FaRegFilePdf
                  className="pdf-Goodsreport"
                  style={{ cursor: "pointer" }}
                  onClick={(event) => {
                    exportPDF();
                  }}
                />
                <CSVLink
                  data={filter ? filteredList : GoodsReportDatas}
                  headers={headers}
                  filename="Goods-Return Report.csv"
                  className="hidden"
                  ref={csvDownloadRef}
                  target="_blank"
                />
                <FaFileCsv
                  className="csv-Goodsreport"
                  style={{ cursor: "pointer" }}
                  onClick={(event) => {
                    convertToCsv(event);
                  }}
                />
              </div>

              <div className="search-name-Goodsreport">
                <input
                  placeholder="Search by Firm,Product Name"
                  onChange={filterListSearch}
                />
              </div>
            </div>
            <div className="table-customer-Goodsreport">
              <table id="customers-Goodsreport">
                <tr className="table-customer-heading-Goodsreport">
                  <th>S.No</th>
                  <th>Date</th>
                  <th>Firm Name </th>
                  <th>Invoice No.</th>
                  <th>Region</th>
                  <th>Product Name</th>
                  <th>Discount Amt.</th>
                  <th>SGST</th>
                  <th>CGST</th>
                  <th>IGST</th>
                  <th>CESS</th>
                  <th>Total Tax Amt</th>
                  <th>Amount </th>
                </tr>

                {ele}
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

import "./VoucherReport.css";
import { React, useEffect, useState, useRef } from "react";
import { FaRegFilePdf } from "react-icons/fa";
import { FaFileCsv, FaRegCopy } from "react-icons/fa";
import axios from "axios";
// import VoucherReportHeader from "./VoucherReportHeader";
import Layout from "../../Layout/Layout.js";

// import value from "../SalesReport/value";

import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { CSVLink } from "react-csv";

// ============== Headers for csv file======================= //
const headers = [
  { label: "Firm Name", key: "firmName" },
  { label: "Date", key: "date" },
  { label: "Invoice No.", key: "invoiceNo" },
  { label: "Region", key: "region" },
  { label: "Type", key: "type" },
  { label: "Sub Total", key: "subTotal" },
  { label: "Discount Amt.", key: "discAmt" },
  { label: "Total Tax Amt.", key: "totalTax" },
  { label: "SGST", key: "sgst" },
  { label: "CGST", key: "cgst" },
  { label: "IGST", key: "igst" },
  { label: "CESS", key: "cess" },
  { label: "G/R Amount", key: "" },
  { label: "Credit Note Amt.", key: "cnVoucher" },
  { label: "Payable Amt.", key: "grandTotal" },
];

export default function Voucherreport() {
  const [salesVoucherData, setSalesVoucherData] = useState([]);

  useEffect(() => {
    SalesVoucherData();
  }, []);

  const SalesVoucherData = async () => {
    try {
      let token = localStorage.getItem("x-api-key");
      let response = await axios(
        "api/user/sell/cnReportsSell",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": token,
            "x-api-plan": "SaaS",
          },
        }
      );

      let result = response.data;
      if (result.data) {
        setSalesVoucherData(result.data);
      } else {
        setSalesVoucherData([]);
      }
    } catch (error) {
      console.error(error.response.data.message);
    }
  };

  // code for filtering firm according to search bar;
  const [filteredList, setFilteredList] = new useState([...salesVoucherData]);
  const [filter, setFilter] = useState(false);
  const filterSellReportSearch = (event) => {
    setFilter(true);
    let updatedList = [...salesVoucherData];
    updatedList = updatedList.filter((item) => {
      return (
        item.firmName
          .toLowerCase()
          .indexOf(event.target.value.toLowerCase()) !== -1 ||
        item.properCnRefNo
          .toLowerCase()
          .indexOf(event.target.value.toLowerCase()) !== -1
      );
    });
    setFilteredList(updatedList);
  };

  //==========  converting data to pdf=========== //
  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape //

    const marginLeft = 350;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Sell Credit Note Voucher Report";
    const headers = [
      [
        "S.No.",
        "Firm Name",
        "Date",
        "CN Ref No.",
        "Region",
        "Type",
        "Discount Amt.",
        "SGST",
        "CGST",
        "IGST",
        "CESS.",
        "Total Tax Amt.",
        "Credit Note Amt.",
      ],
    ];

    let data;
    if (filter) {
      data = filteredList.map((elt, index) => [
        index + 1,
        elt.firmName,
        elt.date,
        elt.properCnRefNo,
        elt.region,
        elt.type,
        elt.discAmt,
        elt.sgst,
        elt.cgst,
        elt.igst,
        elt.cess,
        elt.totalTax,
        elt.grandTotal,
      ]);
    } else {
      data = salesVoucherData.map((elt, index) => [
        index + 1,
        elt.firmName,
        elt.date,
        elt.properCnRefNo,
        elt.region,
        elt.type,
        elt.discAmt,
        elt.sgst,
        elt.cgst,
        elt.igst,
        elt.cess,
        elt.totalTax,
        elt.grandTotal,
      ]);
    }

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    autoTable(doc, content);
    doc.save("Sell Credit Note Voucher Report.pdf");
  };

  //========= converting filtered data to csv============ //
  const csvDownloadRef = useRef(null);
  const convertToCsv = () => {
    setTimeout(() => {
      csvDownloadRef.current.link.click();
    }, 500);
  };
  const ele = !filter
    ? salesVoucherData.map(function (item, index) {
        console.log(156, filter);
        return (
          <tr key={index}>
            <td>
              <span className="span-width">{index + 1}</span>
            </td>
            <td>
              <span className="span-width">{item.firmName}</span>
            </td>
            <td>
              <span className="span-width">{item.date}</span>
            </td>
            <td>
              <span className="span-width">{item.properCnRefNo}</span>
            </td>
            <td>
              <span className="span-width">{item.region}</span>
            </td>
            <td>
              <span className="span-width">{item.type}</span>
            </td>
            {/* <td>
              <span className="span-width">{item.amt}</span>
            </td> */}
            <td>
              <span className="span-width">{item.discAmt}</span>
            </td>
            <td>
              <span className="span-width">{item.sgst}</span>
            </td>
            <td>
              <span className="span-width">{item.cgst}</span>
            </td>
            <td>
              <span className="span-width">{item.igst}</span>
            </td>
            <td>
              <span className="span-width">{item.cess}</span>
            </td>
            <td>
              <span className="span-width">{item.totalTax}</span>
            </td>
            {/* <td>
              <span className="span-width">{item.cnVoucher}</span>
            </td> */}
            <td>
              <span className="span-width">{item.grandTotal}</span>
            </td>
          </tr>
        );
      })
    : filteredList.map(function (item, index) {
        console.log(210, filter);
        return (
          <tr key={index}>
            <td>
              <span className="span-width">{index + 1}</span>
            </td>
            <td>
              <span className="span-width">{item.firmName}</span>
            </td>
            <td>
              <span className="span-width">{item.date}</span>
            </td>
            <td>
              <span className="span-width">{item.properCnRefNo}</span>
            </td>
            <td>
              <span className="span-width">{item.region}</span>
            </td>
            <td>
              <span className="span-width">{item.type}</span>
            </td>
            {/* <td>
              <span className="span-width">{item.amt}</span>
            </td> */}
            <td>
              <span className="span-width">{item.discAmt}</span>
            </td>
            <td>
              <span className="span-width">{item.sgst}</span>
            </td>
            <td>
              <span className="span-width">{item.cgst}</span>
            </td>
            <td>
              <span className="span-width">{item.igst}</span>
            </td>
            <td>
              <span className="span-width">{item.cess}</span>
            </td>
            <td>
              <span className="span-width">{item.totalTax}</span>
            </td>
            <td>
              <span className="span-width">{item.grandTotal}</span>
            </td>
            {/* <td>
              <span className="span-width">{item.subTotal}</span>
            </td> */}
          </tr>
        );
      });

  return (
    <Layout heading_1="Sales" heading_2="Credit Note Voucher Report">
      <div className="main-Voucherreport-file">
        <div className="Voucherreport-main">
          {/* <VoucherReportHeader /> */}
          <div className="Voucherreport">
            {/* <div className="Voucherreport-header" style={{ height: "34px" }}>
            <h4>Sell Credit Note Voucher Report</h4>
          </div> */}

            <div className="table-top-Voucherreport">
              <div className="show-name-Voucherreport">
                <label htmlFor="show">Show : </label>
                <select
                  id="show showSelect"
                  style={{
                    height: "36px",
                    width: "69px",
                    marginLeft: "1rem",
                    marginRight: "1rem",
                    borderRadius: "5px",
                  }}
                >
                  <option>All</option>
                </select>
                <FaRegFilePdf
                  className="pdf-Goodsreport"
                  style={{ cursor: "pointer" }}
                  onClick={(event) => {
                    exportPDF();
                  }}
                />
                <CSVLink
                  data={filter ? filteredList : salesVoucherData}
                  headers={headers}
                  filename="Sell Credit Note Voucher Report.csv"
                  className="hidden"
                  ref={csvDownloadRef}
                  target="_blank"
                />
                <FaFileCsv
                  className="csv-Goodsreport"
                  style={{ cursor: "pointer" }}
                  onClick={(event) => {
                    convertToCsv(event);
                  }}
                />
              </div>

              <div className="search-name-Voucherreport">
                <input
                  placeholder="Search by Firm, Cn Ref No."
                  onChange={filterSellReportSearch}
                />
              </div>
            </div>
            <div className="table-customer-Voucherreport">
              <table id="customers-Voucherreport">
                <tr className="table-customer-heading-Voucherreport">
                  <th>S.No</th>
                  <th>Firm Name </th>
                  <th>Date</th>
                  <th>Credit Note Ref No.</th>
                  <th>Region</th>
                  <th>Type</th>
                  {/* <th>Amount </th> */}
                  <th>Discount</th>
                  <th>SGST</th>
                  <th>CGST</th>
                  <th>IGST</th>
                  <th>CESS</th>
                  <th>Tax Amt</th>
                  {/* <th>CN Amt</th> */}
                  <th>Credit Note Amt</th>
                </tr>
                {ele}
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

import "./PurchaseReport.css";
import axios from "axios";
import { React, useEffect, useState, useRef } from "react";
import { FaRegFilePdf } from "react-icons/fa";
import { FaFileCsv, FaRegCopy } from "react-icons/fa";
import Swal from "sweetalert2";
import { useGlobalContext } from "components/Context";
import Layout from "../../Layout/Layout";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { CSVLink } from "react-csv";

//============== Headers for csv file======================= //

const headers = [
  { label: "Firm Name", key: "firmName" },
  { label: "Date", key: "date" },
  { label: "Invoice No.", key: "invoiceNo" },
  { label: "Region", key: "region" },
  { label: "Type", key: "type" },
  { label: "Sub Total", key: "subTotal" },
  { label: "Discount Amt.", key: "discAmt" },
  { label: "Total Tax Amt.", key: "totalTax" },
  { label: "SGST", key: "sgst" },
  { label: "CGST", key: "cgst" },
  { label: "IGST", key: "igst" },
  { label: "CESS", key: "cess" },
  { label: "G/R Amount", key: "" },
  { label: "Credit Note Amt.", key: "cnVoucher" },
  { label: "Payable Amt.", key: "grandTotal" },
];

export default function Purchasereport() {
  const pageSize = 10;

  const [PurchaseReport, setPurchaseReport] = useState([]);

  // let { PaginationControlled, page, setCount, pageSize } = useGlobalContext();

  let { PaginationControlled, page, setTotalPages } = useGlobalContext();

  useEffect(() => {
    getPurchaseReport();
  }, [page]);

  const getPurchaseReport = async () => {
    //token
    let token = localStorage.getItem("x-api-key");
    // console.log(token);
    await axios
      .post(
        "api/user/purchase/purchaseReports",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": token,
            "x-api-plan": "SaaS",
          },

          // params: { page: page, pageSize: pageSize },
        }
      )
      .then((response) => {
        console.log("comming", response.data.data);
        setPurchaseReport(response.data.data);
        // setCount(response.data.totalItems);
      })
      .catch((error) => {
        // Handle errors here
        console.error(error);
      });
  };

  // code for filtering firm according to search bar

  const [filteredList, setFilteredList] = new useState([...PurchaseReport]);
  const [filter, setFilter] = useState(false);
  const filterPurchaseReportSearch = (event) => {
    setFilter(true);
    let updatedList = [...PurchaseReport];
    updatedList = updatedList.filter((item) => {
      return (
        item.firmName
          .toLowerCase()
          .indexOf(event.target.value.toLowerCase()) !== -1 ||
        item.invoiceNo
          .toLowerCase()
          .indexOf(event.target.value.toLowerCase()) !== -1
      );
    });
    setFilteredList(updatedList);
  };
  //==========  converting data to pdf=========== //
  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 350;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Purchase Report";
    const headers = [
      [
        "S.No.",
        "Firm Name",
        "Date",
        "Invoice No.",
        "Region",
        "Type",
        "Sub Total",
        "Discount Amt.",
        "Total Tax Amt..",
        "SGST",
        "CGST",
        "IGST",
        "CESS.",
        "G/R Amount",
        "Credit Note Amt.",
        "Payable Amt.",
      ],
    ];
    // console.log(filteredPurchaseReport);
    let data;
    if (filter) {
      data = filteredList.map((elt, index) => [
        index + 1,
        elt.firmName,
        elt.date,
        elt.invoiceNo,
        elt.region,
        elt.type,
        elt.subTotal,
        elt.discAmt,
        elt.totalTax,
        elt.sgst,
        elt.cgst,
        elt.igst,
        elt.cess,
        elt.goodReturnAmt,
        elt.cnVoucher,
        elt.grandTotal,
      ]);
    } else {
      data = PurchaseReport.map((elt, index) => [
        index + 1,
        elt.firmName,
        elt.date,
        elt.invoiceNo,
        elt.region,
        elt.type,
        elt.subTotal,
        elt.discAmt,
        elt.totalTax,
        elt.sgst,
        elt.cgst,
        elt.igst,
        elt.cess,
        elt.goodReturnAmt,
        elt.cnVoucher,
        elt.grandTotal,
      ]);
    }

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    autoTable(doc, content);
    doc.save("Purchase Report.pdf");
  };

  //========= converting filtered data to csv============ //
  const csvDownloadRef = useRef(null);
  const convertToCsv = () => {
    setTimeout(() => {
      csvDownloadRef.current.link.click();
    }, 500);
  };

  // start

  // Your data (Firm) and other variables

  // Calculate the start and end index for the current page

  const startIndex = (page - 1) * pageSize;

  const endIndex = startIndex + pageSize;

  // Filter the data based on the current page;

  let displayedData = (
    filter
      ? filteredList.slice(startIndex, endIndex)
      : PurchaseReport.slice(startIndex, endIndex)
  ).map((item, index) => ({
    ...item,
    displayIndex: (page - 1) * pageSize + index + 1,
  }));

  // Calculate the total number of pages;

  const totalPages = Math.ceil(
    (filter ? filteredList.length : PurchaseReport.length) / pageSize
  );

  setTotalPages(totalPages);

  // Handle page change;

  // const handleChange = (event, value) => {
  //   setPage(value);
  // };

  const ele = filter
    ? displayedData.map(function (item, index) {
        return (
          <tr>
            <td>
              <span className="span-width-puchase-report">
                {item.displayIndex}
              </span>
            </td>
            <td>
              <span className="span-width-puchase-report">{item.firmName}</span>
            </td>
            <td>
              <span className="span-width-puchase-report">{item.date}</span>
            </td>
            <td>
              <span className="span-width-puchase-report">
                {item.invoiceNo}
              </span>
            </td>
            <td>
              <span className="span-width-puchase-report">{item.region}</span>
            </td>
            <td>
              <span className="span-width-puchase-report">{item.type}</span>
            </td>
            <td>
              <span className="span-width-puchase-report">{item.subTotal}</span>
            </td>
            <td>
              <span className="span-width-puchase-report">{item.discAmt}</span>
            </td>
            <td>
              <span className="span-width-puchase-report">{item.totalTax}</span>
            </td>
            <td>
              <span className="span-width-puchase-report">{item.sgst}</span>
            </td>
            <td>
              <span className="span-width-puchase-report">{item.cgst}</span>
            </td>
            <td>
              <span className="span-width-puchase-report">{item.igst}</span>
            </td>
            <td>
              <span className="span-width-puchase-report">{item.cess}</span>
            </td>
            <td>
              <span className="span-width-puchase-report">
                {item.goodReturnAmt}
              </span>
            </td>
            <td>
              <span className="span-width-puchase-report">
                {item.cnVoucher}
              </span>
            </td>
            <td>
              <span className="span-width-puchase-report">
                {item.grandTotal}
              </span>
            </td>
          </tr>
        );
      })
    : displayedData.map(function (item, index) {
        return (
          <tr>
            <td>
              <span className="span-width-puchase-report">
                {item.displayIndex}
              </span>
            </td>
            <td>
              <span className="span-width-puchase-report">{item.firmName}</span>
            </td>
            <td>
              <span className="span-width-puchase-report">{item.date}</span>
            </td>
            <td>
              <span className="span-width-puchase-report">
                {item.invoiceNo}
              </span>
            </td>
            <td>
              <span className="span-width-puchase-report">{item.region}</span>
            </td>
            <td>
              <span className="span-width-puchase-report">{item.type}</span>
            </td>
            <td>
              <span className="span-width-puchase-report">{item.subTotal}</span>
            </td>
            <td>
              <span className="span-width-puchase-report">{item.discAmt}</span>
            </td>
            <td>
              <span className="span-width-puchase-report">{item.totalTax}</span>
            </td>
            <td>
              <span className="span-width-puchase-report">{item.sgst}</span>
            </td>
            <td>
              <span className="span-width-puchase-report">{item.cgst}</span>
            </td>
            <td>
              <span className="span-width-puchase-report">{item.igst}</span>
            </td>
            <td>
              <span className="span-width-puchase-report">{item.cess}</span>
            </td>
            <td>
              <span className="span-width-puchase-report">
                {item.goodReturnAmt}
              </span>
            </td>
            <td>
              <span className="span-width-puchase-report">
                {item.cnVoucher}
              </span>
            </td>
            <td>
              <span className="span-width-puchase-report">
                {item.grandTotal}
              </span>
            </td>
          </tr>
        );
      });

  return (
    <Layout heading_1="Purchase" heading_2="Purchase Report">
      <div className="main-Purchasereport-file">
        <div className="Purchasereport-main">
          {/* <Header /> */}

          {/* <div className="Purchasereport-header" style={{ height: "34px" }}>
          <h4>Purchase Report</h4>
        </div> */}

          <div className="table-top-Purchasereport">
            <div className="show-name-Purchasereport">
              <label htmlFor="show">Show : </label>
              <select
                id="show showSelect"
                style={{
                  height: "36px",
                  width: "69px",
                  marginLeft: "1rem",
                  marginRight: "1rem",
                  borderRadius: "5px",
                }}
              >
                <option>All</option>
              </select>
              <FaRegFilePdf
                className="pdf-Purchasereport"
                style={{ cursor: "pointer" }}
                onClick={(event) => {
                  exportPDF();
                }}
              />
              <CSVLink
                data={filter ? filteredList : PurchaseReport}
                headers={headers}
                filename="Purchase Report.csv"
                className="hidden"
                ref={csvDownloadRef}
                target="_blank"
              />
              <FaFileCsv
                className="csv-Purchasereport"
                style={{ cursor: "pointer" }}
                onClick={(event) => {
                  convertToCsv(event);
                }}
              />
            </div>

            <div className="search-name-Purchasereport">
              <input
                placeholder="Search by Firm Name, Invoice No."
                onChange={filterPurchaseReportSearch}
              />
            </div>
          </div>
          <div className="table-customer-Purchasereport">
            <table id="customers-Purchasereport">
              <tr className="table-customer-heading-Purchasereport">
                <th>S.No</th>
                <th>Firm Name </th>
                <th>Date</th>
                <th>Invoice No.</th>
                <th>Region</th>
                <th>Type</th>
                <th>Sub Total</th>
                <th>Discount Amt.</th>
                <th>Total Tax Amt</th>
                <th>SGST</th>
                <th>CGST</th>
                <th>IGST</th>
                <th>CESS</th>
                <th>G/R Amt.</th>
                <th>Credit Note Amt</th>
                <th>Payable Amount </th>
              </tr>
              {ele}
            </table>
          </div>
          <PaginationControlled />
        </div>
      </div>
    </Layout>
  );
}

import "./VoucherReport.css";
import { React, useEffect, useState, useRef } from "react";
import { FaRegFilePdf } from "react-icons/fa";
import { FaFileCsv, FaRegCopy } from "react-icons/fa";
// import VoucherReportHeader from "./VoucherReportHeader";
import Layout from "../../Layout/Layout";
import Swal from "sweetalert2";
import value from "../VoucherReport/value";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { CSVLink } from "react-csv";
import axios from "axios";

//============== Headers for csv file======================= //

const headers = [
  { label: "Firm Name", key: "firmName" },
  { label: "Date", key: "date" },
  { label: "CN Ref No.", key: "CnRefNo" },
  { label: "Region", key: "region" },
  { label: "Type", key: "type" },
  { label: "Discount Amt.", key: "discAmt" },
  { label: "SGST", key: "sgst" },
  { label: "CGST", key: "cgst" },
  { label: "IGST", key: "igst" },
  { label: "CESS", key: "cess" },
  { label: "Total Tax Amt.", key: "totalTax" },
  { label: "Credit Note Amt", key: "grandTotal" },
];
export default function Voucherreport() {
  const [purchaseVoucherData, setPurchaseVoucherData] = useState([]);

  useEffect(function () {
    pVoucherData();
  }, []);

  const pVoucherData = async () => {
    //token
    let token = localStorage.getItem("x-api-key");
    let response = await axios.post(
      "api/user/purchase/cnReportsPurchase",
      {},
      {
        // method: "post",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": token,
          "x-api-plan": "SaaS",
        },
        // body: JSON.stringify({}),
      }
    );

    let result = response.data;
    if (result.data) {
      setPurchaseVoucherData(result.data);
    } else {
      setPurchaseVoucherData([]);
    }
    console.log("result", result);
    // transferData(result.data);
  };

  // code for filtering firm according to search bar;
  const [filteredList, setFilteredList] = new useState(purchaseVoucherData);
  const [filter, setFilter] = useState(false);
  const filterPurchaseReportSearch = (event) => {
    setFilter(true);
    let updatedList = [...purchaseVoucherData];
    updatedList = updatedList.filter((item) => {
      return (
        item.firmName
          .toLowerCase()
          .indexOf(event.target.value.toLowerCase()) !== -1 ||
        item.cnRefNo.toLowerCase().indexOf(event.target.value.toLowerCase()) !==
          -1
      );
    });
    setFilteredList(updatedList);
  };

  //==========  converting data to pdf=========== //
  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape //

    const marginLeft = 350;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Purchase Credit Note Voucher Report";
    const headers = [
      [
        "S.No.",
        "Firm Name",
        "Date",
        "CN Ref No.",
        "Region",
        "Type",
        "Discount Amt.",
        "SGST",
        "CGST",
        "IGST",
        "CESS.",
        "Total Tax Amt.",
        "Credit Note Amt",
      ],
    ];
    // console.log(purchaseVoucherData);
    let data;
    if (filter) {
      data = filteredList.map((elt, index) => [
        index + 1,
        elt.firmName,
        elt.date,
        elt.CnRefNo,
        elt.region,
        elt.type,
        elt.discAmt,
        elt.sgst,
        elt.cgst,
        elt.igst,
        elt.cess,
        elt.totalTax,
        elt.grandTotal,
      ]);
    } else {
      data = purchaseVoucherData.map((elt, index) => [
        index + 1,
        elt.firmName,
        elt.date,
        elt.CnRefNo,
        elt.region,
        elt.type,
        elt.discAmt,
        elt.sgst,
        elt.cgst,
        elt.igst,
        elt.cess,
        elt.totalTax,
        elt.grandTotal,
      ]);
    }

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    autoTable(doc, content);
    doc.save("Purchase Credit Note Voucher Report.pdf");
  };

  //========= converting filtered data to csv============ //

  const csvDownloadRef = useRef(null);
  const convertToCsv = () => {
    setTimeout(() => {
      csvDownloadRef.current.link.click();
    }, 500);
  };
  const ele = !filter
    ? purchaseVoucherData.map(function (item, index) {
        console.log(156, filter);
        return (
          <tr key={index}>
            <td>
              <span className="span-width">{index + 1}</span>
            </td>
            <td>
              <span className="span-width">{item.firmName}</span>
            </td>
            <td>
              <span className="span-width">{item.date}</span>
            </td>
            <td>
              <span className="span-width">{item.cnRefNo}</span>
            </td>
            <td>
              <span className="span-width">{item.region}</span>
            </td>
            <td>
              <span className="span-width">{item.type}</span>
            </td>
            {/* <td>
              <span className="span-width">{item.amt}</span>
            </td> */}
            <td>
              <span className="span-width">{item.discAmt}</span>
            </td>
            <td>
              <span className="span-width">{item.sgst}</span>
            </td>
            <td>
              <span className="span-width">{item.cgst}</span>
            </td>
            <td>
              <span className="span-width">{item.igst}</span>
            </td>
            <td>
              <span className="span-width">{item.cess}</span>
            </td>
            <td>
              <span className="span-width">{item.totalTax}</span>
            </td>
            <td>
              <span className="span-width">{item.grandTotal}</span>
            </td>
            {/* <td>
              <span className="span-width">{item.subTotal}</span>
            </td> */}
          </tr>
        );
      })
    : filteredList.map(function (item, index) {
        console.log(210, filter);
        return (
          <tr key={index}>
            <td>
              <span className="span-width">{index + 1}</span>
            </td>
            <td>
              <span className="span-width">{item.firmName}</span>
            </td>
            <td>
              <span className="span-width">{item.date}</span>
            </td>
            <td>
              <span className="span-width">{item.cnRefNo}</span>
            </td>
            <td>
              <span className="span-width">{item.region}</span>
            </td>
            <td>
              <span className="span-width">{item.type}</span>
            </td>
            {/* <td>
              <span className="span-width">{item.amt}</span>
            </td> */}
            <td>
              <span className="span-width">{item.discAmt}</span>
            </td>
            <td>
              <span className="span-width">{item.sgst}</span>
            </td>
            <td>
              <span className="span-width">{item.cgst}</span>
            </td>
            <td>
              <span className="span-width">{item.igst}</span>
            </td>
            <td>
              <span className="span-width">{item.cess}</span>
            </td>
            <td>
              <span className="span-width">{item.totalTax}</span>
            </td>
            <td>
              <span className="span-width">{item.grandTotal}</span>
            </td>
            {/* <td>
              <span className="span-width">{item.subTotal}</span>
            </td> */}
          </tr>
        );
      });

  return (
    <Layout heading_1="Purchase" heading_2="Credit Note Voucher Report">
      <div className="main-Voucherreport-file">
        <div className="Voucherreport-main">
          {/* <VoucherReportHeader /> */}
          <div className="Voucherreport">
            {/* <div className="Voucherreport-header" style={{ height: "34px" }}>
            <h4>Purchase Credit Note Voucher Report</h4>
          </div> */}

            <div className="table-top-Voucherreport">
              <div className="show-name-Voucherreport">
                <label htmlFor="show">Show : </label>
                <select
                  id="show showSelect"
                  style={{
                    height: "36px",
                    width: "69px",
                    marginLeft: "1rem",
                    marginRight: "1rem",
                    borderRadius: "5px",
                  }}
                >
                  <option>All</option>
                </select>
                <FaRegFilePdf
                  className="pdf-Goodsreport"
                  style={{ cursor: "pointer" }}
                  onClick={(event) => {
                    exportPDF();
                  }}
                />
                <CSVLink
                  data={filter ? filteredList : purchaseVoucherData}
                  headers={headers}
                  filename="Purchase Credit Note Voucher Report.csv"
                  className="hidden"
                  ref={csvDownloadRef}
                  target="_blank"
                />
                <FaFileCsv
                  className="csv-Goodsreport"
                  style={{ cursor: "pointer" }}
                  onClick={(event) => {
                    convertToCsv(event);
                  }}
                />
              </div>

              <div className="search-name-Voucherreport">
                <input
                  placeholder="Search by Firm Name, Cn Ref No."
                  onChange={filterPurchaseReportSearch}
                />
              </div>
            </div>
            <div className="table-customer-Voucherreport">
              <table id="customers-Voucherreport">
                <tr className="table-customer-heading-Voucherreport">
                  <th>S.No</th>
                  <th>Firm Name </th>
                  <th>Date</th>
                  <th>Credit Note Ref No.</th>
                  <th>Region</th>
                  <th>Type</th>
                  {/* <th>Amount </th> */}
                  <th>Discount</th>
                  <th>SGST</th>
                  <th>CGST</th>
                  <th>IGST</th>
                  <th>CESS</th>
                  <th>Tax Amt</th>
                  <th>Credit Note Amt</th>
                  {/* <th>Sub Total</th> */}
                </tr>
                {ele}
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

/** @format */

import { React, useState } from "react";
import "../ProductGenerater/productgenerate.css";
// import Header from "./ProductGeneratorHeader";
import axios from "axios";

import Layout from "../../Layout/Layout";
import { FaBars } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const ProductGenerate = () => {
  const [formData, setFormData] = useState({});

  let navigate = useNavigate();

  console.log("formData", formData);

  const collectData = async () => {
    try {
      // token
      let token = localStorage.getItem("x-api-key");
      let result = await axios.post(
        "api/user/addProductVertTwo/addProd",
        {
          formData,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": token,
            "x-api-plan": "SaaS",
          },
        }
      );

      // we are storing local storage for long time to know about user information signup details
      // result = await result.json();
      // console.log(result, "result");

      if (result.data.message === "Product created successfully") {
        Swal.fire("Genereted!", result.data.message, "success");
        // navigate("/user/productlist");
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: result.data.message,
          // footer: '<a href="">Why do I have this issue?</a>'
        });
      }
    } catch (error) {
      console.error(error.result.data.message);
    }
  };

  // Get all the input fields, textarea, and the Submit button
  const inputFields = Array.from(
    document.querySelectorAll('input[type="text"], textarea')
  );
  const submitButton = document.querySelector(".button-sumbit-purchase-gen");

  // Add an event listener to each input field and textarea
  inputFields.forEach((input, index) => {
    input.addEventListener("keyup", (event) => {
      if (event.key === "Enter") {
        // Prevent the default behavior of the Enter key
        event.preventDefault();

        // If there's a next input field or textarea, set focus to it
        if (index < inputFields.length - 1) {
          inputFields[index + 1].focus();
        } else {
          // If the current input is the last one, focus on the Submit button
          submitButton.focus();
        }
      }
    });
  });

  return (
    <div>
      <Layout heading_1="Product Name" heading_2="Product Name Generator">
        <div className="container-product-generate">
          <div className="form-box-product-generate">
            <div className="c-form-product-generate">
              <div class="two-columns-product-generate">
                <fieldset>
                  <label
                    className="c-form-label-for-product-generator"
                    // style={{color:"black", fontWeight:'bold'}}
                  >
                    {" "}
                    Product Name
                  </label>
                  <input
                    placeholder="Enter Product Name"
                    type="text"
                    id="input1"
                    value={formData.productName} // Replace field1 with the actual field name
                    onChange={(e) =>
                      setFormData({ ...formData, productName: e.target.value })
                    }
                  />
                </fieldset>

                <fieldset>
                  <label className="c-form-label-for-product-generator">
                    Category
                  </label>
                  <input
                    placeholder="Enter Category"
                    type="text"
                    id="input2"
                    value={formData.category} // Replace field1 with the actual field name
                    onChange={(e) =>
                      setFormData({ ...formData, category: e.target.value })
                    }
                  />
                </fieldset>
              </div>
              <div className="two-columns-product-generate">
                <fieldset>
                  <label className="c-form-label-for-product-generator">
                    Product Description
                  </label>
                  {/* <textarea
                  placeholder="Enter Formulation"
                  type="text"
                  id="input1"
                  className="textarea"
                  required
                  value={description}
                  onChange={(e) => setdescription(e.target.value)}
                  style={{
                    boxShadow: "0px 5px 5px 0px rgba(0, 0, 0, 0.2)",
                    borderRadius: "7px",
                  }}
                /> */}
                  <textarea
                    placeholder="Product Description"
                    type="text"
                    id="input3"
                    value={formData.description} // Replace field1 with the actual field name
                    onChange={(e) =>
                      setFormData({ ...formData, description: e.target.value })
                    }
                  />
                </fieldset>
                <fieldset>
                  <label className="c-form-label-for-product-generator">
                    {" "}
                    Unit Pack
                  </label>
                  {/* <input
                  placeholder="Enter Unit Packing "
                  style={{ height: "2rem" }}
                  type="text"
                  id="input1"
                  className="c-form-input inputFeild"
                  required
                  value={unitPacking}
                  onChange={(e) => setunitPacking(e.target.value)}
                /> */}
                  <input
                    placeholder="Enter Unit Packing "
                    type="text"
                    id="input4"
                    value={formData.unitPacking} // Replace field1 with the actual field name
                    onChange={(e) =>
                      setFormData({ ...formData, unitPacking: e.target.value })
                    }
                  />
                </fieldset>
              </div>
              <div className="two-columns-product-generate">
                <fieldset>
                  <label className="c-form-label-for-product-generator">
                    Brand
                  </label>
                  {/* <input
                  placeholder="Enter Brand"
                  style={{ height: "2rem" }}
                  type="text"
                  id="input1"
                  className="c-form-input inputFeild"
                  required
                  value={brand}
                  onChange={(e) => setbrand(e.target.value)}
                /> */}
                  <input
                    placeholder="Enter Brand"
                    type="text"
                    id="input5"
                    value={formData.brand} // Replace field1 with the actual field name
                    onChange={(e) =>
                      setFormData({ ...formData, brand: e.target.value })
                    }
                  />
                </fieldset>
                <fieldset>
                  <label className="c-form-label-for-product-generator">
                    HSN/SAC Code
                  </label>
                  {/* <input
                  placeholder="Enter HSN/SAC Code"
                  style={{ height: "2rem" }}
                  type="text"
                  id="input1"
                  className="c-form-input  inputFeild"
                  required
                  value={hsn_sac_code}
                  onChange={(e) => sethsn_sac_code(e.target.value)}
                ></input> */}
                  <input
                    id="input6"
                    placeholder="Enter HSN/SAC Code"
                    type="text"
                    value={formData.hsn_sac_code} // Replace field1 with the actual field name
                    onChange={(e) =>
                      setFormData({ ...formData, hsn_sac_code: e.target.value })
                    }
                  />
                </fieldset>
              </div>
              <div className="two-columns-product-generate">
                <fieldset>
                  <label className="c-form-label-for-product-generator">
                    Formulation
                  </label>
                  <textarea
                    id="input7"
                    placeholder="Enter Formulation"
                    type="text"
                    value={formData.formulation} // Replace field1 with the actual field name
                    onChange={(e) =>
                      setFormData({ ...formData, formulation: e.target.value })
                    }
                  />
                </fieldset>
              </div>
              <button
                className="button-sumbit-purchase-gen"
                onClick={collectData}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default ProductGenerate;

import "./SalesReport.css";
import axios from "axios";
import { React, useEffect, useState, useRef } from "react";
import { FaRegFilePdf } from "react-icons/fa";
import { FaFileCsv, FaRegCopy } from "react-icons/fa";
import Swal from "sweetalert2";

// import SalesReportHeader from "./SalesReportHeader";
import Layout from "../../Layout/Layout.js";

// import value from "../SalesReport/value";

import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { CSVLink } from "react-csv";

//============== Headers for csv file=======================//
const headers = [
  { label: "Firm Name", key: "firmName" },
  { label: "Date", key: "date" },
  { label: "Invoice No.", key: "invoiceNo" },
  { label: "Region", key: "region" },
  { label: "Type", key: "type" },
  { label: "Discount Amt.", key: "discAmt" },
  { label: "SGST", key: "sgst" },
  { label: "CGST", key: "cgst" },
  { label: "IGST", key: "igst" },
  { label: "CESS", key: "cess" },
  { label: "Total Tax Amt.", key: "totalTax" },
  { label: "G/R Amount", key: "" },
  { label: "Credit Note Amt.", key: "cnVoucher" },
  { label: "Sub Total", key: "subTotal" },
  { label: "Payable Amt.", key: "grandTotal" },
];

export default function Salesreport() {
  const [SalesReportData, setSalesReportData] = useState([]);

  useEffect(() => {
    salesReportData();
  }, []);

  const salesReportData = async () => {
    try {
      console.log("okay its running");
      //token
      let token = localStorage.getItem("x-api-key");
      let response = await axios.post(
        "api/user/sell/salesReports",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": token,
            "x-api-plan": "SaaS",
          },
        }
      );

      let result = response.data;
      console.log(result, "result");
      if (result.data) {
        setSalesReportData(result.data);
      } else {
        setSalesReportData([]);
      }
    } catch (error) {
      console.error(error.response.data.message);
    }
  };

  // code for filtering firm according to search bar;

  const [filteredList, setFilteredList] = new useState([...SalesReportData]);
  const [filter, setFilter] = useState(false);
  const filterSellReportSearch = (event) => {
    setFilter(true);
    let updatedList = [...SalesReportData];
    updatedList = updatedList.filter((item) => {
      return (
        item.firmName
          .toLowerCase()
          .indexOf(event.target.value.toLowerCase()) !== -1 ||
        item.properInvoice
          .toLowerCase()
          .indexOf(event.target.value.toLowerCase()) !== -1
      );
    });
    setFilteredList(updatedList);
  };

  //==========  converting data to pdf=========== //

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 350;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Sales Report";
    const headers = [
      [
        "S.No.",
        "Firm Name",
        "Date",
        "Invoice No.",
        "Region",
        "Type",
        "Discount",
        "SGST",
        "CGST",
        "IGST",
        "CESS.",
        "Total Tax Amt.",
        "G/R Amount",
        "Credit Note Amt.",
        "Sub Total",
        "Payable Amt.",
      ],
    ];
    // console.log(SalesReportData);
    let data;
    if (filter) {
      data = filteredList.map((elt, index) => [
        index + 1,
        elt.firmName,
        elt.date,
        elt.invoiceNo,
        elt.region,
        elt.type,
        elt.discAmt,
        elt.sgst,
        elt.cgst,
        elt.igst,
        elt.cess,
        elt.totalTax,
        elt.goodReturnAmt,
        elt.cnVoucher,
        elt.subTotal,
        elt.grandTotal,
      ]);
    } else {
      data = SalesReportData.map((elt, index) => [
        index + 1,
        elt.firmName,
        elt.date,
        elt.invoiceNo,
        elt.region,
        elt.type,
        elt.discAmt,
        elt.sgst,
        elt.cgst,
        elt.igst,
        elt.cess,
        elt.totalTax,
        elt.goodReturnAmt,
        elt.cnVoucher,
        elt.subTotal,
        elt.grandTotal,
      ]);
    }

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    autoTable(doc, content);
    doc.save("Sales Report.pdf");
  };

  //========= converting filtered data to csv============
  const csvDownloadRef = useRef(null);
  const convertToCsv = () => {
    setTimeout(() => {
      csvDownloadRef.current.link.click();
    }, 500);
  };

  const ele = filter
    ? filteredList.map(function (item, index) {
        return (
          <tr>
            <td>
              <span className="span-width-sales-report">{index + 1}</span>
            </td>
            <td>
              <span className="span-width-sales-report">{item.firmName}</span>
            </td>
            <td>
              <span className="span-width-sales-report">{item.date}</span>
            </td>
            <td>
              <span className="span-width-sales-report">
                {item.properInvoice}
              </span>
            </td>
            <td>
              <span className="span-width-sales-report">{item.region}</span>
            </td>
            <td>
              <span className="span-width-sales-report">{item.type}</span>
            </td>
            <td>
              <span className="span-width-sales-report">{item.discAmt}</span>
            </td>
            <td>
              <span className="span-width-sales-report">{item.sgst}</span>
            </td>
            <td>
              <span className="span-width-sales-report">{item.cgst}</span>
            </td>
            <td>
              <span className="span-width-sales-report">{item.igst}</span>
            </td>
            <td>
              <span className="span-width-sales-report">{item.cess}</span>
            </td>
            <td>
              <span className="span-width-sales-report">{item.totalTax}</span>
            </td>
            <td>
              <span className="span-width-sales-report">
                {item.goodReturnAmt}
              </span>
            </td>
            <td>
              <span className="span-width-sales-report">{item.cnVoucher}</span>
            </td>
            <td>
              <span className="span-width-sales-report">{item.subTotal}</span>
            </td>
            <td>
              <span className="span-width-sales-report">{item.grandTotal}</span>
            </td>
          </tr>
        );
      })
    : SalesReportData.map(function (item, index) {
        return (
          <tr>
            <td>
              <span className="span-width-sales-report">{index + 1}</span>
            </td>
            <td>
              <span className="span-width-sales-report">{item.firmName}</span>
            </td>
            <td>
              <span className="span-width-sales-report">{item.date}</span>
            </td>
            <td>
              <span className="span-width-sales-report">
                {item.properInvoice}
              </span>
            </td>
            <td>
              <span className="span-width-sales-report">{item.region}</span>
            </td>
            <td>
              <span className="span-width-sales-report">{item.type}</span>
            </td>
            <td>
              <span className="span-width-sales-report">{item.discAmt}</span>
            </td>
            <td>
              <span className="span-width-sales-report">{item.sgst}</span>
            </td>
            <td>
              <span className="span-width-sales-report">{item.cgst}</span>
            </td>
            <td>
              <span className="span-width-sales-report">{item.igst}</span>
            </td>
            <td>
              <span className="span-width-sales-report">{item.cess}</span>
            </td>
            <td>
              <span className="span-width-sales-report">{item.totalTax}</span>
            </td>
            <td>
              <span className="span-width-sales-report">
                {item.goodReturnAmt}
              </span>
            </td>
            <td>
              <span className="span-width-sales-report">{item.cnVoucher}</span>
            </td>
            <td>
              <span className="span-width-sales-report">{item.subTotal}</span>
            </td>
            <td>
              <span className="span-width-sales-report">{item.grandTotal}</span>
            </td>
          </tr>
        );
      });

  return (
    <Layout heading_1="Sales" heading_2="Sales Report ">
      <div className="main-salesreport-file">
        <div className="salesreport-main">
          {/* <SalesReportHeader /> */}
          <div className="salesreport">
            {/* <div className="salesreport-header" style={{ height: "34px" }}>
            <h4>Sales Report </h4>
          </div> */}

            <div className="table-top-salesreport">
              <div className="show-name-salesreport">
                <label htmlFor="show">Show : </label>
                <select
                  id="show showSelect"
                  style={{
                    height: "36px",
                    width: "69px",
                    marginLeft: "1rem",
                    marginRight: "1rem",
                    borderRadius: "5px",
                  }}
                >
                  <option>All</option>
                </select>
                <FaRegFilePdf
                  className="pdf-Sellreport"
                  size={22}
                  style={{ cursor: "pointer" }}
                  onClick={(event) => {
                    exportPDF();
                  }}
                />
                <CSVLink
                  data={filter ? filteredList : SalesReportData}
                  headers={headers}
                  filename="Sell Report.csv"
                  className="hidden"
                  ref={csvDownloadRef}
                  target="_blank"
                />
                <FaFileCsv
                  className="csv-Sellreport"
                  size={22}
                  style={{ cursor: "pointer" }}
                  onClick={(event) => {
                    convertToCsv(event);
                  }}
                />
              </div>

              <div className="search-name-salesreport">
                <input
                  placeholder="Search by Firm Name, Invoice No."
                  onChange={filterSellReportSearch}
                />
              </div>
            </div>
            <div className="table-customer-salesreport">
              <table id="customers-salesreport">
                <tr className="table-customer-heading-salesreport">
                  <th>S.No</th>
                  <th>Firm Name </th>
                  <th>Date</th>
                  <th>Invoice No.</th>
                  <th>Region</th>
                  <th>Type</th>
                  <th>Discount</th>
                  <th>SGST</th>
                  <th>CGST</th>
                  <th>IGST</th>
                  <th>CESS</th>
                  <th>Tax Amt</th>
                  <th>G/R Amt.</th>
                  <th>CN Amt</th>
                  <th>Sub Total</th>
                  <th>Payable Amt.</th>
                </tr>

                {ele}
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
